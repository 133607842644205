import React from "react";
import {
    Card,
    Grid
} from "@mui/material";
import CentreAboutDetailPage from "./centreDetail/CentreAboutDetailPage";
import CentreFacilityPage from "./centreDetail/CentreFacilityPage";
import CentreClassesPage from "./centreDetail/CentreClassesPage";
import CentreLogoUploadDialog from "./../../component/form/centreComponent/CentreLogoUploadDialog";
import CentrePageHeader from "./centreDetail/header/CentrePageHeader";
import CentrePostPage from "./centreDetail/CentrePostPage";
import CentreStudentPage from "./centreDetail/CentreStudentPage";
import CentreTutorPage from "./centreDetail/CentreTutorPage";
import LoadingPage from "./LoadingPage";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import { getCentreInfo } from "./../../redux/CentreManagement";
import { pushRedirect } from "./../../redux/Redirect";
import { SpaceHolder } from "./../../component/common/Others";
import { styled } from "@mui/material/styles";
import { useAuthenticationState } from "./../../redux/authentication";
import { useCentreManagementState } from "./../../redux/CentreManagement";
import { useEnrolmentState, loadUserEnrolment } from "./../../redux/Enrolment";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce';

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export default function CentrePage() {

    let [searchParams] = useSearchParams();
    let { centreId } = useParams();
    const { t } = useTranslation(["centre", "general"]);
    const [logoDialogOpen, setLogoDialogOpen] = React.useState(false);
    const SEARCH_PARAM_TAB = searchParams.get("tab") || "about";
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const CURRENT_ROLE = useAuthenticationState().currentRole;
    const CENTRE_REQUEST = useCentreManagementState().request;
    const ENROLMENT_REQUEST = useEnrolmentState().request;
    const IS_PLATFORM_ADMIN = CURRENT_ROLE.roleName === "platform_admin";
    const IS_BELONGS_TO_CENTRE = CURRENT_ROLE.centreId == centreId;
    const IS_CENTRE_ADMIN = CURRENT_ROLE.roleName === "centre_admin";
    const IS_TUTOR = CURRENT_ROLE.roleName === "tutor";
    const { enqueueSnackbar } = useSnackbar();

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Card style={{ minHeight: '90%', width: '90%' }}>
                    Thank you for your subscription
                </Card>
            </DashboardRootContainer>
        </React.Suspense>
    );
}
