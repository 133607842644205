import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { uploadCentrePhotos } from "./../../../redux/CentreManagement";
import ImageUploadDialog from "./../../../component/common/ImageUploadDialog";

export default function CentreUploadImageDialog({ open, setOpen }) {
    const { t } = useTranslation();
    let { centreId } = useParams();

    const uploadedLogo = (acceptedFiles) => {
        const formData = new FormData();
        for (let file of acceptedFiles) {
            formData.append("files", file);
        }
        formData.append("centreId", centreId);
        uploadCentrePhotos(formData);
        setOpen(false);
    }

    return (
        <ImageUploadDialog setOpen={setOpen} open={open} title={t("general:dialog_title-upload_photos")} uploadedImageHandler={uploadedLogo} />
    );
}
