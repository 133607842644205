import * as YUP from "yup";

function createClassBillFormSchema() {
    return YUP.object().shape({
        dueDate: YUP.date().required("general:validation-mandatory").nullable().typeError("general:validation-date"),
        // billAmount: YUP.number().nullable().required("general:validation-mandatory"),
        billDetails: YUP.array().of(
            YUP.object().shape({
                desc: YUP.string()
                    .required("general:validation-mandatory"),
                amount: YUP.number()
                    .required("general:validation-mandatory")
            })
        )
    });
}

const initClassBillForm = (currentClass,t) => {

    return {
        classId: currentClass._id,
        studentId: '',
        issueDate: new Date(),
        dueDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        currency: "hkd",
        billAmount: currentClass.classFee,
        billDetails: [
            {
                desc: t("bill:attribute_value-item-description",{entity:currentClass.name}),
                amount: currentClass.classFee
            }
        ],
        payments: [],
        paidAmount: 0,
        paidOn: null,
        status: "unpaid"
    }
}

const CLASS_BILL_FIELD = {
    "dueDate": "dueDate",
    "billAmount" : "billAmount",
    "billDetails" : "billDetails"
}

export {
    createClassBillFormSchema, initClassBillForm,
    CLASS_BILL_FIELD
}