import { createReduxModule } from "hooks-for-redux"
import { pushRedirect } from "./Redirect";
import {
    getCentreAdminEnrolmentRequest,
    sendCentreAdminInvitationEmail,
    removeCentreAdminRequest,
    addCentreEnrolmentRequest,
    getCentreEnrolmentRequest,
    getUserEnrolmentRequest,
    acceptTutorEnrolmentRequest,
    rejectTutorEnrolmentRequest,
    removeTutorEnrolmentRequest,
    acceptStudentEnrolmentRequest,
    rejectStudentEnrolmentRequest,
    removeStudentEnrolmentRequest
} from "./../api/enrolmentAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
function initialState() {
    return {
        updateAction: [],
        centreId: null,
        centreAdmins: [],
        enrolmentId: "",
        enrolmenTotBeDeleted: null,
        enrolmentToBeAdded: null,
        enroledTutor: [],
        enroledTutorCount: 0,
        pendingTutorEnrolment: [],
        enroledStudent: [],
        enroledStudentCount: 0,
        pendingStudentEnrolment: [],
        enrolmentType: null,
        pendingEnrolment: [],
        pendingEnrolmentMap: {},
        centrePendingEnrolment: {},
        centreToBeSaved: null,
        searchCriteria: {},
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "ADD_ADMINISTRATOR": "ADD_ADMINISTRATOR",
    "GET_CENTRE_ADMIN": "GET_CENTRE_ADMIN",
    "REMOVE_ADMINISTRATOR": "REMOVE_ADMINISTRATOR",
    "ADD_CENTRE_ENROLMENT": "ADD_CENTRE_ENROLMENT",
    "LOAD_CENTRE_TUTOR_ENROLMENT": "LOAD_CENTRE_TUTOR_ENROLMENT",
    "LOAD_CENTRE_STUDENT_ENROLMENT": "LOAD_CENTRE_STUDENT_ENROLMENT",
    "LOAD_USER_ENROLMENT": "LOAD_USER_ENROLMENT",
    "ACCEPT_TUTOR_ENROLMENT": "ACCEPT_TUTOR_ENROLMENT",
    "REJECT_TUTOR_ENROLMENT": "REJECT_TUTOR_ENROLMENT",
    "REMOVE_TUTOR_ENROLMENT": "REMOVE_TUTOR_ENROLMENT",
    "ACCEPT_STUDENT_ENROLMENT": "ACCEPT_STUDENT_ENROLMENT",
    "REJECT_STUDENT_ENROLMENT": "REJECT_STUDENT_ENROLMENT",
    "REMOVE_STUDENT_ENROLMENT": "REMOVE_STUDENT_ENROLMENT"
};

function refreshPendingMapAction(state, payload) {
    let result = {};
    for (let role of state.pendingEnrolment) {
        if (role.profileId && role.centre) {
            let centreId = role.centre;
            let profileId = role.profileId;
            if (!result[centreId]) {
                result[centreId] = {};
            }
            result[centreId][profileId] = true;
        }
    }
    return result;
}

export const [useEnrolmentState, {
    setUpdating,
    getCentreAdminEnrolment, getCentreAdminEnrolmentSuccess, getCentreAdminEnrolmentFail,
    inviteCentreAdmin, inviteCentreAdminSuccess, inviteCentreAdminFail,
    removeCentreAdmin, removeCentreAdminSuccess, removeCentreAdminFail,
    addCentreEnrolment, addCentreEnrolmentSuccess, addCentreEnrolmentFail,
    loadUserEnrolment, loadUserEnrolmentSuccess, loadUserEnrolmentFail,
    loadCentreTutorEnrolment, loadCentreTutorEnrolmentSuccess, loadCentreTutorEnrolmentFail,
    loadCentreStudentEnrolment, loadCentreStudentEnrolmentSuccess, loadCentreStudentEnrolmentFail,
    acceptTutorEnrolment, acceptTutorEnrolmentSuccess, acceptTutorEnrolmentFail,
    rejectTutorEnrolment, rejectTutorEnrolmentSuccess, rejectTutorEnrolmentFail,
    removeTutorEnrolment, removeTutorEnrolmentSuccess, removeTutorEnrolmentFail,
    acceptStudentEnrolment, acceptStudentEnrolmentSuccess, acceptStudentEnrolmentFail,
    rejectStudentEnrolment, rejectStudentEnrolmentSuccess, rejectStudentEnrolmentFail,
    removeStudentEnrolment, removeStudentEnrolmentSuccess, removeStudentEnrolmentFail,
    refreshPendingMap
},
    enrolmentState
] = createReduxModule(
    "enrolmentState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getCentreAdminEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_ADMIN),
        "centreId": payload.centreId
    }),
    getCentreAdminEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreAdmins: payload.data
    }),
    getCentreAdminEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    inviteCentreAdmin: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_ADMINISTRATOR),
        enrolmentToBeAdded: {
            email: payload.username,
            centreId: payload.centreId
        }
    }),
    inviteCentreAdminSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        enrolmentToBeAdded: null,
        centreToBeSaved: {}
    }),
    inviteCentreAdminFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeCentreAdmin: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_ADMINISTRATOR),
        enrolmenTotBeDeleted: payload
    }),
    removeCentreAdminSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        enrolmenTotBeDeleted: {},
        centreToBeSaved: {}
    }),
    removeCentreAdminFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentreEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_CENTRE_ENROLMENT),
        enrolmentToBeAdded: payload
    }),
    addCentreEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        enrolmentToBeAdded: {}
    }),
    addCentreEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    loadUserEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.LOAD_USER_ENROLMENT),
        status: payload.status
    }),
    loadUserEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        pendingEnrolment: payload.data
    }),
    loadUserEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    loadCentreTutorEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.LOAD_CENTRE_TUTOR_ENROLMENT),
        enrolmentType: "tutor",
        centreId: payload.centreId,
        searchCriteria: payload.searchCriteria
    }),
    loadCentreTutorEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        enroledTutor: payload.data.activeTutor,
        pendingTutorEnrolment: payload.data.pendingTutor,
        enroledTutorCount: payload.data.tutorCount
    }),
    loadCentreTutorEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    loadCentreStudentEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.LOAD_CENTRE_STUDENT_ENROLMENT),
        enrolmentType: "student",
        centreId: payload.centreId,
        searchCriteria: payload.searchCriteria
    }),
    loadCentreStudentEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        enroledStudent: payload.data.activeStudent,
        pendingStudentEnrolment: payload.data.pendingStudent,
        enroledStudentCount: payload.data.studentCount
    }),
    loadCentreStudentEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    acceptTutorEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ACCEPT_TUTOR_ENROLMENT),
        enrolmentId: payload.enrolmentId
    }),
    acceptTutorEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    acceptTutorEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    rejectTutorEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REJECT_TUTOR_ENROLMENT),
        enrolmentId: payload.enrolmentId
    }),
    rejectTutorEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    rejectTutorEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeTutorEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_TUTOR_ENROLMENT),
        enrolmentId: payload.enrolmentId
    }),
    removeTutorEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    removeTutorEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    acceptStudentEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ACCEPT_STUDENT_ENROLMENT),
        enrolmentId: payload.enrolmentId
    }),
    acceptStudentEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    acceptStudentEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    rejectStudentEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REJECT_STUDENT_ENROLMENT),
        enrolmentId: payload.enrolmentId
    }),
    rejectStudentEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    rejectStudentEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeStudentEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_STUDENT_ENROLMENT),
        enrolmentId: payload.enrolmentId
    }),
    removeStudentEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    removeStudentEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    refreshPendingMap: (state, payload) => Object.assign({}, state,
        {
            "pendingEnrolmentMap": refreshPendingMapAction(state, payload)
        })

});

const UPDATE_ACTION_HANDLER = {
    "ADD_ADMINISTRATOR": {
        handler: async (currentState) => {
            var result = await sendCentreAdminInvitationEmail(currentState.enrolmentToBeAdded);
            if (result) {
                if (result.success) {
                    inviteCentreAdminSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message
                    });
                } else {
                    inviteCentreAdminFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: inviteCentreAdminFail
    },
    "GET_CENTRE_ADMIN": {
        handler: async (currentState) => {
            var result = await getCentreAdminEnrolmentRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    getCentreAdminEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getCentreAdminEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreAdminEnrolmentFail
    },
    "REMOVE_ADMINISTRATOR": {
        handler: async (currentState) => {
            let result = await removeCentreAdminRequest(currentState.enrolmenTotBeDeleted);
            if (result) {
                if (result.success) {
                    removeCentreAdminSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    removeCentreAdminFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeCentreAdminFail
    },
    "ADD_CENTRE_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await addCentreEnrolmentRequest(currentState.enrolmentToBeAdded);
            if (result) {
                if (result.success) {
                    addCentreEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    addCentreEnrolmentFail({
                        "message": result.message,
                        "requestStatus": result.status,
                        "action": action,
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeCentreAdminFail
    },
    "LOAD_CENTRE_TUTOR_ENROLMENT": {
        handler: async (currentState) => {
            let result = await getCentreEnrolmentRequest(currentState.centreId, currentState.enrolmentType, currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    loadCentreTutorEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    loadCentreTutorEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: loadCentreTutorEnrolmentFail
    }, "LOAD_CENTRE_STUDENT_ENROLMENT": {
        handler: async (currentState) => {
            let result = await getCentreEnrolmentRequest(currentState.centreId, currentState.enrolmentType, currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    loadCentreStudentEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    loadCentreStudentEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: loadCentreStudentEnrolmentFail
    },
    "LOAD_USER_ENROLMENT": {
        handler: async (currentState) => {
            let result = await getUserEnrolmentRequest(currentState.status);
            if (result) {
                if (result.success) {
                    loadUserEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    refreshPendingMap({});
                } else {
                    loadUserEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: loadUserEnrolmentFail
    },
    "ACCEPT_TUTOR_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await acceptTutorEnrolmentRequest({ enrolmentId: currentState.enrolmentId });
            if (result) {
                if (result.success) {
                    acceptTutorEnrolmentSuccess({
                        "success": true,
                        action: action,
                        requestStatus: result.status,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    if (currentState.centreId) {
                        loadCentreTutorEnrolment({
                            enrolmentType: currentState.enrolmentType,
                            centreId: currentState.centreId,
                            searchCriteria: currentState.searchCriteria
                        });
                    }
                } else {
                    acceptTutorEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: acceptTutorEnrolmentFail
    },
    "REJECT_TUTOR_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await rejectTutorEnrolmentRequest({ enrolmentId: currentState.enrolmentId });
            if (result) {
                if (result.success) {
                    rejectTutorEnrolmentSuccess({
                        "success": true,
                        action: action,
                        "error": result.error,
                        requestStatus: result.status,
                        "message": result.message,
                        "data": result.data
                    });
                    if (currentState.centreId) {
                        loadCentreTutorEnrolment({
                            enrolmentType: currentState.enrolmentType,
                            centreId: currentState.centreId,
                            searchCriteria: currentState.searchCriteria
                        });
                    }
                } else {
                    rejectTutorEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: rejectTutorEnrolmentFail
    },
    "REMOVE_TUTOR_ENROLMENT": {
        handler: async (currentState) => {
            let result = await removeTutorEnrolmentRequest({ enrolmentId: currentState.enrolmentId });
            if (result) {
                if (result.success) {
                    removeTutorEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    if (currentState.centreId) {
                        loadCentreTutorEnrolment({
                            enrolmentType: currentState.enrolmentType,
                            centreId: currentState.centreId,
                            searchCriteria: currentState.searchCriteria
                        });
                    }
                    pushRedirect({ path: "/" })
                } else {
                    removeTutorEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeTutorEnrolmentFail
    }, "ACCEPT_STUDENT_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await acceptStudentEnrolmentRequest({ enrolmentId: currentState.enrolmentId });
            if (result) {
                if (result.success) {
                    acceptStudentEnrolmentSuccess({
                        "success": true,
                        action: action,
                        "error": result.error,
                        requestStatus: result.status,
                        "message": result.message,
                        "data": result.data
                    });
                    if (currentState.centreId) {
                        loadCentreStudentEnrolment({
                            enrolmentType: currentState.enrolmentType,
                            centreId: currentState.centreId,
                            searchCriteria: currentState.searchCriteria
                        });
                    }
                } else {
                    acceptStudentEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: acceptStudentEnrolmentFail
    },
    "REJECT_STUDENT_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await rejectStudentEnrolmentRequest({ enrolmentId: currentState.enrolmentId });
            if (result) {
                if (result.success) {
                    rejectStudentEnrolmentSuccess({
                        "success": true,
                        action: action,
                        requestStatus: result.status,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    if (currentState.centreId) {
                        loadCentreStudentEnrolment({
                            enrolmentType: currentState.enrolmentType,
                            centreId: currentState.centreId,
                            searchCriteria: currentState.searchCriteria
                        });
                    }
                } else {
                    rejectStudentEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: rejectStudentEnrolmentFail
    },
    "REMOVE_STUDENT_ENROLMENT": {
        handler: async (currentState) => {
            let result = await removeStudentEnrolmentRequest({ enrolmentId: currentState.enrolmentId });
            if (result) {
                if (result.success) {
                    removeStudentEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    if (currentState.centreId) {
                        loadCentreStudentEnrolment({
                            enrolmentType: currentState.enrolmentType,
                            centreId: currentState.centreId,
                            searchCriteria: currentState.searchCriteria
                        });
                    }
                    pushRedirect({ path: "/" })
                } else {
                    removeStudentEnrolmentFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeStudentEnrolmentFail
    }
}

enrolmentState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});