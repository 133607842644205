import React from 'react';
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useAccountManagementState, setUpdating, getUserInformation, updateUserInfo, UPDATE_ACTION } from "./../../../redux/AccountManagement";
import { useOptionsState } from "./../../../redux/Options";
import { yupResolver } from "@hookform/resolvers/yup";
import { INITIAL_USER_FORM, createUserFormSchema } from "./../../form/model/UserFormModel";
import { useForm, FormProvider } from "react-hook-form";

import AccountInformationEditForm from "./../../form/AccountInformationEditForm";


export default function AccountInformation() {
    const { t } = useTranslation(["account", "general"]);
    const { enqueueSnackbar } = useSnackbar();
    const LOCATION = useLocation();
    const USER_INFORMATION = useAccountManagementState().user;
    const USERNAME_FROM_ACCOUNT_MANAGEMENT = useAccountManagementState().username;
    const AUTH_STATE = useAuthenticationState();
    const REQUEST = useAccountManagementState().request || {};
    const VALID_VALUES = { SYSTEM_LANGUAGE: useOptionsState().systemLanguage.validValues };
    const USER_FORM_SCHEMA = createUserFormSchema(VALID_VALUES.SYSTEM_LANGUAGE);
    const accountInformationFormMethod = useForm({
        resolver: yupResolver(USER_FORM_SCHEMA),
        defaultValues: INITIAL_USER_FORM
    });
    const LOCATION_USERNAME = LOCATION.state ? LOCATION.state.username : null;

    React.useEffect(() => {
        if (REQUEST && REQUEST.lastAction == UPDATE_ACTION.SAVE_USER_INFO) {
            if (REQUEST.error && REQUEST.message && REQUEST.message != "") {
                enqueueSnackbar(t(REQUEST.message), {
                    "variant": "error"
                })
            } else {
                if (REQUEST.success && REQUEST.message && REQUEST.message != "") {
                    enqueueSnackbar(t(REQUEST.message), {
                        "variant": "success"
                    });
                    setUpdating({
                        updating: false,
                        message: null,
                        errors: null
                    });

                }
            }
        }
    }, [REQUEST])

    React.useEffect(() => {
        let username = AUTH_STATE.username;
        if (LOCATION.state) {
            let state = LOCATION.state;
            if (state.username) {
                username = LOCATION_USERNAME;
            }
        }

        if (USERNAME_FROM_ACCOUNT_MANAGEMENT != username) {
            getUserInformation(username);
        }
    }, [LOCATION_USERNAME]);

    React.useEffect(() => {
        if (USER_INFORMATION && USER_INFORMATION._id) {
            accountInformationFormMethod.reset(USER_INFORMATION);
        }
    }, [USER_INFORMATION])

    function submitUserInfoForm(data) {
        updateUserInfo({
            user: data
        });
    }


    return (
        <React.Fragment>
            <FormProvider  {...accountInformationFormMethod}>
                <form onSubmit={accountInformationFormMethod.handleSubmit(submitUserInfoForm)} >
                    <AccountInformationEditForm></AccountInformationEditForm>
                </form>
            </FormProvider>
        </React.Fragment>
    )
}