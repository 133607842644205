import React from "react";
import GeneralPendingDialog from "./../../../common/GeneralPendingDialog";
import ClassCard from "./../../../page/class/ClassCard";

export default function PendingClassDialog({ title, pendingClassList, isDialogOpen, setIsDialogOpen }) {

    const openNewTab = (event, currentClass) => {
        window.open(`/class/${currentClass._id}`, "_blank");
    }

    return (
        <GeneralPendingDialog title={title} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}>
            {
                pendingClassList.map((currentClass) =>
                    <ClassCard
                        key={"class_card_" + currentClass._id}
                        cardOnClick={openNewTab}
                        currentClass={currentClass}
                    />)
            }
        </GeneralPendingDialog>
    )
}