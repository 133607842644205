import React from "react";
import {
	Grid, Button,
	Dialog, DialogTitle, DialogContent, DialogActions, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Row = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
}));

export default function ConfirmActionDialog({ title, actionName, open, setOpen, confirmOnClick, content }) {

	const { t } = useTranslation();

	const closeLogoDialog = (event) => {
		setOpen(false);
	}

	return (
		<Dialog open={open}
			onClose={closeLogoDialog}
			scroll="paper"
			aria-labelledby="responsive-dialog-title">
			<DialogTitle id="responsive-dialog-title">
				{title}
			</DialogTitle>
			<DialogContent dividers data-cy="edit_dialog_content">
				<Row>
					<p>{t("general:dialog_content-confirm_action")}</p>
					{ content && 
						<Typography variant="subtitle2" color={"secondary"} style={{ textAlign: 'center' }}>
							{ content }
						</Typography>
					}
				</Row>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Button autoFocus onClick={closeLogoDialog} color="success" variant="contained">
						{t("general:button-cancel")}
					</Button>
					<Button autoFocus onClick={(event) => {
						confirmOnClick.call(this, event);
						closeLogoDialog(event);
					}
					} color="error" variant="contained">
						{actionName}
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
