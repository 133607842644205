import React from 'react';
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useProfileState } from "./../../../redux/ProfileManagement";
import TutorFieldList from "./../../form/tutorComponent/TutorFieldList";
import PortraitUploader from "./../../form/commonComponent/PortraitUploader";
import TutorCards from "./TutorCards";
import { SuccessButton } from "./../../common/Buttons";
import { DialogFixedForm } from "./../../common/Forms";

export default function TutorProfiles({ tutorDialogOpen, handleTutorDialogClose,
  addTutorOnClick, tutorPhotoObject, setTutorPhotoObject }) {
  const { t } = useTranslation(["account", "general", "attribute"]);
  const { handleSubmit } = useFormContext();
  const [needRenderTutor, setNeedRenderTutor] = React.useState(false);
  const TUTOR_LIST = useProfileState().tutorProfiles;

  React.useEffect(() => {
    if (TUTOR_LIST && TUTOR_LIST.length > 0) {
      setNeedRenderTutor(true);
    } else {
      setNeedRenderTutor(false);
    }
  }, [TUTOR_LIST]);


  return (
    <React.Fragment>
      {
        needRenderTutor ? (<TutorCards />) : null
      }

      <Dialog
        open={tutorDialogOpen}
        onClose={handleTutorDialogClose}
        fullWidth
        scroll="paper"
        aria-labelledby="Tutor-profile-dialog-title"
      >

        <DialogFixedForm onSubmit={handleSubmit(addTutorOnClick)} noValidate>
          <DialogTitle id="Tutor-profile-dialog-title">
            {t("general:dialog_title-add_entity", { entity: t("attribute:tutor_profile") })}
          </DialogTitle>
          <DialogContent dividers>
            <PortraitUploader labelText={t("attribute:tutor-profile_picture")} id="edit_dialog_uploader" photoObject={tutorPhotoObject} setPhotoObject={setTutorPhotoObject} />
            <TutorFieldList></TutorFieldList>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Button onClick={handleTutorDialogClose} variant="outlined" color="primary">
                  {t("general:button-cancel")}
                </Button>
                <SuccessButton data-cy="btn_add_tutor_submit" variant="contained" type="submit">
                  {t("general:button-add")}
                </SuccessButton>
            </Grid>
          </DialogActions>
        </DialogFixedForm>
      </Dialog>
    </React.Fragment>)
}

