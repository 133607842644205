import React from 'react';
import { FormControl, TextField, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from "react-hook-form";
import TUTOR_PROFILE_FIELD from "./../../../const/TutorProfile";
import { INITIAL_TUTOR_FORM } from "./../model/TutorFormModel";
import { useOptionsState } from "./../../../redux/Options";
import { useFormContext } from "react-hook-form";
import DropdownItems from "./../commonComponent/DropdownItems";
import MultipleDropdownItems from "./../commonComponent/MultipleDropdownItems";
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

export default function TutorFieldList({ currentTutor }) {
    const {
        t
    } = useTranslation("attribute");
    const OPTIONS = {
        GENDER: useOptionsState().gender.options,
        MAIN_LANGUAGE: useOptionsState().mainLanguage.options,
        EDUCATION_LEVEL: useOptionsState().educationLevel.options,
        SUBJECT: useOptionsState().subject.options
    };
    const { control, formState: { errors }, setValue, reset } = useFormContext();

    React.useEffect(() => {
        if (currentTutor) {
            reset(currentTutor);
        } else {
            reset(INITIAL_TUTOR_FORM)
        }
    }, [currentTutor]);

    function ErrorMessage({ errors }) {
        if (errors) {
            return (<FormHelperText>{t(errors.message)}</FormHelperText>);
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <Controller
                name={TUTOR_PROFILE_FIELD.legalName}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:tutor-full_name_english")}
                        helperText={errors[TUTOR_PROFILE_FIELD.legalName] ? t(errors[TUTOR_PROFILE_FIELD.legalName].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.legalName] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={TUTOR_PROFILE_FIELD.engName}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:tutor-display_name-english")}
                        helperText={errors[TUTOR_PROFILE_FIELD.engName] ? t(errors[TUTOR_PROFILE_FIELD.engName].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.engName] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={TUTOR_PROFILE_FIELD.chiName}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:tutor-display_name-chinese")}
                        helperText={errors[TUTOR_PROFILE_FIELD.chiName] ? t(errors[TUTOR_PROFILE_FIELD.chiName].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.chiName] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.gender}
                control={control}
                render={
                    ({ field }) => <StyledFormControl
                        fullWidth
                        variant="outlined" data-cy="genderSelect"
                        error={errors[TUTOR_PROFILE_FIELD.gender] != null} >
                        <DropdownItems field={field}
                            options={OPTIONS.GENDER}
                            labelId="tutor-gender-label"
                            labelText={t("attribute:tutor-gender")} />
                        {errors[TUTOR_PROFILE_FIELD.gender] != null &&
                            <FormHelperText>{t(errors[TUTOR_PROFILE_FIELD.gender].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.birthday}
                control={control}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        fullWidth
                        disableFuture
                        openTo="year"
                        views={["year", "month", "day"]}
                        id={TUTOR_PROFILE_FIELD.birthday}
                        format="DD/MM/yyyy"
                        label={t("attribute:tutor-birthday")}
                        onChange={(newValue) => {
                            setValue(TUTOR_PROFILE_FIELD.birthday, newValue);
                        }}
                        renderInput={(params) => <StyledTextField {...params} fullWidth
                        helperText={errors[TUTOR_PROFILE_FIELD.birthday] ? t(errors[TUTOR_PROFILE_FIELD.birthday].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.birthday] != null}
                        />}
                    />
                )}
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.phone}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:tutor-phone_number")}
                        data-cy="input_tutor_phone"
                        helperText={errors[TUTOR_PROFILE_FIELD.phone] ? t(errors[TUTOR_PROFILE_FIELD.phone].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.phone] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.nationality}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:tutor-nationality")}
                        helperText={errors[TUTOR_PROFILE_FIELD.nationality] ? t(errors[TUTOR_PROFILE_FIELD.nationality].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.nationality] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.mainLanguage}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="mainLanguageSelect"
                        error={errors[TUTOR_PROFILE_FIELD.mainLanguage] != null}>
                        <MultipleDropdownItems field={field}
                            options={OPTIONS.MAIN_LANGUAGE}
                            labelId="tutor-mainLanguage-label"
                            labelText={t("attribute:tutor-main_language")} />
                        {
                            errors[TUTOR_PROFILE_FIELD.mainLanguage] ? <ErrorMessage errors={errors[TUTOR_PROFILE_FIELD.mainLanguage]} /> : null
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.educationLevel}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="educationLevelSelect"
                        error={errors[TUTOR_PROFILE_FIELD.educationLevel] != null}>
                        <DropdownItems field={field}
                            options={OPTIONS.EDUCATION_LEVEL}
                            labelId="tutor-educationLevel-label"
                            labelText={t("attribute:tutor-education-level")} />
                        {errors[TUTOR_PROFILE_FIELD.educationLevel] != null &&
                            <FormHelperText>{t(errors[TUTOR_PROFILE_FIELD.educationLevel].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.educationBackground}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:tutor-education-background")}
                        helperText={errors[TUTOR_PROFILE_FIELD.educationBackground] ? t(errors[TUTOR_PROFILE_FIELD.educationBackground].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.educationBackground] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.subject}
                control={control}

                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="subjectSelect"
                        error={errors[TUTOR_PROFILE_FIELD.subject] != null}>
                        <MultipleDropdownItems field={field}
                            options={OPTIONS.SUBJECT}
                            labelId="tutor-subject-label"
                            labelText={t("attribute:tutor-subjects")} />
                        {
                            errors[TUTOR_PROFILE_FIELD.subject] ? <ErrorMessage errors={errors[TUTOR_PROFILE_FIELD.subject]} /> : null
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={TUTOR_PROFILE_FIELD.remarks}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:tutor-remarks")}
                        helperText={errors[TUTOR_PROFILE_FIELD.remarks] != null ? t(errors[TUTOR_PROFILE_FIELD.remarks].message) : null}
                        error={errors[TUTOR_PROFILE_FIELD.remarks] != null}
                        {...field}
                    />
                }
            />

        </React.Fragment>
    );
}