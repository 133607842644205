import React, { useState, useEffect } from 'react';

const ProductDisplay = () => (
  <section>
    <div className="product">
      <div className="description">
        <h3>Product 1 to Gmail</h3>
        <h5>$1000 / product</h5>
      </div>
    </div>
    <form action="/payment/create-centre-payment" target="_blank" method="POST">
      <input type="hidden" name="productName" value="Product 1 to Gmail" />
      <input type="hidden" name="price" value="1000" />
      <input type="hidden" name="quantity" value="1" />
      <input type="hidden" name="accountId" value="acct_1NTZWhQx7E2AXnuB" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
    <div className="product">
      <div className="description">
        <h3>Product 1 to Test</h3>
        <h5>$1000 / product</h5>
      </div>
    </div>
    <form action="/payment/create-centre-payment" target="_blank" method="POST">
      <input type="hidden" name="productName" value="Product 1 to Test" />
      <input type="hidden" name="price" value="1000" />
      <input type="hidden" name="quantity" value="1" />
      <input type="hidden" name="accountId" value="acct_1KbeHbRMW33ngnCQ" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
    <div className="product">
      <div className="description">
        <h3>Product 2 to Test</h3>
        <h5>$1000 / product</h5>
      </div>
    </div>
    <form action="/payment/create-centre-payment" target="_blank" method="POST">
      <input type="hidden" name="productName" value="Product 2 to Test" />
      <input type="hidden" name="price" value="1000" />
      <input type="hidden" name="quantity" value="2" />
      <input type="hidden" name="accountId" value="acct_1KbeHbRMW33ngnCQ" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
    
    <div className="product">
      <div className="description">
        <h3>Product 2 to Gmail</h3>
        <h5>$1000 / product</h5>
      </div>
    </div>
    <form action="/payment/create-centre-payment" target="_blank" method="POST">
      <input type="hidden" name="productName" value="Product 2 to Gmail" />
      <input type="hidden" name="price" value="1000" />
      <input type="hidden" name="quantity" value="2" />
      <input type="hidden" name="accountId" value="acct_1KgLW7ROVrc6R7rw" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>

  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/payment/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function App() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
