import React from 'react';
import { useTranslation } from "react-i18next";
import {
	Grid, Button,
	Dialog, DialogTitle, DialogContent, DialogActions,
	TextField,
	Stack, Container
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DialogFixedForm } from "../../../common/Forms";
import { SuccessButton } from "../../../common/Buttons";
import SectionLabel from "./../../../display/styled/SectionLabel";
import CENTRE_NOTIFICATION_I18N from "../../../../const/CentreNotificationI18N";

export default function CentreNotificationDialog({ title, submitMethod, isDialogOpen, handleCloseDialog, formMethod }) {
	const { t } = useTranslation();
	return (
		<Dialog
			open={isDialogOpen}
			onClose={handleCloseDialog}
			scroll="paper"
			aria-labelledby="administrator-dialog-title"
			aria-describedby="administrator-dialog-description"
		>
			<DialogFixedForm
				onSubmit={formMethod.handleSubmit(submitMethod)} noValidate>
				<DialogTitle id="administrator-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent dividers>
					<Stack spacing={2.5}>
						{Object.keys(CENTRE_NOTIFICATION_I18N.systemLanguages).map((language) => 
							<Container key={language} item>
								<SectionLabel>{t(CENTRE_NOTIFICATION_I18N.systemLanguages[language])}</SectionLabel>
								<Controller
								name={`${language}_title`}
								defaultValue={''}
								control={formMethod.control}
								render={
									({ field }) => <TextField
										{...field}
										fullWidth
										variant="outlined"
										margin="normal"
										label={t("settings:Notification-template-title")}
										helperText={formMethod.formState.errors[`${language}_title`] ? t(formMethod.formState.errors[`${language}_title`].message) : null}
										error={formMethod.formState.errors[`${language}_title`] ? true : false}
										type="string"
									/>
								}
								/>
								<Controller
								name={`${language}_content`}
								defaultValue={''}
								control={formMethod.control}
								render={
									({ field }) => <TextField
										{...field}
										fullWidth
										variant="outlined"
										margin="normal"
										label={t("settings:Notification-template-content")}
										helperText={formMethod.formState.errors[`${language}_content`] ? t(formMethod.formState.errors[`${language}_content`].message) : null}
										error={formMethod.formState.errors[`${language}_content`] ? true : false}
										type="string"
										multiline
										rows={4}
									/>
								}
								/>
							</Container>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="space-between">
						<Button onClick={handleCloseDialog} variant="outlined" autoFocus>
							{t("general:button-cancel")}
						</Button>
						<SuccessButton type="onSubmit" variant="contained" >
							{t("general:button-save")}
						</SuccessButton>
					</Grid>
				</DialogActions>
			</DialogFixedForm>
		</Dialog>

	);
}
