import React from 'react';
import { Select, MenuItem, Typography, Stack, Container } from "@mui/material";
import { changeRole, updateCurrentRole, useAuthenticationState } from "./../../redux/authentication";
import { clearUsername as clearProfileUsername, useProfileState } from "./../../redux/ProfileManagement";
import { clearUsername as clearAccountManagementUsername } from "./../../redux/AccountManagement";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import useMediaQuery from '@mui/material/useMediaQuery';
import LimitedTextLabel from "./../../component/display/LimitedTextLabel";
import { nameInBracketStyle } from "./../../util/DisplayUtil";

const AddNewIndicator = styled(AddCircleIcon)(({ theme }) => ({
    "marginRight": "15px"
}));

const CommonTextSelect = styled(Select)(({ theme }) => ({
    color: theme.palette.primary.contrastText
}));


export default function RoleSwitcher() {
    const navigate = useNavigate();

    const AUTH_STATE = useAuthenticationState();
    const { t } = useTranslation("role");
    const ID_NAME_MAP = useProfileState().idNameMap;
    const STUDENT_PROFILES = useProfileState().studentProfiles || [];
    const TUTOR_PROFILES = useProfileState().tutorProfiles || [];
    let profileNotExists = TUTOR_PROFILES.length == 0 && STUDENT_PROFILES.length == 0;
    const handleChange = event => {
        switch (event.target.value) {
            case "add_new":
                navigate("/centres");
                changeRole({ role: event.target.value });
                break;
            default:
                changeRole({ role: event.target.value });
                updateCurrentRole();
                clearAccountManagementUsername();
                clearProfileUsername();
                navigate("/");
                break
        }

    };

    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));

    let centreVariant = "h6";
    let nameVariant = "body2";
    if (smDown) {
        centreVariant = "body2";
        nameVariant = "caption";
    }

    if (profileNotExists && AUTH_STATE.roles.length == 0) {
        return null;
    }

    return (
        <Container sx={{
            maxWidth: { xs: "calc(100vw - 50px)", sm: "68vw" },
            "marginLeft": { xs: "0px", sm: "15px" },
            "marginRight": { xs: "0px", sm: "15px" },
            color: (theme) => theme.palette.primary.contrastText
        }} >
            <CommonTextSelect sx={{ maxWidth: { xs: "100%" } }} variant="standard" inputProps={{
                name: 'role',
                id: 'role-switcher',
            }} value={AUTH_STATE.currentRole.roleId || "add_new"} onChange={handleChange} >


                {AUTH_STATE.roles.map((role) => {
                    let chiName = null;
                    let engName = null;
                    let nameObject = ID_NAME_MAP[role.profileId];
                    if (nameObject) {
                        chiName = nameObject.chiName;
                        engName = nameObject.engName;
                    }
                    let roleIndicator = t("role:" + role.role);
                    switch (role.role) {
                        case "student":
                            roleIndicator = (<ChildCareIcon />);
                            break;
                        case "tutor":
                            roleIndicator = (<SupportAgentIcon />);
                            break;
                        default:
                            break;
                    }
                    return (<MenuItem sx={{
                        "&.MuiMenuItem-root": {
                            "width": "100%",
                            overflow: "hidden"
                        }
                    }} value={role._id} key={role._id}>
                        {
                            role.centre && role.centre.length > 0 ? (
                                <Stack sx={{ "width": "100%" }}>
                                    <LimitedTextLabel component="span" variant={centreVariant}>
                                        {role.centre}
                                    </LimitedTextLabel>
                                    <LimitedTextLabel component="span" variant={nameVariant}>
                                        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                                            {roleIndicator}
                                            <LimitedTextLabel> {nameInBracketStyle(chiName, engName)}  </LimitedTextLabel>
                                        </Stack>
                                    </LimitedTextLabel>
                                </Stack>

                            ) : (<Stack>
                                <LimitedTextLabel variant={centreVariant}>
                                    {t("general:platform_name")}
                                </LimitedTextLabel>
                                <LimitedTextLabel variant={nameVariant} >
                                    {roleIndicator}
                                </LimitedTextLabel>
                            </Stack>)
                        }
                    </MenuItem>
                    );
                })}

                {profileNotExists ? null : (<MenuItem value={"add_new"} onClick={(event) => {
                    navigate("/centres");
                    changeRole({ role: "add_new" });
                }} key="add_new">
                    <Stack direction="row" alignItems="center">
                        <AddNewIndicator />
                        <Typography variant="h6">{t("role:add")}</Typography>
                    </Stack>
                </MenuItem>
                )}
            </CommonTextSelect>
        </Container>
    );
}

