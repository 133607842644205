import { createReduxModule } from "hooks-for-redux"

const initialState = {
	path: null
}

export const [useRedirectState, {
	pushRedirect,
	clearRedirect
},
	redirectState
] = createReduxModule(
	"redirectState",
	initialState, {
	pushRedirect: (redirectState, payload) => Object.assign({}, redirectState, {
		path: payload.path
	}),
	clearRedirect: (redirectState, payload) => Object.assign({}, redirectState, {
		path: null
	})
}
);
