import React from "react";
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from "moment";

const Label = styled(Typography)(({theme}) => ({
    marginRight: theme.spacing(1)
}));

const SeparatedLabelContainer = styled(Grid)(({theme}) => ({
        marginTop: "0px",
        marginBottom: "0px",
        paddingTop: "0px",
        paddingBottom: "0px"
}));

const Content = styled(Typography)(({theme}) => ({
        maxWidth: "300px",
        "wordWrap": "break-word"
}));

function SingleRowLabelText({ labelText, content }) {
    return (<Grid container alignItems="center">
        <Label color="textSecondary" component="span" >
            {labelText}:
        </Label>
        <Typography align="center" variant="body1" component="span">
            {content}
        </Typography>
    </Grid>)
}

function SeparatedLabelText({ labelText, content }) {
    return (<Grid container>
        <SeparatedLabelContainer container item>
            <Label color="textSecondary" component="span" >
                {labelText}:
            </Label>
        </SeparatedLabelContainer>
        <SeparatedLabelContainer container item>
            <Content variant="body1" component="span">
                {content}
            </Content>
        </SeparatedLabelContainer>
    </Grid>)
}

export default function LabelText(props) {
    var content = props.content;
    switch (props.type) {
        case "date":
            content = moment(props.content).format('YYYY-MM-DD');
            break;
        default:
            //do nothing
            break;
    }
    if (!props.isSeparated) {
        return (<SingleRowLabelText content={content} labelText={props.labelText} />);
    } else {
        return (<SeparatedLabelText content={content} labelText={props.labelText} />);
    }

}