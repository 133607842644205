import {
    createReduxModule
} from "hooks-for-redux";
import {
    getChatMessagesRequest,
    readChatMessagesRequest,
    getConversationIdRequest,
    getConversationsRequest,
    getCentreBroadcastListRequest,
    getUnreadMessagesCountRequest
} from "./../api/messageAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        currentConversationId: "",
        messages: [],
        roleConversations: [],
        centreConversations: [],
        updateAction: [],
        centreBroadcastList: [],
        unreadMessagesCount: 0,
        conversationUnreadCount: [],
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "READ_CHAT_MESSAGES": "READ_CHAT_MESSAGES",
    "GET_CHAT_MESSAGES": "GET_CHAT_MESSAGES",
    "GET_CONVERSATION_ID": "GET_CONVERSATION_ID",
    "GET_CONVERSATIONS": "GET_CONVERSATIONS",
    "GET_CENTRE_BROADCAST_LIST": "GET_CENTRE_BROADCAST_LIST",
    "GET_UNREAD_MESSAGES_COUNT": "GET_UNREAD_MESSAGES_COUNT"
};

export const [useMessageManagementState, {
    setUpdating,
    getChatMessages, getChatMessagesSuccess, getChatMessagesFail,
    readChatMessages, readChatMessagesSuccess, readChatMessagesFail,
    getConversationId, getConversationIdSuccess, getConversationIdFail,
    getConversations, getConversationsSuccess, getConversationsFail,
    getCentreBroadcastList, getCentreBroadcastListSuccess, getCentreBroadcastListFail,
    getUnreadMessagesCount, getUnreadMessagesCountSuccess, getUnreadMessagesCountFail
},
    messageManagementState
] = createReduxModule(
    "messageManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getChatMessages: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CHAT_MESSAGES),
        conversationId: payload.conversationId
    }),
    getChatMessagesSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        messages: payload.data.messages
    }),
    getChatMessagesFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    readChatMessages: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.READ_CHAT_MESSAGES),
        conversationId: payload.conversationId,
        from: payload.from
    }),
    readChatMessagesSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload)),
    readChatMessagesFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getConversationId: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CONVERSATION_ID),
        from: payload.from,
        to: payload.to
    }),
    getConversationIdSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentConversationId: payload.data.conversationId
    }),
    getConversationIdFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getConversations: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CONVERSATIONS),
        username: payload.username
    }),
    getConversationsSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        roleConversations: payload.data.role_conversations,
        centreConversations: payload.data.centre_conversations
    }),
    getConversationsFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentreBroadcastList: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_BROADCAST_LIST),
        centreId: payload.centreId
    }),
    getCentreBroadcastListSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreBroadcastList: payload.data.centreBroadcastList,
    }),
    getCentreBroadcastListFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getUnreadMessagesCount: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_UNREAD_MESSAGES_COUNT),
        username: payload.username
    }),
    getUnreadMessagesCountSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        unreadMessagesCount: payload.data.unreadMessagesCount,
        conversationUnreadCount: payload.data.conversationUnreadCount
    }),
    getUnreadMessagesCountFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload))
});


const UPDATE_ACTION_HANDLER = {
    "GET_CHAT_MESSAGES": {
        handler: async (currentState) => {
            let result = await getChatMessagesRequest(currentState.conversationId);
            if (result) {
                if (result.success) {
                    getChatMessagesSuccess({
                        "success": true,
                        "error": result.error,
                        "action": result.action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getChatMessagesFail({
                        "message": "messsages:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getChatMessagesFail
    },
    "READ_CHAT_MESSAGES": {
        handler: async (currentState) => {
            // const by = currentState.by;
            let result = await readChatMessagesRequest(currentState.conversationId, currentState.from);
            // let result = await readChatMessagesRequest(currentState.conversationId, by);
            if (result) {
                if (result.success) {
                    readChatMessagesSuccess({
                        "success": true,
                        "error": result.error,
                        "action": result.action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    readChatMessagesFail({
                        "message": "messsages:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: readChatMessagesFail
    },
    "GET_CONVERSATIONS": {
        handler: async (currentState, action) => {
            let result = await getConversationsRequest(currentState.username);
            if (result) {
                if (result.success) {
                    getConversationsSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getConversationsFail({
                        "message": "conversations:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getConversationsFail
    },
    "GET_CONVERSATION_ID": {
        handler: async (currentState, action) => {
            let result = await getConversationIdRequest(currentState.from, currentState.to);
            if (result) {
                if (result.success) {
                    getConversationIdSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getConversationIdFail({
                        "message": "conversationId:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getConversationIdFail
    },
    "GET_CENTRE_BROADCAST_LIST": {
        handler: async (currentState, action) => {
            let result = await getCentreBroadcastListRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    getCentreBroadcastListSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getCentreBroadcastListFail({
                        "message": "centreBroadcastList:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreBroadcastListFail
    },
    "GET_UNREAD_MESSAGES_COUNT": {
        handler: async (currentState, action) => {
            let result = await getUnreadMessagesCountRequest(currentState.username);
            if (result) {
                if (result.success) {
                    getUnreadMessagesCountSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getUnreadMessagesCountFail({
                        "message": "centreBroadcastList:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getUnreadMessagesCountFail
    }
};

messageManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});