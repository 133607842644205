import React from 'react';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Paper, Grid, Typography, Fab, IconButton, Table, TableBody, TableCell, TableRow, TablePagination, TableHead, CircularProgress, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from '@mui/icons-material/Download';
import SmsIcon from "@mui/icons-material/Sms";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SpaceHolder } from "./../../common/Others";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useClassesManagementState, deleteTutorFromClass, deleteFacilityFromClass } from "./../../../redux/ClassesManagement";
import { useSubscriptionManagementState, getCentreSubscription, addCentreSubscription } from "./../../../redux/SubscriptionManagement";
import ContentRow from "./../../common/ContentRow";
import TutorCard from "./../../page/tutor/TutorCard";
import CentreFacilityCard from "./../../page/centreDetail/CentreFacilityCard";
import moment from "moment";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import ContextualMenu from "./../../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../../component/common/ConfirmActionDialog";
import { Can } from "./../../..//casl/authorization";
import ViewableByAdminsOnly from "./../../acl/ViewableByAdminsOnly";
import { subject } from "@casl/ability";
import { isPlatformAdmin, isCentreAdmin } from "./../../../util/ACLUtils";
import SubscriptionDialog from './dialog/SubscriptionDialog';
import AmericanExpressLogo from "./../../../asset/card_logo/American Express.png";
import MasterCardLogo from "./../../../asset/card_logo/MasterCard.png";
import UnionPayLogo from "./../../../asset/card_logo/UnionPay.png";
import VisaLogo from "./../../../asset/card_logo/Visa.png";

const HeaderRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 900
}));

const CARD_LOGO = {
    visa: VisaLogo,
    mastercard: MasterCardLogo,
    unionpay: UnionPayLogo,
    amex: AmericanExpressLogo
}

export default function CentreSubscriptionSettingsTab() {
    const { t } = useTranslation(["centre", "general"]);
    const { centreId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [paymentAnchorEl, setPaymentAnchorEl] = React.useState(null);
    const [planAnchorEl, setPlanAnchorEl] = React.useState(null);
    const [invoiceAnchorEl, setInvoiceAnchorEl] = React.useState(null);

    const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = React.useState(false);
    const [changeSubscriptionDialogOpen, setChangeSubscriptionDialogOpen] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedInvoice, setSelectedInvoice] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        event.preventDefault();
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const togglePlanContextualMenu = (event) => {
        event.preventDefault();
        setPlanAnchorEl(event.currentTarget);
    };

    const togglePaymentContextualMenu = (event) => {
        event.preventDefault();
        setPaymentAnchorEl(event.currentTarget);
    }

    const toggleInvoiceContextualMenu = (event, invoice) => {
        event.preventDefault();
        setSelectedInvoice(invoice);
        setInvoiceAnchorEl(event.currentTarget);
    }

    const openSubscriptionDialog = (event) => {
        event.preventDefault();
        setPlanAnchorEl(null);

        setChangeSubscriptionDialogOpen(true);
    }

    const cancelSubscription = async (event) => {
        event.preventDefault();
        setPlanAnchorEl(null);

        const res = await fetch(`/subscription/cancel?centre=${centreId}`, { method: "GET" });

		const body = await res.json();
		window.location.href = body.data.url;
    }

    const resumeSubscription = async (event) => {
        event.preventDefault();
        setPlanAnchorEl(null);

        const res = await fetch(`/subscription/resume?centre=${centreId}`, { method: "GET" });

		const body = await res.json();
		window.location.href = body.data.url;
    }

    const changePaymentMethod = async (event) => {
        event.preventDefault();
        setPaymentAnchorEl(null);

        const res = await fetch(`/subscription/change-payment?centre=${centreId}`, { method: "GET" });

		const body = await res.json();
		window.location.href = body.data.url;
    }

    const downloadInvoice = (event) => {
        event.preventDefault();
        setInvoiceAnchorEl(null);

        window.open(selectedInvoice.url);
    }

    const confirmCancelSubscription = async (event) => {
        setCancelSubscriptionDialogOpen(false);
    }

    const debouncedAddCentreSubscription = React.useMemo(() => debounce(addCentreSubscription, 50), []);
    const debouncedGetCentreSubscription = React.useMemo(() => debounce(getCentreSubscription, 50), []);

    const SUBSCRIPTION_PLAN = useSubscriptionManagementState().subscriptionPlan;
    const PAYMENT_METHOD = useSubscriptionManagementState().paymentMethod;
    const INVOICES = useSubscriptionManagementState().invoices || [];
    const SUBSCRIBED = useSubscriptionManagementState().subscribed;
    const CURRENT_ROLE = useAuthenticationState().currentRole;

    React.useEffect(() => {
        if (searchParams.get("session")) {
            const sessionId = searchParams.get("session");
            setSearchParams({ tab: searchParams.get("tab") });
            debouncedAddCentreSubscription({ sessionId: sessionId });
        } else {
            debouncedGetCentreSubscription({ centreId: centreId });
        }
    }, []);

    React.useEffect(() => {
        if (CURRENT_ROLE.roleName == "centre_admin" && SUBSCRIBED && 
            (SUBSCRIBED?.centre !== centreId || !(["active", "trialing", "cancel_at_period_end"].includes(SUBSCRIBED?.status)))) {
            setChangeSubscriptionDialogOpen(true) ;                                               
        }
    }, [SUBSCRIBED, centreId]);

    return (
        <React.Fragment>
            {SUBSCRIPTION_PLAN && <Paper sx={{ borderRadius: "10px" }}>
                <Grid container>
                    <SpaceHolder container item xs={0.5} />
                    <Grid sx={{ paddingBottom: 1 }} container item xs={11}>
                        <HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
                            <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("subscription:section_title-subscription")}</Typography>
                            {CURRENT_ROLE.roleName == "centre_admin" && <IconButton onClick={togglePlanContextualMenu}
                                aria-label="plan-menu">
                                <MoreHorizIcon />
                            </IconButton>}
                        </HeaderRow>
                        <Grid>
                            <ContentRow needI18Nhandle title={t("subscription:label-plan")} content={SUBSCRIPTION_PLAN?.name} />
                            <ContentRow needI18Nhandle title={t("attribute:subscription_plan-student_limit")} 
                                content={`${SUBSCRIBED?.currentStudent} / ${SUBSCRIPTION_PLAN?.studentQuota}`} />
                            <ContentRow needI18Nhandle title={t("attribute:subscription_plan-price")} content={SUBSCRIPTION_PLAN?.monthlyPrice} />
                            {SUBSCRIPTION_PLAN?.monthlyPrice > 0 && 
                            <ContentRow needI18Nhandle content={SUBSCRIPTION_PLAN?.nextPayment}
                                title={SUBSCRIBED?.status === "cancel_at_period_end" ? 
                                    t("subscription:label-cancel_on") : 
                                    t("subscription:label-next_payment")} />}
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0.5} />
                </Grid>
            </Paper>}

            {PAYMENT_METHOD && 
            <Paper sx={{ marginTop: 2, marginBottom: 2, borderRadius: "10px" }}>
                <Grid container>
                    <SpaceHolder container item xs={0.5} />
                    <Grid sx={{ paddingBottom: 1 }} container item xs={11}>
                        <HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
                            <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("subscription:section_title-payment_method")}</Typography>
                            {CURRENT_ROLE.roleName == "centre_admin" && <IconButton onClick={togglePaymentContextualMenu}
                                aria-label="payment-menu">
                                <MoreHorizIcon />
                            </IconButton>}
                        </HeaderRow>
                        <Grid>
                            <Grid>
                            </Grid>
                            <Grid container spacing={10}>
                                <Grid item display="flex">
                                    <img src={CARD_LOGO[PAYMENT_METHOD?.brand]} alt="logo" style={{maxHeight: "20px"}}/>
                                    <Typography marginLeft={1} fontSize="24px">****</Typography>
                                    <Typography marginLeft={1} fontSize="24px">****</Typography>
                                    <Typography marginLeft={1} fontSize="24px">****</Typography>
                                    <Typography marginLeft={1}>{PAYMENT_METHOD?.last4}</Typography>
                                </Grid>
                                <Grid item display="flex">
                                    <Typography fontWeight="bold">{t("subscription:label-credit_exp_date")}</Typography>
                                    <Typography marginLeft={1}>{PAYMENT_METHOD?.exp_date}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0.5} />
                </Grid>
            </Paper>}

            {INVOICES.filter(invoice => invoice.amount > 0).length > 0 && 
            <Paper sx={{ marginBottom: 2, borderRadius: "10px" }}>
                <Grid container>
                    <SpaceHolder container item xs={0.5} />
                    <Grid sx={{ paddingBottom: 1 }} container item xs={11}>
                        <HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
                            <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("subscription:section_title-invoices")}</Typography>
                        </HeaderRow>
                    </Grid>
                    <SpaceHolder container item xs={0.5} />
                </Grid>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow  >
                            <BoldTableCell >{t("attribute:subscription-payment_date")}</BoldTableCell>
                            <BoldTableCell >{t("attribute:subscription-amount")}</BoldTableCell>
                            <BoldTableCell align="right"></BoldTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {INVOICES.filter(invoice => invoice.amount > 0)
                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                            .map((invoice, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        {invoice.date}
                                    </TableCell>
                                    <TableCell >{`$${invoice.amount}`}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={(event) => { toggleInvoiceContextualMenu(event, invoice) }} aria-label="delete">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage={t("general:label-rows_per_page")}
                    rowsPerPageOptions={[1, 5, 10, 25]}
                    component="div"
                    count={INVOICES.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}

            <ContextualMenu id={"subscription-menu"} anchorEl={planAnchorEl} setAnchorEl={setPlanAnchorEl} >
                {SUBSCRIBED?.status === "cancel_at_period_end" ? 
                <ContextualMenuItem
                    onClick={resumeSubscription}
                    title={t("subscription:button-resume_subscription")}>
                    <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                </ContextualMenuItem> :
                <div>
                    <ContextualMenuItem
                        onClick={openSubscriptionDialog}
                        title={t("subscription:button-change_plan")}>
                        <EditIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    <ContextualMenuItem
                        onClick={cancelSubscription}
                        title={t("subscription:button-cancel_subscription")}>
                        <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </div>}
            </ContextualMenu>
            <ContextualMenu id={"payment-menu"} anchorEl={paymentAnchorEl} setAnchorEl={setPaymentAnchorEl} >
                <ContextualMenuItem
                    onClick={changePaymentMethod}
                    title={t("subscription:button-change_payment_method")}>
                    <EditIcon sx={{ mr: 0 }} fontSize="small" />
                </ContextualMenuItem>
            </ContextualMenu>
            <ContextualMenu id={"invoice-menu"} anchorEl={invoiceAnchorEl} setAnchorEl={setInvoiceAnchorEl} >
                <ContextualMenuItem
                    onClick={downloadInvoice}
                    title={t("general:button-download")}>
                    <DownloadIcon sx={{ mr: 0 }} fontSize="small" />
                </ContextualMenuItem>
            </ContextualMenu>

            <ConfirmActionDialog
                title={t("subscription:dialog_title-cancel_subscription")}
                actionName={t("general:button-confirm")}
                open={cancelSubscriptionDialogOpen}
                setOpen={setCancelSubscriptionDialogOpen}
                confirmOnClick={confirmCancelSubscription}
            />

            <SubscriptionDialog
                open={changeSubscriptionDialogOpen}
                setOpen={setChangeSubscriptionDialogOpen}
                currentPlan={SUBSCRIPTION_PLAN?.id}
                subscribed={SUBSCRIBED}
            />
        </React.Fragment >
    );
}
