import {
    createReduxModule
} from "hooks-for-redux"
import {
    loadCentres,
    addCentreRequest,
    updateCentreRequest,
    getCentreInfoRequest,
    getCentreCustomFieldsRequest,
    addCentreCustomFieldRequest,
    removeCentreCustomFieldRequest,
    updateCentreCustomFieldRequest,
    uploadCentreLogoRequest,
    uploadCentrePhotosRequest,
    deleteCentrePhotoRequest,
    getCentrePostRequest,
    addCentrePostRequest,
    updateCentrePostRequest,
    deleteCentrePostRequest,
    getCentreNotificationRequest,
    updateCentreNotificationRequest,
    addCentreNotificationRequest
} from "./../api/centreAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        centres: [],
        centreCount: 0,
        customFields: [],
        currentCentre: {},
        centrePost: [],
        centrePostCount: 0,
        centrePostSearchCriteria: {},
        centreId: null,
        searchCriteria: {},
        centreToBeSaved: null,
        logoToBeUploaded: null,
        photosToBeUploaded: null,
        photoToBeDeleted: null,
        centrePostToBeSaved: null,
        centrePostToBeDeleted: null,
        centreNotifications: {},
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "GET_CENTRE": "GET_CENTRE",
    "ADD_CENTRE": "ADD_CENTRE",
    "UPDATE_CENTRE": "UPDATE_CENTRE",
    "GET_CENTRE_INFO": "GET_CENTRE_INFO",
    "ADD_CUSTOM_FIELD": "ADD_CUSTOM_FIELD",
    "GET_CENTRE_CUSTOM_FIELD": "GET_CENTRE_CUSTOM_FIELD",
    "UPDATE_CUSTOM_FIELD": "UPDATE_CUSTOM_FIELD",
    "REMOVE_CUSTOM_FIELD": "REMOVE_CUSTOM_FIELD",
    "UPLOAD_LOGO": "UPLOAD_LOGO",
    "UPLOAD_PHOTO": "UPLOAD_PHOTO",
    "DELETE_PHOTO": "DELETE_PHOTO",
    "ADD_POST": "ADD_POST",
    "GET_POST": "GET_POST",
    "UPDATE_POST": "UPDATE_POST",
    "DELETE_POST": "DELETE_POST",
    "TOGGLE_POST_VISIBILITY": "TOGGLE_POST_VISIBILITY",
    "GET_CENTRE_NOTIFICATION": "GET_CENTRE_NOTIFICATION",
    "UPDATE_CENTRE_NOTIFICATION": "UPDATE_CENTRE_NOTIFICATION",
    "ADD_CENTRE_NOTIFICATION": "ADD_CENTRE_NOTIFICATION"
};

export const [useCentreManagementState, {
    setUpdating,
    getCentres, getCentresSuccess, getCentresFail,
    getCentreInfo, getCentreInfoSuccess, getCentreInfoFail,
    addCentre, addCentreSuccess, addCentreFail,
    updateCentre, updateCentreSuccess, updateCentreFail,
    getCentreCustomFields, getCentreCustomFieldsSuccess, getCentreCustomFieldsFail,
    addCentreCustomFields, addCentreCustomFieldsSuccess, addCentreCustomFieldsFail,
    updateCentreCustomFields, updateCentreCustomFieldsSuccess, updateCentreCustomFieldsFail,
    removeCentreCustomField, removeCentreCustomFieldSuccess, removeCentreCustomFieldFail,
    uploadCentreLogo, uploadCentreLogoSuccess, uploadCentreLogoFail,
    uploadCentrePhotos, uploadCentrePhotosSuccess, uploadCentrePhotosFail,
    deleteCentrePhoto, deleteCentrePhotoSuccess, deleteCentrePhotoFail,
    getCentrePost, getCentrePostSuccess, getCentrePostFail,
    addCentrePost, addCentrePostSuccess, addCentrePostFail,
    updateCentrePost, updateCentrePostSuccess, updateCentrePostFail,
    deleteCentrePost, deleteCentrePostSuccess, deleteCentrePostFail,
    toggleCentrePostVisibility, toggleCentrePostVisibilitySuccess, toggleCentrePostVisibilityFail,
    getCentreNotification, getCentreNotificationSuccess, getCentreNotificationFail,
    updateCentreNotification, updateCentreNotificationSuccess, updateCentreNotificationFail,
    addCentreNotification, addCentreNotificationSuccess, addCentreNotificationFail,
},
    centreManagementState
] = createReduxModule(
    "centreManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getCentres: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE),
        searchCriteria: Object.assign({}, payload)
    }),
    getCentresSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centres: payload.centres,
        centreCount: payload.centreCount
    }),
    getCentresFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentreInfo: (state, payload) => Object.assign({}, state, {
        "updateAction": pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_INFO),
        "centreId": payload.centreId
    }),
    getCentreInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentCentre: payload.centreDetail
    }),
    getCentreInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentre: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_CENTRE),
        centreToBeSaved: payload.centre
    }),
    addCentreSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreToBeSaved: {}
    }),
    addCentreFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateCentre: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_CENTRE),
        centreToBeSaved: payload.centre
    }),
    updateCentreSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentCentre: payload.centre
    }),
    updateCentreFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentreCustomFields: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_CUSTOM_FIELD),
        centreId: payload
    }),
    getCentreCustomFieldsSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        customFields: payload.data,
    }),
    getCentreCustomFieldsFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentreCustomFields: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_CUSTOM_FIELD),
        fieldToBeSaved: {
            centreId: payload.centreId,
            name: payload.name
        }
    }),
    addCentreCustomFieldsSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        fieldToBeSaved: {}
    }),
    addCentreCustomFieldsFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateCentreCustomFields: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_CUSTOM_FIELD),
        fieldToBeSaved: payload
    }),
    updateCentreCustomFieldsSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        fieldToBeSaved: {}
    }),
    updateCentreCustomFieldsFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeCentreCustomField: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_CUSTOM_FIELD),
        fieldToBeDeleted: payload._id
    }),
    removeCentreCustomFieldSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        fieldToBeDeleted: null
    }),
    removeCentreCustomFieldFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    uploadCentreLogo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPLOAD_LOGO),
        logoToBeUploaded: payload
    }),
    uploadCentreLogoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        logoToBeUploaded: null
    }),
    uploadCentreLogoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    uploadCentrePhotos: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPLOAD_PHOTO),
        photosToBeUploaded: payload
    }),
    uploadCentrePhotosSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        photosToBeUploaded: null
    }),
    uploadCentrePhotosFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteCentrePhoto: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_PHOTO),
        photoToBeDeleted: payload
    }),
    deleteCentrePhotoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        photoToBeDeleted: null
    }),
    deleteCentrePhotoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentrePost: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_POST),
        centreId: payload.centreId,
        centrePostSearchCriteria: payload
    }),
    getCentrePostSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centrePost: payload.data,
        centrePostCount: payload.count
    }),
    getCentrePostFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentrePost: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_POST),
        centrePostToBeSaved: payload
    }),
    addCentrePostSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centrePostToBeSaved: null
    }),
    addCentrePostFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateCentrePost: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_POST),
        centrePostToBeSaved: payload
    }),
    updateCentrePostSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centrePostToBeSaved: null
    }),
    updateCentrePostFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteCentrePost: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_POST),
        centrePostToBeDeleted: payload
    }),
    deleteCentrePostSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centrePostToBeDeleted: null
    }),
    deleteCentrePostFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    toggleCentrePostVisibility: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.TOGGLE_POST_VISIBILITY),
        centrePostToBeSaved: payload
    }),
    toggleCentrePostVisibilitySuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centrePostToBeSaved: null
    }),
    toggleCentrePostVisibilityFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentreNotification: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_NOTIFICATION),
        centreId: payload
    }),
    getCentreNotificationSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreNotifications: payload.data
    }),
    getCentreNotificationFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateCentreNotification: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_CENTRE_NOTIFICATION),
        notificationScheme: payload
    }),
    updateCentreNotificationSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreNotifications: payload.data
    }),
    updateCentreNotificationFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentreNotification: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_CENTRE_NOTIFICATION),
        centreId: payload
    }),
    addCentreNotificationSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreNotifications: payload.data
    }),
    addCentreNotificationFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload))
});


const UPDATE_ACTION_HANDLER = {
    "GET_CENTRE": {
        handler: async (currentState) => {
            var result = await loadCentres(currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    getCentresSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "centres": result.data.centres,
                        "centreCount": result.data.count
                    });
                } else {
                    getCentresFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentresFail
    },
    "ADD_CENTRE": {
        handler: async (currentState) => {
            var result = await addCentreRequest(currentState.centreToBeSaved);
            if (result) {
                if (result.success) {
                    addCentreSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "centres": result.data
                    });
                    let centreId = result.data.centre._id;
                    pushRedirect({
                        path: `/centre_settings/${centreId}`
                    })
                } else {
                    addCentreFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentreFail
    },
    "UPDATE_CENTRE": {
        handler: async (currentState, action) => {
            var result = await updateCentreRequest(currentState.centreToBeSaved);
            if (result) {
                if (result.success) {
                    updateCentreSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "centre": result.data.centre
                    });
                } else {
                    updateCentreFail({
                        error: result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                    });
                }
            }
        },
        failHandler: updateCentreFail
    },
    "GET_CENTRE_INFO": {
        handler: async (currentState, action) => {
            var result = await getCentreInfoRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    getCentreInfoSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "centreDetail": result.data.centre
                    });
                } else {
                    getCentreInfoFail({
                        requestStatus: result.status,
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreInfoFail
    },
    "ADD_CUSTOM_FIELD": {
        handler: async (currentState) => {
            let result = await addCentreCustomFieldRequest(currentState.fieldToBeSaved);
            if (result) {
                if (result.success) {
                    addCentreCustomFieldsSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreCustomFields(currentState.centreId);
                } else {
                    addCentreCustomFieldsFail({
                        "message": "centre:fail_to_add",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentreCustomFieldsFail
    },
    "GET_CENTRE_CUSTOM_FIELD": {
        handler: async (currentState) => {
            let result = await getCentreCustomFieldsRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    getCentreCustomFieldsSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getCentreCustomFieldsFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreCustomFieldsFail
    },
    "UPDATE_CUSTOM_FIELD": {
        handler: async (currentState) => {
            var result = await updateCentreCustomFieldRequest(currentState.fieldToBeSaved);
            if (result) {
                if (result.success) {
                    updateCentreCustomFieldsSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreCustomFields(currentState.centreId);
                } else {
                    updateCentreCustomFieldsFail({
                        "message": "centre:fail_to_update",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateCentreCustomFieldsFail
    },
    "REMOVE_CUSTOM_FIELD": {
        handler: async (currentState) => {
            let result = await removeCentreCustomFieldRequest(currentState.fieldToBeDeleted);
            if (result) {
                if (result.success) {
                    removeCentreCustomFieldSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreCustomFields(currentState.centreId);
                } else {
                    removeCentreCustomFieldFail({
                        "message": "centre:fail_remove",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeCentreCustomFieldFail
    },
    "UPLOAD_LOGO": {
        handler: async (currentState) => {
            let result = await uploadCentreLogoRequest(currentState.logoToBeUploaded);
            if (result) {
                if (result.success) {
                    uploadCentreLogoSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreInfo({
                        centreId: currentState.centreId
                    });
                } else {
                    uploadCentreLogoFail({
                        "message": "centre:fail_to_upload",
                        error: result.error
                    });
                }
            }
        },
        failHandler: uploadCentreLogoFail
    },
    "UPLOAD_PHOTO": {
        handler: async (currentState) => {
            let result = await uploadCentrePhotosRequest(currentState.photosToBeUploaded);
            if (result) {
                if (result.success) {
                    uploadCentrePhotosSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreInfo({
                        centreId: currentState.centreId
                    });
                } else {
                    uploadCentrePhotosFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: uploadCentrePhotosFail
    },
    "DELETE_PHOTO": {
        handler: async (currentState) => {
            let result = await deleteCentrePhotoRequest(currentState.photoToBeDeleted);
            if (result) {
                if (result.success) {
                    deleteCentrePhotoSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreInfo({
                        centreId: currentState.centreId
                    });
                } else {
                    deleteCentrePhotoFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteCentrePhotoFail
    },
    "ADD_POST": {
        handler: async (currentState) => {
            let result = await addCentrePostRequest(currentState.centrePostToBeSaved);
            if (result) {
                if (result.success) {
                    addCentrePostSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentrePost(currentState.centrePostSearchCriteria);
                } else {
                    addCentrePostFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentrePostFail
    },
    "GET_POST": {
        handler: async (currentState) => {
            let result = await getCentrePostRequest(currentState.centreId, currentState.centrePostSearchCriteria);
            if (result) {
                if (result.success) {
                    getCentrePostSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "count": result.count
                    });
                } else {
                    getCentrePostFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentrePostFail
    },
    "UPDATE_POST": {
        handler: async (currentState) => {
            let result = await updateCentrePostRequest(currentState.centrePostToBeSaved);
            if (result) {
                if (result.success) {
                    updateCentrePostSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentrePost(currentState.centrePostSearchCriteria);
                } else {
                    updateCentrePostFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateCentrePostFail
    },
    "DELETE_POST": {
        handler: async (currentState) => {
            let result = await deleteCentrePostRequest(currentState.centrePostToBeDeleted);
            if (result) {
                if (result.success) {
                    deleteCentrePostSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentrePost(currentState.centrePostSearchCriteria);
                } else {
                    deleteCentrePostFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteCentrePostFail
    },
    "TOGGLE_POST_VISIBILITY": {
        handler: async (currentState) => {
            let result = await updateCentrePostRequest(currentState.centrePostToBeSaved);
            if (result) {
                if (result.success) {
                    toggleCentrePostVisibilitySuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentrePost(currentState.centrePostSearchCriteria);
                } else {
                    toggleCentrePostVisibilityFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateCentrePostFail
    },
    "GET_CENTRE_NOTIFICATION": {
        handler: async (currentState) => {
            let result = await getCentreNotificationRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    getCentreNotificationSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getCentreNotificationFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreNotificationFail
    },
    "UPDATE_CENTRE_NOTIFICATION": {
        handler: async (currentState) => {
            let result = await updateCentreNotificationRequest(currentState.notificationScheme);
            if (result) {
                if (result.success) {
                    updateCentreNotificationSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    updateCentreNotificationFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateCentreNotificationFail
    },
    "ADD_CENTRE_NOTIFICATION": {
        handler: async (currentState) => {
            let result = await addCentreNotificationRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    addCentreNotificationSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    addCentreNotificationFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentreNotificationFail
    }
};

centreManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});