import React from 'react';
import { Grid, Paper, Typography,Stack } from '@mui/material';
import { changeShade } from "./../../../redux/ThemePicker";
import hueFromString from "./../../../util/hueConverter";
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { useThemePickerState } from "./../../../redux/ThemePicker";

const OuterContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  "marginBottom": theme.spacing(3),
  flexGrow: 1,
}));

const PaperContainer = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  padding: "2px",
  width: 200,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  height: 30,
  width: 30
}));

const CheckedIcon = styled(CheckIcon)(({ theme }) => ({
  "color": theme.palette.primary.contrastText
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: "#BBBBBB",
  fontWeight: "bold",
  marginTop: 30,
  marginBottom: 10
}));

const shadeOptions = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, "A100", "A200", "A400", "A700"];


export default function ShapeSlider({ themePickerKey }) {
  const { t } = useTranslation("attribute");
  var themePickerState = useThemePickerState();
  var shade = themePickerState[themePickerKey].shade;
  var theme = themePickerState[themePickerKey].theme;
  function handleOnClick(event, newValue, themePickerKey) {
    event.preventDefault();
    changeShade({
      type: themePickerKey,
      shade: newValue
    });
  };


  function GridRow({ shadeOptions, theme, shade }) {
    return (
      <React.Fragment>
        {shadeOptions.map((currentShade, index) => {
          return <StyledGrid
            data-cy={themePickerKey + "-shade-" + currentShade}
            item key={currentShade}
            onClick={(event) => { handleOnClick(event, currentShade, themePickerKey) }}
            container>
            <PaperContainer style={generateStyle(theme, currentShade)} >
              {currentShade == shade ? <CheckedIcon /> : null}
            </PaperContainer>
          </StyledGrid>;
        })}
      </React.Fragment>
    );
  }

  function generateStyle(theme, shade) {
    var backgroundColor = hueFromString(theme, shade);
    return {
      "background": backgroundColor
    };
  };


  return (
    <OuterContainer>
      <Subtitle>{t("attribute:centre-color-saturation")}</Subtitle>
      <Stack direction="row">
        <GridRow shadeOptions={shadeOptions} theme={theme} shade={shade} ></GridRow>
      </Stack>
    </OuterContainer>
  );
}
