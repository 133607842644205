import React from 'react';
import { BrowserRouter } from 'react-router-dom';
//-- start -- do not remove as hooks-for-redux will use it
import store from "./store/store";
//-- end -- do not remove as hooks-for-redux will use it
import { Provider } from 'hooks-for-redux';
import ItutorApp from './component/layout/ItutorApp';
import history from "./history";
import LoadingPage from "./component/page/LoadingPage";

function App() {
  return (

    <Provider>
      <BrowserRouter history={history}>
        <React.Suspense fallback={<LoadingPage />}>
          <ItutorApp></ItutorApp>
        </React.Suspense>
      </BrowserRouter>
    </Provider>

  );
}

export default App;

if (window.Cypress) {
  window.store = store
}