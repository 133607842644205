import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Paper, Grid,
	Typography, IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SpaceHolder } from "./../../common/Others";
import { useParams } from "react-router-dom";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useClassSessionManagementState } from "./../../../redux/ClassSessionManagement";
import ContentRow from "./../../common/ContentRow";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import ContextualMenu from "./../../../component/common/ContextualMenu";
import { Can } from "./../../..//casl/authorization";
import { subject } from "@casl/ability";
import ClassSessionProgressDialog from "./../../page/session/dialog/ClassSessionProgressDialog";
const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

export default function ClassSessionProgressPage() {
	const CLASS_SESSION_INFO = useClassSessionManagementState().currentSession || {};
	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;
	const { sessionId } = useParams();


	const { t } = useTranslation(["centre", "general"]);

	const [classAnchorEl, setClassAnchorEl] = React.useState(null);
	const [editDialogOpen, setEditDialogOpen] = React.useState(false);

	const editClassInfo = (event) => {
		event.preventDefault();
		setClassAnchorEl(null);
		setEditDialogOpen(true);
	}


	const toggleClassContextualMenu = (event) => {
		event.preventDefault();
		setClassAnchorEl(event.currentTarget);
	}
	let isStudent = AUTH_STATE.currentRole.roleName == "student";

	const ATTENDANCE_STATUS = {
		"present": t("attribute:attendance-present"),
		"absent": t("attribute:attendance-absent")
	}
	const ATTENDANCE = CLASS_SESSION_INFO.attendance || {};

	return (
		<React.Fragment>
			{isStudent ?
				(<Paper sx={{ marginBottom: 2, borderRadius: "10px" }}>
					<Grid container>
						<SpaceHolder container item xs={0.5} />
						<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
							<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
								<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("attribute:session-attendance")}</Typography>
								<Typography variant="button" component="div">{ATTENDANCE_STATUS[ATTENDANCE[AUTH_STATE.currentRole.roleId]] ? ATTENDANCE_STATUS[ATTENDANCE[AUTH_STATE.currentRole.roleId]] : ""}</Typography>
							</HeaderRow>
						</Grid>
						<SpaceHolder container item xs={0.5} />
					</Grid>
				</Paper>) : null
			}
			<Paper sx={{ marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("session:section_title-progress")}</Typography>
							<Can I="update" this={subject("class_session", CLASS_SESSION_INFO)} field="progress" ability={ABILITY} >
								<IconButton onClick={toggleClassContextualMenu}
									aria-label="comment">
									<MoreHorizIcon />
								</IconButton>
							</Can>
						</HeaderRow>
						<ContentRow content={CLASS_SESSION_INFO.progress} />
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>

			<ContextualMenu id={"class-menu"} anchorEl={classAnchorEl} setAnchorEl={setClassAnchorEl} >
				<ContextualMenuItem
					onClick={editClassInfo}
					title={t("general:button-edit")}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ClassSessionProgressDialog
				title={t("general:dialog_title-edit_entity", { entity: t("attribute:session-progress") })}
				currentSession={CLASS_SESSION_INFO}
				isDialogOpen={editDialogOpen}
				setIsDialogOpen={setEditDialogOpen} />

		</React.Fragment >
	);
}
