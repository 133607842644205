import React from 'react';
import { useAuthenticationState } from "./../../redux/authentication";
import {
    useLocation,
    Navigate
} from "react-router-dom";
import { subject } from "@casl/ability";

export default function CanAccess({ children, action, entity, entityType, redirect, or = false }) {
    const AUTH_STATE = useAuthenticationState();
    let location = useLocation();
    let ability = AUTH_STATE.ability;
    if (ability) {
        if (ability.can(action, subject(entityType, entity)) || or) {
            return children;
        }
    };
    if (redirect) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    return null;
}
