import React from "react";
import {
    Typography, Breadcrumbs,
    Grid, IconButton, Paper, Stack,
    Tabs, Tab, Divider
} from "@mui/material";
import { useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TabContainer } from "./../../../../component/common/Containers";
import { SpaceHolder } from "./../../../../component/common/Others";
import { useCentreManagementState } from "./../../../../redux/CentreManagement";
import { useAuthenticationState } from "./../../../../redux/authentication";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function CentreHeader({
    TAB_ELEMENTS, value, setValue, setLogoDialogOpen }) {
    const { t } = useTranslation();
    let { centreId } = useParams();
    const CENTRE = useCentreManagementState().currentCentre;
    const ABILITY = useAuthenticationState().ability;
    const navigate = useNavigate();
    const LOCATION = useLocation();

    const navigateToCentre = (event) => {
        event.preventDefault();
        navigate("/centre/" + centreId, { state: LOCATION.state });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate({
            pathname: ("/centre_settings/" + centreId),
            search: createSearchParams({
                tab: newValue
            }).toString()
        }, { state: LOCATION.state });
    };

    return (
        <React.Fragment>
            <Paper sx={{ display: "block", width: "100%", borderRadius: "10px" }}>
                <Grid container item sm={12}>
                    <SpaceHolder container item xs={0.5} sm={0.5} />
                    <Grid sx={{ marginTop: 4.5 }} container item xs={11.5} sm={11}>
                        <Stack alignItems="center" direction="row">
                            <IconButton
                                aria-label="upload picture" component="label"
                                sx={{ marginLeft: -2 }} size="large" color="primary"
                                onClick={navigateToCentre}>
                                <ArrowBackIcon fontSize="middle" />
                            </IconButton>
                            <Typography variant="h5" component="div" sx={{ display: { xs: "none", sm: "block" }, fontWeight: "bold" }}>{CENTRE.displayName}</Typography>
                            <Typography variant="h6" component="div" sx={{ display: { xs: "block", sm: "none" }, fontWeight: "bold" }}>{CENTRE.displayName}</Typography>
                        </Stack>
                        <Grid sx={{ marginBottom: 3 }} container item xs={12}>
                            <Breadcrumbs sx={{ display: { xs: "none", sm: "block" },cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="small" />}>
                                <Typography color="secondary">{t("centre:page_title-home")}</Typography>
                                <Typography color="secondary">{t("centre:page_title-settings")}</Typography>
                            </Breadcrumbs>
                            <Breadcrumbs sx={{ display: { xs: "block", sm: "none" },cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="subtitle2" />}>
                                <Typography variant="subtitle2" color="secondary">{t("centre:page_title-home")}</Typography>
                                <Typography variant="subtitle2" color="secondary">{t("centre:page_title-settings")}</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} sm={0.5} />

                    <SpaceHolder container item xs={0} sm={0.5} />
                    <Grid container item xs={12} sm={11}>
                        <TabContainer>
                            <Divider sx={{ display: { xs: "block", sm: "none" }, marginTop: 2.5 }} />
                            <Tabs
                                value={value} onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="centre_settings_tabs">
                                {TAB_ELEMENTS.map(((element, index) => element.acl ?
                                    (
                                        ABILITY.can(element.acl.i, element.acl.a) ?
                                            <Tab sx={{
                                                textTransform: "none",
                                                minWidth: 70
                                            }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                            : null
                                    )
                                    : (
                                        <Tab key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                    )))
                                }
                            </Tabs>
                        </TabContainer>
                    </Grid>
                    <SpaceHolder container item xs={0} sm={0.5} />
                </Grid>
            </Paper>
        </React.Fragment>
    )

}