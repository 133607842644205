import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export async function getCentreSubscriptionRequest(centreId: string): Promise<UpdateResultResponse> {
	const url = `/subscription/${centreId}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err
	}
}

export async function addCentreSubscriptionRequest(sessionId: string): Promise<UpdateResultResponse> {
	const url = `/subscription/save`;
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, { sessionId: sessionId });
		return data;
	} catch (err) {
		throw err
	}
}

// export async function updateCentreSubscriptionRequest(centreId: string): Promise<UpdateResultResponse> {
// 	const url = `/subscription/save`;
// 	try {
// 		const {
// 			data
// 		} = await axios.post<UpdateResultResponse>(url, { centreId: centreId });
// 		return data;
// 	} catch (err) {
// 		throw err
// 	}
// }

export async function cancelCentreSubscriptionRequest(sessionId: string): Promise<UpdateResultResponse> {
	const url = `/subscription/cancel`;
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, { sessionId: sessionId });
		return data;
	} catch (err) {
		throw err
	}
}


// export async function createCheckoutSession(centreId: string, planId: string): Promise<UpdateResultResponse> {
// 	const url = `/subscription/checkout?centre=${centreId}&plan=${planId}`;
// 	try {
// 		const {
// 			data
// 		} = await axios.get<UpdateResultResponse>(url, {});
// 		return data;
// 	} catch (err) {
// 		throw err
// 	}
// }

// export async function createUpdateSession(centreId: string, planId: string): Promise<UpdateResultResponse> {
// 	const url = `/subscription/update?centre=${centreId}&plan=${planId}`;
// 	try {
// 		const {
// 			data
// 		} = await axios.get<UpdateResultResponse>(url, {});
// 		return data;
// 	} catch (err) {
// 		throw err
// 	}
// }

// export async function createCancelSession(centreId: string): Promise<UpdateResultResponse> {
// 	const url = `/subscription/cancel?centre=${centreId}`;
// 	try {
// 		const {
// 			data
// 		} = await axios.get<UpdateResultResponse>(url, {});
// 		return data;
// 	} catch (err) {
// 		throw err
// 	}
// }

