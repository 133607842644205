import React from "react";
import {
    Grid, Box, Button, Stack, Typography, IconButton,
    Dialog, DialogTitle, DialogContent, DialogActions,
    CircularProgress, Backdrop
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LabelText from "./../../../display/LabelText";
import { SpaceHolder } from "./../../../common/Others";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useCentreManagementState } from "./../../../../redux/CentreManagement";
import CloseIcon from "@mui/icons-material/Close";

const PayslipTitle = ({ title }) => {
    const { t } = useTranslation(["payslip"]);
    return (
        <Stack sx={{ marginBottom: 4 }} direction="row" justifyContent="center">
            <Typography variant="h5">{t("attribute:payslip")}</Typography>
        </Stack>
    );
};

const InfoItem = ({ label, value }) => {
    const { t,i18n } = useTranslation(["payslip"]);

    if(value){
        return (
            <React.Fragment>
                <Stack sx={{ width: "100%" }} direction="row" justifyContent="space-between">
                    <Grid item xs={6}>
                        <Typography>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {value}
                        </Typography>
                    </Grid>
                </Stack>
            </React.Fragment>
        );        
    }else{
        return null;
    }
}

const PayslipInfoSection = ({ payslip }) => {
    const { t, i18n } = useTranslation(["general", "config", "attribute", "payslip"]);

    return (
        <React.Fragment>
            <Stack sx={{ marginBottom: 2 }} spacing={1} justifyContent="space-between">
                <InfoItem value={payslip.tutorProfile.legalName} label={t("attribute:payslip-name")} />
                <InfoItem value={payslip.employeeId} label={t("attribute:payslip-employee_id")} />
                <InfoItem value={payslip.designation} label={t("attribute:payslip-designation")} />
                <InfoItem value={moment(payslip.payPeriodFrom).format("LL")} label={t("attribute:payslip-pay_period_start_date")} />
                <InfoItem value={moment(payslip.payPeriodTo).format("LL")} label={t("attribute:payslip-pay_period_end_date")} />
                <InfoItem value={moment(payslip.paymentDate).format("LL")} label={t("attribute:payslip-pay_date")} />
                <InfoItem value={moment(payslip.contributionDate).format("LL")} label={t("attribute:payslip-contribution_date")} />
                <InfoItem value={payslip.paymentMethod} label={t("attribute:payslip-payment_method")} />
            </Stack>
        </React.Fragment>
    );
};

const calcuateNetpay = (relevantIncomes, nonRelevantIncomes, detections) => {
    let relevantIncomeTotal = 0;
    let nonRelevantIncomeTotal = 0;
    let deductionTotal = 0;

    if (relevantIncomes) {
        relevantIncomeTotal = relevantIncomes.reduce((acc, income) => parseFloat(acc) + parseFloat(income.amount), 0);
    }

    if (nonRelevantIncomes) {
        nonRelevantIncomeTotal = nonRelevantIncomes.reduce((acc, income) => parseFloat(acc) + parseFloat(income.amount), 0);
    }

    if (detections) {
        deductionTotal = detections.reduce((acc, deduction) => parseFloat(acc) + parseFloat(deduction.amount), 0);
    }

    let total = relevantIncomeTotal + nonRelevantIncomeTotal - deductionTotal;
    return total;
}

const PayslipListItems = ({ list, totalLabel, title }) => {
    let listItems = null;
    if (list) {
        let total = 0;
        listItems = list.map((item, index) => {
            total += item.amount;
            return (<Stack direction="row" justifyContent="space-between" key={index}>
                <Typography>
                    {item.desc}
                </Typography>
                <Typography>
                    {item.amount}
                </Typography>
            </Stack>);
        });
        if (list.length > 0) {
            return (
                <Stack sx={{ marginBottom: 3 }} spacing={1}>
                    <Stack direction="row" justifyContent="left">
                        <Typography variant="title" fontWeight="bold" >{title}</Typography>
                    </Stack>
                    {listItems}
                    <Stack direction="row" justifyContent="space-between" >
                        <Typography fontWeight="bold" >
                            {totalLabel}
                        </Typography>
                        <Typography fontWeight="bold">
                            {total}
                        </Typography>
                    </Stack>
                </Stack>
            );
        } else {
            return null;
        }

    }
    else {
        return null;
    }

};

const PayslipDetailsSection = ({ payslip }) => {
    const { t } = useTranslation(["payslip"]);
    return (
        <React.Fragment>
            <Stack sx={{ marginTop: 3, marginBottom: 3 }} direction="row" justifyContent="right">
                <Typography variant="title" fontWeight="bold" >{t("general:label-amount-hkd")}</Typography>
            </Stack>
            <PayslipListItems totalLabel={t("general:label-total_amount", { amount: t("attribute:payslip-relevant_income") })} title={t("attribute:payslip-relevant_income")} list={payslip.relevantIncomes} />
            <PayslipListItems totalLabel={t("general:label-total_amount", { amount: t("attribute:payslip-non_relevant_income") })} title={t("attribute:payslip-non_relevant_income")} list={payslip.nonRelevantIncomes} />
            <PayslipListItems totalLabel={t("general:label-total_amount", { amount: t("attribute:payslip-deduction") })} title={t("attribute:payslip-deduction")} list={payslip.deductions} />
            <Stack direction="row" sx={{ marginBottom: 3 }} justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold" >{t("attribute:payslip-net_pay")}</Typography>
                <Typography variant="h6" fontWeight="bold" >{calcuateNetpay(payslip.relevantIncomes, payslip.nonRelevantIncomes, payslip.deductions)}</Typography>
            </Stack>
            <PayslipListItems totalLabel={t("general:label-total_amount", { amount: t("attribute:payslip-employer_contributions") })} title={t("attribute:payslip-employer_contributions")} list={payslip.employerContributions} />
            <SpaceHolder sx={{ marginBottom: 8 }}></SpaceHolder>
        </React.Fragment>
    );
};

export default function TutorViewPayslipsDialog({
    title, payslip, centreId, isDialogOpen, setIsDialogOpen }) {
    const { t,i18n } = useTranslation(["general", "config", "attribute", "payslip"]);
    const closeDialog = () => {
        setIsDialogOpen(false);
    }
    const [overlayShow,setOverlayShow] = React.useState(false);
    const printRef = React.useRef();

    const handleDownloadPdf = async () => {

        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('payslip.pdf');
    };
    let centreDisplayname = payslip.centre.engBusinessName;
    const CONTENT_PADDING = 4;

    return (
        <React.Fragment>
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Stack sx={{ paddingLeft: CONTENT_PADDING, paddingRight: CONTENT_PADDING }} direction="row" alignItems="center" justifyContent="space-between">
                    <Box>
                        {title}
                    </Box>
                    <Box>
                        <IconButton
                            aria-label="close"
                            onClick={closeDialog}
                            sx={{
                                marginRight: -5,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <div ref={printRef}>
                    <Stack spacing={2} direction="row" justifyContent="space-between">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <Stack sx={{ marginTop: 8, marginBottom: 4 }} spacing={1} direction="row" justifyContent="left">
                                {payslip.centre ?
                                    <Box component="img" alt={"centre logo"} sx={{
                                        height: "auto",
                                        width: { xs: "30px", sm: "50px" },
                                        objectFit: "cover",
                                        objectPosition: "centre",
                                        "MozUserSelect": "none",
                                        "KhtmlUserSelect": "none",
                                        "WebkitUserSelect": "none",
                                        "msUserSelect": "none",
                                        "userSelect": "none",
                                        borderRadius: "10px 10px 0px 0px"
                                    }} src={payslip.centre.rootPath + payslip.centre._id + "/centre_logo_thumbnail.jpg"} /> : null
                                }
                                <Typography variant="h4">{centreDisplayname}</Typography>
                            </Stack>
                            <PayslipTitle />
                            <PayslipInfoSection payslip={payslip} />
                            <PayslipDetailsSection payslip={payslip} />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Stack>
                </div>

            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Button fullWidth onClick={(event)=>{
                        let currentLanguage = i18n.language;
                        let currentMomentLocale = moment.locale();
                        setOverlayShow(true);
                        i18n.changeLanguage("en");
                        moment.locale("en-gb");
                        setTimeout(async ()=>{

                            await handleDownloadPdf(event);
                            i18n.changeLanguage(currentLanguage);
                            moment.locale(currentMomentLocale);
                            setOverlayShow(false);
                        },1000);
                        
                    }} variant="contained" >
                        {t("general:button-download")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 12000}}
          open={overlayShow}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        </React.Fragment>
    )
}