import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface Payslip {
	_id: string,
	centreId: string,
    tutorProfileId: string,
	employeeId: string,
    designation: string,
	payPeriodFrom: Date,
    payPeriodTo: Date,
    paymentDate: Date,
    contributionDate: Date,
    paymentMethod: string,
    relevantIncomes: [],
    relevantIncomesTotal: number,
    nonRelevantIncomes: [],
    nonRelevantIncomesTotal: number,
    deduction: [],
    deductionTotal: number,
    employerContributions: [],
    employerContributionsTotal: number,
    netPay: number,
    status: string
}

export async function loadPayslip() {
	
}

export async function getPayslipsRequest(tutorProfileId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/payslip/tutor/${tutorProfileId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function issuePayslipRequest(body: object): Promise<UpdateResultResponse> {
	console.log('issuePayslipRequest ' + JSON.stringify(body));
	const url = "/payslip/issue";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		console.log('Called add payslip');
		return data;
	} catch (err) {
		throw err
	}
}

export async function getPayslipRequest(tutorProfileId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/payslip/${tutorProfileId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function updatePayslipRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/payslip/update";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function deletePayslipRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/payslip/delete";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

