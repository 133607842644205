import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
	Paper, Grid,
	Typography, IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from "@hookform/resolvers/yup";
import {
	createUpdateCentrePublicInfoFormSchema,
	INITIAL_CENTRE_FORM
} from "./../../form/model/CentreFormModel";
import { SpaceHolder } from "./../../common/Others";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { deleteCentrePhoto, useCentreManagementState } from "./../../../redux/CentreManagement";
import { useOptionsState } from "./../../../redux/Options";
import CentreEditDialog from "./../../form/centreComponent/CentreEditDialog";
import CentreUploadImageDialog from "./../../form/centreComponent/CentreUploadImageDialog";
import ConfirmActionDialog from "./../../common/ConfirmActionDialog";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ContextualMenu from "./../../common/ContextualMenu";
import ContentRow from "./../../common/ContentRow";
import { ContextualMenuItem } from "./../../common/Buttons";
import CarouselImage from "./../../display/CarouselImage";
import CentreDefaultImage from "./../../../asset/centre_default/centre_background.jpg";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5)
}));

export default function CentreAboutDetailPage() {
	const CENTRE = useCentreManagementState().currentCentre;
	const { t } = useTranslation(["centre", "general"]);
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	const [isUploadImageDialogOpen, setIsUploadImageDialogOpen] = React.useState(false);
	const [isDeleteImageDialogOpen, setIsDeleteImageDialogOpen] = React.useState(false);
	const [selectedImage, setSelectedImage] = React.useState({});
	const [imageAnchorEl, setImageAnchorEl] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);


	let { centreId } = useParams();
	const VALID_VALUES = {
		DISTRICT: useOptionsState().district.validValues
	};

	const handleClick = (event, selectedValue) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};


	const triggerImagesMenu = (event, value) => {
		event.preventDefault();
		setImageAnchorEl(event.currentTarget);
		setSelectedImage(value);
	}

	const CENTRE_PUBLIC_FIELD_FORM_METHOD = useForm({
		resolver: yupResolver(createUpdateCentrePublicInfoFormSchema(VALID_VALUES.DISTRICT)),
		defaultValues: INITIAL_CENTRE_FORM
	});

	const triggerEditCentreInfo = () => {
		setIsDialogOpen(true);
		CENTRE_PUBLIC_FIELD_FORM_METHOD.reset(CENTRE);
		setAnchorEl(null);
	};

	const triggerDeletePhoto = () => {
		setIsDeleteImageDialogOpen(true);
		setImageAnchorEl(null);
	}

	const triggerAddNewPhoto = () => {
		setIsUploadImageDialogOpen(true);
		setImageAnchorEl(null);
	}

	const deleteSelectedPhoto = () => {
		deleteCentrePhoto({
			centreId: centreId,
			filename: selectedImage.filename
		});
	}

	return (
		<React.Fragment>
			<Grid sx={{ marginBottom: "-2px" }} container>
				<Grid item xs={12} sx={{ maxHeight: { xs: "200px", sm: "300px" } }}>
					{CENTRE.photos ?
						(
							<Carousel maxHeight="300px" showThumbs={false} showStatus={false} infiniteLoop={true} showIndicators={false}>
								{CENTRE.photos.map((value) => (
									<CarouselImage centreId={centreId} key={value.filename + "_container"} value={value} triggerImagesMenu={triggerImagesMenu} />
								))}
							</Carousel>
						) :
						(
							<Carousel maxHeight="300px" showThumbs={false} showStatus={false} infiniteLoop={true} showIndicators={false}>
								<CarouselImage
									centreId={centreId}
									value={{
										src: CentreDefaultImage,
										filename: "default.jpg"
									}} triggerImagesMenu={triggerImagesMenu} />
							</Carousel>
						)
					}
				</Grid>
			</Grid>

			<Paper sx={{ borderRadius: "0px 0px 10px 10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-about")}</Typography>
							<CanUpdateCentre centreId={centreId} >
								<IconButton onClick={(event) => {
									handleClick(event);
								}} aria-label="comment">
									<MoreHorizIcon />
								</IconButton>
							</CanUpdateCentre>
						</HeaderRow>
						<ContentRow content={CENTRE.description} />
						<ContentRow title={t("attribute:centre-address")} content={CENTRE.address} />
						<ContentRow title={t("attribute:centre-opening_hours")} content={CENTRE.openinghour} />
						<ContentRow title={t("attribute:centre-phone_number")} content={CENTRE.phone} />
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			<ContextualMenu id="image-menu" anchorEl={imageAnchorEl} setAnchorEl={setImageAnchorEl}>
				{CENTRE.photos ? (<ContextualMenuItem title={t("general:button-delete")}
					onClick={triggerDeletePhoto}>
					<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>) : null}
				<ContextualMenuItem title={t("general:button-add_entity", { entity: t("attribute:general-photos").toLowerCase() })} onClick={triggerAddNewPhoto}>
					<AddIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ContextualMenu id="edit-menu" anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				<ContextualMenuItem title={t("general:button-edit")} onClick={triggerEditCentreInfo}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<CentreEditDialog open={isDialogOpen} setOpenDialog={setIsDialogOpen} currentCentre={CENTRE} />
			<CentreUploadImageDialog open={isUploadImageDialogOpen} setOpen={setIsUploadImageDialogOpen} />
			<ConfirmActionDialog
				title={t("general:dialog_title-delete_entity", { entity: t("attribute:general-photo") })}
				actionName={t("general:button-delete")}
				open={isDeleteImageDialogOpen}
				setOpen={setIsDeleteImageDialogOpen}
				confirmOnClick={deleteSelectedPhoto} />
		</React.Fragment >
	);
}
