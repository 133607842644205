import React from 'react';
import { INITIAL_CENTRE_FORM } from "./../model/CentreFormModel";
import CentrePaymentFieldsSection from "./CentrePaymentFieldsSection";
import CentrePublicFieldsSection from "./CentrePublicFieldsSection";
import { useFormContext } from "react-hook-form";
import {Stack, Container} from "@mui/material";
export default function CentreFieldList({ currentCentre, hidePaymentInfo, showLabel }) {


    const { reset } = useFormContext();

    React.useEffect(() => {
        if (currentCentre) {
            reset(currentCentre);
        } else {
            reset(INITIAL_CENTRE_FORM)
        }
    }, [currentCentre])

    return (
        <Stack spacing={2.5}>
            {hidePaymentInfo ? null : (<Container><CentrePaymentFieldsSection showLabel={showLabel} /></Container>)}
            <Container><CentrePublicFieldsSection showLabel={showLabel} /></Container>
        </Stack>
    );
}
