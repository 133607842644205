import { useAuthenticationState } from "./../../redux/authentication";
import { isPlatformAdmin, isCentreAdmin, isCentreAdminBelongsToCentre } from "./../../util/ACLUtils";

export default function ViewableByAdminsOnly({ children, objectId }) {
    const AUTH_STATE = useAuthenticationState();
    let currentRole = AUTH_STATE.currentRole;
    if (currentRole) {
        let roleName = currentRole.roleName;
        if (isPlatformAdmin(roleName)) {
            return children;
        }
        if (isCentreAdmin(roleName)) {
            if (!objectId) {
                return children;
            }
            if (isCentreAdminBelongsToCentre(objectId, currentRole.centreId)) {
                return children
            }
        }
    }
    return null;
}
