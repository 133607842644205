import React from "react";
import { Typography } from '@mui/material';

export default function LimitedTextLabel(props) {
    let numberOfLineLimited = props.linelimited || 2;

    return (
        <Typography sx={Object.assign({
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: numberOfLineLimited,
            WebkitBoxOrient: "vertical"
        }, (props.customstyle ? props.customstyle : {}))} {...props}
        >
            {props.children}
        </Typography>)
}