import React from 'react';
import debounce from 'lodash.debounce';
import { useParams } from "react-router-dom";
import { useClassSessionManagementState, getClassSession } from "./../../../redux/ClassSessionManagement";
import CalendarView from "./../../display/CalendarView";

const ACTION_IN_CHECK = {
	DELETE_CLASS_SESSION: true,
	CONFIRM_CLASS_SESSION: true,
	CREATE_CLASS_SESSION: true
}

export default function StudentSchedulePage() {
	const { enrolmentId } = useParams();
	const CLASS_SESSION_REQUEST = useClassSessionManagementState().request;
	const EVENT = useClassSessionManagementState().classEvent || [];
	
	const eventOnClick = (info) => {
		info.jsEvent.preventDefault();
		window.open(info.event.url);
	}


	let deboundedGetClassSession = React.useMemo(() => debounce(getClassSession, 50), []);

	React.useEffect(() => {
		if (ACTION_IN_CHECK[CLASS_SESSION_REQUEST.lastAction]) {
			if (CLASS_SESSION_REQUEST.status === "REFRESH_DATA_REQUIRE") {
				deboundedGetClassSession({
					type: "student",
					queryId: enrolmentId
				});
			}
		}
	}, [CLASS_SESSION_REQUEST]);

	React.useEffect(() => {
		deboundedGetClassSession({
			type: "student",
			queryId: enrolmentId
		});
	}, []);

	React.useEffect(() => {
		deboundedGetClassSession({
			type: "student",
			queryId: enrolmentId
		});
	}, [enrolmentId]);

	return (
		<React.Fragment>
			<CalendarView events={EVENT}
				eventOnClick={eventOnClick}
			/>
		</React.Fragment >
	);
}
