import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import blue from '@mui/material/colors/blue';
import lightBlue from '@mui/material/colors/lightBlue';
import cyan from '@mui/material/colors/cyan';
import teal from '@mui/material/colors/teal';
import green from '@mui/material/colors/green';
import lightGreen from '@mui/material/colors/lightGreen';
import lime from '@mui/material/colors/lime';
import yellow from '@mui/material/colors/yellow';
import amber from '@mui/material/colors/amber';
import orange from '@mui/material/colors/orange';
import deepOrange from '@mui/material/colors/deepOrange';
import grey from '@mui/material/colors/grey';
import blueGrey from '@mui/material/colors/blueGrey';
import brown from '@mui/material/colors/brown';

export default function hueFromString(hue, shade) {
	var result = "";
	switch (hue) {
		case "grey":
			result = grey[shade];
			break;
		case "blueGrey":
			result = blueGrey[shade];
			break;
		case "brown":
			result = brown[shade];
			break;
		case "red":
			result = red[shade];
			break;
		case "pink":
			result = pink[shade];
			break;
		case "purple":
			result = purple[shade];
			break;
		case "deepPurple":
			result = deepPurple[shade];
			break;
		case "indigo":
			result = indigo[shade];
			break;
		case "blue":
			result = blue[shade];
			break;
		case "lightBlue":
			result = lightBlue[shade];
			break;
		case "cyan":
			result = cyan[shade];
			break;
		case "teal":
			result = teal[shade];
			break;
		case "green":
			result = green[shade];
			break;
		case "lightGreen":
			result = lightGreen[shade];
			break;
		case "lime":
			result = lime[shade];
			break;
		case "yellow":
			result = yellow[shade];
			break;
		case "amber":
			result = amber[shade];
			break;
		case "orange":
			result = orange[shade];
			break;
		case "deepOrange":
			result = deepOrange[shade];
			break;
		default :
			//do nothing
			break;
	}
	
	return result;
}