import { createReduxModule } from "hooks-for-redux"
import {
    signUpUser,
    getUserInfo,
    saveUserInfo
} from "./../api/accountAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalUpdatingRequest,
    generalSuccessReduxResponse,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";

function initialState() {
    return {
        updateAction: [],
        user: {

        },
        username: null,
        userToBeUpdated: {

        },
        request: initalRequestState()
    }
}

export const UPDATE_ACTION = {
    "USER_SIGNUP": "USER_SIGNUP",
    "GET_USER_PROFILE": "GET_USER_PROFILE",
    "SAVE_USER_INFO": "SAVE_USER_INFO"
};

export const [useAccountManagementState, {
    setUpdating,
    submitSignUpUser, submitSignUpUserFail, submitSignUpUserSuccess,
    updateUserInfo, updateUserInfoFail, updateUserInfoSuccess,
    getUserInformation, getUserInformationFail, getUserInformationSuccess,
    clearUsername
},
    accountManagementState
] = createReduxModule(
    "accountManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    submitSignUpUser: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.USER_SIGNUP),
        userToBeUpdated: payload.user
    }),
    submitSignUpUserFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    submitSignUpUserSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), { user: payload.data }),
    updateUserInfo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SAVE_USER_INFO),
        userToBeUpdated: payload.user
    }),
    updateUserInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateUserInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        user: payload.data,
        userToBeUpdated: {},
    }),
    getUserInformation: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_USER_PROFILE),
        username: payload
    }),
    getUserInformationFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getUserInformationSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), { user: payload.data }),
    clearUsername: (state, payload) => Object.assign({}, state, {
        username: ""
    })
}
);

const UPDATE_ACTION_HANDLER = {
    "USER_SIGNUP": {
        handler: async (currentState) => {
            var result = await signUpUser(currentState.userToBeUpdated);
            if (result) {
                if (result.success) {
                    submitSignUpUserSuccess({
                        "success": true,
                        "message": result.message
                    });
                    getUserInformation();
                } else {
                    submitSignUpUserFail({
                        "message": "signUpUser request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: submitSignUpUserFail
    },
    "GET_USER_PROFILE": {
        handler: async (currentState) => {
            var result = await getUserInfo(currentState.username);
            if (result) {
                if (result.success) {
                    getUserInformationSuccess({
                        "success": true,
                        "data": result.data,
                        "message": result.message
                    });
                } else {
                    getUserInformationFail({
                        "message": "getUserInformation request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getUserInformationFail
    },
    "SAVE_USER_INFO": {
        handler: async (currentState, action) => {
            var result = await saveUserInfo(currentState.userToBeUpdated);
            if (result) {
                if (result.success) {
                    updateUserInfoSuccess({
                        "success": true,
                        action: action,
                        "data": result.data,
                        "message": result.message
                    });
                } else {
                    updateUserInfoFail({
                        "message": "updateUserInfo request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateUserInfoFail
    }
}

accountManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});