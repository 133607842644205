import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import StudentFieldList from "./../../form/studentComponent/StudentFieldList";
import { useOptionsState } from "./../../../redux/Options";
import { saveStudentProfile } from "./../../../redux/ProfileManagement";
import PortraitUploader from "./../../form/commonComponent/PortraitUploader";
import { createStudentFormSchema, INITIAL_STUDENT_FORM } from "./../../form/model/StudentFormModel";
import { SuccessButton } from "./../../common/Buttons";
import { DialogFixedForm } from "./../../common/Forms";
import {nameInBracketStyle} from "./../../../util/DisplayUtil";

export default function StudentEditDialog({
    open,
    setOpenDialog,
    currentStudent
}) {
    const { t } = useTranslation("account", "attribute", "general");
    const [photoObject, setPhotoObject] = React.useState({});
    const VALID_VALUES = {
        GENDER: useOptionsState().gender.validValues,
        GRADE: useOptionsState().grade.validValues,
        ALIAS: useOptionsState().alias.validValues,
        RELATIONSHIP: useOptionsState().relationship.validValues,
        DISTRICT: useOptionsState().district.validValues
    };
    const StudentProfileSchema = createStudentFormSchema(VALID_VALUES.GENDER, VALID_VALUES.GRADE, VALID_VALUES.DISTRICT, VALID_VALUES.ALIAS, VALID_VALUES.RELATIONSHIP);

    const STUDENT_CARD_FORM_METHODS = useForm({
        resolver: yupResolver(StudentProfileSchema)
    });

    React.useEffect(() => {
        if (currentStudent && currentStudent.photo) {
            setPhotoObject(currentStudent.photo);
        } else {
            setPhotoObject({});
        }
    }, [open])

    React.useEffect(() => {
        if (currentStudent && currentStudent.photo) {
            setPhotoObject(currentStudent.photo);
        }
    }, [currentStudent])

    function saveUpdatedStudent(data) {
        var updatedStudent = Object.assign({}, data, { photo: photoObject });
        delete updatedStudent.contactPerson;
        let formData = new FormData();

        if (photoObject && photoObject.file && typeof photoObject.file != String) {
            formData.append("studentPhoto", photoObject.file);
        }
        formData.append("student", JSON.stringify(data));
        saveStudentProfile({
            student: updatedStudent,
            studentInForm: formData
        });
        STUDENT_CARD_FORM_METHODS.reset(INITIAL_STUDENT_FORM);
        setOpenDialog(false);
    }


    function closeEditDialog() {
        setPhotoObject({});
        STUDENT_CARD_FORM_METHODS.reset(INITIAL_STUDENT_FORM);
        setOpenDialog(false);

    }

    return (<Dialog
        open={open}
        onClose={closeEditDialog}
        scroll="paper"
        aria-labelledby="responsive-student-dialog-title"
    >
        <FormProvider {...STUDENT_CARD_FORM_METHODS}>
            <DialogFixedForm
                control={STUDENT_CARD_FORM_METHODS.control}
                errors={STUDENT_CARD_FORM_METHODS.errors}
                onSubmit={STUDENT_CARD_FORM_METHODS.handleSubmit(saveUpdatedStudent)}>
                <DialogTitle id="responsive-student-dialog-title">
                    {t("general:dialog_title-edit_entity-with_name", { entity: t("attribute:student_profile"), name: nameInBracketStyle(currentStudent.chiName, currentStudent.engName) })}
                </DialogTitle>
                <DialogContent dividers data-cy="edit_dialog_content">
                    <PortraitUploader id="edit_dialog_uploader" labelText={t("attribute:student-profile_picture")} photoObject={photoObject} setPhotoObject={setPhotoObject} />
                    <StudentFieldList currentStudent={currentStudent}></StudentFieldList>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button autoFocus data-cy={"edit_student_card_cancel"} onClick={closeEditDialog} variant="outlined">
                            {t("general:button-cancel")}
                        </Button>
                        <SuccessButton type="submit" data-cy={"edit_student_card_save"} variant="contained" >
                            {t("general:button-save")}
                        </SuccessButton>
                    </Grid>
                </DialogActions>
            </DialogFixedForm>
        </FormProvider>
    </Dialog>);
}