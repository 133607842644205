import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
	Paper, Grid, Stack, InputAdornment,
	Typography, Fab, TextField, Box, Checkbox,
	IconButton
} from "@mui/material";
import { alpha } from '@mui/material/styles';
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import SmsIcon from "@mui/icons-material/Sms";

import { SpaceHolder } from "./../../common/Others";
import { useParams } from "react-router-dom";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import debounce from 'lodash.debounce';
import ContextualMenu from "./../../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../../component/common/ConfirmActionDialog";
import { useAuthenticationState } from "./../../../redux/authentication";
import {
	useClassSessionManagementState,
	getActiveClassStudentForClassSession,
	removeStudentFromClassSession,
	updateStudentAttendance
} from "./../../../redux/ClassSessionManagement";
import ClassPendingStudentDialog from "./../../page/class/dialog/ClassPendingStudentDialog";
import ClassSessionAddStudentDialog from "./../../page/session/dialog/ClassSessionAddStudentDialog";
import { nameInBracketStyle } from "./../../../util/DisplayUtil";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EnhancedTableToolbar from "./EnhancedTableToolbar";
const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 900
}));

export default function ClassSessionStudentPage() {
	const { sessionId } = useParams();
	const { t } = useTranslation(["centre", "general"]);
	const CLASS_SESSION_INFO = useClassSessionManagementState().currentSession || {};
	const STUDENT_LIST = useClassSessionManagementState().activeClassStudentList || [];
	const STUDENT_COUNT = useClassSessionManagementState().activeClassStudentCount || 0;
	const SESSION_REQUEST = useClassSessionManagementState().request;
	const AUTH_STATE = useAuthenticationState();
	const ATTENDANCE = CLASS_SESSION_INFO.attendance || {};

	const [selectedStudent, setSelectedStudent] = React.useState(null);
	const [studentAnchorEl, setStudentAnchorEl] = React.useState(null);
	const [deleteStudentDialogOpen, setDeleteStudentDialogOpen] = React.useState(false);
	const [addStudentDialogOpen, setAddStudentDialogOpen] = React.useState(false);
	const [selected, setSelected] = React.useState([]);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [searchString, setSearchString] = React.useState(null);
	const [pendingStudentDialogOpen, setPendingStudentDialogOpen] = React.useState(false);


	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			let newSelected = STUDENT_LIST.map((n) => n._id);
			newSelected = newSelected.concat(newSelected, selected);
			setSelected(Array.from(new Set(newSelected)));
			return;
		}
		setSelected([]);
	};

	const triggerAddStudentDialog = (event) => {
		setAddStudentDialogOpen(true);
	}

	const deleteStudentFromClassSession = (event) => {
		removeStudentFromClassSession({ sessionId: sessionId, students: selectedStudent._id });
		setSelected([]);
	}

	const searchFieldOnChange = (event) => {
		setSearchString(event.target.value);
	}

	const toggleStudentContextualMenu = (event, studentFromOnClick) => {
		event.preventDefault();
		event.stopPropagation();
		setSelectedStudent(studentFromOnClick);
		setStudentAnchorEl(event.currentTarget);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const openNewStudentTab = (event) => {
		event.preventDefault();
		setStudentAnchorEl(null);
		window.open(`/student/${selectedStudent._id}`, "_blank");;
	}

	const navigateToChatroom = (event) => {
		event.preventDefault();
		setStudentAnchorEl(null);
        window.location.href = (`/message/direct?student=${selectedStudent._id}`);
    }

	const triggerDeleteDialog = (event) => {
		setStudentAnchorEl(null);
		setDeleteStudentDialogOpen(true);
	}

	const rowOnClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const markAsAbsent = (event) => {
		updateStudentAttendance({
			sessionId: sessionId,
			students: selected,
			value: "absent"
		});
		setSelected([]);
	}

	const markAsPresent = (event) => {
		updateStudentAttendance({
			sessionId: sessionId,
			students: selected,
			value: "present"
		});
		setSelected([]);
	}

	const debouncedGetActiveClassStudent = React.useMemo(() => debounce(getActiveClassStudentForClassSession, 50), []);

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const ACTION_IN_CHECK = {
		"ADD_STUDENT_TO_SESSION": true,
		"REMOVE_STUDENT_FROM_SESSION": true,
	};

	React.useEffect(() => {
		if (ACTION_IN_CHECK[SESSION_REQUEST.lastAction]) {
			if (SESSION_REQUEST.status === "REFRESH_DATA_REQUIRE") {
				debouncedGetActiveClassStudent({
					sessionId: sessionId,
					searchCriteria: {
						search: searchString, page: page, rowsPerPage: rowsPerPage
					}
				});
			}
		}
	}, [SESSION_REQUEST]);

	React.useEffect(() => {
		debouncedGetActiveClassStudent({
			sessionId: sessionId,
			searchCriteria: {
				search: searchString, page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [sessionId, searchString, page, rowsPerPage]);
	return (
		<React.Fragment>
			<Paper sx={{ marginTop: 0, marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-students")}</Typography>
							<Box>
								<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddStudentDialog} aria-label="comment">
									<AddIcon />
								</Fab>
							</Box>
						</HeaderRow>
						<Stack sx={{ width: "100%" }}>
							<Stack sx={{ width: "100%", marginTop: 0, marginBottom: 1 }} spacing={1}>
								<TextField
									id="outlined-start-adornment"
									fullWidth
									onChange={(event) => { searchFieldOnChange(event) }}
									placeholder={t("general:label-search")}
									InputProps={{
										startAdornment:
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>,
									}}
								/>
							</Stack>
						</Stack>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			<TableContainer sx={{ borderRadius: "10px", }} component={Paper}>

				<Table aria-label="simple table">
					<TableHead>
						{selected.length > 0 ? (<TableRow>
							<TableCell
								sx={{
									pl: 0.5,
									bgcolor: (theme) =>
										alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
								}} padding="none">
								<Checkbox
									color="primary"
									indeterminate={selected.length > 0 && selected.length < STUDENT_COUNT}
									checked={STUDENT_COUNT > 0 && selected.length === STUDENT_COUNT}
									onChange={handleSelectAllClick}
									inputProps={{
										'aria-label': 'select all desserts',
									}}
								/>
							</TableCell>
							<TableCell padding="none" colSpan={4}>
								<EnhancedTableToolbar numSelected={selected.length}
									markAsPresentHandler={markAsPresent}
									markAsAbsentHandler={markAsAbsent}
								/>
							</TableCell>
						</TableRow>) :
							(
								<TableRow  >
									<TableCell padding="checkbox">
										<Checkbox
											color="primary"
											indeterminate={selected.length > 0 && selected.length < STUDENT_COUNT}
											checked={STUDENT_COUNT > 0 && selected.length === STUDENT_COUNT}
											onChange={handleSelectAllClick}
											inputProps={{
												'aria-label': 'select all desserts',
											}}
										/>
									</TableCell>
									<BoldTableCell >{t("attribute:student-name")}</BoldTableCell>
									<BoldTableCell >{t("attribute:student-school")}</BoldTableCell>
									<BoldTableCell >{t("attribute:session-attendance")}</BoldTableCell>
									<BoldTableCell align="right"></BoldTableCell>
								</TableRow>
							)}
					</TableHead>
					<TableBody>
						{STUDENT_LIST.map((row) => {
							let profile = row.profile;
							const isItemSelected = isSelected(row._id);
							const labelId = `student-checkbox-${row._id}`;
							return (
								<TableRow key={profile._id}
									onClick={(event) => rowOnClick(event, row._id)}
								>
									<TableCell padding="checkbox">
										<Checkbox
											color="primary"
											checked={isItemSelected}
											inputProps={{
												'aria-labelledby': labelId,
											}}
										/>
									</TableCell>
									<TableCell component="th" scope="row">
										{nameInBracketStyle(profile.chiName, profile.engName)}
									</TableCell>
									<TableCell >{profile.school}</TableCell>
									<TableCell >{ATTENDANCE[row._id] ? t(`attribute:attendance-${ATTENDANCE[row._id]}`) : null}</TableCell>
									<TableCell align="right">
										<IconButton onClick={(event) => { toggleStudentContextualMenu(event, row) }} aria-label="delete">
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={t("general:label-rows_per_page")}
					rowsPerPageOptions={[5, 10, 25, 100]}
					component="div"
					count={STUDENT_COUNT}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>

			</TableContainer>
			<ContextualMenu id={"admin-menu"} anchorEl={studentAnchorEl} setAnchorEl={setStudentAnchorEl} >
				<ContextualMenuItem
					onClick={openNewStudentTab}
					title={t("general:button-view")}>
					<VisibilityIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				{AUTH_STATE.currentRole.roleName != "platform_admin" && <ContextualMenuItem
					onClick={navigateToChatroom}
					title={t("general:button-chat")}>
					<SmsIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>}
				<ContextualMenuItem
					onClick={triggerDeleteDialog}
					title={t("session:button-remove")}>
					<RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ClassSessionAddStudentDialog
				title={t("general:dialog_title-select_entity", { entity: t("attribute:student") })}
				isDialogOpen={addStudentDialogOpen}
				setIsDialogOpen={setAddStudentDialogOpen} />
			<ConfirmActionDialog
				title={t("general:dialog_title-remove_entity", { entity: t("attribute:student") })}
				actionName={t("general:button-remove")}
				open={deleteStudentDialogOpen}
				setOpen={setDeleteStudentDialogOpen}
				confirmOnClick={deleteStudentFromClassSession}
			/>
			<ClassPendingStudentDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:students") })}
				isDialogOpen={pendingStudentDialogOpen}
				setIsDialogOpen={setPendingStudentDialogOpen}
			/>
		</React.Fragment >
	);
}
