import { createContextualCan } from "@casl/react";
import React from "react";
import { AbilityBuilder, Ability } from '@casl/ability';


function defaultAbility() {
    const {
        can,
        build
    } = new AbilityBuilder(Ability);

    can('read', ['settings'], {
        userId: "default"
    });
    return build();
}


export const AbilityContext = React.createContext();
export const Can = createContextualCan(AbilityContext.Consumer);


export const ANNOYMOUS_ABILITY = defaultAbility();