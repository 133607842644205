import React from 'react';
import Sidebar from './../sidebar/Sidebar';
import siteIcon from "./../../asset/img/site_icon.png";
import { AppBar, Toolbar, Typography, Grid, IconButton, Drawer, Stack, Box, Badge } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import RouteContainer from "./../page/RouteContainer";
import LoadingPage from "./../page/LoadingPage";
import UserListItem from "./../menuItem/userListItem";
import LanguageSwitcher from "./../../component/I18N/LanguageSwitcher";
import RoleSwitcher from "./../../component/role/RoleSwitcher";
import MenuIcon from '@mui/icons-material/Menu';
import SmsIcon from '@mui/icons-material/Sms';
import { styled } from '@mui/material/styles';
import { useAuthenticationState } from "./../../redux/authentication";
import WithSelectedEnrolmentOnly from "./../acl/WithSelectedEnrolmentOnly";
import WithoutSelectedEnrolmentOnly from "./../acl/WithoutSelectedEnrolmentOnly";
import { useProfileState, getStudentProfile, getTutorProfile } from "./../../redux/ProfileManagement";
import { clearRedirect, useRedirectState } from "./../../redux/Redirect";
import io from "socket.io-client";
import MessageNotificationSound from "./../../asset/notification_sound/message.mp3";
import { useMessageManagementState, getUnreadMessagesCount } from "./../../redux/MessageManagement";
import { useSubscriptionManagementState, getCentreSubscription } from "./../../redux/SubscriptionManagement";

import {
    loadAliasOptions,
    loadDistrictOptions,
    loadEducationLevelOptions,
    loadGenderOptions,
    loadGradeOptions,
    loadMainLanguageOptions,
    loadRelationshipOptions,
    loadSubjectOptions,
    loadSystemLanguageOptions,
    loadWeekDayOptions
} from "./../../redux/Options";

const Title = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
}));

const SiteIcon = styled('img')(({ theme }) => ({
    width: "auto",
    objectFit: "contain",
    objectPosition: "50% 50%",
    height: "50px",
    margin: "10px"
}));

const socket = io(process.env.REACT_APP_SOCKET_IO_URL, { path:"/itutor_socket/",autoConnect: true });

export default function DashBoard() {
    const REDIRECT_STATE = useRedirectState();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const STUDENT_PROFILES = useProfileState().studentProfiles || [];
    const TUTOR_PROFILES = useProfileState().tutorProfiles || [];
    const [initialized, setInitialized] = React.useState(false);
    let navigate = useNavigate();
    const LOCATION = useLocation();
    const AUTH_STATE = useAuthenticationState();
    const PROFILE_REQUEST = useProfileState().request;
    const PROFILE_ACTION_QUEUE = useProfileState().updateAction;
    const AUTH_ACTION_QUEUE = useAuthenticationState().updateAction;
    const LOCATION_USERNAME = LOCATION.state ? LOCATION.state.username : null;
    const localStorageSessionID = React.useRef(localStorage.getItem("sessionID"));
    const documentTitle = React.useRef(document.title);
    const UNREAD_MESSAGES_COUNT = useMessageManagementState().unreadMessagesCount;
    const [notificationCount, setNotificationCount] = React.useState(0);
    const CENTRE_SUBSCRIPTION = useSubscriptionManagementState().subscribed;
    const [unsubscribed, setUnsubscribed] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prev) => !prev);
    };

    React.useEffect(() => {
        let path = REDIRECT_STATE.path;
        if (path) {
            navigate(path);
            clearRedirect();
        }
    }, [REDIRECT_STATE.path])

    React.useEffect(() => {
        loadAliasOptions();
        loadDistrictOptions();
        loadEducationLevelOptions();
        loadGenderOptions();
        loadGradeOptions();
        loadMainLanguageOptions();
        loadRelationshipOptions();
        loadSubjectOptions();
        loadSystemLanguageOptions();
        loadWeekDayOptions();

        let username = AUTH_STATE.username;
        if (LOCATION.state) {
            let state = LOCATION.state;
            if (state.username) {
                username = state.username;
            }
        }
        getStudentProfile({ username: username });
        getTutorProfile({ username: username });
        getUnreadMessagesCount({ username: username });
        window.scrollTo(0, 0);
        setInitialized(true);
    }, []);

    React.useEffect(() => {
        setNotificationCount(UNREAD_MESSAGES_COUNT);
    }, [UNREAD_MESSAGES_COUNT]);
    
    React.useEffect(() => {
        if (notificationCount > 0) {
            document.title = `(${notificationCount}) ${documentTitle.current}`;
        } else {
            document.title = documentTitle.current;
        }
    }, [notificationCount]);


    React.useEffect(() => {
        let registrationNotExists = AUTH_STATE.roles.length == 0;
        let profileNotExists = TUTOR_PROFILES.length == 0 && STUDENT_PROFILES.length == 0;
        if (initialized && PROFILE_ACTION_QUEUE.length == 0 && AUTH_ACTION_QUEUE.length == 0) {
            if (LOCATION.pathname == "/" && !LOCATION_USERNAME) {
                if (registrationNotExists) {
                    if (profileNotExists) {
                        navigate("/account", { state: {} });
                    } else {
                        navigate("/centres", { state: {} });
                    }
                } else {
                    if (AUTH_STATE.currentRole.centreId) {
                        if (AUTH_STATE.currentRole.roleName === "centre_admin") {
                            getCentreSubscription({ centreId: AUTH_STATE.currentRole.centreId });
                        } else {
                            setUnsubscribed(false);
                        }
                        navigate(`/centre/${AUTH_STATE.currentRole.centreId}`, { state: { hideEnrolmentSidebar: true } });
                    } else {
                        navigate("/centres", { state: {} });
                    }
                }
            }
        }
    }, [STUDENT_PROFILES, TUTOR_PROFILES, LOCATION.pathname, AUTH_ACTION_QUEUE])

    React.useEffect(() => {
        const rooms = [];
        AUTH_STATE.roles.forEach((role) => {
            if (role && (role.status == "active" || role.status == "pending")) {
                if (role.role == "centre_admin") {
                    rooms.push(role.centreId[0]);
                } else if (role.role == "student" || role.role == "tutor") {
                    rooms.push(role._id);
                }
            }
        });
        if (localStorageSessionID.current) {
            socket.auth = { sessionID: localStorageSessionID.current, userID: AUTH_STATE.userId, rooms: rooms };
        }else{
            socket.auth = { userID: AUTH_STATE.userId, rooms: rooms };
        }
        socket.connect();  
        
        if (!socket.hasListeners("SESSION")) {
            socket.on("SESSION", ({ sessionID, userID }) => {
                // attach the session ID to the next reconnection attempts
                socket.auth = { sessionID: sessionID, userID: AUTH_STATE.userId, rooms: rooms };
                
                // store it in the localStorage
                localStorage.setItem("sessionID", sessionID);
                // save the ID of the user
                socket.userID = AUTH_STATE.userId;
            });
        }
    }, []);

    React.useEffect(() => {
        if (socket.hasListeners("NOTIFICATION")) {
            socket.off("NOTIFICATION");
        }
        socket.on("NOTIFICATION", () => {
            setNotificationCount(notificationCount + 1);
            document.title = `(${notificationCount + 1}) ${documentTitle.current}`;
            new Audio(MessageNotificationSound).play();
        });
    }, [notificationCount]);

    React.useEffect(() => {
        const redirect = AUTH_STATE.currentRole.roleName === "centre_admin" && 
            CENTRE_SUBSCRIPTION && !(["active", "trialing", "cancel_at_period_end"].includes(CENTRE_SUBSCRIPTION?.status));
        setUnsubscribed(redirect);
        if (redirect) {
            navigate(`/centre_settings/${AUTH_STATE.currentRole.centreId}?tab=4`, { state: {} });
        } else if (!CENTRE_SUBSCRIPTION) {
            getCentreSubscription({ centreId: AUTH_STATE.currentRole.centreId });
        }
    }, [CENTRE_SUBSCRIPTION]);

    return (
        <Stack>
            <AppBar sx={{
                backgroundColor: (theme) => theme.palette.primary.dark,
                color: (theme) => theme.palette.primary.contrastText,
                width: { xs: "100vw", sm: "100vw" },
                maxWidth: { xs: "100vw", sm: "100vw" },
                zIndex: "1",
                position: "relative"
            }} >
                <Toolbar>
                    <Stack sx={{ width: "100vw" }} direction="row" alignItems="center" justifyContent="space-between">
                        <IconButton
                            sx={{ display: { xs:"none", sm: 'none', md: 'none' } }}
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                        
                        <SiteIcon sx={{ display: { xs: 'none', md: 'block' } }} alt="Company Logo" src={siteIcon} />
                        <Title variant="h6">
                            <RoleSwitcher />
                        </Title>
                        {AUTH_STATE.currentRole.roleName != "platform_admin" && 
                        <Box sx={{ display: { xs: "none", "sm": "block" } }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open chat"
                                disabled={unsubscribed}
                                onClick={(e) => window.location.href = (`/message`)}
                            >
                                {notificationCount > 0 && <Badge badgeContent={notificationCount} color="info">
                                    <SmsIcon/>
                                </Badge>}
                                {notificationCount == 0 && <SmsIcon/>}
                            </IconButton>
                        </Box>}
                        <Box sx={{ display: { xs: "none", "sm": "block" } }}>
                            <UserListItem documentTitle={documentTitle.current}/>
                        </Box>
                        <Box sx={{ display: { xs: "none", "sm": "block" } }}>
                            <LanguageSwitcher />
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Grid justifyContent="center" container>
                <React.Suspense fallback={<LoadingPage />}>
                    <WithSelectedEnrolmentOnly>
                        <Drawer sx={{ display: { xs: "block", sm: 'block', md: 'none' } }} anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
                            <Sidebar handleDrawerClose={setMobileOpen} isInDrawer={true} notificationCount={notificationCount} documentTitle={documentTitle.current}></Sidebar>
                        </Drawer>
                        <Stack sx={{ width: "100vw" }} justifyContent="flex-start" direction="row">
                            <Sidebar></Sidebar>
                            <RouteContainer socket={socket} notificationCount={notificationCount} setNotificationCount={setNotificationCount}/>
                        </Stack>
                    </WithSelectedEnrolmentOnly>
                    <WithoutSelectedEnrolmentOnly>
                        <Grid item xs={12} lg={12} xl={12}>
                            <RouteContainer socket={socket} notificationCount={notificationCount} setNotificationCount={setNotificationCount}/>
                        </Grid>
                    </WithoutSelectedEnrolmentOnly>
                </React.Suspense>
            </Grid>
        </Stack >
    );
}