import React from 'react';
import {
	Typography,
	Fab,
	Grid
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';


export default function GeneralHeader({ title, onClickHandler, displayButton }) {
	return (
		<Grid sx={{
			marginTop: 6,
			marginBottom: 0,
		}} alignItems="center" justifyContent="space-between" container item md={12} >
			<Typography variant="h4" component="div">{title}</Typography>
			{
				(onClickHandler && displayButton) ? (<Fab color="primary" onClick={onClickHandler} size="small">
					<AddIcon />
				</Fab>
				) : null
			}
		</Grid >
	);
}
