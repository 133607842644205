import React from "react";
import {
    Typography, Divider,
    Grid,
    IconButton, Paper, Stack,
    Tabs, Tab, ButtonBase,
    Breadcrumbs, Link
} from "@mui/material";
import { useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TabContainer } from "./../../../../component/common/Containers";
import { SpaceHolder } from "./../../../../component/common/Others";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useProfileState } from "./../../../../redux/ProfileManagement";
import { useAuthenticationState } from "./../../../../redux/authentication";
import { acceptTutorEnrolment, rejectTutorEnrolment, removeTutorEnrolment } from "./../../../../redux/Enrolment";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import useMediaQuery from '@mui/material/useMediaQuery';
import CanUpdateCentre from "./../../../acl/CanUpdateCentre";
import EmptyAvatar from "./../../../../asset/portrait/empty_avatar.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import SmsIcon from '@mui/icons-material/Sms';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ConfirmActionDialog from "./../../../../component/common/ConfirmActionDialog";
import { nameInBracketStyle } from "./../../../../util/DisplayUtil";

const LogoContainer = styled(Grid)(({ theme }) => ({
    "paddingBottom": theme.spacing(1),
    "paddingRight": theme.spacing(2)
}));

const ButtonGrid = styled(Grid)(({ theme }) => ({

}));

const LogoThumbnail = styled("img")(({ theme }) => ({
    objectFit: "cover",
    objectPosition: "50% 50%",
    display: "block",
    borderRadius: "10px",
    width: "100px",
    height: "100px"
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


function Buttons({ triggerTutorMenu, navigateToChatroom }) {
    const TUTOR_PROFILE_INFO = useProfileState().tutorProfileInfo || {};
    const CENTRE_ID = TUTOR_PROFILE_INFO.centreId;
    const CURRENT_ROLE = useAuthenticationState().currentRole;
    
    return (
        <CanUpdateCentre centreId={CENTRE_ID} 
        or={["student", "tutor"].includes(CURRENT_ROLE.roleName)}>
            <IconButton
                sx={{
                    marginTop: 2,
                    backgroundColor: "#DDDDDD",
                }}
                color="primary"
                aria-label="upload picture" component="label"
                onClick={triggerTutorMenu}
            >
                <MoreHorizIcon />
            </IconButton>
        </CanUpdateCentre>
    );
}

function ShowInStatus({ children, status }) {
    const TUTOR_PROFILE_INFO = useProfileState().tutorProfileInfo || {};

    if (TUTOR_PROFILE_INFO.status == status) {
        return children;
    }
    return null;
}

export default function TutorPageHeader({
    TAB_ELEMENTS, value, setValue }) {
    const { t } = useTranslation("general");
    let { enrolmentId } = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const TUTOR_PROFILE_INFO = useProfileState().tutorProfileInfo || {};
    const CENTRE_ID = TUTOR_PROFILE_INFO.centreId;
    const LOCATION = useLocation();
    const CURRENT_ROLE = useAuthenticationState().currentRole;
    
    const triggerTutorMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    const triggerDeleteDialog = (event) => {
        event.preventDefault();
        setDeleteDialogOpen(true);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate({
            pathname: ("/tutor/" + enrolmentId),
            search: createSearchParams({
                tab: newValue
            }).toString(),
        }, { state: LOCATION.state });
    };

    const acceptTutor = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        acceptTutorEnrolment({ enrolmentId: enrolmentId });
    }

    const rejectTutor = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        rejectTutorEnrolment({ enrolmentId: enrolmentId });
    }

    const triggerRemoveTutor = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        removeTutorEnrolment({ enrolmentId: enrolmentId });
    }

    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();

    const navigateToCentre = (event) => {
        navigate("/centre/" + CENTRE_ID + "?tab=tutor", { state: { hideEnrolmentSidebar: true } });
    }

    const navigateToChatroom = (event) => {
        window.location.href = `/message/direct?tutor=${enrolmentId}`;
    }

    return (
        <React.Fragment>
            <Paper sx={{ width: "100%", borderRadius: "10px" }}>
                <Grid container item sm={12} xs={12}>
                    <SpaceHolder container item sm={0.5} xs={0.5} />
                    {smUp ? (
                        <React.Fragment>
                            <Grid sx={{ marginTop: 3 }} container item sm={11}>
                                <LogoContainer container alignItems="flex-start" item xs={11} sm={10}
                                    justifyContent="flex-start"  >
                                    <Stack direction="row" justifyContent="flex-start"
                                        alignItems="center" spacing={2}>
                                        <ButtonBase disabled={true} sx={{ marginTop: 1 }}>
                                            {!TUTOR_PROFILE_INFO.photo || !TUTOR_PROFILE_INFO.photo.file || TUTOR_PROFILE_INFO.enrolmentId != enrolmentId ? (
                                                <LogoThumbnail alt="tutor_logo" src={EmptyAvatar} />) :
                                                (<LogoThumbnail alt="tutor_logo" src={TUTOR_PROFILE_INFO.photo.file} />
                                                )}
                                        </ButtonBase>
                                        <Stack spacing={1}>
                                            <Stack alignItems="center" direction="row">
                                                <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>{nameInBracketStyle(TUTOR_PROFILE_INFO.chiName, TUTOR_PROFILE_INFO.engName)}</Typography>
                                            </Stack>
                                            <Breadcrumbs sx={{ display: { xs: "none", sm: "block" },cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="small" />}>
                                                <Link underline="hover" onClick={navigateToCentre} color="secondary">
                                                    <Typography color="secondary">{TUTOR_PROFILE_INFO.centreDisplayName}</Typography>
                                                </Link>
                                                <Typography color="secondary">{TUTOR_PROFILE_INFO.status ? t("tutor:page_title-" + TUTOR_PROFILE_INFO.status) : ""}</Typography>
                                            </Breadcrumbs>
                                        </Stack>
                                    </Stack>
                                </LogoContainer>
                                <ButtonGrid container alignItems="flex-start" justifyContent="right"
                                    item xs={1} sm={2}>
                                    <Buttons triggerTutorMenu={triggerTutorMenu} navigateToChatroom={navigateToChatroom} />
                                </ButtonGrid>
                                <Grid container item sm={12}>
                                    <TabContainer>
                                        <Tabs value={value} onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            allowScrollButtonsMobile
                                            aria-label="tutor_settings_tabs">
                                            {TAB_ELEMENTS.map(
                                                (element, index) => (
                                                    <Tab
                                                        value={element.tab_index}
                                                        sx={{
                                                            textTransform: "none"
                                                        }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                                )
                                            )}
                                        </Tabs>
                                    </TabContainer>
                                </Grid>
                            </Grid>
                            <SpaceHolder container item sm={0.5} />
                        </React.Fragment>
                    ) : null}
                    {smDown ? (
                        <React.Fragment>
                            <Grid sx={{ marginTop: 3 }} container item xs={11.5}>
                                <LogoContainer container item xs={12} md={2}
                                    alignItems="center" justifyContent="space-between"  >
                                    <Grid container item xs={6}>
                                        <ButtonBase disabled={true}>
                                            {!TUTOR_PROFILE_INFO.photo || !TUTOR_PROFILE_INFO.photo.file || TUTOR_PROFILE_INFO.enrolmentId != enrolmentId ? (
                                                <LogoThumbnail alt="tutor_logo" src={EmptyAvatar} />) :
                                                (<LogoThumbnail alt="tutor_logo" src={TUTOR_PROFILE_INFO.photo.file} />
                                                )}
                                        </ButtonBase>
                                    </Grid>
                                    <ButtonGrid container alignItems="center" justifyContent="right" item xs={6} md={2}>
                                        <Buttons triggerTutorMenu={triggerTutorMenu} navigateToChatroom={navigateToChatroom}/>
                                    </ButtonGrid>
                                </LogoContainer>
                                <Grid sx={{}} container alignItems="center" justifyContent="center" item xs={12} md={8}>
                                    <Grid container item xs={12} md={12}>
                                        <Stack alignItems="center" direction="row">
                                            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>{nameInBracketStyle(TUTOR_PROFILE_INFO.chiName, TUTOR_PROFILE_INFO.engName)}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid container item xs={12} md={12}>
                                        <Breadcrumbs sx={{ display: { xs: "block", sm: "none" },cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="small" />}>
                                            <Link underline="hover" onClick={navigateToCentre} color="secondary">
                                                <Typography variant="subtitle2" component="div" color="secondary">{TUTOR_PROFILE_INFO.centreDisplayName}</Typography>
                                            </Link>
                                            <Typography variant="subtitle2" component="div" color="secondary">{TUTOR_PROFILE_INFO.status ? t("tutor:page_title-" + TUTOR_PROFILE_INFO.status) : ""}</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <SpaceHolder container item xs={0} />
                            <Grid container item xs={12} md={12}>
                                <TabContainer>
                                    <Divider sx={{ marginTop: 2.5 }} />
                                    <Tabs
                                        value={value} onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="tutor_page_tabs">
                                        {TAB_ELEMENTS.map((element, index) => (
                                            <Tab
                                                value={element.tab_index}
                                                sx={{
                                                    textTransform: "none",
                                                    minWidth: 70
                                                }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                        ))
                                        }
                                    </Tabs>
                                </TabContainer>
                            </Grid>
                        </React.Fragment>) : null}
                </Grid>
            </Paper>
            <ContextualMenu id={"header-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
                <ShowInStatus status="pending" >
                    <ContextualMenuItem
                        onClick={acceptTutor}
                        title={t("general:button-accept")}>
                        <CheckCircleOutlineIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    <ContextualMenuItem
                        onClick={rejectTutor}
                        title={t("general:button-delete")}>
                        <CancelIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    {CURRENT_ROLE.roleName != "platform_admin" && <ContextualMenuItem
                        onClick={navigateToChatroom}
                        title={t("tutor:button-chat")}>
                        <SmsIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>}
                </ShowInStatus>
                <ShowInStatus status="active" >
                    {CURRENT_ROLE.roleName != "platform_admin" && <ContextualMenuItem
                        onClick={navigateToChatroom}
                        title={t("tutor:button-chat")}>
                        <SmsIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>}
                    <CanUpdateCentre centreId={CENTRE_ID}>
                        <ContextualMenuItem
                            onClick={triggerDeleteDialog}
                            title={t("general:button-remove_entity", { entity: t("attribute:tutor").toLowerCase() })}>
                            <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                        </ContextualMenuItem>
                    </CanUpdateCentre>
                </ShowInStatus>
            </ContextualMenu>
            <ConfirmActionDialog
                title={t("general:dialog_title-remove_entity", { entity: t("attribute:tutor") })}
                actionName={t("general:button-remove")}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                confirmOnClick={triggerRemoveTutor} />
        </React.Fragment>
    )
}