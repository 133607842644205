import React from "react";
import {
    Grid,
    Dialog, DialogTitle, DialogContent
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { getClassFacility, addFacilityToClass, useClassesManagementState } from "./../../../../redux/ClassesManagement";
import CentreFacilityCard from "./../../centreDetail/CentreFacilityCard";


const NarrowRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

export default function CentreClassDialog({
    title, currentClass, isDialogOpen, setIsDialogOpen }) {


    const { classId } = useParams();

    const CLASS_FACILITIES_LIST = useClassesManagementState().classFacilityList || [];

    React.useEffect(() => {
        if (isDialogOpen) {
            getClassFacility({
                classId: classId
            })
        }
    }, [isDialogOpen, classId]);

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const addFacilityToClassOnClick = (event, currentFacility) => {
        event.preventDefault();
        setIsDialogOpen(false);
        addFacilityToClass({
            classId: classId,
            facilityId: currentFacility._id
        });

    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <NarrowRow>
                    <Grid container justifyContent="center" item sm={12} md={12}>
                        {CLASS_FACILITIES_LIST.map(
                            (currentFacility, index) => {
                                return (<Grid key={currentFacility._id + currentFacility.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
                                    <CentreFacilityCard key={currentFacility._id + "card"} handleOnClick={addFacilityToClassOnClick} currentFacility={currentFacility} index={index} />
                                </Grid>)
                            })
                        }
                    </Grid>
                </NarrowRow>
            </DialogContent>
        </Dialog >
    )
}