import React from "react";
import {
    Grid, Box,
    Dialog, DialogTitle, DialogContent,
    Stack, IconButton, Typography
} from "@mui/material";
import debounce from 'lodash.debounce';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getClassInfo, useClassesManagementState } from "../../../../redux/ClassesManagement";
import { getStudentBill ,useStudentBillManagementState, getStripeBillSecret,clearStripeBillSecretData } from "../../../../redux/StudentBillManagement";
import CloseIcon from "@mui/icons-material/Close";
import LoadingPage from "./../../../page/LoadingPage";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./../CheckoutForm";
import moment from "moment";



function BillInfoItem({ label, value }) {
    return (
        <Stack direction="row" justifyContent="space-between">
            <Grid xs={2} item>
                <Typography color="textSecondary" component="span">
                    {label}
                </Typography>
            </Grid>
            <Grid xs={10} item>
                {value}
            </Grid>
        </Stack>
    );
}

function BillAmountItem({ label, value, variant, textColor }) {
    const color = textColor || "textSecondary";
    return (
        <Stack direction="row" justifyContent="space-between">
            <Grid item>
                <Typography variant={variant} color={color} component="span">
                    {label}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={variant} color={color} component="span">
                    {value}
                </Typography>
            </Grid>
        </Stack>
    );
}


export default function PayBillDialog({
    bill, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["bill", "general", "config"]);
    const deouncedGetClassInfo = React.useMemo(() => debounce(getClassInfo, 50), []);
    const deouncedGetStripeBillSecret = React.useMemo(() => debounce(getStripeBillSecret, 50), []);
    const CLASS_INFO = useClassesManagementState().currentClass;
    const ACCOUNT_ID = useStudentBillManagementState().stripeAccountId;
    const CLIENT_SECRET = useStudentBillManagementState().stripeClientSecret;
    const STUDENT_BILL_REQUEST = useStudentBillManagementState().request;
    const [stripePromise, setStripePromise] = React.useState(null);
    let { classId } = useParams();
    const options = {
        // passing the client secret obtained from the server
        clientSecret: CLIENT_SECRET
    };

    React.useEffect(() => {
        if (isDialogOpen && classId) {
            deouncedGetClassInfo({ classId: classId });
        }
    }, []);

    React.useEffect(() => {
        if (isDialogOpen && classId) {
            deouncedGetClassInfo({ classId: classId });
        }
    }, [classId,isDialogOpen]);

    React.useEffect(() => {
        const dynamicallyLoadStripeObject = async () => {
            if (isDialogOpen && ACCOUNT_ID) {
                
                let loadedStripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
                    stripeAccount: ACCOUNT_ID
                });
                setStripePromise(loadedStripe);
            }
        };
        
        dynamicallyLoadStripeObject();
    }, [ACCOUNT_ID,isDialogOpen]);

    React.useEffect(() => {
        if (isDialogOpen && bill) {
            console.log("called");
            deouncedGetStripeBillSecret({
                billId: bill._id
            });
        }
    }, [isDialogOpen]);
    
    let { enrolmentId } = useParams();

    const checkoutFormSuccessCallback = () =>{
        closeDialog();
        //it is not suppose to have RPP and search string.
        const myTimeout = setTimeout(function(){
            getStudentBill({
                    enrolmentId: enrolmentId,
                    searchCriteria: {
                        search: null, page: 0, rowsPerPage: 10
                    }});    
        }, 2000);
        
    }

    const checkoutFormFailCallback = ()=>{
        //do nothing this phrase.
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
        clearStripeBillSecretData();
    }

    const CONTENT_PADDING = 4;
    if (bill && stripePromise && CLIENT_SECRET && !STUDENT_BILL_REQUEST?.updating ) {
        return (
            <Elements stripe={stripePromise} options={options}>
                <Dialog
                    open={isDialogOpen}
                    onClose={closeDialog}
                    scroll="paper"
                    maxWidth="md"
                    fullWidth
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <Stack sx={{ paddingLeft: CONTENT_PADDING, paddingRight: CONTENT_PADDING }} direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                                {t("bill:dialog_title-view_bill_for_entity", { entity: CLASS_INFO.name })}
                            </Box>
                            <Box>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeDialog}
                                    sx={{
                                        marginRight: -5,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </DialogTitle>
                    <DialogContent dividers>
                        <React.Suspense fallback={<LoadingPage />}>
                            <Stack sx={{padding:2}} spacing={2}>
                                <BillInfoItem label={t("attribute:bill-from")} value={CLASS_INFO?.centre?.displayName ? CLASS_INFO.centre.displayName : ""} />
                                <BillInfoItem label={t("attribute:bill-issue_date")} value={moment(bill.issueDate).format("LL")} />
                                <BillInfoItem label={t("attribute:bill-due_date")} value={moment(bill.dueDate).format("LL")} />
                                <BillAmountItem variant="h6" textColor="textPrimary" label={t("attribute:bill-amount-total")} value={`$ ${bill.billAmount || 0}`} />
                                <BillAmountItem label={t("attribute:bill-amount-paid")} value={`$ ${bill.paidAmount || 0}`} />
                                <BillAmountItem label={t("attribute:bill-amount-due")} value={`$ ${bill.billAmount - (bill.paidAmount || 0)}`} />
                            </Stack>
                            <CheckoutForm submitCallbackSuccess={checkoutFormSuccessCallback} submitCallbackFail={checkoutFormFailCallback} />
                        </React.Suspense>
                    </DialogContent>
                </Dialog >
            </Elements>
        );
    } else if(STUDENT_BILL_REQUEST?.error != null){
        return (<Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                scroll="paper"
                maxWidth="md"
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Stack sx={{ paddingLeft: CONTENT_PADDING, paddingRight: CONTENT_PADDING }} direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            {t("bill:dialog_title-view_bill_for_entity", { entity: CLASS_INFO.name })}
                        </Box>
                        <Box>
                            <IconButton
                                aria-label="close"
                                onClick={closeDialog}
                                sx={{
                                    marginRight: -5,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="h6">
                    {t("bill:error-cannot_load_bill")}
                    </Typography>
                </DialogContent>
            </Dialog >
            );
    } else {
        return (
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                scroll="paper"
                maxWidth="md"
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Stack sx={{ paddingLeft: CONTENT_PADDING, paddingRight: CONTENT_PADDING }} direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            {t("bill:dialog_title-view_bill_for_entity", { entity: CLASS_INFO.name })}
                        </Box>
                        <Box>
                            <IconButton
                                aria-label="close"
                                onClick={closeDialog}
                                sx={{
                                    marginRight: -5,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <LoadingPage />
                </DialogContent>
            </Dialog >
        )
    }

}