import React from 'react';
import { CENTRE_CLASS_FIELD } from "./../model/CentreFormModel";
import { useOptionsState } from "./../../../redux/Options";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, FormControl, FormHelperText, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import MultipleDropdownItems from "./../commonComponent/MultipleDropdownItems";
import OPTIONS_CATEGORY from "./../../../const/OptionsCategory";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

function ErrorMessage({ errors }) {
    const { t } = useTranslation("attribute");
    if (errors) {
        return (<FormHelperText>{t(errors.message)}</FormHelperText>);
    } else {
        return null;
    }
}

export default function CentreClassFieldList() {
    const StyledFormControl = styled(FormControl)(({ theme }) => ({
        "marginTop": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
    }));

    const StyledTextField = styled(TextField)(({ theme }) => ({
        "marginTop": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
    }));
    const OPTIONS_STATE = useOptionsState();
    const { control, formState: { errors }, setValue } = useFormContext();
    const { t } = useTranslation("attribute");

    return (
        <React.Fragment>
            <Controller
                name={CENTRE_CLASS_FIELD.name}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:class-name")}
                        helperText={errors[CENTRE_CLASS_FIELD.name] ? t(errors[CENTRE_CLASS_FIELD.name].message) : null}
                        error={errors[CENTRE_CLASS_FIELD.name] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_CLASS_FIELD.subjects}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="mainLanguageSelect"
                        error={errors[CENTRE_CLASS_FIELD.subjects] != null}>
                        <MultipleDropdownItems field={field}
                            options={OPTIONS_STATE[OPTIONS_CATEGORY.SUBJECT].options}
                            labelId="class-subjects-label"
                            labelText={t("attribute:class-subjects")} />
                        {
                            errors[CENTRE_CLASS_FIELD.subjects] ? <ErrorMessage errors={errors[CENTRE_CLASS_FIELD.subjects]} /> : null
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={CENTRE_CLASS_FIELD.grades}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="mainLanguageSelect"
                        error={errors[CENTRE_CLASS_FIELD.grades] != null}>
                        <MultipleDropdownItems field={field}
                            options={OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].options}
                            labelId="class-grades-label"
                            labelText={t("attribute:class-grades")} />
                        {
                            errors[CENTRE_CLASS_FIELD.grades] ? <ErrorMessage errors={errors[CENTRE_CLASS_FIELD.grades]} /> : null
                        }
                    </StyledFormControl>
                }
            />
            <Controller
                name={CENTRE_CLASS_FIELD.weekdays}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="mainLanguageSelect"
                        error={errors[CENTRE_CLASS_FIELD.weekdays] != null}>
                        <MultipleDropdownItems field={field}
                            options={OPTIONS_STATE[OPTIONS_CATEGORY.WEEK_DAY].options}
                            labelId="class-days-label"
                            labelText={t("attribute:class-days")} />
                        {
                            errors[CENTRE_CLASS_FIELD.weekdays] ? <ErrorMessage errors={errors[CENTRE_CLASS_FIELD.weekdays]} /> : null
                        }
                    </StyledFormControl>
                }
            />
            <Controller
                name={CENTRE_CLASS_FIELD.startTime}
                defaultValue={""}
                control={control}
                render={({ field }) => (
                    <TimePicker
                        {...field}
                        fullWidth
                        disableFuture
                        id={CENTRE_CLASS_FIELD.startTime}
                        label={t("attribute:class-time-start")}
                        error={errors[CENTRE_CLASS_FIELD.startTime] != null}
                        onChange={(newValue) => {
                            setValue(CENTRE_CLASS_FIELD.startTime, newValue);
                        }}
                        renderInput={(params) => <StyledTextField
                            {...params}
                            fullWidth
                            error={errors[CENTRE_CLASS_FIELD.startTime]}
                            helperText={errors[CENTRE_CLASS_FIELD.startTime] ? t(errors[CENTRE_CLASS_FIELD.startTime].message) : null}
                        />}
                    />
                )}
            />
            <Controller
                name={CENTRE_CLASS_FIELD.endTime}
                defaultValue={""}
                control={control}
                render={({ field }) => (
                    <TimePicker
                        {...field}
                        fullWidth
                        disableFuture
                        id={CENTRE_CLASS_FIELD.endTime}
                        label={t("attribute:class-time-end")}
                        error={errors[CENTRE_CLASS_FIELD.endTime] != null}
                        onChange={(newValue) => {
                            setValue(CENTRE_CLASS_FIELD.endTime, newValue);
                        }}
                        renderInput={(params) => <StyledTextField
                            {...params}
                            fullWidth
                            error={errors[CENTRE_CLASS_FIELD.endTime]}
                            helperText={errors[CENTRE_CLASS_FIELD.endTime] ? t(errors[CENTRE_CLASS_FIELD.endTime].message) : null}
                        />}
                    />
                )}
            />
            <Controller
                name={CENTRE_CLASS_FIELD.classFee}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                        }}
                        type="number"
                        variant="outlined" fullWidth
                        label={t("attribute:class-class_fee")}
                        helperText={errors[CENTRE_CLASS_FIELD.classFee] ? t(errors[CENTRE_CLASS_FIELD.classFee].message) : null}
                        error={errors[CENTRE_CLASS_FIELD.classFee] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_CLASS_FIELD.classSize}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        type="number"
                        label={t("attribute:class-class_size")}
                        helperText={errors[CENTRE_CLASS_FIELD.classSize] ? t(errors[CENTRE_CLASS_FIELD.classSize].message) : null}
                        error={errors[CENTRE_CLASS_FIELD.classSize] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={CENTRE_CLASS_FIELD.description}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        minRows={2}
                        multiline variant="outlined" fullWidth
                        label={t("attribute:class-description")}
                        helperText={errors[CENTRE_CLASS_FIELD.description] ? t(errors[CENTRE_CLASS_FIELD.description].message) : null}
                        error={errors[CENTRE_CLASS_FIELD.description] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={CENTRE_CLASS_FIELD.remarks}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        minRows={2}
                        multiline variant="outlined" fullWidth
                        label={t("attribute:class-remarks")}
                        helperText={errors[CENTRE_CLASS_FIELD.remarks] ? t(errors[CENTRE_CLASS_FIELD.remarks].message) : null}
                        error={errors[CENTRE_CLASS_FIELD.remarks] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
