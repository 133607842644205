import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
	Grid, Box, Fab,
	Typography, Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAuthenticationState } from "./../../../redux/authentication";
import { getTutorActiveClass, useClassesManagementState } from "./../../../redux/ClassesManagement";
import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";
import PendingClassDialog from "./../../page/class/dialog/PendingClassDialog";
import ClassCard from "./../../page/class/ClassCard";
import CentreClassDialog from "./../../page/class/dialog/CentreClassDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

export default function TutorClassPage() {
	const { t } = useTranslation(["centre", "general"]);
	const TUTOR_ACTIVE_CLASSES = useClassesManagementState().tutorActiveClasses || [];
	const PENDING_CLASS = useClassesManagementState().tutorPendingClasses || [];
	const navigate = useNavigate();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [addDialogOpen, setAddDialogOpen] = React.useState(false);
	const AUTH_STATE = useAuthenticationState();
	const CURRENT_ROLE = AUTH_STATE.currentRole;

	let { enrolmentId } = useParams();

	let deboundedGetTutorActiveClass = React.useMemo(() => debounce(getTutorActiveClass, 50), []);

	React.useEffect(() => {
		deboundedGetTutorActiveClass({
			enrolmentId: enrolmentId
		});

	}, [])

	React.useEffect(() => {
		deboundedGetTutorActiveClass({
			enrolmentId: enrolmentId
		});
	}, [enrolmentId])

	const IS_OWN_TUTOR_PAGE = CURRENT_ROLE.roleId == enrolmentId;

	const triggerAddDialog = (event) => {
		event.preventDefault();
		setAddDialogOpen(true);
	}

	const handleClick = (event) => {
		event.preventDefault();
		if (PENDING_CLASS.length > 0) {
			setDialogOpen(true);
		}
	}

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-classes")}</Typography>
							{IS_OWN_TUTOR_PAGE ? (
								<React.Fragment>
									<Box>
										<Button onClick={handleClick} sx={{ height: "50px", width: "140px" }} variant="contained">
											{t("general:button-pending") + ` (${PENDING_CLASS.length})`}
										</Button>
										<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddDialog} aria-label="comment">
											<AddIcon />
										</Fab>
									</Box>
								</React.Fragment>
							) :
								null}
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>
			<Grid container justifyContent="center" item sm={12} md={12}>
				{TUTOR_ACTIVE_CLASSES.map(
					(currentClass, index) => {
						return (
							<ClassCard index={index} key={currentClass._id + currentClass.displayName} cardOnClick={() => { navigate(`/class/${currentClass._id}`) }} currentClass={currentClass} />
						)
					})
				}
			</Grid>
			<CentreClassDialog
				title={t("general:dialog_title-add_entity", { entity: t("attribute:class") })}
				currentCentreId={CURRENT_ROLE.centreId}
				isDialogOpen={addDialogOpen}
				setIsDialogOpen={setAddDialogOpen} />
			<PendingClassDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:classes") })}
				pendingClassList={PENDING_CLASS}
				isDialogOpen={dialogOpen}
				setIsDialogOpen={setDialogOpen} />
		</React.Fragment >
	);
}

