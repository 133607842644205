import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import {
	Grid, Typography
} from "@mui/material";

import { getStudentActiveClass, useClassesManagementState } from "./../../../redux/ClassesManagement";
import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";

import ClassCard from "./../../page/class/ClassCard";


const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

export default function StudentClassPage() {
	const { t } = useTranslation(["centre", "general"]);
	const STUDENT_ACTIVE_CLASSES = useClassesManagementState().studentActiveClasses || [];
	const navigate = useNavigate();

	let { enrolmentId } = useParams();

	let deboundedGetStudentActiveClass = React.useMemo(() => debounce(getStudentActiveClass, 50), []);

	React.useEffect(() => {
		deboundedGetStudentActiveClass({
			enrolmentId: enrolmentId
		});

	}, [])

	React.useEffect(() => {
		deboundedGetStudentActiveClass({
			enrolmentId: enrolmentId
		});
	}, [enrolmentId])

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-classes")}</Typography>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>
			<Grid container justifyContent="center" item sm={12} md={12}>
				{STUDENT_ACTIVE_CLASSES.map(
					(currentClass, index) => {
						return (
							<ClassCard index={index} key={currentClass._id + currentClass.displayName} cardOnClick={() => { navigate(`/class/${currentClass._id}`) }} currentClass={currentClass} />
						)
					})
				}
			</Grid>
		</React.Fragment >
	);
}

