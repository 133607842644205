import React from 'react';
import {  CENTRE_POST_FIELD } from "./../model/CentreFormModel";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function CentrePostFieldList({}) {

    const StyledTextField = styled(TextField)(({ theme }) => ({
        "marginTop": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
    }));

    const { control, formState: { errors } ,setValue} = useFormContext();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Controller
                name={CENTRE_POST_FIELD.subject}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:post-title")}
                        helperText={errors[CENTRE_POST_FIELD.subject] ? t(errors[CENTRE_POST_FIELD.subject].message) : null}
                        error={errors[CENTRE_POST_FIELD.subject] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={CENTRE_POST_FIELD.content}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        minRows={6}
                        multiline variant="outlined" fullWidth
                        label={t("attribute:post-content")}
                        helperText={errors[CENTRE_POST_FIELD.content] ? t(errors[CENTRE_POST_FIELD.content].message) : null}
                        error={errors[CENTRE_POST_FIELD.content] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_POST_FIELD.publishDate}
                control={control}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        fullWidth
                        disableFuture
                        openTo="year"
                        views={["year", "month", "day"]}
                        id={CENTRE_POST_FIELD.publishDate}
                        format="DD/MM/yyyy"
                        label={t("attribute:post-publish_date")}
                        onChange={(newValue) => {
                            setValue(CENTRE_POST_FIELD.publishDate, newValue);
                        }}
                        renderInput={(params) => <StyledTextField {...params} fullWidth
                            helperText={errors[CENTRE_POST_FIELD.publishDate] ? t(errors[CENTRE_POST_FIELD.publishDate].message) : null}
                            error={errors[CENTRE_POST_FIELD.publishDate] != null}
                        />}
                    />
                )}
            />


        </React.Fragment>
    );
}
