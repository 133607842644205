import { createReduxModule } from "hooks-for-redux"
import {
	uploadHomeworkListRequest,
	getHomeworkListRequest,
	deleteHomeworkListRequest
} from "./../api/homeworkAPI.tsx";
import {
	generalFailReudxResponse,
	pushActionToUpdateActionList,
	generalSuccessReduxResponse,
	generalUpdatingRequest,
	generalReduxRequestController,
	initalRequestState
} from "./../util/ReduxUtils";

function initialState() {
	return {
		updateAction: [],
		enrolmentId: null,
		homeworkLists: [],
		homeworkListToBeUploaded: null,
		homeworkListToBeDeleted: null,
		request: initalRequestState()
	}
}

export const UPDATE_ACTION = {
	"UPLOAD_HOMEWORK_LIST": "UPLOAD_HOMEWORK_LIST",
	"DELETE_HOMEWORK_LIST": "DELETE_HOMEWORK_LIST",
	"GET_HOMEWORK_LISTS": "GET_HOMEWORK_LISTS"
};

export const [useHomeworkManagementState, {
	setUpdating,
	uploadHomeworkList, uploadHomeworkListFail, uploadHomeworkListSuccess,
	getHomeworkList, getHomeworkListFail, getHomeworkListSuccess,
	deleteHomeworkList, deleteHomeworkListFail, deleteHomeworkListSuccess
},
	homeworkManagementState
] = createReduxModule(
	"homeworkManagementState",
	initialState(), {
	setUpdating: (state, payload) => Object.assign({}, state, {
		request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
	}),
	uploadHomeworkList: (state, payload) => Object.assign({}, state, {
		updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPLOAD_HOMEWORK_LIST),
		homeworkListToBeUploaded: payload.formData
	}),
	uploadHomeworkListFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
	uploadHomeworkListSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
	}),
	getHomeworkList: (state, payload) => Object.assign({}, state, {
		updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_HOMEWORK_LISTS),
		enrolmentId: payload.enrolmentId
	}),
	getHomeworkListFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
	getHomeworkListSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
		homeworkLists: payload.homeworkLists,
	}),
	deleteHomeworkList: (state, payload) => Object.assign({}, state, {
		updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_HOMEWORK_LIST),
		homeworkListToBeDeleted: {
			enrolmentId: payload.enrolmentId,
			file: payload.file
		}
	}),
	deleteHomeworkListFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
	deleteHomeworkListSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
	})
});

const UPDATE_ACTION_HANDLER = {
	"UPLOAD_HOMEWORK_LIST": {
		handler: async (currentState, action) => {
			var result = await uploadHomeworkListRequest(currentState.homeworkListToBeUploaded);
			if (result) {
				if (result.success) {
					let users = [];
					let userCount = 0;
					if (result.data) {
						users = result.data.users;
						userCount = result.data.count;
					}
					uploadHomeworkListSuccess({
						"success": true,
						"user": users,
						"userCount": userCount,
						"message": result.message,
						"action": action,
						"requestStatus": result.status,
					});
				} else {
					uploadHomeworkListFail({
						"message": "uploadHomeworkList request failed",
						error: result.error
					});
				}
			}
		},
		failHandler: uploadHomeworkListFail
	}, "DELETE_HOMEWORK_LIST": {
		handler: async (currentState, action) => {
			var result = await deleteHomeworkListRequest(currentState.homeworkListToBeDeleted);
			if (result) {
				if (result.success) {
					deleteHomeworkListSuccess({
						"success": true,
						"action": action,
						"requestStatus": result.status,
						"message": result.message
					});
				} else {
					deleteHomeworkListFail({
						"message": "deleteHomeworkList request failed",
						error: result.error
					});
				}
			}
		},
		failHandler: deleteHomeworkListFail
	}, "GET_HOMEWORK_LISTS": {
		handler: async (currentState, action) => {
			var result = await getHomeworkListRequest(currentState.enrolmentId);
			if (result) {
				if (result.success) {
					getHomeworkListSuccess({
						"success": true,
						"action": action,
						"requestStatus": result.status,
						"homeworkLists": result.data,
						"message": result.message
					});
				} else {
					getHomeworkListFail({
						"message": "uploadHomeworkList request failed",
						error: result.error
					});
				}
			}
		},
		failHandler: getHomeworkListFail
	}
}

homeworkManagementState.subscribe((currentState) => {
	generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});