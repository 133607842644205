import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import {
	Grid, Button, Card, CardContent, Typography, CardActions, Container,
	Dialog, DialogTitle, DialogContent, DialogActions, Box, Backdrop, Paper
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { getPlans, usePlanManagementState } from "./../../../../redux/PlanManagement";
import { useAuthenticationState } from "./../../../../redux/authentication";

function Plan({ planId, name, monthlyPrice, studentQuota, isCurrent, subscribed, freeTrialDays }) {
	const { t } = useTranslation();
    const { centreId } = useParams();

	const stripeActive = ["active", "trialing", "exceeded_quota"].includes(subscribed?.status);

	const createStripeSession = async (event) => {
		event.preventDefault();
		
		const res = stripeActive && (subscribed?.trialed || monthlyPrice === 0) ? 
			await fetch(`/subscription/update?centre=${centreId}&plan=${planId}`, { method: "GET" }) :
			await fetch(`/subscription/checkout?centre=${centreId}&plan=${planId}`, { method: "GET" });

		const body = await res.json();
		window.location.href = body.data.url;
	}

	return (
		<Card raised
			style={{
				display: 'inline-block',
				marginLeft: '12px',
				marginRight: '12px',
				width: '250px',
				height: '450px'
			}}>
			<CardContent style={{ height: '15%', position: 'relative' }}>
				<Box style={{ height: '50%' }}>
					<Typography style={{ fontWeight: 'bold' }}
						variant="h5" 
						color={'primary'}>
						{`${name}`}
					</Typography>
				</Box>
				<Box style={{ bottom: '10%', position: 'absolute', display: 'flex' }}>
					<Typography style={{ fontWeight: 'bold' }}
						variant="body1">
						{`$${monthlyPrice}`}
					</Typography>
					<Typography style={{ marginLeft: '5%' }}
						variant="body1" color={'secondary'}> 
						{t("subscription:label-per_month")}
					</Typography>
				</Box>
			</CardContent>
			<CardActions style={{ justifyContent: 'center', height: '20%' }}>
				<Button 
					variant="contained" 
					size="medium"
					disabled={(stripeActive && isCurrent) || subscribed?.currentStudent > studentQuota}
					onClick={createStripeSession}>
					{`${stripeActive && isCurrent ? t("subscription:button-current_plan") 
						: subscribed?.trialed || freeTrialDays == 0 ? t("subscription:button-choose") : t("subscription:button-try_for_free")}`}</Button>
			</CardActions>
			<CardContent style={{ height: '45%' }}>
				<Typography style={{ fontWeight: 'bold'}}
					variant="subtitle1">
					<CheckIcon fontSize="small" /> {`${studentQuota} ${t("subscription:label-students")}`}
				</Typography>
				<Typography variant="subtitle1">
					<CheckIcon fontSize="small" /> {`${t("subscription:label-unlimited_admins")}`}
				</Typography>
				<Typography variant="subtitle1">
					<CheckIcon fontSize="small" /> {`${t("subscription:label-unlimited_tutors")}`}
				</Typography>
				<Typography variant="subtitle1">
					<CheckIcon fontSize="small" /> {`${t("subscription:label-unlimited_classes")}`}
				</Typography>
				<Typography variant="subtitle1">
					<CheckIcon fontSize="small" /> {`${t("subscription:label-all_advanced_features")}`}
				</Typography>
			</CardContent>
		</Card>
	);
}

export default function SubscriptionDialog({ open, setOpen, currentPlan, subscribed }) {

	const { t } = useTranslation();

    const PLANS = usePlanManagementState().plans || [];

	const canClose = ["active", "trialing", "cancel_at_period_end"].includes(subscribed?.status);

	const closeLogoDialog = (event) => {
		if (canClose) {
			setOpen(false);
		}
	}

    let deboundedGetPlans = React.useMemo(() => debounce(getPlans, 50), []);

	useEffect(() => {
        deboundedGetPlans({ rowsPerPage: 0 });
	}, []);

	return (
		<Dialog open={open}
			onClose={closeLogoDialog}
			scroll="paper"
			aria-labelledby="responsive-dialog-title"
			maxWidth="lg"
			sx={{ zIndex: 0 }}>
			<DialogTitle id="responsive-dialog-title">
				{t("subscription:dialog_title-choose_your_plan")}
				{canClose ? (
					<IconButton
						aria-label="close"
						onClick={closeLogoDialog}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
			<DialogContent dividers >
				<Container
					maxWidth="lg"
					style={{
						scrollBehavior: 'smooth',
						overflowX: 'auto',
						whiteSpace: 'nowrap',
						padding: '16px',
					}}>
					{PLANS.map(plan => {
						return <Plan key={plan._id}
							planId={plan._id}
							name={plan.name} 
							monthlyPrice={plan.monthlyPrice} 
							studentQuota={plan.studentQuota}
							isCurrent={plan._id === currentPlan}
							subscribed={subscribed}
							freeTrialDays={plan.freeTrialDays}/>
					})}
				</Container>
			</DialogContent>
		</Dialog>
	);
}




