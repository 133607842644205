import React from 'react';
import { useAuthenticationState } from "../../redux/authentication";
import {
    useLocation,
    Navigate
} from "react-router-dom";
import { isPlatformAdmin } from "../../util/ACLUtils";

export default function NotPlatformAdmin({ children }) {
    const AUTH_STATE = useAuthenticationState();
    let location = useLocation();
    let currentRole = AUTH_STATE.currentRole;
    if (currentRole) {
        let roleName = currentRole.roleName;
        if (!isPlatformAdmin(roleName)) {
            return children;
        }
    };
    return <Navigate to="/" state={{ from: location }} replace />;

}
