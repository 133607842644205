import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { subject } from "@casl/ability";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
	Paper, Grid,
	Typography, Fab, Box,
	IconButton
} from "@mui/material";
import moment from "moment";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import { Can } from "./../../../casl/authorization";

import { nameInBracketStyle } from "./../../../util/DisplayUtil";
import { useAuthenticationState } from "./../../../redux/authentication";
import { getPayslips, deletePayslip, usePayslipManagementState } from "./../../../redux/PayslipManagement";
import { useProfileState } from "./../../../redux/ProfileManagement";
import { TabTitleContainer } from "../../common/Containers";
import { SpaceHolder } from "../../common/Others";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import ContextualMenu from "./../../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../../component/common/ConfirmActionDialog";

import TutorPayslipsDialog from "./../tutor/dialog/TutorPayslipsDialog";
import TutorViewPayslipsDialog from "./../tutor/dialog/TutorViewPayslipsDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 900
}));

export default function TutorPayslipPage() {
	const { t } = useTranslation(["centre", "general"]);

	const TUTOR_PAYSLIPS = usePayslipManagementState().payslips || [];
	const TUTOR_PAYSLIPS_COUNT = usePayslipManagementState().payslipsCount || 0;
	const PAYSLIP_REQUEST = usePayslipManagementState().request || null;
	const TUTOR_PROFILE_INFO = useProfileState().tutorProfileInfo || {};

	const [selectedPayslips, setSelectedPayslips] = React.useState(null);
	const [payslipsAnchorEl, setPayslipsAnchorEl] = React.useState(null);

	const [addDialogOpen, setAddDialogOpen] = React.useState(false);
	const [editDialogOpen, setEditDialogOpen] = React.useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}
	const ACTION_IN_CHECK = {
		UPDATE_PAYSLIP: true,
		ISSUE_PAYSLIP: true
	}

	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;
	const CURRENT_ROLE = AUTH_STATE.currentRole;

	let { enrolmentId } = useParams();

	let deboundedGetPayslips = React.useMemo(() => debounce(getPayslips, 50), []);


	React.useEffect(() => {
		if (ACTION_IN_CHECK[PAYSLIP_REQUEST.lastAction]) {
			if (PAYSLIP_REQUEST.status === "REFRESH_DATA_REQUIRE") {
				deboundedGetPayslips({
					tutorProfileId: enrolmentId
				});
			}
		}
	}, [PAYSLIP_REQUEST]);

	React.useEffect(() => {
		deboundedGetPayslips({
			tutorProfileId: enrolmentId
		});
	}, [])

	React.useEffect(() => {
		deboundedGetPayslips({
			tutorProfileId: enrolmentId
		});
	}, [enrolmentId])

	const togglePayslipsContextualMenu = (event, payslipFormOnClick) => {
		event.preventDefault();
		setSelectedPayslips(payslipFormOnClick);
		setPayslipsAnchorEl(event.currentTarget);
	};

	const triggerDownloadPayslips = (event) => {
		event.preventDefault();
		setPayslipsAnchorEl(false);
		setDownloadDialogOpen(true);
	}

	const triggerAddDialog = (event) => {
		event.preventDefault();
		setPayslipsAnchorEl(false);
		setAddDialogOpen(true);
	}

	const openEditPayslipsDialog = (event) => {
		event.preventDefault();
		setPayslipsAnchorEl(false);
		setEditDialogOpen(true);
	}

	const triggerDeletePayslipsDialog = (event) => {
		event.preventDefault();
		setPayslipsAnchorEl(false);
		setDeleteDialogOpen(true);
	}

	const confirmDeletePayslips = (event) => {
		setDeleteDialogOpen(false);
		deletePayslip(selectedPayslips);
	}

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-payslips")}</Typography>
							<React.Fragment>
								<Box>
									<Can I="issue" this={"payslip"} field="name" ability={ABILITY} >
										<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddDialog} aria-label="comment">
											<AddIcon />
										</Fab>
									</Can>
								</Box>
							</React.Fragment>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>

			<TableContainer sx={{ borderRadius: "10px", }} component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow  >
							<BoldTableCell >{t("attribute:payslip-pay_period")}</BoldTableCell>
							<BoldTableCell >{t("attribute:payslip-pay_date")}</BoldTableCell>
							<BoldTableCell >{t("attribute:payslip-net_pay")}</BoldTableCell>
							<BoldTableCell align="right"></BoldTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{TUTOR_PAYSLIPS.map((payslips) => {
							return (
								<TableRow key={payslips._id}>
									<TableCell component="th" scope="row">
										{moment(payslips.payPeriodFrom).format("LL")} - {moment(payslips.payPeriodTo).format("LL")}
									</TableCell>
									<TableCell >{moment(payslips.paymentDate).format("LL")}</TableCell>
									<TableCell >{payslips.netPay}</TableCell>
									<TableCell align="right">
										<IconButton onClick={(event) => { togglePayslipsContextualMenu(event, payslips) }} aria-label="delete">
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={t("general:label-rows_per_page")}
					rowsPerPageOptions={[1, 5, 10, 25]}
					component="div"
					count={TUTOR_PAYSLIPS_COUNT}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>

			<ContextualMenu id={"admin-menu"} anchorEl={payslipsAnchorEl} setAnchorEl={setPayslipsAnchorEl}>
				{selectedPayslips ? (
					<React.Fragment>
						<ContextualMenuItem
							permission={{
								I: "read",
								this: subject("payslip", { "tutorProfileId": enrolmentId, "centreId": selectedPayslips.centreId }),
								ability: ABILITY
							}}
							onClick={triggerDownloadPayslips}
							title={t("general:button-view_download")}>
							<VisibilityIcon sx={{ mr: 0 }} fontSize="small" />
						</ContextualMenuItem>
						<ContextualMenuItem
							permission={{
								I: "update",
								this: subject("payslip", { "centreId": selectedPayslips.centreId }),
								ability: ABILITY
							}}
							onClick={openEditPayslipsDialog}
							title={t("general:button-edit")}>
							<EditIcon sx={{ mr: 0 }} fontSize="small" />
						</ContextualMenuItem>
						<ContextualMenuItem
							permission={{
								I: "delete",
								this: subject("payslip", { "centreId": selectedPayslips.centreId }),
								ability: ABILITY
							}}
							onClick={triggerDeletePayslipsDialog}
							title={t("general:button-delete")}>
							<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
						</ContextualMenuItem>
					</React.Fragment>
				) : null}
			</ContextualMenu>

			<TutorPayslipsDialog
				title={t("tutor:dialog_title-issue_payslip", { name: nameInBracketStyle(TUTOR_PROFILE_INFO.chiName, TUTOR_PROFILE_INFO.engName) })}
				centreId={CURRENT_ROLE.centreId}
				isDialogOpen={addDialogOpen}
				setIsDialogOpen={setAddDialogOpen}
			/>

			{selectedPayslips ? (
				<React.Fragment>
					<TutorViewPayslipsDialog
						title={t("attribute:payslip")}
						centreId={selectedPayslips.centreId}
						payslip={selectedPayslips}
						isDialogOpen={downloadDialogOpen}
						setIsDialogOpen={setDownloadDialogOpen}
					/>
					<TutorPayslipsDialog
						title={t("general:dialog_title-edit_entity", { entity: t("attribute:payslip") })}
						centreId={selectedPayslips.centreId}
						payslip={selectedPayslips}
						isDialogOpen={editDialogOpen}
						setIsDialogOpen={setEditDialogOpen}
					/>

					<ConfirmActionDialog
						title={t("general:dialog_title-delete_entity", { entity: t("attribute:payslip") })}
						actionName={t("general:button-delete")}
						open={deleteDialogOpen}
						setOpen={setDeleteDialogOpen}
						confirmOnClick={confirmDeletePayslips}
					/>
				</React.Fragment>
			) : null}
		</React.Fragment >
	);
}

