import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Button, Grid, Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ThemePicker from "./../../setting/themePicker/ThemePicker";
import { useSnackbar } from "notistack";
import {
    isRequestSuccessWithStack,
    isRequestFailWithStack
} from "./../../../util/RequestUtil";
import {
    themePickerState, saveThemeSetting, useThemePickerState,
    captureOriginalStateSnapshot, restoreFromOriginalStateSnapshot
} from "./../../../redux/ThemePicker";
import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";

const SaveButton = styled(Button)(({ theme }) => ({
    width: "180px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5)
}));

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3)
}));


export default function CentreLookSettingsTab() {
    const { t } = useTranslation(["settings", "general"]);
    const [snapshot, setSnapshot] = React.useState(themePickerState.getState().originalStateSnapshot);
    const { centreId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const THEME_REQUEST = useThemePickerState().request;

    React.useEffect(() => {

        if (snapshot == null) {
            captureOriginalStateSnapshot();
            setSnapshot(themePickerState.getState().originalStateSnapshot);
        } else {
            return () => {
                setSnapshot(null);
                restoreFromOriginalStateSnapshot();
            };
        }

    }, [snapshot]);

    const savingSettings = function(event) {
        event.preventDefault();
        saveThemeSetting({ centreId: centreId });
    }

    React.useEffect(() => {
        if (THEME_REQUEST.lastAction === "SAVE") {
            if (isRequestSuccessWithStack(THEME_REQUEST)) {
                enqueueSnackbar(t(THEME_REQUEST.message), {
                    "variant": "success"
                });
            }

            if (isRequestFailWithStack(THEME_REQUEST)) {
                enqueueSnackbar(t(THEME_REQUEST.message), {
                    "variant": "error"
                })
            }
        }
    }, [THEME_REQUEST])

    return (

        <TabTitleContainer>
            <Grid sx={{ paddingBottom: 3 }} container>
                <SpaceHolder container item xs={0.5} />
                <Grid container item xs={11}>
                    <Row alignItems="center" justifyContent="space-between" container item xs={12}>
                        <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">
                            {t("centre:section_title-color_scheme")}
                        </Typography>
                    </Row>
                    <Grid item xs={12}>
                        <ThemePicker themePickerKey="primary"></ThemePicker>
                    </Grid>
                    <Grid sx={{}} justifyContent="center" alignItems="center" container item xs={12}>
                        <SaveButton onClick={savingSettings} variant="contained" size="large" >{t("general:button-save")}</SaveButton>
                    </Grid>
                </Grid>
                <SpaceHolder container item xs={0.5} />
            </Grid>
        </TabTitleContainer>

    );
}

