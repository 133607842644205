import {
    createReduxModule
} from "hooks-for-redux";
import {
    loadOptions
} from "./../api/optionsAPI.tsx";
import OPTIONS_CATEGORY from "./../const/OptionsCategory";

function initialState() {
    var result = {
        updateProcess: {
            /* TEMPLATE:
            ${actionQueue_name}: {
                    updating: false,
                    error: null,
                    message: null,
            }, */
        },
        actionQueue: []
        /* TEMPLATE:
        ${category_name}: {
            options: [],
            validvalue: []
        }, */
    };
    for (var category of Object.values(OPTIONS_CATEGORY)) {
        result[category] = {
            options: [],
            validValues: []
        };
    }
    return result;
}

function setUpdatingAction(optionsState, payload) {
    var newObject = {};
    newObject[payload.optionType] = {
        updating: true,
        message: "",
        error: null
    }
    var result = Object.assign({}, optionsState.updateProcess, newObject);
    return {
        updateProcess: result
    };
}


function loadOptionsAction(category, optionsState, payload) {
    var result = optionsState.actionQueue.slice();
    result.push(category);
    return {
        actionQueue: result
    };
}

function loadOptionsSuccessAction(category, optionsState, payload) {
    var newProcessStatus = {};
    newProcessStatus[category] = {
        error: null,
        message: "",
        updating: false
    };
    var result = {
        updateProcess: Object.assign({}, optionsState.updateProcess, newProcessStatus)
    };
    result[category] = {
        options: payload.options,
        validValues: payload.valid_values
    };
    return result;
}

function loadOptionsFailAction(type, optionsState, payload) {
    var newProcessStatus = {};
    newProcessStatus[type] = {
        error: null,
        message: "",
        updating: false
    };
    return {
        updateProcess: Object.assign({}, optionsState.updateProcess, newProcessStatus)
    }
}

function removeFirstActionInQueueAction(optionsState, payload) {
    var actionQueueList = optionsState.actionQueue.slice();
    actionQueueList.shift();
    return {
        actionQueue: actionQueueList
    }
}

export const [useOptionsState, {
        setUpdating,
        removeFirstActionInQueue,
        loadAliasOptions,
        loadAliasOptionsFail,
        loadAliasOptionsSuccess,
        loadDistrictOptions,
        loadDistrictOptionsFail,
        loadDistrictOptionsSuccess,
        loadEducationLevelOptions,
        loadEducationLevelOptionsFail,
        loadEducationLevelOptionsSuccess,
        loadGenderOptions,
        loadGenderOptionsFail,
        loadGenderOptionsSuccess,
        loadGradeOptions,
        loadGradeOptionsFail,
        loadGradeOptionsSuccess,
        loadMainLanguageOptions,
        loadMainLanguageOptionsFail,
        loadMainLanguageOptionsSuccess,
        loadRelationshipOptions,
        loadRelationshipOptionsFail,
        loadRelationshipOptionsSuccess,
        loadSubjectOptions,
        loadSubjectOptionsFail,
        loadSubjectOptionsSuccess,
        loadSystemLanguageOptions,
        loadSystemLanguageOptionsFail,
        loadSystemLanguageOptionsSuccess,
        loadWeekDayOptions,
        loadWeekDayOptionsFail,
        loadWeekDayOptionsSuccess,
    },
    optionsState
] = createReduxModule(
    "optionsState",
    initialState(), {
        setUpdating: (optionsState, payload) => Object.assign({}, optionsState, setUpdatingAction(optionsState, payload)),
        removeFirstActionInQueue: (optionsState, payload) => Object.assign({}, optionsState, removeFirstActionInQueueAction(optionsState, payload)),
        loadAliasOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.ALIAS, optionsState, payload)),
        loadAliasOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.ALIAS, optionsState, payload)),
        loadAliasOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.ALIAS, optionsState, payload)),
        loadDistrictOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.DISTRICT, optionsState, payload)),
        loadDistrictOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.DISTRICT, optionsState, payload)),
        loadDistrictOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.DISTRICT, optionsState, payload)),
        loadEducationLevelOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.EDUCATION_LEVEL, optionsState, payload)),
        loadEducationLevelOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.EDUCATION_LEVEL, optionsState, payload)),
        loadEducationLevelOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.EDUCATION_LEVEL, optionsState, payload)),
        loadGenderOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.GENDER, optionsState, payload)),
        loadGenderOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.GENDER, optionsState, payload)),
        loadGenderOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.GENDER, optionsState, payload)),
        loadGradeOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.GRADE, optionsState, payload)),
        loadGradeOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.GRADE, optionsState, payload)),
        loadGradeOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.GRADE, optionsState, payload)),
        loadMainLanguageOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.MAIN_LANGUAGE, optionsState, payload)),
        loadMainLanguageOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.MAIN_LANGUAGE, optionsState, payload)),
        loadMainLanguageOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.MAIN_LANGUAGE, optionsState, payload)),
        loadRelationshipOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.RELATIONSHIP, optionsState, payload)),
        loadRelationshipOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.RELATIONSHIP, optionsState, payload)),
        loadRelationshipOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.RELATIONSHIP, optionsState, payload)),
        loadSubjectOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.SUBJECT, optionsState, payload)),
        loadSubjectOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.SUBJECT, optionsState, payload)),
        loadSubjectOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.SUBJECT, optionsState, payload)),
        loadSystemLanguageOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.SYSTEM_LANGUAGE, optionsState, payload)),
        loadSystemLanguageOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.SYSTEM_LANGUAGE, optionsState, payload)),
        loadSystemLanguageOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.SYSTEM_LANGUAGE, optionsState, payload)),
        loadWeekDayOptions: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsAction(OPTIONS_CATEGORY.WEEK_DAY, optionsState, payload)),
        loadWeekDayOptionsFail: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsFailAction(OPTIONS_CATEGORY.WEEK_DAY, optionsState, payload)),
        loadWeekDayOptionsSuccess: (optionsState, payload) => Object.assign({}, optionsState, loadOptionsSuccessAction(OPTIONS_CATEGORY.WEEK_DAY, optionsState, payload)),
    }
);

function loadOptionsSuccess(cateory, result) {
    switch (cateory) {
        case OPTIONS_CATEGORY.ALIAS:
            loadAliasOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.DISTRICT:
            loadDistrictOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.EDUCATION_LEVEL:
            loadEducationLevelOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.GENDER:
            loadGenderOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.GRADE:
            loadGradeOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.MAIN_LANGUAGE:
            loadMainLanguageOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.RELATIONSHIP:
            loadRelationshipOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.SUBJECT:
            loadSubjectOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.SYSTEM_LANGUAGE:
            loadSystemLanguageOptionsSuccess(result);
            break;
        case OPTIONS_CATEGORY.WEEK_DAY:
            loadWeekDayOptionsSuccess(result);
            break;
        default:
            break;
    }
}

function loadOptionsFail(cateory, result) {
    switch (cateory) {
        case OPTIONS_CATEGORY.ALIAS:
            loadAliasOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.DISTRICT:
            loadDistrictOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.EDUCATION_LEVEL:
            loadEducationLevelOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.GENDER:
            loadGenderOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.GRADE:
            loadGradeOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.MAIN_LANGUAGE:
            loadMainLanguageOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.RELATIONSHIP:
            loadRelationshipOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.SUBJECT:
            loadSubjectOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.SYSTEM_LANGUAGE:
            loadSystemLanguageOptionsFail(result);
            break;
        case OPTIONS_CATEGORY.WEEK_DAY:
            loadWeekDayOptionsFail(result);
            break;
        default:
            break;
    }
}

async function handleLoadOptionsRequest(currentState, cateory) {
    removeFirstActionInQueue();
    setUpdating({
        optionType: cateory
    });
    try {
        var result = await loadOptions(cateory);
        if (result.status === "success") {
            loadOptionsSuccess(cateory, result.result);
        } else {
            loadOptionsFail(cateory, result.result);
        }
    } catch (error) {
        console.error(error);
        loadOptionsFail(cateory, result.result);
    }
}

optionsState.subscribe((currentState) => {
    var nextAction = "";
    if (currentState.actionQueue && currentState.actionQueue.length > 0) {
        nextAction = currentState.actionQueue[0];
    }
    if (!currentState.updateProcess[nextAction] || currentState.updateProcess[nextAction].updating == false) {
        if (Object.values(OPTIONS_CATEGORY).indexOf(nextAction) > -1) {
            handleLoadOptionsRequest(currentState, nextAction);
        }
    } else {
        removeFirstActionInQueue();
    }
});