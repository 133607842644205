import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface themeSettings {
  centreId: string
  primary: string
  secondary: string
}

export async function getThemeSettings(): Promise<themeSettings> {
  const url = '/theme/';

  try {
    const { data } = await axios.get<themeSettings>(url);
    return data;
  } catch (err) {
    throw err
  }
}

export async function updateThemeSettings(themeSettings: themeSettings): Promise<UpdateResultResponse> {
  const url = '/theme/save';
  var body = themeSettings;
  try {
    const { data } = await axios.post<UpdateResultResponse>(url, body);
    return data;
  } catch (err) {
    throw err
  }
}
