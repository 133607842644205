import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';
import {
	Autocomplete, FormControl,
	Grid, Stack, TextField,
	Divider, InputAdornment, Collapse,
	Typography, IconButton, Pagination,
	Button
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import OPTIONS_CATEGORY from "./../../../const/OptionsCategory";
import { SpaceHolder } from "./../../common/Others";
import { TabTitleContainer } from "./../../common/Containers";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import { loadCentreTutorEnrolment, useEnrolmentState } from "./../../../redux/Enrolment";

import { useOptionsState } from "./../../../redux/Options";
import CentrePendingTutorDialog from "./../../page/centreDetail/dialog/CentrePendingTutorDialog";

import { convertOptionsForAutoComplete } from "./../../../util/AutoCompleteUtil";
import TutorCard from "./../../page/tutor/TutorCard";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	"marginTop": theme.spacing(1),
	"paddingBottom": theme.spacing(1),
}));

const NarrowRow = styled(Stack)(({ theme }) => ({
	marginTop: theme.spacing(1),
}));

export default function CentreTutorPage() {
	const { t } = useTranslation(["centre", "general"]);
	const [isFilterOpen, setIsFilterOpen] = React.useState(false);
	const [subjectFilterValue, setSubjectFilterValue] = React.useState([]);
	const [searchString, setSearchString] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const rowsPerPage = 10;
	const [page, setPage] = React.useState(0);
	const TUTORS = useEnrolmentState().enroledTutor || [];
	const TUTOR_COUNT = useEnrolmentState().enroledTutorCount || 0;
	const PENDING_TUTOR = useEnrolmentState().pendingTutorEnrolment || [];
	const navigate = useNavigate();

	const OPTIONS_STATE = useOptionsState();
	let { centreId } = useParams();

	const OPTIONS = {
		SUBJECT: OPTIONS_STATE[OPTIONS_CATEGORY.SUBJECT].options
	};

	let deboundedLoadCentreTutorEnrolment = React.useMemo(() => debounce(loadCentreTutorEnrolment, 50), []);
	const TUTOR_FILTER_FORM = useForm({ subjectFilter: [] });

	const handleChange = (event, newValue) => {
		event.preventDefault();
		setSubjectFilterValue(newValue);
	};

	const updateSearchString = (event) => {
		event.preventDefault();
		setSearchString(event.target.value);
	};

	const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

	const toggleFilter = () => {
		setIsFilterOpen((prev) => !prev)
	}


	const handleClick = (event) => {
		event.preventDefault();
		if (PENDING_TUTOR.length > 0) {
			setDialogOpen((prev) => !prev);
		}
	};

	React.useEffect(() => {
		deboundedLoadCentreTutorEnrolment({
			centreId: centreId, searchCriteria: {
				search: searchString, subject: subjectFilterValue, page: page, rowsPerPage: rowsPerPage
			}
		});

	}, [])

	React.useEffect(() => {
		deboundedLoadCentreTutorEnrolment({
			centreId: centreId, searchCriteria: {
				search: searchString, subject: subjectFilterValue, page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [centreId, searchString, page, rowsPerPage, subjectFilterValue])

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-tutors")}</Typography>
							<CanUpdateCentre centreId={centreId} >
								<Button onClick={handleClick} sx={{ height: "50px", width: "140px" }} variant="contained">
									{t("general:button-pending") + ` (${PENDING_TUTOR.length})`}
								</Button>
							</CanUpdateCentre>
						</HeaderRow>
						<Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
							<TextField
								id="outlined-start-adornment"
								fullWidth
								onChange={(event) => { searchFieldOnChange(event) }}
								placeholder={t("general:label-search")}
								InputProps={{
									startAdornment:
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>,
									endAdornment:
										<InputAdornment position="end">
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											<IconButton
												name="filterToggle"
												aria-label="toggle filter"
												onClick={toggleFilter}
												onMouseDown={(event) => { event.preventDefault() }}
											>
												<FilterListIcon />
											</IconButton>
										</InputAdornment>,
								}}
							/>
							<Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
								<Stack>
									<StyledFormControl fullWidth variant="outlined">
										<Controller
											name="subjectFilter"
											defaultValue={subjectFilterValue}
											control={TUTOR_FILTER_FORM.control}
											render={
												({ field }) =>
													<Autocomplete
														multiple
														id="tutor_subject_filter"
														options={convertOptionsForAutoComplete(OPTIONS.SUBJECT)}
														getOptionLabel={(option) => t(`attribute:${option}`)}
														defaultValue={subjectFilterValue}
														value={subjectFilterValue}
														onChange={handleChange}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																label={t("attribute:tutor-subjects")}
																placeholder={t("attribute:tutor-subjects")}
															/>
														)}
													/>
											}
										/>
									</StyledFormControl>
								</Stack>
							</Collapse>
						</Stack>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>
			<NarrowRow spacing={0} >
				<Grid container justifyContent="center" item sm={12} md={12}>
					{TUTORS.map(
						(tutorEnrolment, index) => {
							let currentTutor = tutorEnrolment.profile;
							return (<Grid key={currentTutor._id + currentTutor.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
								<TutorCard index={index} key={currentTutor._id + currentTutor.displayName} cardOnClick={() => { navigate(`/tutor/${tutorEnrolment._id}`) }} currentTutor={currentTutor} />
							</Grid>)
						})
					}
					<Pagination
						onChange={(event, pageNumber) => {
							event.preventDefault();
							setPage(pageNumber - 1);
						}}
						count={Math.ceil(TUTOR_COUNT / rowsPerPage)}
					/>
				</Grid>
			</NarrowRow>
			<CentrePendingTutorDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:tutors") })}
				pendingTutorList={PENDING_TUTOR}
				isDialogOpen={dialogOpen}
				setIsDialogOpen={setDialogOpen} />
		</React.Fragment >
	);
}

