import React from 'react';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import {

    List, ListItem, ListItemText,
    Grid, Fab, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField,
    Typography, IconButton
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { yupResolver } from "@hookform/resolvers/yup";
import { createCentreAdminInvitationFormSchema } from "./../../form/model/CentreFormModel";
import { SpaceHolder } from "./../../common/Others";
import ConfirmActionDialog from "./../../common/ConfirmActionDialog";
import { DialogFixedForm } from "./../../common/Forms";
import { AdminFormRow, TabTitleContainer } from "./../../common/Containers";
import { SuccessButton, ContextualMenuItem } from "./../../common/Buttons";
import ContextualMenu from "./../../common/ContextualMenu";
import {
    inviteCentreAdmin,
    getCentreAdminEnrolment,
    removeCentreAdmin,
    useEnrolmentState
} from "./../../../redux/Enrolment";
const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5)
}));

export default function CentreAdminSettingsTab() {

    const { t } = useTranslation("centre", "general");
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const rows = useEnrolmentState().centreAdmins || [];
    const [selected, setSelected] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    let { centreId } = useParams();

    const handleClick = (event, selectedValue) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelected(selectedValue);
    };
    const triggerRemoveAdministrator = () => {
        removeCentreAdmin({
            enrolmentId: selected._id,
            centreId: centreId
        });
        setAnchorEl(null);
        getCentreAdminEnrolment({ centreId: centreId });
    };

    const openDeleteDialog = (event) => {
        event.preventDefault();
        setDeleteDialogOpen(true);
        setAnchorEl(null);
    }

    const openAddCentreAdminDialog = (event) => {
        event.preventDefault();
        setIsDialogOpen(true);
    };
    const handleCloseDialog = (event) => {
        event.preventDefault();
        setIsDialogOpen(false);
    }


    const addPlatformAdminFormMethod = useForm({
        resolver: yupResolver(createCentreAdminInvitationFormSchema()),
        defaultValue: {
            "email": ""
        }
    });
    const deboundedGetCentreAdminEnrolment = React.useMemo(() => debounce(getCentreAdminEnrolment, 50), []);
    
    const submitCentreAdmin = (data) => {
        inviteCentreAdmin({ username: data.email, centreId: centreId });
        setIsDialogOpen(false);
        addPlatformAdminFormMethod.reset();
        deboundedGetCentreAdminEnrolment({ centreId: centreId });
    }

    React.useEffect(() => {
        deboundedGetCentreAdminEnrolment({ centreId: centreId });
    }, []);


    React.useEffect(() => {
        deboundedGetCentreAdminEnrolment({ centreId: centreId });
    }, [centreId]);

    return (
        <React.Fragment>
            <TabTitleContainer>
                <Grid container>
                    <SpaceHolder container item xs={0.5} />
                    <Grid container item xs={11}>
                        <Row alignItems="center" justifyContent="space-between" container item md={12}>
                            <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">
                                {t("centre:section_title-centre_admins")}
                            </Typography>

                            <Fab color="primary" onClick={openAddCentreAdminDialog} size="small">
                                <AddIcon />
                            </Fab>
                        </Row>
                        <Grid sx={{ marginBottom: 2 }} justifyContent="space-between" container item xs={12}>
                            <List sx={{ width: '100%', marginTop: 0, paddingTop: 0, paddingLeft: 0 }}>
                                {rows.map((value) => (
                                    <ListItem
                                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                                        key={value._id}
                                        secondaryAction={
                                            <IconButton sx={{ position: "absolute", top: "-20px", "right": "-15px" }}
                                                onClick={(event) => {
                                                    handleClick(event, value)
                                                }} aria-label="comment">
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText primary={value.username} />
                                    </ListItem>
                                ))}
                            </List>
                            <ContextualMenu
                                id="admin-menu"
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            >
                                <ContextualMenuItem title={t("general:button-remove")} onClick={openDeleteDialog}>
                                    <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                                </ContextualMenuItem>
                            </ContextualMenu>
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0.5} />
                </Grid>
            </TabTitleContainer>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isDialogOpen}
                onClose={handleCloseDialog}
                scroll="paper"
                aria-labelledby="administrator-dialog-title"
                aria-describedby="administrator-dialog-description"
            >
                <DialogFixedForm
                    onSubmit={addPlatformAdminFormMethod.handleSubmit(submitCentreAdmin)} noValidate>
                    <DialogTitle id="administrator-dialog-title">
                        {t("general:dialog_title-add_entity", { entity: t("attribute:centre-admin") })}
                    </DialogTitle>
                    <DialogContent dividers>
                        <AdminFormRow justifyContent="center" alignItems="center" container >
                            <Controller
                                name="email"
                                defaultValue={''}
                                control={addPlatformAdminFormMethod.control}
                                render={
                                    ({ field }) => <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        label={t("attribute:user-email_address")}
                                        helperText={addPlatformAdminFormMethod.formState.errors.email ? t(addPlatformAdminFormMethod.formState.errors.email.message) : null}
                                        error={addPlatformAdminFormMethod.formState.errors.email ? true : false}
                                        type="string"
                                    />
                                }
                            />
                        </AdminFormRow>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button onClick={handleCloseDialog} variant="outlined" autoFocus>
                                {t("general:button-cancel")}
                            </Button>
                            <SuccessButton type="onSubmit" variant="contained" >
                                {t("general:button-add")}
                            </SuccessButton>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </Dialog>
            <ConfirmActionDialog
                title={t("general:dialog_title-delete_entity", { entity: t("attribute:centre-admin") })}
                actionName={t("general:button-delete")}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                confirmOnClick={triggerRemoveAdministrator} />
        </React.Fragment>
    );
}
