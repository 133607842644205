import React from 'react';
import { useTranslation } from "react-i18next";
import {
    Typography, CardActionArea, Card, CardContent, Grid,
    Stack, IconButton
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import {useClassesManagementState} from "./../../../redux/ClassesManagement";
import SchoolIcon from '@mui/icons-material/School';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LimitedTextLabel from "./../../../component/display/LimitedTextLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import { SpaceHolder } from "./../../common/Others";
import { nameInBracketStyle } from "./../../../util/DisplayUtil";

function AttributesDisplayer({ variant, index, subject }) {
    const { t } = useTranslation("attribute");
    const SEPARATOR = t("general:separator");
    if (subject) {
        if (subject.length == 1) {
            return (<Typography variant={variant} >{t(subject, { ns: "attribute" })}</Typography>);
        } else if (subject.length > 1) {

            let joinedString = subject.map(
                (currentSubject, index) => (t(currentSubject, { ns: "attribute" }))
            ).join(SEPARATOR);
            let result = (
                <LimitedTextLabel variant={variant}>
                    {joinedString}
                </LimitedTextLabel>
            )
            return (<Grid container>{result}</Grid>);
        }
    }
    return null;

}

export default function ClassCard({ disabled, currentClass, index, cardOnClick, moreButtonOnClick }) {

    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let displayNameStyle = { fontWeight: "bold", paddingLeft: 0.5 };
    let cardContentStyle = { paddingLeft: 0 };
    let TUTOR_NAME_MAP = useClassesManagementState().tutorNameMap || {};
    let contentVariant = "body2";
    if (smDown) {
        cardContentStyle = { width: "100%", paddingLeft: 0 };
        displayNameStyle = { paddingLeft: 0.5 }
        contentVariant = "caption";
    }

    let cardStyle = { display: { md: "block" }, width: "100%", marginTop: 0, marginBottom: 2, borderRadius: '10px' };
    if (disabled) {
        cardStyle.backgroundColor = "#EEEEEE";
    }

    return (
        <React.Fragment>
            <Card sx={cardStyle}>
                <CardActionArea component="span" disabled={disabled} disableTouchRipple data-cy={"edit_tutor_btn_" + (index + 1)} onClick={(event) => { cardOnClick.call(this, event, currentClass) }}>
                    <CardContent sx={cardContentStyle} >
                        <Grid container >
                            <SpaceHolder container item xs={0.5} />
                            <Grid sx={{ paddingBottom: 2 }} container item xs={11}>
                                <Stack sx={{ width: "100%" }} justifyContent="center" alignItems="flex-start" spacing={1}>
                                    <Stack sx={{ width: "100%" }} justifyContent="space-between" alignItems="center" spacing={0} direction="row">
                                        <LimitedTextLabel component="div" customstyle={displayNameStyle} variant="h6">
                                            {currentClass.name}
                                        </LimitedTextLabel>
                                        {moreButtonOnClick ?
                                            <IconButton onClick={(event) => {
                                                event.stopPropagation();
                                                moreButtonOnClick.call(this, event, currentClass);
                                            }}>
                                                <MoreHorizIcon />
                                            </IconButton>
                                            : null}
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <SchoolIcon color="disabled" fontSize="small" />
                                        <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                            <AttributesDisplayer variant={contentVariant} subject={currentClass.grades} />
                                        </LimitedTextLabel>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <LocalOfferIcon color="disabled" fontSize="small" />
                                        <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                            <AttributesDisplayer variant={contentVariant} subject={currentClass.subjects} />
                                        </LimitedTextLabel>
                                    </Stack>
                                    {TUTOR_NAME_MAP[currentClass.tutors[0]]?
                                        <Stack direction="row" spacing={1}>
                                            <SupportAgentIcon color="disabled" fontSize="small" />
                                            <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                                {nameInBracketStyle(TUTOR_NAME_MAP[currentClass.tutors[0]].chiName,TUTOR_NAME_MAP[currentClass.tutors[0]].engName)}
                                            </LimitedTextLabel>
                                        </Stack>
                                        :null
                                    }
                                </Stack>
                            </Grid>
                            <SpaceHolder container item xs={0.5} />
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}