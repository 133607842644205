import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface stripeConfigSetting {
  status: "success" | "fail",
  publishableKey: string
}

export interface paymentIntentResponse{
  status: "success"|"fail",
  clientSecret:string
}

export async function getStripeConfig(): Promise<stripeConfigSetting> {
  var userId= null;

  if(userId === null || userId === ""){
    userId = "default";
  }
  const url = '/payment/config';

  try {
    const { data } = await axios.get<stripeConfigSetting>(url);
    return data;
  } catch (err) {
    throw err
  }
}

export async function createPaymentIntent(): Promise<paymentIntentResponse> {
  const url = "/payment/create-payment-intent";
  try {
    const { data } = await axios.get<paymentIntentResponse>(url);
    return data;
  } catch (err) {
    throw err
  }
}

export async function createPaymentIntentOnBehalf(): Promise<paymentIntentResponse> {
  const url = "/payment/create-payment-intent-onbehalf";
  try {
    const { data } = await axios.get<paymentIntentResponse>(url);
    return data;
  } catch (err) {
    throw err
  }
}


export async function createCentrePaymentSession(amount:number,product:string,centre:string,quantity:number): Promise<UpdateResultResponse> {
  const url = '/payment/create-centre-payment';
  var body = {
    "amount":amount,
    "product":product,
    "centre":centre,
    "quantity":quantity
  };
  try {
    const { data } = await axios.post<UpdateResultResponse>(url,body);
    return data;
  } catch (err) {
    throw err
  }
}

export async function createCheckoutSession(lookupKey:string): Promise<UpdateResultResponse> {
  const url = '/payment/create-checkout-session';
  var body = {"lookup_key":lookupKey};
  try {
    const { data } = await axios.post<UpdateResultResponse>(url,body);
    return data;
  } catch (err) {
    throw err
  }
}

export async function createPortalSession(sessionId:string): Promise<UpdateResultResponse> {
  const url = '/payment/create-portal-session';
  var body = {"session_id":sessionId};

  try {
    const { data } = await axios.post<UpdateResultResponse>(url,body);
    return data;
  } catch (err) {
    throw err
  }
}
