import React from "react";
import {
    Grid, Stack,
    Dialog, DialogTitle, DialogContent
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useClassSessionManagementState, getClassTutorForClassSession, addTutorToClassSession } from "./../../../../redux/ClassSessionManagement";
import TutorCard from "./../../../page/tutor/TutorCard";

const NarrowRow = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

export default function ClassSessionAddTutorDialog({
    title, isDialogOpen, setIsDialogOpen }) {
    const { sessionId } = useParams();

    const CLASS_TUTOR_LIST = useClassSessionManagementState().newTutorList || [];

    React.useEffect(() => {
        if (isDialogOpen) {
            getClassTutorForClassSession({
                sessionId: sessionId
            })
        }
    }, [isDialogOpen, sessionId]);

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const addTutorToClassOnClick = (event, selectedTutor) => {
        event.preventDefault();
        setIsDialogOpen(false);
        addTutorToClassSession({ sessionId:sessionId, tutors: selectedTutor._id })
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <NarrowRow spacing={0} >
                        <Grid container justifyContent="center" item sm={12} md={12}>
                            {CLASS_TUTOR_LIST.map(
                                (tutorEnrolment, index) => {
                                    let currentTutor = tutorEnrolment.profile;
                                    return (<Grid key={currentTutor._id + currentTutor.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
                                        <TutorCard index={index} key={currentTutor._id + currentTutor.displayName} cardOnClick={(event) => { addTutorToClassOnClick(event, tutorEnrolment) }} currentTutor={currentTutor} />
                                    </Grid>)
                                })
                            }
                        </Grid>
                    </NarrowRow>
                </DialogContent>
            
        </Dialog >
    )
}