import * as YUP from "yup";

function createPayslipFormSchema() {
    return YUP.object().shape({
        payPeriodFrom: YUP.date().required("general:validation-mandatory").nullable().typeError("general:validation-date"),
        payPeriodTo: YUP.date().required("general:validation-mandatory").nullable().typeError("general:validation-date"),
        paymentDate: YUP.date().required("general:validation-mandatory").nullable().typeError("general:validation-date"),
        contributionDate: YUP.date().required("general:validation-mandatory").nullable().typeError("general:validation-date"),
        paymentMethod: YUP.string(),
        relevantIncomes: YUP.array().of(
            YUP.object().shape({
                desc: YUP.string()
                    .required("general:validation-mandatory"),
                amount: YUP.number()
                    .required("general:validation-mandatory")
            })
        ),
        nonRelevantIncomes: YUP.array().of(
            YUP.object().shape({
                desc: YUP.string()
                    .required("general:validation-mandatory"),
                amount: YUP.number()
                    .required("general:validation-mandatory")
            })
        ),
        deductions: YUP.array().of(
            YUP.object().shape({
                desc: YUP.string()
                    .required("general:validation-mandatory"),
                amount: YUP.number()
                    .required("general:validation-mandatory")
            })
        ),
        employerContributions: YUP.array().of(
            YUP.object().shape({
                desc: YUP.string()
                    .required("general:validation-mandatory"),
                amount: YUP.number()
                    .required("general:validation-mandatory")
            })
        )
    });
}

const INIT_PAYSLIP_FORM = {
        employeeId: "",
        designation: "",
        payPeriodFrom: null,
        payPeriodTo: null,
        paymentDate: new Date(),
        contributionDate: new Date(),
        paymentMethod: "",
        relevantIncomes: [{
            desc:"Basic Salary",
            amount:0
        },{
            desc:"Leave Pay",
            amount:0
        }],
        relevantIncomesTotal: 0,
        nonRelevantIncomes: [],
        nonRelevantIncomesTotal: 0,
        deductions: [{
            desc:"MPF - Employee Mandatory Contribution",
            amount:0
        },{
            desc:"MPF - Employee Voluntary Contribution",
            amount:0
        }],
        deductionTotal: 0,
        employerContributions: [{
            desc:"MPF - Employer Mandatory Contribution",
            amount:0
        },{
            desc:"MPF - Employer Voluntary Contribution",
            amount:0
        }],
        employerContributionsTotal: 0,
        netPay: 0,
        status: "paid"
}

const PAYSLIP_FIELD = {
    "employeeId":"employeeId",
    "designation": "designation",
    "payPeriodFrom":"payPeriodFrom",
    "payPeriodTo":"payPeriodTo",
    "paymentDate":"paymentDate",
    "contributionDate":"contributionDate",
    "paymentMethod":"paymentMethod",
    "relevantIncomes":"relevantIncomes",
    "relevantIncomesTotal":"relevantIncomesTotal",
    "nonRelevantIncomes":"nonRelevantIncomes",
    "nonRelevantIncomesTotal":"nonRelevantIncomesTotal",
    "deductions":"deductions",
    "deductionTotal":"deductionTotal",
    "employerContributions":"employerContributions",
    "employerContributionsTotal":"employerContributionsTotal",
    "netPay":"netPay"
}

export {
    createPayslipFormSchema, INIT_PAYSLIP_FORM,
    PAYSLIP_FIELD
}