import React from 'react';
import {
	Menu
} from "@mui/material";

export default function ContextualMenu({ id, children, anchorEl, setAnchorEl }) {
	return (
		<Menu id={id}
			MenuListProps={{
				'aria-labelledby': 'fade-button',
				sx: {
					padding: "0",
				}
			}}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 0.5,
					paddingTop: 1,
					paddingBottom: 1,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0,
					},
				},
			}}
			anchorEl={anchorEl}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			open={Boolean(anchorEl)}
			onClose={() => {
				setAnchorEl(null);
			}}
		>
			{children}
		</Menu>

	);
}
