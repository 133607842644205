import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export async function getChatMessagesRequest(conversationId: string): Promise<UpdateResultResponse> {
	const url = `/message/messages/${conversationId}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err;
	}
}

export async function readChatMessagesRequest(conversationId: string, from: string): Promise<UpdateResultResponse> {
	const url = `/message/messages/${conversationId}?from=${from}`;
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err;
	}
}

export async function getConversationsRequest(username: string): Promise<UpdateResultResponse> {
	const url = `/message/conversations/${username}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err;
	}
}

export async function getConversationIdRequest(from: string, to: string): Promise<UpdateResultResponse> {
	const url = `/message/conversation?from=${from}&to=${to}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err;
	}
}

export async function getCentreBroadcastListRequest(centreId: string): Promise<UpdateResultResponse> {
	const url = `/message/broadcast/centre/${centreId}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err;
	}
}

export async function getUnreadMessagesCountRequest(username: string): Promise<UpdateResultResponse> {
	const url = `/message/messages/unread/${username}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err;
	}
}