import React from 'react';
import { styled } from '@mui/material/styles';
import StudentCard from "./../../page/student/StudentCard";
import StudentEditDialog from "./../../page/student/StudentEditDialog";
import { Grid, FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useProfileState } from "./../../../redux/ProfileManagement";
import { ProfileContainer } from "./../../common/Containers";


const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    "fontWeight": "bold",
    "color": "#AAAAAA",
    "marginTop": theme.spacing(1),
    "marginBottom": theme.spacing(2)
}));


export default function StudentCards() {

    const STUDENT_LIST = useProfileState().studentProfiles;
    const [editDialog, setEditDialog] = React.useState(false);
    const [selectedStudent, setSelectedStudent] = React.useState({});
    const { t } = useTranslation();

    return (<React.Fragment>
        {STUDENT_LIST.length > 0 ? <StyledFormLabel>{t("attribute:student_profile")}</StyledFormLabel>
            : null}
        {STUDENT_LIST.map(
            (currentStudent, index) =>
                <ProfileContainer key={currentStudent._id + "_container_grid"} container spacing={3}  >
                    <Grid item xs={12} md>
                        <StudentCard key={currentStudent._id} currentStudent={currentStudent} cardOnClick={() => {
                            setSelectedStudent(currentStudent);
                            setEditDialog(true);
                        }} index={index}></StudentCard>
                    </Grid>
                </ProfileContainer>
        )}
        <StudentEditDialog currentStudent={selectedStudent} open={editDialog} setOpenDialog={setEditDialog} />
    </React.Fragment>);
}
