import React from 'react';
import {
    Grid,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import LoadingPage from "./LoadingPage";
import CentreBusinessSettingsTab from "./centreSettings/CentreBusinessSettingsTab";
import CentreAdminSettingsTab from "./centreSettings/CentreAdminSettingsTab";
import CentreLookSettingsTab from "./centreSettings/CentreLookSettingsTab";
import CentreDataSettingsTab from "./centreSettings/CentreDataSettingsTab";
import CentreSubscriptionSettingsTab from "./centreSettings/CentreSubscriptionSettingsTab";
import CentreNotificationSettingsTab from "./centreSettings/CentreNotificationSettingsTab";
import { getCentreInfo } from "./../../redux/CentreManagement";
import { useAuthenticationState } from "./../../redux/authentication";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import { Can } from "./../../casl/authorization";
import CanUpdateCentre from "./../acl/CanUpdateCentre";
import CentreSettingPageHeader from "./../../component/page/centreSettings/header/CentreSettingPageHeader"
const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));



export default function CentreSettings() {
    let { centreId } = useParams();
    let [searchParams] = useSearchParams();

    const SEARCH_PARAM_TAB = parseInt(searchParams.get("tab")) || 0;
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const ABILITY = useAuthenticationState().ability;

    const TAB_ELEMENTS = [
        { "tab_element": (<CentreAdminSettingsTab />), "tab_label": "centre:tab_name-admins", acl: { i: "update", a: "centre" } },
        { "tab_element": (<CentreLookSettingsTab />), "tab_label": "centre:tab_name-look", acl: { i: "update", a: "centre" } },
        { "tab_element": (<CentreNotificationSettingsTab />), "tab_label": "centre:tab_name-notifications", acl: { i: "update", a: "centre" } },
        { "tab_element": (<CentreDataSettingsTab />), "tab_label": "centre:tab_name-data", acl: { i: "update", a: "centre" } },
        { "tab_element": (<CentreSubscriptionSettingsTab />), "tab_label": "centre:tab_name-subscription", acl: { i: "update", a: "centre" } },
        { "tab_element": (<CentreBusinessSettingsTab />), "tab_label": "centre:tab_name-business", acl: { i: "update", a: "centre_business_info" } },

    ]

    React.useEffect(() => {
        getCentreInfo({ centreId: centreId });
    }, []);


    return (
        <React.Suspense fallback={<LoadingPage />}>
            <CanUpdateCentre centreId={centreId} redirect={true} >
                <DashboardRootContainer>
                    <Grid sx={{
                        width: "100%"
                    }} container>
                        <SpaceHolder container item  xs={0} md={0.5} lg={1} xl={2}  />
                        <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                            <Row container item xs={12}>
                                <CentreSettingPageHeader value={value} TAB_ELEMENTS={TAB_ELEMENTS} setValue={setValue} />
                            </Row>
                            <Grid sx={{marginTop:2}} container item xs={12}>
                                {TAB_ELEMENTS.map(((element, index) =>
                                    element.acl ?
                                        (
                                            <TabPanel key={`element_${index}`} value={value} index={index}>
                                                <Can I={element.acl.i} a={element.acl.a} ability={ABILITY} >
                                                    {element.tab_element}
                                                </Can>
                                            </TabPanel>
                                        )
                                        : (
                                            <TabPanel key={`element_${index}`} value={value} index={index}>
                                                {element.tab_element}
                                            </TabPanel>
                                        )))
                                }
                            </Grid>
                        </Grid>
                        <SpaceHolder container item  xs={0} md={0.5} lg={1} xl={2}  />
                    </Grid>
                </DashboardRootContainer>
            </CanUpdateCentre>
        </React.Suspense>
    );
}
