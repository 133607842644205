import React from "react";
import {
    Grid, Button, TextField,
    InputAdornment, IconButton
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";

import { useAuthenticationState, updatePassword, UPDATE_ACTION } from "./../../redux/authentication";
import { createPasswordFormSchema } from "./model/PasswordFormModel";



const StyledForm = styled('form')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    width: "100%"
}));

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));

const RootContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    paddingBottom: theme.spacing(1)
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
}));

const ConfirmPassword = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(1)
}));



export default function PasswordForm() {
    const { t } = useTranslation(["reset_password", "account", "general"]);
    const { enqueueSnackbar } = useSnackbar();
    const request = useAuthenticationState().request;
    const [showPassword, setShowPassword] = React.useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowPassword = (name) => {
        switch (name) {
            case "password":
                setShowPassword((prev) => !prev);
                break;
            case "confirmPassword":
                setShowConfirmPassword((prev) => !prev);
                break;
            case "currentPassword":
                setShowCurrentPassword((prev) => !prev);
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        if (request && request.error && request.message && request.message != "") {
            enqueueSnackbar(t(request.message), {
                "variant": "error"
            })
        } else {
            if (request.success && request.lastAction === UPDATE_ACTION.UPDATE_PASSWORD
                && request.message && request.message != "") {
                enqueueSnackbar(t(request.message), {
                    "variant": "success"
                });
            }
        }
    }, [request])

    function updatePasswordFormSubmit(data) {
        updatePassword({
            password: data.currentPassword,
            renewPassword: data.password
        });
        reset();
    }

    const { control, handleSubmit, reset, "formState": { errors } } = useForm({
        resolver: yupResolver(createPasswordFormSchema()),
        defaultValue: {
            "currentPassword": "",
            "password": "00000000",
            "confirmPassword": "00000000"
        }
    });


    return (
        <RootContainer container justifyContent="center">
            <Grid item xs={12} md={10} xl={8}>
                <StyledForm onSubmit={handleSubmit(updatePasswordFormSubmit)} noValidate>
                    <Row justifyContent="center" container >
                        <Controller
                            name="currentPassword"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label={t("reset_password:field-password-current")}
                                helperText={errors.currentPassword ? t(errors.currentPassword.message) : null}
                                error={errors.currentPassword ? true : false}
                                type={showCurrentPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                name="test"
                                                aria-label="toggle password visibility"
                                                onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClickShowPassword("currentPassword") }}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...field}

                            />}
                        />
                    </Row>
                    <Row justifyContent="center" container >
                        <Controller
                            name="password"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label={t("reset_password:field-password-new-1")}
                                helperText={errors.password ? t(errors.password.message) : null}
                                error={errors.password ? true : false}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                name="test"
                                                aria-label="toggle password visibility"
                                                onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClickShowPassword("password") }}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...field}
                            />}
                        />
                    </Row>
                    <Row justifyContent="center" container >
                        <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => <ConfirmPassword
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label={t("reset_password:field-password-new-2")}
                                helperText={errors.confirmPassword ? t(errors.confirmPassword.message) : null}
                                error={errors.confirmPassword ? true : false}
                                type={showConfirmPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                name="test"
                                                aria-label="toggle password visibility"
                                                onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClickShowPassword("confirmPassword") }}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...field}
                            />}
                        />
                    </Row>
                    <Row justifyContent="center" container >
                        <SubmitButton data-cy="btn_save_password" type="submit" fullWidth variant="contained">{t("general:button-submit")}</SubmitButton>
                    </Row>
                </StyledForm>
            </Grid>
        </RootContainer>)
}
