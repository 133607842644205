import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
	Paper, Grid, Stack, InputAdornment, FormControl,
	Typography, Fab, TextField, Collapse, Box,
	IconButton, Button, Divider, Autocomplete
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import SmsIcon from "@mui/icons-material/Sms";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import { SpaceHolder } from "./../../common/Others";
import { useParams } from "react-router-dom";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useClassesManagementState, getClassStudent, removeStudentFromClass } from "./../../../redux/ClassesManagement";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import debounce from 'lodash.debounce';
import ContextualMenu from "./../../../component/common/ContextualMenu";

import { Can } from "./../../..//casl/authorization";
import { subject } from "@casl/ability";
import { Controller, useForm } from "react-hook-form";
import { convertOptionsForAutoComplete } from "./../../../util/AutoCompleteUtil";
import OPTIONS_CATEGORY from "./../../../const/OptionsCategory";
import { useOptionsState } from "./../../../redux/Options";
import { nameInBracketStyle } from "./../../../util/DisplayUtil";

import ConfirmActionDialog from "./../../../component/common/ConfirmActionDialog";
import ClassPendingStudentDialog from "./../../page/class/dialog/ClassPendingStudentDialog";
import ClassAddStudentDialog from "./../../page/class/dialog/ClassAddStudentDialog";
import ClassBillDialog from "./../../page/class/dialog/ClassBillDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 900
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	"marginTop": theme.spacing(1),
	"paddingBottom": theme.spacing(1),
}));


export default function ClassStudentPage() {
	const CLASS_INFO = useClassesManagementState().currentClass || {};
	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;
	const { classId } = useParams();
	const { t } = useTranslation(["centre", "general"]);
	const STUDENT_LIST = useClassesManagementState().activeStudents || [];
	const STUDENT_COUNT = useClassesManagementState().activeStudentsCount || 0;
	const PENDING_STUDENTS = useClassesManagementState().classPendingStudents || [];
	const STUDENT_BILL_STATUS = useClassesManagementState().studentBillStatus || [];
	const [isFilterOpen, setIsFilterOpen] = React.useState(false);
	
	const [selectedStudent, setSelectedStudent] = React.useState(null);
	const [studentAnchorEl, setStudentAnchorEl] = React.useState(null);
	const [deleteStudentDialogOpen, setDeleteStudentDialogOpen] = React.useState(false);
	const [addStudentDialogOpen, setAddStudentDialogOpen] = React.useState(false);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [gradeFilterValue, setGradeFilterValue] = React.useState([]);
	const [searchString, setSearchString] = React.useState(null);
	const [pendingStudentDialogOpen, setPendingStudentDialogOpen] = React.useState(false);
	const [issueBillDialogOpen, setIssueBillDialogOpen] = React.useState(false);

	const CLASS_REQUEST = useClassesManagementState().request;

	const STUDENT_FILTER_FORM = useForm({ gradeFilter: [] });
	const OPTIONS_STATE = useOptionsState();
	const triggerAddStudentDialog = (event) => {
		setAddStudentDialogOpen(true);
	}

	const triggerIssueBillDialog = (event)=>{
		event.preventDefault();
		setStudentAnchorEl(null);
		setIssueBillDialogOpen(true);
	}

	const deleteStudentFromClass = (event) => {
		removeStudentFromClass({ classId: classId, enrolmentId: selectedStudent._id });
	}

	const searchFieldOnChange = (event) => {
		setSearchString(event.target.value);
	}

	const gradeFilterOnChange = (event, newValue) => {
		event.preventDefault();
		setGradeFilterValue(newValue);
	};

	const toggleStudentContextualMenu = (event, studentFromOnClick) => {
		event.preventDefault();
		setSelectedStudent(studentFromOnClick);
		setStudentAnchorEl(event.currentTarget);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const pendingButtonOnClick = (event) => {
		if (PENDING_STUDENTS && PENDING_STUDENTS.length > 0) {
			setPendingStudentDialogOpen(true);
		}
	}

	const openNewStudentTab = (event) => {
		event.preventDefault();
		setStudentAnchorEl(null);
		window.open(`/student/${selectedStudent._id}`, "_blank");;
	}

    const navigateToChatroom = (event) => {
		event.preventDefault();
		setStudentAnchorEl(null);
        window.location.href = (`/message/direct?student=${selectedStudent._id}`);
    }

	const toggleFilter = (event) => {
		setIsFilterOpen((prev) => !prev);
	}

	const triggerDeleteDialog = (event) => {
		setStudentAnchorEl(null);
		setDeleteStudentDialogOpen(true);
	}

	const STUDENT_CHANGE_REQUEST = {
		"ACCEPT_STUDENT_CLASS_ENROLMENT": true,
		"REMOVE_STUDENT_CLASS_ENROLMENT": true,
		"ADD_STUDENT_TO_CLASS": true,
		"REMOVE_STUDENT_FROM_CLASS": true,
	}
const debouncedGetClassStudent = React.useMemo(() => debounce(getClassStudent, 50), []);

	React.useEffect(() => {
		let IS_STUDENT_LIST_CHANGED = STUDENT_CHANGE_REQUEST[CLASS_REQUEST.lastAction];
		if (IS_STUDENT_LIST_CHANGED) {
			if (CLASS_REQUEST.success && CLASS_REQUEST.status == "REFRESH_DATA_REQUIRE") {
				debouncedGetClassStudent({
					classId: classId,
					searchCriteria: {
						search: searchString, grades: gradeFilterValue, page: page, rowsPerPage: rowsPerPage
					}
				});
			}
		}
	}, [CLASS_REQUEST]);


    
	React.useEffect(() => {
		debouncedGetClassStudent({
			classId: classId,
			searchCriteria: {
				search: searchString, grades: gradeFilterValue, page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [classId, searchString, page, rowsPerPage, gradeFilterValue]);

	return (
		<React.Fragment>
			<Paper sx={{ marginTop: 0, marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-students")}</Typography>
								<Box>
									<Can I="update" this="class">
										<Button onClick={pendingButtonOnClick} sx={{ height: "50px", width: "140px" }} variant="contained">
											{t("general:button-pending") + ` (${PENDING_STUDENTS.length})`}
										</Button>
									</Can>
									<Can I="update" this={subject("class", CLASS_INFO)} field="students" ability={ABILITY}>
										<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddStudentDialog} aria-label="comment">
											<AddIcon />
										</Fab>
									</Can>
								</Box>
						</HeaderRow>
						<Stack sx={{ width: "100%" }}>
							<Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
								<TextField
									id="outlined-start-adornment"
									fullWidth
									onChange={(event) => { searchFieldOnChange(event) }}
									placeholder={t("general:label-search")}
									InputProps={{
										startAdornment:
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>,
										endAdornment:
											<InputAdornment position="end">
												<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
												<IconButton
													name="filterToggle"
													aria-label="toggle filter"
													onClick={toggleFilter}
													onMouseDown={(event) => { event.preventDefault() }}
												>
													<FilterListIcon />
												</IconButton>
											</InputAdornment>,
									}}
								/>
								<Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
									<Stack>
										<StyledFormControl fullWidth variant="outlined">
											<Controller
												name="gradeFilter"
												defaultValue={gradeFilterValue}
												control={STUDENT_FILTER_FORM.control}
												render={
													({ field }) =>
														<Autocomplete
															multiple
															id="tutor_subject_filter"
															options={convertOptionsForAutoComplete(OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].options)}
															getOptionLabel={(option) => t(`attribute:${option}`)}
															defaultValue={gradeFilterValue}
															value={gradeFilterValue}
															onChange={gradeFilterOnChange}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant="outlined"
																	label={t("attribute:class-grades")}
																	placeholder={t("attribute:class-grades")}
																/>
															)}
														/>
												}
											/>
										</StyledFormControl>
									</Stack>
								</Collapse>
							</Stack>
						</Stack>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			<TableContainer sx={{ borderRadius: "10px", }} component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow  >
							<BoldTableCell >{t("attribute:student-name")}</BoldTableCell>
							<BoldTableCell >{t("attribute:student-school")}</BoldTableCell>
							<BoldTableCell >{t("attribute:bill-status")}</BoldTableCell>
							<BoldTableCell align="right"></BoldTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{STUDENT_LIST.map((row) => {
							let profile = row.profile;
							return (
								<TableRow key={profile._id}>
									<TableCell component="th" scope="row">
										{nameInBracketStyle(profile.chiName, profile.engName)}
									</TableCell>
									<TableCell >{profile.school}</TableCell>
									<TableCell >{STUDENT_BILL_STATUS[row._id]?t("attribute:bill-status-"+STUDENT_BILL_STATUS[row._id]):""}</TableCell>
									<TableCell align="right">
										<IconButton onClick={(event) => { toggleStudentContextualMenu(event, row) }} aria-label="delete">
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={t("general:label-rows_per_page")}
					rowsPerPageOptions={[1, 5, 10, 25]}
					component="div"
					count={STUDENT_COUNT}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
			<ContextualMenu id={"admin-menu"} anchorEl={studentAnchorEl} setAnchorEl={setStudentAnchorEl} >
				<ContextualMenuItem
					onClick={openNewStudentTab}
					title={t("general:button-view")}>
					<VisibilityIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				{AUTH_STATE.currentRole.roleName != "platform_admin" && <ContextualMenuItem
					onClick={navigateToChatroom}
					title={t("general:button-chat")}>
					<SmsIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>}
				<ContextualMenuItem
					onClick={triggerIssueBillDialog}
					title={t("class:button-issue_bill")}>
					<LocalAtmIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					onClick={triggerDeleteDialog}
					title={t("class:button-remove")}>
					<RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ClassAddStudentDialog
				title={t("general:dialog_title-select_entity", { entity: t("attribute:student") })}
				isDialogOpen={addStudentDialogOpen}
				setIsDialogOpen={setAddStudentDialogOpen} />
			<ConfirmActionDialog
				title={t("class:dialog_title-remove_entity", { entity: t("attribute:student") })}
				actionName={t("general:button-remove")}
				open={deleteStudentDialogOpen}
				setOpen={setDeleteStudentDialogOpen}
				confirmOnClick={deleteStudentFromClass}
			/>
			<ClassPendingStudentDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:students") })}
				isDialogOpen={pendingStudentDialogOpen}
				setIsDialogOpen={setPendingStudentDialogOpen}
			/>
			<ClassBillDialog
			        currentBill={null}
			        currentClassId={classId}
			        currentStudent={selectedStudent}
			        isDialogOpen={issueBillDialogOpen}
			        setIsDialogOpen={setIssueBillDialogOpen} />        
		</React.Fragment >
	);
}
