import React from "react";
import { useTranslation } from "react-i18next";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import { acceptStudentEnrolment, rejectStudentEnrolment } from "./../../../../redux/Enrolment";
import { useSubscriptionManagementState } from "./../../../../redux/SubscriptionManagement";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import GeneralPendingDialog from "./../../../../component/common/GeneralPendingDialog";
import StudentCard from "./../../../page/student/StudentCard";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function CentrePendingStudentDialog({
	title, pendingStudentList, isDialogOpen, setIsDialogOpen }) {
	const { t } = useTranslation(["general", "config"]);
	const { centreId } = useParams();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedStudent, setSelectedStudent] = React.useState(null);

    const { enqueueSnackbar } = useSnackbar();
    const SUBSCRIBED = useSubscriptionManagementState().subscribed;
	const PLAN = useSubscriptionManagementState().subscriptionPlan;

	const openNewTab = (event, currentStudent) => {
		window.open(`/student/${currentStudent.enrolmentId}`, "_blank");
	}

	const toggleContextualMenu = (event, studentFromOnclick) => {
		setSelectedStudent(studentFromOnclick);
		setAnchorEl(event.currentTarget);
	};

	const acceptStudent = (event) => {
		event.preventDefault();
		setAnchorEl(null);
		if (SUBSCRIBED.currentStudent == PLAN.studentQuota) {
			enqueueSnackbar(t("subscription:warning-student_limit_reached"), {
				"variant": "warning"
			});
			navigate(`/centre_settings/${centreId}?tab=4`);
		} else {
			acceptStudentEnrolment({ enrolmentId: selectedStudent.enrolmentId });
		}
	}

	const rejectStudent = (event) => {
		event.preventDefault();
		setAnchorEl(null);
		rejectStudentEnrolment({ enrolmentId: selectedStudent.enrolmentId });
	}

	return (
		<React.Fragment>
			<GeneralPendingDialog title={title} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}>
				{
					pendingStudentList.map((currentStudent) =>
						<StudentCard
							key={"student_card_" + currentStudent.profile.enrolmentId}
							cardOnClick={openNewTab}
							currentStudent={currentStudent.profile}
							moreButtonOnClick={toggleContextualMenu}
						/>)
				}
			</GeneralPendingDialog>
			<ContextualMenu id={"admin-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				<ContextualMenuItem
					onClick={acceptStudent}
					title={t("general:button-accept")}>
					<CheckCircleOutlineIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					onClick={rejectStudent}
					title={t("general:button-delete")}>
					<CancelIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
		</React.Fragment>
	)
}