import React from 'react';
import { Grid, Typography, IconButton, Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import LanguageSwitcher from "./../I18N/LanguageSwitcher";
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from "./../../redux/authentication";
import GeneralRegistrationForm from "./../../component/form/GeneralRegistrationForm";
import { useTranslation } from "react-i18next";
import { NonDashboardRootContainer } from "./../../component/common/Containers";
import {
  useOptionsState,
  loadSystemLanguageOptions
} from "./../../redux/Options";
import { LogoutArrow } from "./../../component/common/Containers";



const ContentGrid = styled(Grid)(({ theme }) => ({
  "textAlign": "center"
}));

const LowestRowGrid = styled(Grid)(({ theme }) => ({
}));

const LoadingFrame = styled(Backdrop)(({ theme }) => ({
  zIndex: "500"
}));

export default function SignUpPage() {
  const VALID_VALUES = { SYSTEM_LANGUAGE: useOptionsState().systemLanguage.validValues };
  const [isLoading, setIsLoading] = React.useState(true);
  const { t } = useTranslation("general");

  React.useEffect(() => {
    loadSystemLanguageOptions();
  }, []);

  React.useEffect(() => {
    if (VALID_VALUES.SYSTEM_LANGUAGE.length > 0) {
      setIsLoading(false);
    }
  }, [
    VALID_VALUES.SYSTEM_LANGUAGE.length
  ]);

  function logout(event) {
    event.preventDefault();
    signOut();
  }

  return (
    <NonDashboardRootContainer container>
      <LoadingFrame open={isLoading}>
        <CircularProgress />
      </LoadingFrame>
      <Grid item xs={1} md={1} xl={1}>
        <LogoutArrow item xs={12} md={12} xl={12}>
          <Tooltip title={t("general:button-logout")}>
            <IconButton data-cy='logoutArrow' size="small" onClick={logout}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </LogoutArrow>
      </Grid>
      <ContentGrid item xs={10} md={10} xl={10}>
      </ContentGrid>
      <ContentGrid item xs={1} md={1} xl={1}>
        <Typography variant="body2" component="span" color="textSecondary" align="right">
          <LanguageSwitcher disableContrast={true} showText={false}></LanguageSwitcher>
        </Typography>
      </ContentGrid>
      <LowestRowGrid container>
        <Grid item xs={1} md={3} xl={4}>
        </Grid>
        <Grid item xs={10} md={6} xl={4}>
          <GeneralRegistrationForm isHidePassword={true}></GeneralRegistrationForm>
        </Grid>
        <Grid item xs={1} md={3} xl={4}>
        </Grid>
      </LowestRowGrid>
    </NonDashboardRootContainer>
  );
}