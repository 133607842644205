import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import {
	Paper
} from "@mui/material";
import { useI18NControlState } from "./../../redux/I18NControl";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ReorderIcon from '@mui/icons-material/Reorder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function CalendarView({ eventOnClick, events, displayEventEnd }) {
	const { t } = useTranslation();
	const I18N_STATE = useI18NControlState();
	const locale = I18N_STATE.locales[I18N_STATE.lang];
	const [aspectRatio, setAspectRatio] = React.useState(1.35);
	const [footerToolbar, setFooterToolbar] = React.useState({
		end: "",
		center: "",
		start: ""
	});
	const [headerToolbar, setHeaderToolbar] = React.useState({
		end: "dayGridMonth,listMonth",
		center: "title",
		start: "prev,today,next"
	});

	const [dateFormat, setDateFormat] = React.useState({
		dayGridMonth: { // name of view
			titleFormat: { year: 'numeric', month: 'long' }
			// other view-specific options here
		},
		listMonth: { // name of view
			titleFormat: { year: 'numeric', month: 'long' }
			// other view-specific options here
		}
	});

	const eventOnClickHandler = (info) => {
		if (eventOnClick && typeof eventOnClick == "function") {
			eventOnClick.call(this, info);
		}
	}


	let theme = useTheme();
	let smDown = useMediaQuery(theme.breakpoints.down("sm"));
	React.useEffect(() => {
		if (smDown) {
			setAspectRatio(0.6);

			setHeaderToolbar({
				end: "",
				center: "title",
				start: ""
			});

			setFooterToolbar({
				end: "dayGridMonth,listMonth",
				center: "",
				start: "prev,today,next"
			});

			setDateFormat({
				dayGridMonth: { // name of view
					titleFormat: { year: 'numeric', month: 'short' }
					// other view-specific options here
				},
				listMonth: { // name of view
					titleFormat: { year: 'numeric', month: 'short' }
					// other view-specific options here
				}
			});
		} else {
			setAspectRatio(1.35);
			setHeaderToolbar({
				end: "dayGridMonth,listMonth",
				center: "title",
				start: "prev,today,next"
			});

			setFooterToolbar({
				end: "",
				center: "",
				start: ""
			})

			setDateFormat({
				dayGridMonth: { // name of view
					titleFormat: { year: 'numeric', month: 'long' }
					// other view-specific options here
				},
				listMonth: { // name of view
					titleFormat: { year: 'numeric', month: 'long' }
					// other view-specific options here
				}
			});
		}

	}, [smDown])

	return (
		<React.Fragment>
			<Paper sx={{ marginTop: 0, marginBottom: 2, padding: 2, borderRadius: "10px" }}>
				<FullCalendar
					locale={locale}
					plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
					dayMaxEvents={true}
					buttonText={{
						today: t('general:button-today'),
						dayGridMonth: <CalendarMonthIcon />,
						listMonth: <ReorderIcon />
					}}
					eventTimeFormat={{
						hour: 'numeric',
						minute: '2-digit',
						omitZeroMinute: false
					}}
					aspectRatio={aspectRatio}
					eventDisplay="block"
					displayEventEnd={displayEventEnd}
					themeSystem="standard"
					views={dateFormat}
					titleFormat={dateFormat}
					eventClick={eventOnClickHandler}
					events={events}
					noEventsContent={{ html: `<i>${t("general:message-no_event")}</i>` }}
					headerToolbar={headerToolbar}
					footerToolbar={footerToolbar}
					initialView="dayGridMonth"
				/>
			</Paper>
		</React.Fragment >
	);
}
