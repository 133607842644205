import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, MenuItem, ListItemIcon } from "@mui/material";
import { Can } from "./../../casl/authorization";
function ContextualMenuItem(props) {
    const CONTENT = (
        <MenuItem sx={{ fontSize: 16, minWidth: 150 }} {...props}>
            <ListItemIcon sx={{ mr: -0.5 }}>
                {props.children}
            </ListItemIcon>
            {props.title}
        </MenuItem>
    );

    if (props.permission) {
        let permission = props.permission;
        return (
            <Can I={permission.I} this={permission.this} ability={permission.ability}>
                {CONTENT}
            </Can>
            )

    }
    return CONTENT;
}


const SuccessButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.success.light,
    backgroundColor: theme.palette.success.light,
    '&:hover': {
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.main,
    }
}));

export { SuccessButton, ContextualMenuItem };