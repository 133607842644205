import React from 'react';
import { Container } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import SignUpPage from "./SignUpPage";
import AccountManagement from "./AccountManagement";
import UserManagement from "./UserManagement";
import PlatformAdmin from "./PlatformAdmin";
import CentreList from "./CentreList";
import PaymentInfo from "./differentAccountPayment/PaymentInfo";
import SubscriptionDemo from "./subscriptionDemo/Subscription";
import Completion from "./paymentDemo/Completion";
import PlatformAdminOnly from "./../../component/acl/PlatformAdminOnly";
import NotPlatformAdmin from "./../../component/acl/NotPlatformAdmin";
import CentrePage from "./CentrePage";
import CentreSettings from "./CentreSettings";
import FacilityDetails from "./FacilityDetails";
import TutorDetail from "./TutorDetail";
import ClassDetail from "./ClassDetail";
import StudentDetail from "./StudentDetail";
import ChatroomPage from "./ChatroomPage";
import ClassSessionPage from "./ClassSessionPage";
import PlanList from "./PlanList";
import PayBillSuccess from './PayBillSuccess';
import Subscription from './Subscription';

export default function RouteContainer({socket, notificationCount, setNotificationCount}) {
    return (
        <Container sx={{
            margin: "0px",
            width: "100%",
            paddingLeft: "5px",
            paddingRight: "5px",
            "background": "rgb(243,243,243)",
            minHeight: "100%",
            height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 66px)", md: "calc(100vh - 72px)" },
            "overflowY": "auto"
        }} maxWidth="false">
            <Routes>
                <Route index path='/' element={<Home />} />
                <Route path='/home' element={<Home />} />
                <Route path='/account' element={<AccountManagement />} />
                <Route path="/paymentDemo" element={<PaymentInfo />} />
                <Route path="/subscriptionDemo" element={<SubscriptionDemo />} />
                <Route path="/completion" element={<Completion />} />


                <Route path="/users" element={
                    <PlatformAdminOnly>
                        < UserManagement />
                    </PlatformAdminOnly>
                } />
                <Route path="/platform_admins" element={
                    <PlatformAdminOnly>
                        < PlatformAdmin />
                    </PlatformAdminOnly>} />
                    <Route path="/plans" element={
                    <PlatformAdminOnly>
                        < PlanList />
                    </PlatformAdminOnly>} />

                <Route path="/centres" element={
                    < CentreList />
                } />
                <Route path="/centre/:centreId" element={< CentrePage />} />
                <Route path='/centre_settings/:centreId' element={
                    <CentreSettings />
                } />
                <Route path="/facility/:facilityId" element={< FacilityDetails />} />
                <Route path="/class_session/:sessionId" element={< ClassSessionPage />} />

                <Route path="/subscription" element={< Home />} />

                <Route path="/tc" element={<SignUpPage />} />
                <Route path="/class/:classId" element={< ClassDetail />} />
                <Route path="/tutor/:enrolmentId" element={< TutorDetail />} />
                <Route path="/student/:enrolmentId" element={< StudentDetail />} />
                <Route path="/message" element={
                    <NotPlatformAdmin>
                        < ChatroomPage socket={socket} notificationCount={notificationCount} setNotificationCount={setNotificationCount}/>
                    </NotPlatformAdmin>
                } />
                <Route path="/message/:messageType" element={
                    <NotPlatformAdmin>
                        < ChatroomPage socket={socket} notificationCount={notificationCount} setNotificationCount={setNotificationCount}/>
                    </NotPlatformAdmin>
                } />
                <Route path="/paybillsuccess/:sessionId" element={< PayBillSuccess />}/>
                <Route path='*' element={<Home />} />
            </Routes>
        </Container>
    );
}