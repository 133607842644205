import * as YUP from "yup";

YUP.addMethod(YUP.array, "atLeastOneTrue", function(errorMessage) {
  return this.test('at-least-one-true', errorMessage, function(inputValue) {
    const { path, createError } = this;
    return (inputValue.includes(true) || createError({ path, message: errorMessage })
    );
  });
});

const USER_DATA_FIELD = {
  emailAddress: "emailAddress",
  password: "password",
  confirmPassword: "confirmPassword",
  displayName: "displayName",
  phone: "phone",
  notificationLanguage: "notificationLanguage",
  notificationMethod: "notificationMethod",
  "agreeTC": "agreeTC",
  "agreePrivacy":"agreePrivacy"
};


function createUserRegistrationFormSchema(systemLanguageValidValue) {
  return YUP.object().shape({
    displayName: YUP.string().required("general:validation-mandatory"),
    phone: YUP.string().matches(/^\+?[0-9]{7,15}$/, "general:validation-phone"),
    notificationLanguage: YUP.string().oneOf(systemLanguageValidValue).nullable().required("general:validation-mandatory"),
    notificationMethod: YUP.object().nullable(),
    agreeTC: YUP.boolean().oneOf([true], 'account:validation-agree_tc'),
    agreePrivacy: YUP.boolean().oneOf([true], 'account:validation-agree_pp')
  });

};

function createUserFormSchema(systemLanguageValidValue) {
  return YUP.object().shape({
    displayName: YUP.string().required("general:validation-mandatory"),
    phone: YUP.string().matches(/^\+?[0-9]{7,15}$/, "general:validation-phone"),
    notificationLanguage: YUP.string().oneOf(systemLanguageValidValue).nullable().required("general:validation-mandatory"),
    notificationMethod: YUP.object()
  });

};


function createAdminRegistrationFormSchema(systemLanguageValidValue) {
  return YUP.object().shape({
    displayName: YUP.string().required("general:validation-mandatory"),
    phone: YUP.string().matches(/^\+?[0-9]{7,15}$/, "general:validation_type_phone"),
    password: YUP.string().min(8, "general:validation_password_length").required("general:validation-mandatory"),
    confirmPassword: YUP.string().required("general:validation-mandatory")
      .oneOf([YUP.ref('password'), null], "general:validation_password_not_match"),
    notificationLanguage: YUP.string().oneOf(systemLanguageValidValue).nullable().required("general:validation-mandatory"),
    notificationMethod: YUP.object().nullable(),
    agreeTC: YUP.boolean().oneOf([true], 'tc_must_be_checked'),
    agreePrivacy: YUP.boolean().oneOf([true], 'tc_must_be_checked')
  });

};

const INITIAL_USER_FORM = {
  emailAddress: "",
  password: "",
  confirmPassword: "",
  displayName: "",
  phone: "",
  notificationLanguage: "",
  notificationMethod: { email: false, app:false},
  agreeTC: false,
  agreePrivacy: false
};


export {
  createUserRegistrationFormSchema,
  createAdminRegistrationFormSchema,
  createUserFormSchema,
  INITIAL_USER_FORM,
  USER_DATA_FIELD
};