import React from "react";
import {
    Dialog, DialogTitle, DialogContent
} from "@mui/material";
import SessionCard from "./../../../page/class/SessionCard";
import { useClassSessionManagementState } from "./../../../../redux/ClassSessionManagement";

export default function ClassPendingSessionDialog({
    title, isDialogOpen, setIsDialogOpen }) {
        
    const PENDING_CLASS_SESSION = useClassSessionManagementState().pendingClassEvent || [];

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const openNewTab = (event, currentSession) => {
        window.open(`/class_session/${currentSession._id}`, "_blank");
    }

    return (
        <React.Fragment>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                scroll="paper"
                maxWidth="md"
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 1, paddingTop: 3 }} dividers>
                    {
                        PENDING_CLASS_SESSION.map((currentSession) =>
                            <SessionCard
                                key={"class_card_" + currentSession._id}
                                cardOnClick={openNewTab}
                                currentSession={currentSession}
                            />)
                    }
                </DialogContent>
            </Dialog >
        </React.Fragment >
    )
}