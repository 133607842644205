import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Paper, Grid, Button,
	Typography, Fab, Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SpaceHolder } from "./../../common/Others";
import debounce from 'lodash.debounce';
import { useParams } from "react-router-dom";
import { Can } from "./../../..//casl/authorization";
import { subject } from "@casl/ability";
import { useClassSessionManagementState, getClassSession } from "./../../../redux/ClassSessionManagement";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useClassesManagementState } from "./../../../redux/ClassesManagement";
import ClassPendingSessionDialog from "./../../page/class/dialog/ClassPendingSessionDialog";
import ClassSessionDialog from "./../../page/class/dialog/ClassSessionDialog";
import CalendarView from "./../../display/CalendarView";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5)
}));

const ACTION_IN_CHECK = {
	DELETE_CLASS_SESSION: true,
	CONFIRM_CLASS_SESSION: true,
	CREATE_CLASS_SESSION: true
}

export default function ClassSchedulePage() {
	const { t } = useTranslation();
	const [addClassSectionDialog, setAddClassSectionDialog] = React.useState(false);
	const [pendingClassSectionDialog, setPendingClassSectionDialog] = React.useState(false);
	const { classId } = useParams();
	const CLASS_INFO = useClassesManagementState().currentClass;
	const CLASS_SESSION_REQUEST = useClassSessionManagementState().request;
	const EVENT = useClassSessionManagementState().classEvent || [];
	const PENDING_CLASS_SESSION = useClassSessionManagementState().pendingClassEvent || [];
	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;

	const pendingButtonOnClick = (event) => {
		event.preventDefault();
		if (PENDING_CLASS_SESSION.length > 0) {
			setPendingClassSectionDialog(true);
		}
	}

	const triggerAddDialog = (event) => {
		event.preventDefault();
		setAddClassSectionDialog(true);
	}

	const eventOnClick = (info) => {
		info.jsEvent.preventDefault();
		window.open(info.event.url);
	}


	let deboundedGetClassSession = React.useMemo(() => debounce(getClassSession, 50), []);

	React.useEffect(() => {
		if (ACTION_IN_CHECK[CLASS_SESSION_REQUEST.lastAction]) {
			if (CLASS_SESSION_REQUEST.status === "REFRESH_DATA_REQUIRE") {
				deboundedGetClassSession({
					type: "class",
					queryId: classId
				});
			}
		}
	}, [CLASS_SESSION_REQUEST]);

	React.useEffect(() => {
		deboundedGetClassSession({
			type: "class",
			queryId: classId
		});
	}, []);

	React.useEffect(() => {
		deboundedGetClassSession({
			type: "class",
			queryId: classId
		});
	}, [classId]);

	return (
		<React.Fragment>
			<Paper sx={{ marginTop: 0, marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-sessions")}</Typography>
							<Box>
								<Can I="update" this={subject("class", CLASS_INFO)} ability={ABILITY}>
									<Can I="create" this={"class_session"} ability={ABILITY}>
										<Button onClick={pendingButtonOnClick} sx={{ height: "50px", width: "140px" }} variant="contained">
											{t("general:button-pending") + ` (${PENDING_CLASS_SESSION.length})`}
										</Button>
										<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddDialog} aria-label="comment">
											<AddIcon />
										</Fab>
									</Can>
								</Can>
							</Box>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			<CalendarView events={EVENT}
				eventOnClick={eventOnClick}
				displayEventEnd={true}
			/>
			<ClassSessionDialog
				title={t("general:dialog_title-add_entity", { entity: t("attribute:session") })}
				currentClassId={classId}
				isDialogOpen={addClassSectionDialog}
				setIsDialogOpen={setAddClassSectionDialog} />

			<ClassPendingSessionDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:session") })}
				isDialogOpen={pendingClassSectionDialog}
				setIsDialogOpen={setPendingClassSectionDialog} />

		</React.Fragment >
	);
}
