import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useOptionsState } from "./../../../redux/Options";
import { saveTutorProfile } from "./../../../redux/ProfileManagement";
import TutorFieldList from "./../../form/tutorComponent/TutorFieldList";
import PortraitUploader from "./../../form/commonComponent/PortraitUploader";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { INITIAL_TUTOR_FORM, createTutorFormSchema } from "./../../form/model/TutorFormModel";
import { SuccessButton } from "./../../common/Buttons";
import { DialogFixedForm } from "./../../common/Forms";
import { nameInBracketStyle } from "./../../../util/DisplayUtil";

export default function TutorEditDialog({
    open,
    setOpenDialog,
    currentTutor
}) {
    const { t } = useTranslation(["account", "general"]);
    const [photoObject, setPhotoObject] = React.useState(null);
    const VALID_VALUES = {
        GENDER: useOptionsState().gender.validValues,
        SUBJECTS: useOptionsState().subject.validValues,
        EDUCATION_LEVEL: useOptionsState().educationLevel.validValues,
        MAIN_LANGUAGE: useOptionsState().mainLanguage.validValues
    };
    const TUTOR_RPROFILE_SCHEMA = createTutorFormSchema(VALID_VALUES.GENDER, VALID_VALUES.MAIN_LANGUAGE, VALID_VALUES.EDUCATION_LEVEL, VALID_VALUES.SUBJECTS);
    const TUTOR_FORM_METHODS = useForm({
        resolver: yupResolver(TUTOR_RPROFILE_SCHEMA),
        defaultValues: INITIAL_TUTOR_FORM
    });


    React.useEffect(() => {
        if (currentTutor && currentTutor.photo) {
            setPhotoObject(currentTutor.photo);
        } else {
            setPhotoObject({});
        }
    }, [open])

    React.useEffect(() => {

        if (currentTutor && currentTutor.photo) {
            setPhotoObject(currentTutor.photo);
        }
    }, [currentTutor])



    function saveUpdatedTutor(data) {
        var updatedTutor = Object.assign({}, data, { photo: photoObject });

        let formData = new FormData();

        if (photoObject && photoObject.file && typeof photoObject.file != String) {
            formData.append("tutorPhoto", photoObject.file);
        }
        formData.append("tutor", JSON.stringify(data));
        saveTutorProfile({
            tutor: updatedTutor,
            tutorInForm: formData
        });
        TUTOR_FORM_METHODS.reset(INITIAL_TUTOR_FORM);
        setOpenDialog(false);
    }


    function closeEditDialog() {
        setPhotoObject({});
        TUTOR_FORM_METHODS.reset(INITIAL_TUTOR_FORM);
        setOpenDialog(false);
    }

    return (<Dialog
        open={open}
        onClose={closeEditDialog}
        scroll={"paper"}
        aria-labelledby="responsive-tutor-dialog-title"
    >
        <FormProvider {...TUTOR_FORM_METHODS}>
            <DialogFixedForm
                control={TUTOR_FORM_METHODS.control} errors={TUTOR_FORM_METHODS.errors}
                onSubmit={TUTOR_FORM_METHODS.handleSubmit(saveUpdatedTutor)}>
                <DialogTitle id="responsive-tutor-dialog-title">
                    {t("general:dialog_title-edit_entity-with_name", { entity: t("attribute:tutor_profile"), name: nameInBracketStyle(currentTutor.chiName, currentTutor.engName) })}
                </DialogTitle>
                <DialogContent dividers data-cy="edit_dialog_content">
                    <PortraitUploader labelText={t("attribute:tutor-profile_picture")} id="edit_dialog_uploader" photoObject={photoObject} setPhotoObject={setPhotoObject} />
                    <TutorFieldList currentTutor={currentTutor}></TutorFieldList>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button autoFocus data-cy={"edit_tutor_card_cancel"} onClick={closeEditDialog} variant="outlined">
                            {t("general:button-cancel")}
                        </Button>
                        <SuccessButton type="submit" data-cy={"edit_tutor_card_save"} variant="contained">
                            {t("general:button-save")}
                        </SuccessButton>
                    </Grid>
                </DialogActions>
            </DialogFixedForm>
        </FormProvider>
    </Dialog>);
}