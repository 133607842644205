import { createReduxModule } from "hooks-for-redux"
import {
    getThemeSettings,
    updateThemeSettings
} from "./../api/settingAPI.tsx";
import { getDefaultThemeColor } from "./../util/ColorUtils";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";

const UPDATE_ACTION = {
    SAVE: "SAVE",
    RELOAD: "RELOAD"
};

const initialState = Object.assign({}, {
    request: initalRequestState(),
    centreId: null,
    updateAction: [],
    originalStateSnapshot: null,
}, getDefaultThemeColor());

function changeShadeAction(themePickerState, shade, type) {
    var resultObj = {};
    resultObj[type] = themePickerState[type];
    resultObj[type].shade = shade;
    return resultObj;
}

function changeThemeAction(themePickerState, theme, type) {
    var resultObj = {};
    resultObj[type] = themePickerState[type];
    resultObj[type].theme = theme;
    return resultObj;
}

export const [useThemePickerState, {
    setUpdating,
    captureOriginalStateSnapshot,
    restoreFromOriginalStateSnapshot,
    reloadThemeSetting, reloadThemeSettingSuccess, reloadThemeSettingFail,
    saveThemeSetting, saveThemeSettingSuccess, saveThemeSettingFail,
    changeTheme,
    changeShade,
    initThemeSetting
},
    themePickerState
] = createReduxModule(
    "ThemePickerState",
    initialState, {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    captureOriginalStateSnapshot: (state, payload) => Object.assign({}, state, {
        originalStateSnapshot: {
            primary: Object.assign({}, state.primary, {}),
            secondary: Object.assign({}, state.secondary, {})
        }
    }),
    restoreFromOriginalStateSnapshot: (state, payload) => Object.assign({}, state, state.originalStateSnapshot, {
        originalStateSnapshot: null
    }),
    reloadThemeSetting: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.RELOAD)
    }),
    reloadThemeSettingSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        originalStateSnapshot: null,
        primary: payload.primary,
        secondary: payload.secondary
    }),
    reloadThemeSettingFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    saveThemeSetting: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SAVE),
        centreId: payload.centreId
    }),
    saveThemeSettingSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        originalStateSnapshot: {
            primary: state.primary,
            secondary: state.secondary,
        }
    }),
    saveThemeSettingFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload), {
        originalStateSnapshot: {
            primary: payload.primary,
            secondary: payload.secondary,
        },
    }),
    changeTheme: (state, payload) => Object.assign({}, state, changeThemeAction(state, payload.theme, payload.type)),
    changeShade: (state, payload) => Object.assign({}, state, changeShadeAction(state, payload.shade, payload.type)),
    initThemeSetting: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.RELOAD)
    }),
});

const UPDATE_ACTION_HANDLER = {
    "SAVE": {
        handler: async (currentState, action) => {
            let result = await updateThemeSettings({
                centreId: currentState.centreId,
                primary: currentState.primary,
                secondary: currentState.secondary,
            });
            if (result.success) {
                saveThemeSettingSuccess({
                    action: action,
                    requestStatus: result.status,
                    message: result.message,
                });
            } else {
                saveThemeSettingFail({
                    action: action,
                    requestStatus: result.status,
                    message: result.message
                });
            }
        },
        failHandler: saveThemeSettingFail
    },
    "RELOAD": {
        handler: async (currentState) => {
            var result = await getThemeSettings();
            if (result.result) {
                reloadThemeSettingSuccess(result.result);
            } else {
                reloadThemeSettingSuccess(getDefaultThemeColor());
            }
        },
        failHandler: reloadThemeSettingFail
    }
}

themePickerState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});
