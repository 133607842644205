import React, { useEffect } from 'react';
import {
  Button, CssBaseline, TextField, Link, Paper, Box, Grid, Typography,
  InputAdornment, IconButton,Stack
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthenticationState, signIn, UPDATE_ACTION } from "./../../redux/authentication";
import LanguageSwitcher from "./../../component/I18N/LanguageSwitcher";
import { NeedHelp } from "./../../component/common/Others";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import DefaultImage from "./../../asset/signin_default/background.jpg";
import * as yup from "yup";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import siteIcon from "./../../asset/img/site_icon.png";
import { SiteIcon } from "./../../component/common/Others";
import appStoreIcon from "./../../asset/img/app_store.webp";
import playStoreIcon from "./../../asset/img/play_store.webp";

const RootContainer = styled(Grid)(({ theme }) => ({
  height: '100vh',
}));

const ImageContainer = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${DefaultImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledDiv = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const LanguageSwitcherContainer = styled(Typography)(({ theme }) => ({
  "paddingTop": "10px",
  "paddingRight": "20px"
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  "marginTop": "10px"
}));

const ForgetPassword = styled(RouterLink)(({ theme }) => ({
  color: '#455a64',
  textDecoration: 'none',
  "&:hover": {
    textDecoration: 'underline'
  }
}));

const LoginFormSchema = yup.object().shape({
  username: yup.string().email("general:validation-email").required("login:validation-mandatory"),
  password: yup.string().min(8, "general:validation-password-length").required("login:validation-mandatory"),
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        LYF Group Ltd
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignlnPage() {
  const { t } = useTranslation("login", "attribute", "general");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(LoginFormSchema)
  });

  const handleClickShowPassword = (name) => {
    switch (name) {
      case "password":
        setShowPassword((prev) => !prev);
        break;
      default:
        break;
    }
  };
  const AUTH_STATE = useAuthenticationState();

  useEffect(() => {
    if (errors.username) {
      setErrorMessage(t(errors.username.message));
      return;
    }
    if (errors.password) {
      setErrorMessage(t(errors.password.message));
      return;
    }

    if (AUTH_STATE && AUTH_STATE.request) {
      let request = AUTH_STATE.request;
      if (request.lastAction) {
        switch (request.lastAction) {
          case UPDATE_ACTION.SIGNIN:
            if (request.error) {
              setErrorMessage(t("login:error-login"));
            }
            break;
          case UPDATE_ACTION.UPDATE_FORGET_PASSWORD:
            if (request.error) {
              setErrorMessage(t("general:error-unknown"), {
                "variant": "error"
              });
            } else {
              enqueueSnackbar(t("general:success-save"), {
                "variant": "success"
              });
            }
            break;
          default:
            break;
        }
      }


      return;
    }
  }, [AUTH_STATE, errors.username, errors.password]);


  function login(data) {
    signIn({ username: data.username, password: data.password });
  }


  return (
    <RootContainer container component="main" >
      <CssBaseline />
      <ImageContainer item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <LanguageSwitcherContainer variant="body2" component="span" color="textSecondary" align="right">
          <LanguageSwitcher disableContrast={true} showText={false}></LanguageSwitcher>
        </LanguageSwitcherContainer>
        <StyledDiv>
          <SiteIcon sx={{marginBottom:8}} alt="Company Logo" src={siteIcon} />
          <StyledForm onSubmit={handleSubmit(login)} noValidate>
            <Controller
              name="username"
              control={control}
              defaultValue={''}
              render={
                ({ field }) => <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("attribute:user-email_address")}
                  type="email"
                  {...field}
                />
              }
            />
            <Controller
              name="password"
              control={control}
              defaultValue={''}
              render={
                ({ field }) => <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("attribute:user-password")}

                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          name="test"
                          aria-label="toggle password visibility"
                          onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClickShowPassword("password") }}
                          onMouseDown={(event) => { event.preventDefault() }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...field}
                />
              }
            />
            <ErrorMessage component="h2" variant="body2" color="error">
              {errorMessage ? errorMessage : null}

            </ErrorMessage>
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              {t("login:button-login")}
            </SubmitButton>
            <NeedHelp container>
              <Grid item xs>
                <ForgetPassword data-cy="link_forgetPassword" to="/forgetpassword" variant="body2">
                  {t("login:link-forgot_password")}
                </ForgetPassword>
              </Grid>
            </NeedHelp>
            <Box mt={5}>
              <Copyright />
            </Box>
            <Stack sx={{marginTop:4}} justifyContent="center" direction="row" spacing={2}>
              <Link target="_blank" color="inherit" href="https://apps.apple.com/hk/app/lyf-itutor/id1672609338?l=en">
                 <img
                    height={40}
                    src={appStoreIcon}
                    alt={"App store"}
                    loading="lazy"
                  />
              </Link>
              <Link target="_blank" color="inherit" href="https://play.google.com/store/apps/details?id=com.lyfgroup.itutorApp&hl=en">
                 <img
                    height={40}
                    src={playStoreIcon}
                    alt={"App store"}
                    loading="lazy"
                  />
              </Link>
            </Stack>
          </StyledForm>
        </StyledDiv>

      </Grid>
    </RootContainer>
  );
}
