import {
    createReduxModule
} from "hooks-for-redux";
import {
    login,
    logout,
    validateJWT,
    resendVerificationEmail,
    updateRole,
    requestRecoverPasswordEmail,
    requestUpdateForgetPassword,
    requestUpdatePassword
} from "./../api/authAPI.tsx";
import {
    reloadThemeSetting
} from "./ThemePicker";
import USER_TYPE from "./../const/UserType";
import ERROR from "./../const/Error";
import {
    ANNOYMOUS_ABILITY
} from "./../casl/authorization";
import {
    Ability
} from "@casl/ability";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import {
    loadUserEnrolment
} from "./Enrolment";

function initialState() {
    return {
        request: initalRequestState(),
        username: null,
        password: null,
        displayName: "",
        userId: "",
        currentRole: {},
        roles: [],
        userType: "",
        status: "",
        updateAction: [],
        renewPassword: "",
        registeredEnrolmentMap: {},
        token: "",
        email: "",
        ability: ANNOYMOUS_ABILITY
    };
}

export const UPDATE_ACTION = {
    "SIGNIN": "SIGNIN",
    "SIGN_OUT": "SIGN_OUT",
    "RECOVER_PASSWORD": "RECOVER_PASSWORD",
    "VALIDATE_JWT": "VALIDATE_JWT",
    "UPDATE_CURRENT_ROLE": "UPDATE_CURRENT_ROLE",
    "VERIFICATION_EMAIL": "VERIFICATION_EMAIL",
    "UPDATE_FORGET_PASSWORD": "UPDATE_FORGET_PASSWORD",
    "UPDATE_PASSWORD": "UPDATE_PASSWORD"
};

function signInSuccessAction(state, payload) {
    localStorage.setItem("userId", payload.userId);
    return Object.assign({}, generalSuccessReduxResponse(state, payload), {
        currentRole: payload.currentRole,
        roles: payload.roles,
        userId: payload.userId,
        username: payload.username,
        ability: payload.ability,
        displayName: payload.displayName,
        userType: payload.userType,
        status: payload.status
    });
}

function signOutSuccessAction(authenticationState, payload) {
    var result = initialState();
    localStorage.removeItem("userId");
    return result;
}

function validateLocalJWTAction(authenticationState) {
    var result = authenticationState.updateAction.slice();
    result.push(UPDATE_ACTION.VALIDATE_JWT);
    var userId = localStorage.getItem("userId");
    return {
        updateAction: result,
        userId: userId
    };
}

function refreshRegisteredMapAction(state, payload) {
    let result = {};
    for (let role of state.roles) {
        if (role.profileId && role.centreId && role.centreId.length > 0) {
            let centreId = role.centreId;
            let profileId = role.profileId;
            if (!result[centreId]) {
                result[centreId] = {};
            }
            result[centreId][profileId] = true;
        }
    }
    return result;
}

export const [useAuthenticationState, {
    setUpdating,
    signIn, signInSuccess, signInFail,
    validateLocalJWT, validateLocalJWTSuccess, validateLocalJWTFail,
    requestVerificationEmail, requestVerificationEmailSuccess, requestVerificationEmailFail,
    signOut,
    signOutSuccess,
    changeRole,
    updateCurrentRole, updateCurrentRoleSuccess, updateCurrentRoleFail,
    recoverPassword, recoverPasswordSuccess, recoverPasswordFail,
    updateForgetPassword, updateForgetPasswordSuccess, updateForgetPasswordFail,
    updatePassword, updatePasswordSuccess, updatePasswordFail,
    refreshRegisteredMap
},
    authenticationState
] = createReduxModule(
    "authenticationState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    signIn: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SIGNIN),
        username: payload.username,
        password: payload.password,
    }),
    signInSuccess: (state, payload) => Object.assign({}, state, signInSuccessAction(state, payload)),
    signInFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    validateLocalJWT: (state, payload) => Object.assign({}, state, validateLocalJWTAction(state, payload)),
    validateLocalJWTSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentRole: payload.currentRole,
        roles: payload.roles,
        userId: payload.userId,
        username: payload.username,
        displayName: payload.displayName,
        userType: payload.userType,
        ability: payload.ability,
        status: payload.status
    }),
    validateLocalJWTFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    requestVerificationEmail: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.VERIFICATION_EMAIL),
    }),
    requestVerificationEmailSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload)),
    requestVerificationEmailFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    signOut: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SIGN_OUT),
    }),
    signOutSuccess: (state, payload) => Object.assign({}, state, signOutSuccessAction(state, payload)),
    changeRole: (state, payload) => Object.assign({}, state, {
        currentRole: { roleId: payload.role }
    }),
    updateCurrentRole: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_CURRENT_ROLE),
    }),
    updateCurrentRoleSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        ability: payload.ability,
        currentRole: payload.currentRole
    }),
    updateCurrentRoleFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    recoverPassword: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.RECOVER_PASSWORD),
        email: payload.email
    }),
    recoverPasswordSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload)),
    recoverPasswordFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateForgetPassword: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_FORGET_PASSWORD),
        email: payload.email,
        renewPassword: payload.renewPassword,
        token: payload.token
    }),
    updateForgetPasswordSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload)),
    updateForgetPasswordFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updatePassword: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_PASSWORD),
        email: payload.email,
        renewPassword: payload.renewPassword,
        password: payload.password,
        token: payload.token
    }),
    updatePasswordSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        renewPassword: null,
        password: null
    }),
    updatePasswordFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload), {
        renewPassword: null,
        password: null
    }),
    refreshRegisteredMap: (state, payload) => Object.assign({}, state, {
        registeredEnrolmentMap: refreshRegisteredMapAction(state, payload)
    }),
});

const UPDATE_ACTION_HANDLER = {
    "SIGNIN": {
        handler: async (currentState, action) => {
            var result = await login(currentState.username, currentState.password);
            if (result) {
                if (result.success) {
                    if (result.data) {
                        switch (result.data.userType) {
                            case USER_TYPE.PENDING:
                            case USER_TYPE.VERIFIED:
                            case USER_TYPE.NEW_REGISTER:
                            default:
                                let ability = ANNOYMOUS_ABILITY;
                                if (result.data && result.data.abilityRules && result.data.abilityRules) {
                                    ability = new Ability(result.data.abilityRules);
                                }
                                signInSuccess({
                                    token: result.data.token,
                                    currentRole: result.data.currentRole,
                                    roles: result.data.roles,
                                    username: result.data.username,
                                    displayName: result.data.displayName,
                                    ability: ability,
                                    userId: result.data.userId,
                                    userType: result.data.userType,
                                    status: result.data.status,
                                    action: action
                                });
                                refreshRegisteredMap();
                                reloadThemeSetting();
                                loadUserEnrolment({ status: "all" });
                                break;
                        }
                    } else {
                        signInFail({
                            action: action,
                            error: ERROR.USER_MISSING_ERROR,
                            message: "login_failed_user_missing_msg"
                        });
                    }
                } else {
                    signInFail({
                        action: action,
                        error: ERROR.INVALID_AUTHENTICATION_INFORMATION_ERROR,
                        message: "error-login"
                    });
                }
            }
        }, failHandler: signInFail
    },
    "SIGN_OUT": {
        handler: async (currentState) => {
            var result = await logout();
            if (result) {
                if (result.success) {
                    signOutSuccess();
                    reloadThemeSetting();
                }
            }
        }, failHandler: null
    },
    "RECOVER_PASSWORD": {
        handler: async (currentState) => {
            let result = await requestRecoverPasswordEmail(currentState.email);
            if (result.success) {
                recoverPasswordSuccess({});
            } else {
                recoverPasswordFail({
                    message: "resend email request failed",
                    error: result.error
                });
            }
        }, failHandler: recoverPasswordFail
    },
    "VALIDATE_JWT": {
        handler: async (currentState) => {
            var result = await validateJWT();
            if (result.success) {
                if (result.data) {
                    switch (result.data.userType) {
                        case USER_TYPE.PENDING:
                        case USER_TYPE.VERIFIED:
                        case USER_TYPE.NEW_REGISTER:
                        default:
                            let ability = ANNOYMOUS_ABILITY;
                            if (result.data && result.data.abilityRules && result.data.abilityRules) {
                                ability = new Ability(result.data.abilityRules);
                            }
                            validateLocalJWTSuccess({
                                token: result.data.token,
                                currentRole: result.data.currentRole,
                                roles: result.data.roles,
                                username: result.data.username,
                                displayName: result.data.displayName,
                                userId: result.data.userId,
                                userType: result.data.userType,
                                status: result.data.status,
                                ability: ability
                            });
                            refreshRegisteredMap();
                            reloadThemeSetting();
                            loadUserEnrolment({ status: "all" });
                            break;
                    }
                } else {
                    validateLocalJWTFail({
                        error: ERROR.USER_MISSING_ERROR,
                    });
                    signOut();
                }

            } else {
                validateLocalJWTFail({
                    error: ERROR.INVALID_AUTHENTICATION_INFORMATION_ERROR,
                });
                signOut();
            }
        }, failHandler: validateLocalJWTFail
    },
    "UPDATE_CURRENT_ROLE": {
        handler: async (currentState) => {
            let responseResult = await updateRole(currentState.currentRole.roleId);
            if (responseResult.success) {
                let ability = ANNOYMOUS_ABILITY;
                let currentRole = {};
                if (responseResult.result) {
                    let result = responseResult.result;
                    if (result.abilityRules) {
                        ability = new Ability(result.abilityRules);
                    }
                    if (result.currentRole) {
                        currentRole = result.currentRole;
                    }
                }

                updateCurrentRoleSuccess({
                    "success": true,
                    "ability": ability,
                    "currentRole": currentRole,
                    message: responseResult.message
                });
                reloadThemeSetting();
            } else {
                updateCurrentRoleFail({
                    message: "resend email request failed",
                    error: responseResult.error
                });
            }
        }, failHandler: updateCurrentRoleFail
    },
    "VERIFICATION_EMAIL": {
        handler: async (currentState, action) => {
            var result = await resendVerificationEmail();
            if (result) {
                if (result.success) {
                    requestVerificationEmailSuccess({
                        "success": true,
                        message: result.message,
                        action: action
                    });
                } else {
                    requestVerificationEmailFail({
                        message: "resend email request failed",
                        error: result.error
                    });
                }
            }
        }, failHandler: requestVerificationEmailFail
    },
    "UPDATE_FORGET_PASSWORD": {
        handler: async (currentState, action) => {
            let result = await requestUpdateForgetPassword(currentState.email, currentState.renewPassword, currentState.token);
            if (result.success) {
                updateForgetPasswordSuccess({
                    action: action
                });
            } else {
                updateForgetPasswordFail({
                    message: "resend email request failed",
                    error: result.error
                });
            }
        }, failHandler: updateForgetPasswordFail
    },
    "UPDATE_PASSWORD": {
        handler: async (currentState, action) => {
            let result = await requestUpdatePassword(currentState.password, currentState.renewPassword);
            if (result.success) {
                updatePasswordSuccess({
                    message: result.data.message,
                    action: action
                });
            } else {
                updatePasswordFail({
                    message: result.data.message,
                    error: result.error
                });
            }
        }, failHandler: updatePasswordFail
    }
}

authenticationState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});