import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
    Grid, Button, Typography
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useOptionsState } from "./../../../redux/Options";
import { useCentreManagementState, updateCentre } from "./../../../redux/CentreManagement";
import CentrePaymentFieldsSection from "./../../form/centreComponent/CentrePaymentFieldsSection";
import ViewableByPlatformAdminOnly from "./../../acl/ViewableByPlatformAdminOnly";
import { SpaceHolder } from "./../../common/Others";
import {
    isRequestSuccessWithStack,
    isRequestFailWithStack
} from "./../../../util/RequestUtil";
import { TabTitleContainer } from "./../../common/Containers";
import {
    createUpdateCentreBusinessInfoFormSchema,
    INITIAL_CENTRE_FORM
} from "./../../form/model/CentreFormModel"

const FullWidthForm = styled("form")(({ theme }) => ({
    width: "100%"
}));

const SaveButton = styled(Button)(({ theme }) => ({
    width: "180px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5)
}));

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3)
}));

export default function CentreBusinessSettingsTab() {
    const { t } = useTranslation("centre");
    const { enqueueSnackbar } = useSnackbar();
    const VALID_VALUES = {
        DISTRICT: useOptionsState().district.validValues
    };
    const CENTRE = useCentreManagementState().currentCentre;
    const CENTRE_REQUEST = useCentreManagementState().request;

    const CENTRE_BUSINESS_FIELD_FORM_METHOD = useForm({
        resolver: yupResolver(createUpdateCentreBusinessInfoFormSchema(VALID_VALUES.DISTRICT)),
        defaultValues: INITIAL_CENTRE_FORM
    });

    const saveBusinessFields = (data) => {
        updateCentre({ centre: data });
    }

    React.useEffect(() => {
        if (CENTRE_REQUEST.lastAction === "UPDATE_CENTRE") {
            if (isRequestSuccessWithStack(CENTRE_REQUEST)) {
                enqueueSnackbar(t(CENTRE_REQUEST.message), {
                    "variant": "success"
                });
            }

            if (isRequestFailWithStack(CENTRE_REQUEST)) {
                enqueueSnackbar(t(CENTRE_REQUEST.message), {
                    "variant": "error"
                })
            }
        }
    }, [CENTRE_REQUEST])

    React.useEffect(() => {
        CENTRE_BUSINESS_FIELD_FORM_METHOD.reset(CENTRE);
    }, [])


    React.useEffect(() => {
        CENTRE_BUSINESS_FIELD_FORM_METHOD.reset(CENTRE);
    }, [CENTRE])

    return (
        <React.Fragment>
            <ViewableByPlatformAdminOnly>
                <TabTitleContainer>
                    <Grid container>
                        <SpaceHolder container item xs={0.5} />
                        <Grid container item xs={11}>
                            <Row alignItems="center" justifyContent="space-between" container item xs={12}>
                                <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">
                                    {t("centre:section_title-business_info")}
                                </Typography>
                            </Row>
                            <Grid alignItems="center" justifyContent="space-between" container item xs={12}>
                                <FormProvider {...CENTRE_BUSINESS_FIELD_FORM_METHOD} >
                                    <FullWidthForm onSubmit={CENTRE_BUSINESS_FIELD_FORM_METHOD.handleSubmit(saveBusinessFields)} noValidate>
                                        <Grid container item xs={12}>
                                            <CentrePaymentFieldsSection />
                                        </Grid>
                                        <Grid justifyContent="center" alignItems="center" container item xs={12}>
                                            <SaveButton type="submit" variant="contained" size="large" >{t("general:button-save")}</SaveButton>
                                        </Grid>
                                    </FullWidthForm>
                                </FormProvider>
                            </Grid>
                        </Grid>
                        <SpaceHolder container item xs={0.5} />
                    </Grid>
                </TabTitleContainer>
            </ViewableByPlatformAdminOnly>
        </React.Fragment>
    );
}
