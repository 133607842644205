import React from "react";
import {
    Typography, Divider,
    Grid,
    IconButton, Paper, Stack,
    Tabs, Tab, ButtonBase,
    Breadcrumbs, Link
} from "@mui/material";
import { useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TabContainer } from "./../../../../component/common/Containers";
import { SpaceHolder } from "./../../../../component/common/Others";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { getCentreInfo, useCentreManagementState } from "./../../../../redux/CentreManagement";
import { deleteCentreFacility, useFacilityManagementState } from "./../../../../redux/FacilityManagement";
import { useAuthenticationState } from "./../../../../redux/authentication";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../../../component/common/ConfirmActionDialog";
import CentreFacilityDialog from "./../../centreDetail/dialog/CentreFacilityDialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import CanUpdateCentre from "./../../../acl/CanUpdateCentre";

const LogoContainer = styled(Grid)(({ theme }) => ({
    "paddingBottom": theme.spacing(1),
    "paddingRight": theme.spacing(2)
}));

const ButtonGrid = styled(Grid)(({ theme }) => ({

}));

const LogoThumbnail = styled("img")(({ theme }) => ({
    objectFit: "cover",
    objectPosition: "50% 50%",
    display: "block",
    borderRadius: "10px",
    width: "100px",
    height: "100px"
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


function Buttons({ triggerFacilityMenu }) {
    const FACILITY = useFacilityManagementState().currentFacility || {};
    const CENTRE_ID = FACILITY.centreId;
    return (
        <CanUpdateCentre centreId={CENTRE_ID}>
            <IconButton
                sx={{
                    marginTop: 2,
                    backgroundColor: "#DDDDDD",
                }}
                color="primary"
                aria-label="upload picture" component="label"
                onClick={triggerFacilityMenu}
            >
                <MoreHorizIcon />
            </IconButton>
        </CanUpdateCentre>
    );
}

export default function FacilityPageHeader({
    TAB_ELEMENTS, value, setValue, setLogoDialogOpen }) {
    const { t } = useTranslation("general");
    let { facilityId } = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [logoAnchorEl, setLogoAnchorEl] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);

    const FACILITY = useFacilityManagementState().currentFacility || {};
    const CENTRE = useCentreManagementState().currentCentre;
    const CENTRE_ID = FACILITY.centreId;

    const LOCATION = useLocation();
    const ABILITY = useAuthenticationState().ability;

    const triggerFacilityMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }


    const triggerPortraitMenu = (event) => {
        event.preventDefault();
        setLogoAnchorEl(event.currentTarget);
    }

    const triggerUploadPortrait = (event) => {
        event.preventDefault();
        setLogoAnchorEl(null);
        setLogoDialogOpen(true)
    }

    const triggerEditDialog = (event) => {
        event.preventDefault();
        setEditDialogOpen(true);
    }

    const openDeleteDialog = (event) => {
        event.preventDefault();
        setDeleteDialogOpen(true);
    }

    const deleteFacility = (event) => {
        event.preventDefault();
        deleteCentreFacility(FACILITY);
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate({
            pathname: ("/facility/" + facilityId),
            search: createSearchParams({
                tab: newValue
            }).toString(),
        }, { state: LOCATION.state });
    };

    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();

    const navigateToCentre = (event) => {
        navigate("/centre/" + CENTRE_ID + "?tab=facility", { state: { hideEnrolmentSidebar: true } });
    }

    React.useEffect(() => {
        if (CENTRE_ID) {
            getCentreInfo({ centreId: CENTRE_ID });
        }
    }, [CENTRE_ID])

    return (
        <React.Fragment>
            <Paper sx={{ width: "100%", borderRadius: "10px" }}>
                <Grid container item sm={12} xs={12}>
                    <SpaceHolder container item sm={0.5} xs={0.5} />
                    {smUp ? (
                        <React.Fragment>
                            <Grid sx={{ marginTop: 3 }} container item sm={11}>
                                <LogoContainer container alignItems="flex-start" item xs={11} sm={10}
                                    justifyContent="flex-start"  >
                                    <Stack direction="row" justifyContent="flex-start"
                                        alignItems="center" spacing={2}>
                                        <ButtonBase disabled={!ABILITY.can("update", "centre")} sx={{ marginTop: 1 }} onClick={triggerPortraitMenu}>
                                            {!FACILITY.logo || FACILITY._id != facilityId ? (
                                                <Grid sx={{ width: "100px", height: "100px", border: 1, borderColor: "divider" }} container alignItems="center" justifyContent="center">
                                                    <SchoolRoundedIcon color="disabled" fontSize="large" />
                                                </Grid>) :
                                                (<LogoThumbnail alt="facility_logo" src={FACILITY.logo} />
                                                )}
                                        </ButtonBase>
                                        <Stack spacing={1}>
                                            <Stack alignItems="center" direction="row">
                                                <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>{FACILITY.name}</Typography>
                                                <CanUpdateCentre centreId={CENTRE_ID}>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: 1,
                                                            backgroundColor: "#DDDDDD",
                                                        }}
                                                        color="primary"
                                                        aria-label="upload picture" component="label"
                                                        onClick={triggerEditDialog}
                                                    >
                                                        <EditIcon sx={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </CanUpdateCentre>
                                            </Stack>
                                            <Breadcrumbs sx={{ display: { xs: "none", sm: "block" },cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="small" />}>
                                                <Link underline="hover" onClick={navigateToCentre} color="secondary">
                                                    <Typography color="secondary">{CENTRE.displayName}</Typography>
                                                </Link>
                                                <Typography color="secondary">{t("facility:page_title-active")}</Typography>
                                            </Breadcrumbs>
                                        </Stack>
                                    </Stack>
                                </LogoContainer>
                                <ButtonGrid container alignItems="flex-start" justifyContent="right"
                                    item xs={1} sm={2}>
                                    <Buttons triggerFacilityMenu={triggerFacilityMenu} />
                                </ButtonGrid>
                                <Grid container item sm={12}>
                                    <TabContainer>
                                        <Tabs value={value} onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            allowScrollButtonsMobile
                                            aria-label="facility_settings_tabs">
                                            {TAB_ELEMENTS.map(
                                                (element, index) => (
                                                    <Tab
                                                        value={element.tab_index}
                                                        sx={{
                                                            textTransform: "none"
                                                        }}
                                                        key={element.tab_label}
                                                        label={t(element.tab_label)} {...a11yProps(index)} />
                                                )
                                            )}
                                        </Tabs>
                                    </TabContainer>
                                </Grid>
                            </Grid>
                            <SpaceHolder container item sm={0.5} />
                        </React.Fragment>
                    ) : null}
                    {smDown ? (
                        <React.Fragment>
                            <Grid sx={{ marginTop: 3 }} container item xs={11.5}>
                                <LogoContainer container item xs={12} md={2}
                                    alignItems="center" justifyContent="space-between"  >
                                    <Grid container item xs={6}>
                                        <ButtonBase disabled={!ABILITY.can("update", "centre")} onClick={triggerPortraitMenu}>
                                            {!FACILITY.logo || FACILITY._id != facilityId ? (
                                                <Grid sx={{ width: "100px", height: "100px", border: 1, borderColor: "divider" }} container alignItems="center" justifyContent="center">
                                                    <SchoolRoundedIcon color="disabled" fontSize="large" />
                                                </Grid>) :
                                                (<LogoThumbnail alt="facility_logo" src={FACILITY.logo} />
                                                )}
                                        </ButtonBase>
                                    </Grid>
                                    <ButtonGrid container alignItems="center" justifyContent="right" item xs={6} md={2}>
                                        <Buttons triggerFacilityMenu={triggerFacilityMenu} />
                                    </ButtonGrid>
                                </LogoContainer>
                                <Grid sx={{}} container alignItems="center" justifyContent="center" item xs={12} md={8}>
                                    <Grid container item xs={12} md={12}>
                                        <Stack alignItems="center" direction="row">
                                            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>{FACILITY.name}</Typography>
                                            <CanUpdateCentre centreId={CENTRE_ID}>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: 1,
                                                        marginRight: 1,
                                                        backgroundColor: "#DDDDDD",
                                                    }}
                                                    color="primary"
                                                    aria-label="upload picture" component="label"
                                                    onClick={triggerEditDialog}
                                                >
                                                    <EditIcon sx={{ fontSize: 14 }} />
                                                </IconButton>
                                            </CanUpdateCentre>
                                        </Stack>
                                    </Grid>
                                    <Grid container item xs={12} md={12}>
                                        <Breadcrumbs sx={{ display: { xs: "block", sm: "none" },cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="small" />}>
                                            <Link underline="hover" onClick={navigateToCentre} color="secondary">
                                                <Typography variant="subtitle2" component="div" color="secondary">{CENTRE.displayName}</Typography>
                                            </Link>
                                            <Typography variant="subtitle2" component="div" color="secondary">{t("facility:page_title-active")}</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <SpaceHolder container item xs={0} />
                            <Grid container item xs={12} md={12}>
                                <TabContainer>
                                    <Divider sx={{ marginTop: 2.5 }} />
                                    <Tabs
                                        value={value} onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="facility_page_tabs">
                                        {TAB_ELEMENTS.map((element, index) => (
                                            <Tab value={element.tab_index}
                                                sx={{
                                                    textTransform: "none",
                                                    minWidth: 70
                                                }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                        ))
                                        }
                                    </Tabs>
                                </TabContainer>
                            </Grid>
                        </React.Fragment>) : null}
                </Grid>
            </Paper>
            <CanUpdateCentre centreId={CENTRE_ID}>
                <ContextualMenu id={"portrait-menu"} anchorEl={logoAnchorEl} setAnchorEl={setLogoAnchorEl} >
                    <ContextualMenuItem title={t("general:button-change")} onClick={triggerUploadPortrait}>
                        <EditIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ContextualMenu>
            </CanUpdateCentre>
            <CanUpdateCentre centreId={CENTRE_ID}>
                <ContextualMenu id={"facility-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
                    <ContextualMenuItem
                        onClick={openDeleteDialog}
                        title={t("general:button-remove_entity", { entity: t("attribute:facility").toLowerCase() })}>
                        <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ContextualMenu>
            </CanUpdateCentre>
            <CentreFacilityDialog title={t("general:dialog_title-edit_entity_info", { entity: t("attribute:facility") })} isDialogOpen={editDialogOpen} setIsDialogOpen={setEditDialogOpen} currentFacility={FACILITY} />
            <ConfirmActionDialog
                title={t("general:dialog_title-delete_entity", { entity: t("attribute:facility") })}
                actionName={t("general:button-delete")}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                confirmOnClick={deleteFacility}
            />
        </React.Fragment>
    )
}