import React from "react";
import {
    Grid
} from "@mui/material";
import debounce from 'lodash.debounce';
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LoadingPage from "./LoadingPage";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import StudentAboutDetailPage from "./student/StudentAboutDetailPage";
import StudentClassPage from "./student/StudentClassPage";
import StudentSchedulePage from "./student/StudentSchedulePage";
import StudentHomeworkPage from "./student/StudentHomeworkPage";
import StudentBillPage from "./student/StudentBillPage";
import StudentPageHeader from "./student/header/StudentPageHeader";

import { getStudentProfileInfo, useProfileState, UPDATE_ACTION as PROFILE_ACTION } from "./../../redux/ProfileManagement";
import { useEnrolmentState } from "./../../redux/Enrolment";
import { pushRedirect } from "./../../redux/Redirect";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));




export default function StudentDetail() {
    const CURRENT_PROFILE = useProfileState().studentProfileInfo || {};
    const IS_ACTIVE = CURRENT_PROFILE.status == "active";

    const ALL_TABS = [
        { "tab_element": (<StudentAboutDetailPage />), "tab_label": "general:tab_name-about", tab_index: "about", permission: true },
        { "tab_element": (<StudentClassPage />), "tab_label": "general:tab_name-classes", tab_index: "class", permission: true },
        { "tab_element": (<StudentSchedulePage />), "tab_label": "general:tab_name-schedule", tab_index: "schedule", permission: IS_ACTIVE },
        { "tab_element": (<StudentHomeworkPage />), "tab_label": "student:tab_name-schoolwork", tab_index: "schoolwork", permission: IS_ACTIVE },
        { "tab_element": (<StudentBillPage />), "tab_label": "student:tab_name-bills", tab_index: "bill", permission: IS_ACTIVE },
    ]

    const TAB_ELEMENTS = ALL_TABS.filter(element => element.permission == true);

    let { enrolmentId } = useParams();
    let [searchParams] = useSearchParams();
    const SEARCH_PARAM_TAB = searchParams.get("tab") || "about";
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const PROFILE_REQUEST = useProfileState().request;
    const ENROLMENT_REQUEST = useEnrolmentState().request;

    const debouncedGetStudentProfileInfo = React.useMemo(() => debounce(getStudentProfileInfo, 50), []);

    const STATUS_CHANGE_REQUEST = {
        "ACCEPT_STUDENT_ENROLMENT": true,
        "REJECT_STUDENT_ENROLMENT": true,
    }

    React.useEffect(() => {
        setValue(SEARCH_PARAM_TAB)
    }, [SEARCH_PARAM_TAB]);

    React.useEffect((
    ) => {
        if (enrolmentId) {
            debouncedGetStudentProfileInfo({ profileId: enrolmentId });
        }
    }, []);

    React.useEffect(() => {
        if (enrolmentId) {
            debouncedGetStudentProfileInfo({ profileId: enrolmentId });
        }
    }, [enrolmentId]);

    React.useEffect(() => {
        let IS_REQUESTED_PROFILE_INFO_NULL_OR_INVACTIVE =
            PROFILE_REQUEST.success == true &&
            PROFILE_REQUEST.lastAction == PROFILE_ACTION.GET_STUDENT_PROFILE_INFO
            && PROFILE_REQUEST.status == "REQUEST_OBJECT_NOT_FOUND";
        if (IS_REQUESTED_PROFILE_INFO_NULL_OR_INVACTIVE) {
            pushRedirect({ path: "/" });
        }
    }, [PROFILE_REQUEST])

    React.useEffect(() => {
        let IS_STATUS_CHANGE_REQUEST = STATUS_CHANGE_REQUEST[ENROLMENT_REQUEST.lastAction];
        if (IS_STATUS_CHANGE_REQUEST) {
            if (ENROLMENT_REQUEST.success && ENROLMENT_REQUEST.status == "REQUEST_OBJECT_UPDATED") {
                debouncedGetStudentProfileInfo({ profileId: enrolmentId });
            }
        }
    }, [ENROLMENT_REQUEST])

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <Row container item xs={12}>
                            <StudentPageHeader TAB_ELEMENTS={TAB_ELEMENTS}
                                value={value} setValue={setValue} />
                        </Row>
                        <Grid sx={{ marginTop: 2 }} container item xs={12} md={12}>
                            {TAB_ELEMENTS.map(((element, index) => (
                                <TabPanel key={`element_${index}`} value={value} index={element.tab_index}>
                                    {element.tab_element}
                                </TabPanel>
                            )))
                            }
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
        </React.Suspense>
    );
}
