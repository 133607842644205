import * as YUP from "yup";


function createPasswordFormSchema() {
  return YUP.object().shape({
    currentPassword:YUP.string().required("general:validation-mandatory"),
    password: YUP.string().min(8,"general:validation-password-length").required("general:validation-mandatory"),
    confirmPassword: YUP.string().required("general:validation-mandatory")
      .oneOf([YUP.ref('password'), null], "general:validation-password-not_match")
  });
}

function createForgetPasswordFormSchema() {
  return YUP.object().shape({
    password: YUP.string().min(8,"general:validation-password-length").required("general:validation-mandatory"),
    confirmPassword: YUP.string().required("general:validation-mandatory")
      .oneOf([YUP.ref('password'), null], "general:validation-password-not_match")
  });
}



export {createPasswordFormSchema,createForgetPasswordFormSchema};