import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, TextField, Grid, FormControl, FormHelperText, FormControlLabel, FormLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from "react-hook-form";
import DropdownItems from "./commonComponent/DropdownItems";
import { USER_DATA_FIELD } from "./../form/model/UserFormModel";
import { useOptionsState } from "./../../redux/Options";
import { useProfileState } from "./../../redux/ProfileManagement";

const AccountInfoContainer = styled(Grid)(({ theme }) => ({
}));

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    "fontWeight": "bold",
    "color": "#AAAAAA",
    "marginTop": theme.spacing(1)
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
}));

export default function AccountInformationEditForm() {
    const { t } = useTranslation(["account", "attribute", "general"]);
    const { control, setValue,getValues , formState: { errors } } = useFormContext();
    const OPTIONS = { SYSTEM_LANGUAGE: useOptionsState().systemLanguage.options };
    const CURRENT_PROFILE_USERNAME = useProfileState().username;
    return (
        <AccountInfoContainer container justifyContent="center">
            <Grid item xs={12} md={10} xl={8}>
                <Row container>
                    <StyledTextField
                        disabled
                        value={CURRENT_PROFILE_USERNAME || ""}
                        variant="outlined" fullWidth
                        data-cy="field_account_email_address"
                        label={t("attribute:user-email_address")} />
                </Row>
                <Row container>
                    <Controller
                        name={USER_DATA_FIELD.displayName}
                        control={control}
                        render={
                            ({ field }) => <StyledTextField
                                variant="outlined" fullWidth
                                data-cy="field_account_displayname"
                                label={t("attribute:user-display_name")}
                                helperText={errors[USER_DATA_FIELD.displayName] ? t(errors[USER_DATA_FIELD.displayName].message) : null}
                                error={errors[USER_DATA_FIELD.displayName] != null}
                                {...field}
                            />
                        }
                    />
                </Row>
                <Row justifyContent="center" container>
                    <Controller
                        name={USER_DATA_FIELD.phone}
                        control={control}
                        render={
                            ({ field }) => <StyledTextField
                                variant="outlined" fullWidth
                                label={t("attribute:user-mobile_number")}
                                helperText={errors[USER_DATA_FIELD.phone] ? t(errors[USER_DATA_FIELD.phone].message) : null}
                                error={errors[USER_DATA_FIELD.phone] != null}
                                {...field}
                            />
                        }
                    />
                </Row>
                <Row justifyContent="center" container>
                    <Controller
                        name={USER_DATA_FIELD.notificationLanguage}
                        control={control}
                        render={
                            ({ field }) => <StyledFormControl fullWidth variant="outlined"
                                data-cy="notificationLanguageSelect"
                                error={errors[USER_DATA_FIELD.notificationLanguage] != null}>
                                <DropdownItems field={field}
                                    options={OPTIONS.SYSTEM_LANGUAGE}
                                    labelId="user_form_notificationLanguagelabel"
                                    labelText={t("attribute:user-notification_language")} />
                                {errors[USER_DATA_FIELD.notificationLanguage] != null &&
                                    <FormHelperText>{errors[USER_DATA_FIELD.notificationLanguage].message}</FormHelperText>
                                }
                            </StyledFormControl>
                        }
                    />
                </Row>
                <Row justifyContent="center" container>
                    <Grid container item>
                        <Grid container item>
                            <StyledFormLabel>{t("account:section_title-notification_methods")}</StyledFormLabel>
                        </Grid>
                        <Grid container item>
                            <Controller
                                name={USER_DATA_FIELD.notificationMethod + ".email"}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <FormControlLabel
                                            control={<Checkbox value="email" checked={field.value != null && field.value != false}
                                                onChange={(event) => {
                                                    setValue("notificationMethod", Object.assign(getValues("notificationMethod"),{ email: true }));
                                                    field.onChange(event);
                                                }}
                                                name={USER_DATA_FIELD.notificationMethod + ".email"}
                                            />}
                                            label={t("account:checkbox-notification_methods-email")}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid container item>
                        <Controller
                                name={USER_DATA_FIELD.notificationMethod + ".app"}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <FormControlLabel
                                            control={<Checkbox value="app" checked={field.value != null && field.value != false}
                                                onChange={(event) => {
                                                    setValue("notificationMethod", Object.assign(getValues("notificationMethod"),{ app: true }));
                                                    field.onChange(event);
                                                }}
                                                name={USER_DATA_FIELD.notificationMethod + ".app"}
                                            />}
                                            label={t("account:checkbox-notification_methods-app")}
                                        />
                                    )
                                }}
                            />
                            </Grid>
                    </Grid>
                </Row>
                <Row container justifyContent="center" >
                    <SubmitButton data-cy="btn_account_info_submit" type="submit" fullWidth variant="contained">{t("general:button-save")}</SubmitButton>
                </Row>
            </Grid>
        </AccountInfoContainer>)
}