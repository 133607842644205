import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface Centres {
    name: String,
    calories: String,
    fat: String,
    carbs: String,
    protein: String
}



export async function getAdministratorEnrolmentRequest(): Promise<UpdateResultResponse> {
    const url = '/enrolment/platform_admin';
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
}

export async function getCentreAdminEnrolmentRequest(centreId: string): Promise<UpdateResultResponse> {
    const url = '/enrolment/centre_admin/' + centreId;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
}

export async function searchCentre(searchCriteria: object): Promise<UpdateResultResponse> {
    const url = '/enrolment/searchCentre';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, {
            params: searchCriteria
        });
        return data;
    } catch (err) {
        throw err
    }
}

export async function submitEnrolment(searchCriteria: object): Promise<UpdateResultResponse> {
    const url = '/enrolment/enrolment';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, {
            params: searchCriteria
        });
        return data;
    } catch (err) {
        throw err
    }
}

export async function sendAdministratorInvitationEmail(email: String): Promise<UpdateResultResponse> {
    const url = "/enrolment/invite_administrator";
    try {
        let body = { "email": email };
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function removeAdministratorRequest(id: String): Promise<UpdateResultResponse> {
    const url = "/enrolment/remove_administrator";
    try {
        let body = { "id": id };
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function removeCentreAdminRequest(params: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/remove_centre_admin";
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, params);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function sendCentreAdminInvitationEmail(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/invite_centre_admin";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}


export async function addCentreEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/add_new";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function getUserEnrolmentRequest(status: string): Promise<UpdateResultResponse> {
    const url = '/enrolment/user/' + status;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
}

export async function getCentreEnrolmentRequest(centreId: string, enrolmentType: string, searchCriteria: object): Promise<UpdateResultResponse> {
    const url = `/enrolment/centre/${enrolmentType}/${centreId}`;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url, {
            params: searchCriteria
        });
        return data;
    } catch (err) {
        throw err
    }
}

export async function acceptTutorEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/accept/tutor";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function rejectTutorEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/reject/tutor";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function removeTutorEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/remove/tutor";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function acceptStudentEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/accept/student";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function rejectStudentEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/reject/student";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function removeStudentEnrolmentRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/remove/student";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}
export async function updateStudentAdditionInfoRequest(enrolment: object): Promise<UpdateResultResponse> {
    const url = "/enrolment/centre/update/student";
    try {
        let body = enrolment;
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, body);
        return data;
    } catch (err) {
        throw err;
    }
}
