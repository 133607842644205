import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface Centres {
	name: String,
	calories: String,
	fat: String,
	carbs: String,
	protein: String
}

export interface CentrePost {
	_id: string,
	centreId: string,
	content: string,
	title: string
}

export interface CentreFacility {
	_id: string,
	centreId: string,
	name: string,
	description: string
	function: string
}


export async function loadCentres(searchCriteria: object): Promise<UpdateResultResponse> {
	const url = '/centre/centres';
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {
			params: searchCriteria
		});
		return data;
	} catch (err) {
		throw err
	}
}

export async function addCentreRequest(centre: object): Promise<UpdateResultResponse> {
	const url = "/centre/new_centre";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, centre);
		return data;
	} catch (err) {
		throw err
	}
}

export async function updateCentreRequest(centre: object): Promise<UpdateResultResponse> {
	const url = "/centre/save_centre";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, centre);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getCentreInfoRequest(centreId: string): Promise<UpdateResultResponse> {
	const url = "/centre/centre/" + centreId;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}

export async function addCentreCustomFieldRequest(field: object): Promise<UpdateResultResponse> {
	const url = "/centre/add_centre_data_fields/";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, field);
		return data;
	} catch (err) {
		throw err
	}
}

export async function updateCentreCustomFieldRequest(field: object): Promise<UpdateResultResponse> {
	const url = "/centre/save_centre_data_fields/";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, field);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getCentreCustomFieldsRequest(centreId: string): Promise<UpdateResultResponse> {
	const url = "/centre/centre_data_fields";
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: { centreId: centreId } });
		return data;
	} catch (err) {
		throw err
	}
}

export async function removeCentreCustomFieldRequest(fieldId: string): Promise<UpdateResultResponse> {
	const url = "/centre/remove_centre_data_field";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, { fieldId: fieldId });
		return data;
	} catch (err) {
		throw err
	}
}

export async function uploadCentreLogoRequest(fileFormData: FormData): Promise<UpdateResultResponse> {
	const url = "/centre/save_centre_logo";
	let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, fileFormData, {
			headers: headers
		});
		return data;
	} catch (err) {
		throw err
	}
}

export async function uploadCentrePhotosRequest(filesFormData: FormData): Promise<UpdateResultResponse> {
	const url = "/centre/save_centre_photos";
	let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, filesFormData, {
			headers: headers
		});
		return data;
	} catch (err) {
		throw err
	}
}

export async function deleteCentrePhotoRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/delete_photo";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function addCentrePostRequest(centrePost: CentrePost): Promise<UpdateResultResponse> {
	const url = "/centre/post/create";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, centrePost);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getCentrePostRequest(centreId: string, centrePostSearchCriteria: object): Promise<UpdateResultResponse> {
	const url = "/centre/post/" + centreId;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: centrePostSearchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}


export async function updateCentrePostRequest(centrePost: CentrePost): Promise<UpdateResultResponse> {
	const url = "/centre/post/save";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, centrePost);
		return data;
	} catch (err) {
		throw err
	}
}


export async function deleteCentrePostRequest(post: object): Promise<UpdateResultResponse> {
	const url = "/centre/post/remove";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, post);
		return data;
	} catch (err) {
		throw err
	}
}

export async function addCentreFacilityRequest(centreFacility: CentreFacility): Promise<UpdateResultResponse> {
	const url = "/centre/facility/create";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, centreFacility);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getCentreFacilityRequest(centreId: string, centreFacilitySearchCriteria: object): Promise<UpdateResultResponse> {
	const url = "/centre/facility/" + centreId;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: centreFacilitySearchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}


export async function updateCentreFacilityRequest(centreFacility: CentreFacility): Promise<UpdateResultResponse> {
	const url = "/centre/facility/save";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, centreFacility);
		return data;
	} catch (err) {
		throw err
	}
}


export async function deleteCentreFacilityRequest(facility: object): Promise<UpdateResultResponse> {
	const url = "/centre/facility/remove";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, facility);
		return data;
	} catch (err) {
		throw err
	}
}


export async function getFacilityInfoRequest(facilityId: string): Promise<UpdateResultResponse> {
	const url = "/centre/facility/info/" + facilityId;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}


export async function uploadFacilityLogoRequest(fileFormData: FormData): Promise<UpdateResultResponse> {
	const url = "/centre/facility/save_logo";
	let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, fileFormData, {
			headers: headers
		});
		return data;
	} catch (err) {
		throw err
	}
}

export async function uploadFacilityPhotosRequest(filesFormData: FormData): Promise<UpdateResultResponse> {
	const url = "/centre/facility/save_photos";
	let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, filesFormData, {
			headers: headers
		});
		return data;
	} catch (err) {
		throw err
	}
}

export async function deleteFacilityPhotoRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/facility/delete_photo";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getClassesRequest(centreId: string, centreClassSearchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/${centreId}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: centreClassSearchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}
export async function addClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/add";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}
export async function updateClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/update";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}
export async function deleteClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/delete";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}

export async function getClassTutorRequest(classId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/tutor/${classId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function getClassStudentRequest(classId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/student/${classId}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function getClassInfoRequest(classId: string): Promise<UpdateResultResponse> {
	const url = `/centre/class/info/${classId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}

}
export async function uploadClassLogoRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/logo/add";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}
export async function uploadClassPhotosRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/photo/add";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}
export async function deleteClassPhotoRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/photo/delete";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}


export async function publishClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/publish";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}

export async function rejectClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/reject";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}

export async function removeClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/remove";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}


export async function addTutorToClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/tutor/add";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function deleteTutorFromClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/tutor/delete";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}

export async function getClassFacilityRequest(classId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/facility/${classId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function addFacilityToClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/facility/add";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}


export async function deleteFacilityFromClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/facility/delete";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}

export async function applyClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/apply";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}

export async function acceptStudentClassEnrolmentRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/enrolment/accept";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}


export async function removeStudentClassEnrolmentRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/enrolment/remove";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}

}


export async function addStudentToClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/student/add";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}


export async function removeStudentFromClassRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/student/remove";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getStudentForEnrolmentRequest(centreId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/enrolment/student/${centreId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function getStudentActiveClassRequest(enrolmentId: string): Promise<UpdateResultResponse> {
	const url = `/centre/class/active/student_class/${enrolmentId}`

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getTutorActiveClassRequest(enrolmentId: string): Promise<UpdateResultResponse> {
	const url = `/centre/class/active/tutor_class/${enrolmentId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}

export async function uploadClassNotesRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/centre/class/notes/save";

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getClassNotesRequest(classId: string): Promise<UpdateResultResponse> {
	const url = `/centre/class/notes/list/${classId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}

export async function deleteClassNoteRequest(classNoteToBeDeleted: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/notes/delete`;

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, classNoteToBeDeleted);
		return data;
	} catch (err) {
		throw err
	}
}

export async function getCentreNotificationRequest(centreId: string): Promise<UpdateResultResponse> {
	const url = `/centre/centre_notifications/${centreId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}

export async function updateCentreNotificationRequest(notificationScheme: object): Promise<UpdateResultResponse> {
	const url = `/centre/save_centre_notifications`;

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, notificationScheme);
		return data;
	} catch (err) {
		throw err
	}
}

export async function addCentreNotificationRequest(centreId: string): Promise<UpdateResultResponse> {
	const url = `/centre/new_centre_notifications/${centreId}`;

	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url);
		return data;
	} catch (err) {
		throw err
	}
}
