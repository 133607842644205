import {
    createReduxModule
} from "hooks-for-redux"
import {
    getCentreSubscriptionRequest,
    addCentreSubscriptionRequest,
    updateCentreSubscriptionRequest,
    cancelCentreSubscriptionRequest
} from "./../api/subscriptionAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        subscriptionPlan: null,
        paymentMethod: null,
        invoices: [],
        subscribed: null,
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "GET_CENTRE_SUBSCRIPTION": "GET_CENTRE_SUBSCRIPTION",
    "ADD_CENTRE_SUBSCRIPTION": "ADD_CENTRE_SUBSCRIPTION",
    // "UPDATE_CENTRE_SUBSCRIPTION": "UPDATE_CENTRE_SUBSCRIPTION",
    "CANCEL_CENTRE_SUBSCRIPTION": "CANCEL_CENTRE_SUBSCRIPTION"
};

export const [useSubscriptionManagementState, {
    setUpdating,
    getCentreSubscription, getCentreSubscriptionSuccess, getCentreSubscriptionFail,
    // updateCentreSubscription, updateCentreSubscriptionSuccess, updateCentreSubscriptionFail,
    addCentreSubscription, addCentreSubscriptionSuccess, addCentreSubscriptionFail,
    cancelCentreSubscription, cancelCentreSubscriptionSuccess, cancelCentreSubscriptionFail,
},
    subscriptionManagementState
] = createReduxModule(
    "subscriptionManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getCentreSubscription: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_SUBSCRIPTION),
        centreId: payload.centreId
    }),
    getCentreSubscriptionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        subscriptionPlan: payload.data.subscriptionPlan,
        paymentMethod: payload.data.paymentMethod,
        invoices: payload.data.invoices,
        subscribed: payload.data.subscribed
    }),
    getCentreSubscriptionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentreSubscription: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_CENTRE_SUBSCRIPTION),
        sessionId: payload.sessionId,
    }),
    addCentreSubscriptionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        subscriptionPlan: payload.data.subscriptionPlan,
        paymentMethod: payload.data.paymentMethod,
        invoices: payload.data.invoices,
        subscribed: payload.data.subscribed
    }),
    addCentreSubscriptionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    // updateCentreSubscription: (state, payload) => Object.assign({}, state, {
    //     updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_CENTRE_SUBSCRIPTION),
    //     sessionId: payload.sessionId,
    // }),
    // updateCentreSubscriptionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    //     centreSubscription: payload.data.centreSubscription,
    // }),
    // updateCentreSubscriptionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    cancelCentreSubscription: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.CANCEL_CENTRE_SUBSCRIPTION),
        sessionId: payload.sessionId,
    }),
    cancelCentreSubscriptionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        subscriptionPlan: payload.data.subscriptionPlan,
    }),
    cancelCentreSubscriptionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

});

const UPDATE_ACTION_HANDLER = {
    "GET_CENTRE_SUBSCRIPTION": {
        handler: async (currentState, action) => {
            const result = await getCentreSubscriptionRequest(currentState.centreId);
            if (result) {
                if (result.success) {
                    getCentreSubscriptionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    getCentreSubscriptionFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreSubscriptionFail
    },
    "ADD_CENTRE_SUBSCRIPTION": {
        handler: async (currentState) => {
            const result = await addCentreSubscriptionRequest(currentState.sessionId);
            if (result) {
                if (result.success) {
                    addCentreSubscriptionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    addCentreSubscriptionFail({
                        "message": "subscription:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentreSubscriptionFail
    },
    // "UPDATE_CENTRE_SUBSCRIPTION": {
    //     handler: async (currentState) => {
    //         let result = await updateCentreSubscriptionRequest(currentState.sessionId);
    //         if (result) {
    //             if (result.success) {
    //                 updateCentreSubscriptionSuccess({
    //                     "success": true,
    //                     "error": result.error,
    //                     "message": result.message,
    //                     "data": result.data
    //                 });
    //                 pushRedirect({
    //                     path: '/plans'
    //                 })
    //             } else {
    //                 updateCentreSubscriptionFail({
    //                     "message": "subscription:fail_to_load",
    //                     error: result.error
    //                 });
    //             }
    //         }
    //     },
    //     failHandler: updateCentreSubscriptionFail
    // },
    "CANCEL_CENTRE_SUBSCRIPTION": {
        handler: async (currentState) => {
            let result = await cancelCentreSubscriptionRequest(currentState.sessionId);
            if (result) {
                if (result.success) {
                    cancelCentreSubscriptionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    cancelCentreSubscriptionFail({
                        "message": "subscription:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: cancelCentreSubscriptionFail
    },
};

subscriptionManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});