import { styled } from '@mui/material/styles';
import { Grid, Link,Typography } from "@mui/material";

const NeedHelp = styled(Grid)(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  "marginTop": "20px",
  "paddingBottom": "20px"
}));

const SiteIcon = styled('img')(({ theme }) => ({
  width: "auto",
  "marginTop": "-50px",
  position: "relative",
  "zIndex": "4",
  height: "100px",
}));

const ContactUs = styled(Link)(({ theme }) => ({
  "marginLeft": "10px"
}));


const PageTitle = styled(Typography)(({ theme }) => ({
  "marginBottom": "10px",
  "marginTop": "10px",
  "fontWeight": "bold"
}));

const SpaceHolder = styled(Grid)(({ theme }) => ({
}));

export { NeedHelp, SiteIcon, ContactUs, PageTitle, SpaceHolder};