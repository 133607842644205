import React from "react";
import {
    Grid
} from "@mui/material";
import CentreAboutDetailPage from "./centreDetail/CentreAboutDetailPage";
import CentreFacilityPage from "./centreDetail/CentreFacilityPage";
import CentreClassesPage from "./centreDetail/CentreClassesPage";
import CentreLogoUploadDialog from "./../../component/form/centreComponent/CentreLogoUploadDialog";
import CentrePageHeader from "./centreDetail/header/CentrePageHeader";
import CentrePostPage from "./centreDetail/CentrePostPage";
import CentreStudentPage from "./centreDetail/CentreStudentPage";
import CentreTutorPage from "./centreDetail/CentreTutorPage";
import LoadingPage from "./LoadingPage";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import { getCentreInfo } from "./../../redux/CentreManagement";
import { pushRedirect } from "./../../redux/Redirect";
import { SpaceHolder } from "./../../component/common/Others";
import { styled } from "@mui/material/styles";
import { useAuthenticationState } from "./../../redux/authentication";
import { useCentreManagementState } from "./../../redux/CentreManagement";
import { useEnrolmentState, loadUserEnrolment } from "./../../redux/Enrolment";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce';

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export default function CentrePage() {

    let [searchParams] = useSearchParams();
    let { centreId } = useParams();
    const { t } = useTranslation(["centre", "general"]);
    const [logoDialogOpen, setLogoDialogOpen] = React.useState(false);
    const SEARCH_PARAM_TAB = searchParams.get("tab") || "about";
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const CURRENT_ROLE = useAuthenticationState().currentRole;
    const CENTRE_REQUEST = useCentreManagementState().request;
    const ENROLMENT_REQUEST = useEnrolmentState().request;
    const IS_PLATFORM_ADMIN = CURRENT_ROLE.roleName === "platform_admin";
    const IS_BELONGS_TO_CENTRE = CURRENT_ROLE.centreId == centreId;
    const IS_CENTRE_ADMIN = CURRENT_ROLE.roleName === "centre_admin";
    const IS_TUTOR = CURRENT_ROLE.roleName === "tutor";
    const { enqueueSnackbar } = useSnackbar();

    const TAB_ELEMENTS = [
        { "tab_element": (<CentreAboutDetailPage />), "tab_index": "about", "tab_label": "general:tab_name-about", permission: true },
        { "tab_element": (<CentrePostPage />), "tab_index": "post", "tab_label": "centre:tab_name-posts", permission: true },
        { "tab_element": (<CentreFacilityPage />), "tab_index": "facility", "tab_label": "centre:tab_name-facilities", permission: true },
        { "tab_element": (<CentreTutorPage />), "tab_index": "tutor", "tab_label": "centre:tab_name-tutors", permission: true },
        { "tab_element": (<CentreClassesPage />), "tab_index": "class", "tab_label": "general:tab_name-classes", permission: true },
        { "tab_element": (<CentreStudentPage />), "tab_index": "student", "tab_label": "general:tab_name-students", permission: IS_PLATFORM_ADMIN || (IS_BELONGS_TO_CENTRE && (IS_CENTRE_ADMIN)) }
    ]
    let deboundedGetCentreInfo = React.useMemo(() => debounce(getCentreInfo, 50), []);
    const ACTION_IN_CHECK = {
        centre: {
            GET_CENTRE_INFO: true
        },
        enrolment: {
            ADD_CENTRE_ENROLMENT: true
        }
    }

    React.useEffect(() => {
        setValue(SEARCH_PARAM_TAB);
    }, [SEARCH_PARAM_TAB]);

    React.useEffect(() => {
        deboundedGetCentreInfo({ centreId: centreId });
    }, []);

    React.useEffect(() => {
        deboundedGetCentreInfo({ centreId: centreId });
    }, [centreId]);

    React.useEffect(() => {
        if (ACTION_IN_CHECK["enrolment"][ENROLMENT_REQUEST.lastAction]) {
            if (ENROLMENT_REQUEST.status === "DISPLAY_SUCCESS_MESSAGE") {
                enqueueSnackbar(t(ENROLMENT_REQUEST.message), {
                    "variant": "success"
                });
                loadUserEnrolment({ status: "all" });
            }

            if (ENROLMENT_REQUEST.status === "DISPLAY_ERROR_MESSAGE") {
                enqueueSnackbar(t(ENROLMENT_REQUEST.message), {
                    "variant": "error"
                })
            }

        }
    }, [ENROLMENT_REQUEST]);

    React.useEffect(() => {
        if (ACTION_IN_CHECK["centre"][CENTRE_REQUEST.lastAction]) {
            if (CENTRE_REQUEST.status === "REQUEST_OBJECT_NOT_FOUND") {
                pushRedirect({ path: "/" });
            }
        }
    }, [CENTRE_REQUEST]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <Row container item xs={12}>
                            <CentrePageHeader TAB_ELEMENTS={TAB_ELEMENTS}
                                value={value} setValue={setValue}
                                setLogoDialogOpen={setLogoDialogOpen} />
                        </Row>
                        <Grid sx={{ marginTop: 2 }} container item xs={12} md={12}>
                            {TAB_ELEMENTS.map(((element, index) => (element.permission ?
                                (<TabPanel key={`element_${index}`} value={value} index={element.tab_index}>
                                    {element.tab_element}
                                </TabPanel>) : null
                            )))
                            }
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
            <CentreLogoUploadDialog open={logoDialogOpen} setOpen={setLogoDialogOpen} />
        </React.Suspense>
    );
}
