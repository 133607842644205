import React from 'react';
import { FormLabel, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import SectionLabel from "./../../display/styled/SectionLabel";
import { Controller } from "react-hook-form";
import { CENTRE_PROFILE_FIELD } from "./../model/CentreFormModel";
import { useFormContext } from "react-hook-form";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));


export default function CentrePaymentFieldsSection({ currentCentre, showLabel }) {

    const {
        t
    } = useTranslation("attribute");

    const { control, formState: { errors } } = useFormContext();

    return (
        <React.Fragment>
            {showLabel ? <SectionLabel>{t("centre:section_title-business_info")}</SectionLabel> : null}
            <Controller
                name={CENTRE_PROFILE_FIELD.chiBusinessName}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:centre-business_name-chinese")}
                        helperText={errors[CENTRE_PROFILE_FIELD.chiBusinessName] ? t(errors[CENTRE_PROFILE_FIELD.chiBusinessName].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.chiBusinessName] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_PROFILE_FIELD.engBusinessName}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:centre-business_name-english")}
                        helperText={errors[CENTRE_PROFILE_FIELD.engBusinessName] ? t(errors[CENTRE_PROFILE_FIELD.engBusinessName].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.engBusinessName] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_PROFILE_FIELD.paymentKey}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:centre-stripe_account_id")}
                        helperText={errors[CENTRE_PROFILE_FIELD.paymentKey] ? t(errors[CENTRE_PROFILE_FIELD.paymentKey].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.paymentKey] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
