import React from 'react';
import { Grid, IconButton, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EmptyAvatar from "./../../../asset/portrait/empty_avatar.png";

const UploadInput = styled('input')(({ theme }) => ({
    display: "none"
}));

const UploadButton = styled(IconButton)(({ theme }) => ({
    "minWidth": "220px",
    "verticalAlign": "middle"
}));

const UploadButtonLabel = styled("label")(({ theme }) => ({
    height: "100%",
    verticalAlign: 'middle',
    display: 'inline-flex'
}));

const StyledPhotoCamera = styled(PhotoCamera)(({ theme }) => ({
    "marginRight": "10px"
}));

const UploadContainer = styled(Paper)(({ theme }) => ({
    "marginTop": "12px",
    "marginBottom": "30px",
    "padding": "20px",
}));

const Preview = styled('img')(({ theme }) => ({
    "borderColor": theme.palette.primary.light,
    "borderStyle": "dashed",
    "borderWidth": "5px",
    objectFit: "contain",
    objectPosition: "50% 50%",
    "maxHeight": "150px",
    "maxWidth": "125px",
    "height": "150px",
    "width": "auto"
}));

const NoBorderGrid = styled(Grid)(({ theme }) => ({
    "borderStyle": "none",
    "borderWidth": "0px",
}));


export default function PortraitUploader({ id, photoObject, setPhotoObject, labelText }) {

    const [previewPhoto, setPreviewPhoto] = React.useState(EmptyAvatar);

    React.useEffect(() => {
        if (photoObject && photoObject.file) {
            if (typeof photoObject.file == "string") {
                setPreviewPhoto(photoObject.file);
            } else {
                let reader = new FileReader();
                reader.addEventListener("load", (e) => {
                    // convert image file to base64 string
                    e.stopPropagation();
                    setPreviewPhoto(reader.result);
                }, false);
                reader.readAsDataURL(photoObject.file);
            }
        } else {
            setPreviewPhoto(EmptyAvatar);
        }
    }, [photoObject]);

    function imageUpload(event) {
        event.preventDefault();
        var updatedPart = {};
        var updatedPhoto;
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.addEventListener("load", (e) => {
                // convert image file to base64 string
                e.stopPropagation();
                setPreviewPhoto(reader.result);
            }, false);
            reader.readAsDataURL(event.target.files[0]);
            updatedPart.file = event.target.files[0];
            updatedPhoto = Object.assign({}, photoObject, updatedPart);
            setPhotoObject(updatedPhoto)

        } else {
            updatedPart.file = null;
            setPreviewPhoto(EmptyAvatar);
            updatedPhoto = Object.assign({}, photoObject, updatedPart);
            setPhotoObject(updatedPhoto);
        }

    }

    return (<UploadContainer elevation={3}>
        <Grid container item direction="row" xs={12}>
            <Grid sx={{ marginBottom: 1 }} container item direction="row" justifyContent="center" alignItems="center" xs={12} sm={6}>
                <UploadInput accept="image/*" onChange={imageUpload} id={id} type="file" />
                <UploadButtonLabel htmlFor={id}>
                    <UploadButton data-cy={"upload_btn_" + id} size="small" color="primary" aria-label="upload picture" component="span">
                        <StyledPhotoCamera />
                        {labelText}
                    </UploadButton>
                </UploadButtonLabel>
            </Grid>
            <NoBorderGrid container item direction="row" justifyContent="center" xs={12} sm={6}>
                <Preview alt="Preview" src={previewPhoto} />
            </NoBorderGrid>
        </Grid>
    </UploadContainer>);
}
