import axios from './axiosModules';
import OPTIONS_CATEGORY from "./../const/OptionsCategory";
import UpdateResultResponse from "./interface/UpdateResultResponse";

export async function loadOptions(type: string): Promise<UpdateResultResponse> {
    if (Object.values(OPTIONS_CATEGORY).indexOf(type) == -1) {
        return {
            "success": false,
            "status": "fail",
            "result": {},
            "message": "Invalid option category",
            "error": "loadOptions"
        };
    }

    let url = "/options/" + type;
    try {
        const { data } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err;
    }
}