import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export async function getClassSessionRequest(queryId: string, type: string): Promise<UpdateResultResponse> {
    const url = `/class_session/list/${type}/${queryId}`
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
}

export async function getClassSessionInfoRequest(sessionId: string): Promise<UpdateResultResponse> {
    const url = `/class_session/info/${sessionId}`
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
}

export async function getClassStudentForClassSessionRequest(sessionId: string, searchCriteria: object): Promise<UpdateResultResponse> {
    const url = '/class_session/student/list/new/' + sessionId;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url, {
            params: searchCriteria
        });
        return data;
    } catch (err) {
        throw err
    }
}

export async function getActiveClassStudentForClassSessionRequest(sessionId: string, searchCriteria: object): Promise<UpdateResultResponse> {
    const url = '/class_session/student/list/active/' + sessionId;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url, {
            params: searchCriteria
        });
        return data;
    } catch (err) {
        throw err
    }
}

export async function getClassTutorForClassSessionRequest(sessionId: string): Promise<UpdateResultResponse> {
    const url = '/class_session/tutor/list/new/' + sessionId;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
} export async function getClassFacilityForClassSessionRequest(sessionId: string): Promise<UpdateResultResponse> {
    const url = '/class_session/facility/list/new/' + sessionId;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err
    }
}

export async function addTutorToClassSessionRequest(tutorToBeAdded: object): Promise<UpdateResultResponse> {
    const url = '/class_session/tutor/save';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, tutorToBeAdded);
        return data;
    } catch (err) {
        throw err
    }
}

export async function removeTutorFromClassSessionRequest(tutorToBeAdded: object): Promise<UpdateResultResponse> {
    const url = '/class_session/tutor/remove';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, tutorToBeAdded);
        return data;
    } catch (err) {
        throw err
    }
}

export async function addFacilityToClassSessionRequest(facilityToBeAdded: object): Promise<UpdateResultResponse> {
    const url = '/class_session/facility/save';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, facilityToBeAdded);
        return data;
    } catch (err) {
        throw err
    }
}

export async function removeFacilityFromClassSessionRequest(facilityToBeAdded: object): Promise<UpdateResultResponse> {
    const url = '/class_session/facility/remove';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, facilityToBeAdded);
        return data;
    } catch (err) {
        throw err
    }
}

export async function addStudentToClassSessionRequest(studentToBeAdded: object): Promise<UpdateResultResponse> {
    const url = '/class_session/student/save';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, studentToBeAdded);
        return data;
    } catch (err) {
        throw err
    }
}

export async function removeStudentFromClassSessionRequest(studentToBeDeleted: object): Promise<UpdateResultResponse> {
    const url = '/class_session/student/remove';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, studentToBeDeleted);
        return data;
    } catch (err) {
        throw err
    }
}

export async function saveClassSessionRequest(classSessionToBeSaved: object): Promise<UpdateResultResponse> {
    const url = '/class_session/save';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url,
            classSessionToBeSaved
        );
        return data;
    } catch (err) {
        throw err
    }
}

export async function createClassSessionRequest(classSessionToBeSaved: object): Promise<UpdateResultResponse> {
    const url = '/class_session/create';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, {
            classSession: classSessionToBeSaved
        });
        return data;
    } catch (err) {
        throw err
    }
}

export async function deleteClassSessionRequest(sessionInfo: object): Promise<UpdateResultResponse> {
    const url = '/class_session/delete';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, sessionInfo);
        return data;
    } catch (err) {
        throw err
    }
}

export async function confirmClassSessionRequest(sessionInfo: object): Promise<UpdateResultResponse> {
    const url = '/class_session/confirm';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, sessionInfo);
        return data;
    } catch (err) {
        throw err
    }
}

export async function updateStudentAttendanceRequest(studentsToBeUpdated: object): Promise<UpdateResultResponse> {
    const url = '/class_session/student/update_attendance';
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, studentsToBeUpdated);
        return data;
    } catch (err) {
        throw err
    }
}
