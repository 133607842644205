import React from 'react';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Grid, Fab,
    List, ListItem, ListItemText,
    Typography, IconButton
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CentreDataFieldDialog from "./dialog/CentreDataFieldDialog";
import ContextualMenu from "./../../common/ContextualMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TabTitleContainer } from "./../../common/Containers";
import { useForm } from "react-hook-form";
import { createCentreAdditionalFieldFormSchema } from "./../../form/model/CentreFormModel";
import { SpaceHolder } from "./../../common/Others";
import { ContextualMenuItem } from "./../../common/Buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmActionDialog from "./../../common/ConfirmActionDialog";
import {
    addCentreCustomFields, updateCentreCustomFields,
    getCentreCustomFields, useCentreManagementState, removeCentreCustomField
} from "./../../../redux/CentreManagement";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5)
}));

export default function CentreDataSettingsTab() {

    const { t } = useTranslation();
    const rows = useCentreManagementState().customFields;
    const [selected, setSelected] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const { centreId } = useParams();
    const addAdditionalFieldFormMethod = useForm({
        resolver: yupResolver(createCentreAdditionalFieldFormSchema()),
        defaultValue: {
            "name": ""
        }
    });
    const editAdditionalFieldFormMethod = useForm({
        resolver: yupResolver(createCentreAdditionalFieldFormSchema()),
        defaultValue: {
            "name": ""
        }
    });

    const openDeleteDialog = (event) => {
        event.preventDefault();
        setDeleteDialogOpen(true);
        setAnchorEl(null);
    }


    const addAdditionalField = (data) => {
        data.centreId = centreId;
        addCentreCustomFields(data);
        setIsAddDialogOpen(false);
    };

    const editAdditionalField = (data) => {
        updateCentreCustomFields(data);
        setIsEditDialogOpen(false);
    };

    const triggerRemoveCustomField = (event) => {
        event.preventDefault();
        removeCentreCustomField({ _id: selected._id });
    };
    const triggerEditCustomField = (event) => {
        event.preventDefault();
        editAdditionalFieldFormMethod.reset(selected);
        setIsEditDialogOpen(true);
        setAnchorEl(null);
    };

    const handleCloseAddDialog = () => {
        setIsAddDialogOpen(false);
    }

    const handleCloseEditDialog = () => {
        setIsEditDialogOpen(false);
    }

    const openAddAdminDialog = (event) => {
        event.preventDefault();
        addAdditionalFieldFormMethod.reset();
        setIsAddDialogOpen(true);
    };
    const triggerContextualMenu = (event, value) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelected(value);
    };

    const deboundedGetCentreCustomFields = React.useMemo(() => debounce(getCentreCustomFields, 50), []);
    
    React.useEffect(() => {
        deboundedGetCentreCustomFields(centreId);
    }, []);

    return (
        <TabTitleContainer>
            <Grid container>
                <SpaceHolder container item xs={0.5} />
                <Grid container item xs={11}>
                    <Row alignItems="center" justifyContent="space-between" container item xs={12}>
                        <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">
                            {t("centre:section_title-student_info")}
                        </Typography>
                        <Fab color="primary" onClick={openAddAdminDialog} size="small">
                            <AddIcon />
                        </Fab>
                    </Row>
                    <Grid sx={{ marginBottom: 2 }} container>
                        <List sx={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
                            {rows.map((value) => (
                                <ListItem
                                    key={value._id}
                                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                                    secondaryAction={
                                        <IconButton
                                            sx={{ position: "absolute", top: "-20px", "right": "-15px" }}
                                            onClick={(event) => {
                                                triggerContextualMenu(event, value)
                                            }} aria-label="comment">
                                            <MoreVertIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={value.name} />
                                </ListItem>
                            ))}
                        </List>
                        <ContextualMenu
                            id="custom-field-menu"
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                        >
                            <ContextualMenuItem title={t("general:button-edit")} onClick={triggerEditCustomField}>
                                <EditIcon sx={{ mr: 0 }} fontSize="small" />
                            </ContextualMenuItem>
                            <ContextualMenuItem title={t("general:button-delete")} onClick={openDeleteDialog}>
                                <DeleteIcon sx={{ mr: 0 }} fontSize="small" />
                            </ContextualMenuItem>
                        </ContextualMenu>
                    </Grid>
                </Grid>
                <SpaceHolder container item xs={0.5} />
            </Grid>
            <CentreDataFieldDialog
                title={t("general:dialog_title-add_entity", { entity: t("attribute:centre-field") })} submitMethod={addAdditionalField}
                isDialogOpen={isAddDialogOpen} handleCloseDialog={handleCloseAddDialog}
                formMethod={addAdditionalFieldFormMethod} >
            </CentreDataFieldDialog>
            <CentreDataFieldDialog
                title={t("general:dialog_title-edit_entity", { entity: t("attribute:centre-field") })} submitMethod={editAdditionalField}
                isDialogOpen={isEditDialogOpen} handleCloseDialog={handleCloseEditDialog}
                formMethod={editAdditionalFieldFormMethod} >
            </CentreDataFieldDialog>
            <ConfirmActionDialog
                title={t("general:dialog_title-delete_entity", { entity: t("attribute:centre-field") })}
                actionName={t("general:button-delete")}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                confirmOnClick={triggerRemoveCustomField} />
        </TabTitleContainer>
    );
}
