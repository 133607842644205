import React from "react";
import {
    Grid, Button, Box,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Stack, Typography, IconButton
} from "@mui/material";
import moment from "moment";
import debounce from 'lodash.debounce';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    getStripeConfig
} from "./../../../../api/paymentAPI.tsx";
import SectionLabel from "./../../../display/styled/SectionLabel"
import { SpaceHolder } from './../../../common/Others';
import { getClassInfo, useClassesManagementState } from "../../../../redux/ClassesManagement";
import { nameInBracketStyle } from "../../../../util/DisplayUtil";
import CloseIcon from "@mui/icons-material/Close";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

function BillInfoItem({ label, value }) {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Grid xs={2} item>
                <Typography color="textSecondary" component="span">
                    {label}
                </Typography>
            </Grid>
            <Grid xs={10} item>
                {value}
            </Grid>
        </Stack>
    );
}

function BillAmountItem({ label, value, variant, textColor }) {
    const color = textColor || "textSecondary";
    return (
        <Stack direction="row" justifyContent="space-between">
            <Grid item>
                <Typography variant={variant} color={color} component="span">
                    {label}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={variant} color={color} component="span">
                    {value}
                </Typography>
            </Grid>
        </Stack>
    );
}

export default function StudentViewBillDialog({
    bill, currentClassId, student, isDialogOpen, setIsDialogOpen, dialogType }) {
    const { t } = useTranslation(["bill", "general", "config"]);
    const deouncedGetClassInfo = React.useMemo(() => debounce(getClassInfo, 50), []);
    const CLASS_INFO = useClassesManagementState().currentClass;
    let { classId } = useParams();
    if (currentClassId) {
        classId = currentClassId;
    }
    const printRef = React.useRef();

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };

    React.useEffect(() => {
        if (classId) {
            deouncedGetClassInfo({ classId: classId });
        }
    }, []);

    React.useEffect(() => {
        if (classId) {
            deouncedGetClassInfo({ classId: classId });
        }
    }, [classId]);

    const closeDialog = () => {
        setIsDialogOpen(false);
    }
    const downloadBillDialog = () => {
        setIsDialogOpen(false);
    }

    const CONTENT_PADDING = 4;
    const BILL_IN_UNPAID_STATUS = (bill?.status == "unpaid");

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Stack sx={{ paddingLeft: CONTENT_PADDING, paddingRight: CONTENT_PADDING }} direction="row" alignItems="center" justifyContent="space-between">
                    <Box>
                        {t("bill:dialog_title-view_bill_for_entity", { entity: CLASS_INFO.name })}
                    </Box>
                    <Box>
                        <IconButton
                            aria-label="close"
                            onClick={closeDialog}
                            sx={{
                                marginRight: -5,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <div ref={printRef}>
                    <Stack spacing={2} sx={{
                        paddingBottom: 5
                    }} direction="row" justifyContent="space-between">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <Stack sx={{ width: "100%" }} spacing={4}>
                                <Stack sx={{ marginTop: 8, marginBottom: 2 }} direction="row" justifyContent="center">
                                    <Typography variant="h5">{t("bill:file_title-invoice")}</Typography>
                                </Stack>
                                <Stack spacing={1}>
                                    <BillInfoItem label={t("attribute:bill-from")} value={CLASS_INFO?.centre?.engBusinessName ? CLASS_INFO.centre.engBusinessName : ""} />
                                    <BillInfoItem label={t("attribute:bill-to")} value={student.engName} />
                                    <BillInfoItem label={t("attribute:bill-issue_date")} value={moment(bill.issueDate).format("LL")} />
                                    <BillInfoItem label={t("attribute:bill-due_date")} value={moment(bill.dueDate).format("LL")} />

                                    {BILL_IN_UNPAID_STATUS ? null :
                                        <BillInfoItem label={t("attribute:bill-payment_date")} value={bill.paidOn ? moment(bill.paidOn).format("LL") : "N/A"} />
                                    }
                                </Stack>
                                <Stack spacing={1}>
                                    <SectionLabel>{t("attribute:bill-items")}</SectionLabel>
                                    {bill.billDetails.map((item, index) => (
                                        <Stack direction="row" justifyContent="space-between">
                                            <Grid item>
                                                {item.desc}
                                            </Grid>
                                            <Grid item>
                                                {`$ ${item.amount}`}
                                            </Grid>
                                        </Stack>
                                    ))}
                                </Stack>

                                <Stack spacing={1}>
                                    <BillAmountItem variant="h6" textColor="textPrimary" label={t("attribute:bill-amount-total")} value={`$ ${bill.billAmount || 0}`} />
                                    {BILL_IN_UNPAID_STATUS ? null :
                                        <React.Fragment>
                                            <BillAmountItem label={t("attribute:bill-amount-paid")} value={`$ ${bill.paidAmount || 0}`} />
                                            <BillAmountItem label={t("attribute:bill-amount-due")} value={`$ ${bill.billAmount - (bill.paidAmount || 0)}`} />
                                        </React.Fragment>
                                    }
                                </Stack>
                                <SpaceHolder></SpaceHolder>
                            </Stack>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Stack>
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Button fullWidth onClick={handleDownloadPdf} variant="contained" >
                        {t("general:button-download")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog >
    )
}