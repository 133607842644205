import React from "react";
import {
    Grid,  Stack,  Collapse, IconButton, TextField,
    Dialog, DialogTitle, DialogContent, 
    FormControl, InputAdornment, Autocomplete, Divider,
    Pagination
} from "@mui/material";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { getCentreTutorForClass } from "./../../../../redux/ClassesManagement";
import { INITIAL_CENTRE_CLASS_FORM, createClassFormSchema } from "./../../../../component/form/model/CentreFormModel";
import { addTutorToClass, useClassesManagementState } from "./../../../../redux/ClassesManagement";
import { useOptionsState } from "./../../../../redux/Options";
import { yupResolver } from "@hookform/resolvers/yup";
import OPTIONS_CATEGORY from "./../../../../const/OptionsCategory";
import TutorCard from "./../../../page/tutor/TutorCard";
import { convertOptionsForAutoComplete } from "./../../../../util/AutoCompleteUtil";



const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const NarrowRow = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

export default function ClassAddTutorDialog({
    title, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);

    const [isFilterOpen, setIsFilterOpen] = React.useState(false);

    const { classId } = useParams();
    const CLASS_TUTOR_LIST = useClassesManagementState().classTutorList;
    const TUTOR_COUNT = useClassesManagementState().classTutorCount || 0;
    const [searchString, setSearchString] = React.useState(null);
    const rowsPerPage = 10;
    const [page, setPage] = React.useState(0);

    const OPTIONS_STATE = useOptionsState();

    const CLASS_TUTOR_FILTER_METHOD = useForm({
        resolver: yupResolver(createClassFormSchema(
            OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].validValues,
            OPTIONS_STATE[OPTIONS_CATEGORY.WEEK_DAY].validValues,
            OPTIONS_STATE[OPTIONS_CATEGORY.SUBJECT].validValues))
    });

    const [subjectFilterValue, setSubjectFilterValue] = React.useState([]);

    const TUTOR_FILTER_FORM = useForm({ subjectFilter: [] });


    React.useEffect(() => {
        if (isDialogOpen) {
            CLASS_TUTOR_FILTER_METHOD.reset(INITIAL_CENTRE_CLASS_FORM);
            getCentreTutorForClass({
                classId: classId,
                searchCriteria: {
                    search: searchString, subject: subjectFilterValue, page: page, rowsPerPage: rowsPerPage
                }
            })
        }
    }, [isDialogOpen, searchString, subjectFilterValue, page, rowsPerPage, classId]);

    const updateSearchString = (event) => {
        event.preventDefault();
        setSearchString(event.target.value);
    };
    const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setSubjectFilterValue(newValue);
    };


    const toggleFilter = () => {
        setIsFilterOpen((prev) => !prev)
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const addTutorToClassOnClick = (event, selectedTutor) => {
        event.preventDefault();
        setIsDialogOpen(false);
        addTutorToClass({ classId: classId, tutors: selectedTutor._id })
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...CLASS_TUTOR_FILTER_METHOD} >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Stack sx={{ width: "100%" }}>
                        <Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
                            <TextField
                                id="outlined-start-adornment"
                                fullWidth
                                onChange={(event) => { searchFieldOnChange(event) }}
                                placeholder={t("general:label-search")}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <IconButton
                                                name="filterToggle"
                                                aria-label="toggle filter"
                                                onClick={toggleFilter}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                <FilterListIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />
                            <Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
                                <Stack>
                                    <StyledFormControl fullWidth variant="outlined">
                                        <Controller
                                            name="subjectFilter"
                                            defaultValue={subjectFilterValue}
                                            control={TUTOR_FILTER_FORM.control}
                                            render={
                                                ({ field }) =>
                                                    <Autocomplete
                                                        multiple
                                                        id="tutor_subject_filter"
                                                        options={convertOptionsForAutoComplete(OPTIONS_STATE[OPTIONS_CATEGORY.SUBJECT].options)}
                                                        getOptionLabel={(option) => t(`attribute:${option}`)}
                                                        defaultValue={subjectFilterValue}
                                                        value={subjectFilterValue}
                                                        onChange={handleChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label={t("attribute:class-subjects")}
                                                                placeholder={t("attribute:class-subjects")}
                                                            />
                                                        )}
                                                    />
                                            }
                                        />
                                    </StyledFormControl>
                                </Stack>
                            </Collapse>
                        </Stack>
                    </Stack>
                    <NarrowRow spacing={0} >
                        <Grid container justifyContent="center" item sm={12} md={12}>
                            {CLASS_TUTOR_LIST.map(
                                (tutorEnrolment, index) => {
                                    let currentTutor = tutorEnrolment.profile;
                                    return (<Grid key={currentTutor._id + currentTutor.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
                                        <TutorCard index={index} key={currentTutor._id + currentTutor.displayName} cardOnClick={(event) => { addTutorToClassOnClick(event, tutorEnrolment) }} currentTutor={currentTutor} />
                                    </Grid>)
                                })
                            }
                            <Pagination
                                onChange={(event, pageNumber) => {
                                    event.preventDefault();
                                    setPage(pageNumber - 1);
                                }}
                                count={Math.ceil(TUTOR_COUNT / rowsPerPage)}
                            />
                        </Grid>
                    </NarrowRow>
                </DialogContent>
            </FormProvider>
        </Dialog >
    )
}