import React from "react";
import {
	Grid, Button,
	Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	minWidth: "200px",
	minHeight: "200px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};


const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

export default function ImageUpdateDialog({ title, open, content, setOpen, uploadedImageHandler, accept }) {

	const { t } = useTranslation();
	let contentMessage = t("general:dialog_content-upload_photos");
	if(content){
		contentMessage= content;
	}
	let acceptFileConfig = { 'image/*': [] };
	if (accept) {
		acceptFileConfig = accept;
	}
	const {
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({ accept: acceptFileConfig });

	const style = React.useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	const closeLogoDialog = (event) => {
		setOpen(false);
	}

	return (
		<Dialog open={open}
			onClose={closeLogoDialog}
			scroll="paper"
			aria-labelledby="responsive-dialog-title">
			<DialogTitle id="responsive-dialog-title">
				{title}
			</DialogTitle>
			<DialogContent dividers data-cy="edit_dialog_content">
				<Dropzone onDrop={uploadedImageHandler}>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<p>{contentMessage}</p>
						</div>

					)}
				</Dropzone>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Button autoFocus onClick={closeLogoDialog} variant="outlined">
						{t("general:button-cancel")}
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
