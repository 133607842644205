import React from 'react';
import ColorGrids from "./ColorGrids";
import ShadePicker from "./ShadePicker";

function ThemePicker({ themePickerKey }) {
    return (
        <React.Fragment>
            <ColorGrids themePickerKey={themePickerKey} />
            <ShadePicker themePickerKey={themePickerKey} />
        </React.Fragment>
    );
}

export default ThemePicker;  