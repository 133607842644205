import React from 'react';
import { FormControlLabel, FormHelperText, FormControl, Checkbox, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext } from "react-hook-form";
import { USER_DATA_FIELD } from "./../model/UserFormModel";

const TAndCAccordion = styled(Accordion)(({theme}) => ({
    "marginBottom": "12px"
}));

const TAndCAccordionSummary = styled(AccordionSummary)(({theme}) => {
    return ({
    "backgroundColor": theme.palette.primary.light,
    "color": theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(0)
})});

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({theme}) => ({
    "color": theme.palette.primary.contrastText
}));

const ReadAndAgreeCheckBoxLabel = styled(FormControlLabel)(({theme}) => ({
    color: theme.palette.primary.contrastText,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "&$readAndAgreeChecked": {
        color: theme.palette.primary.contrastText
    }
}));

const TAndCAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    "maxHeight": "300px",
    overflowY: "auto"
}));

export default function TermsAndConditionsAccordion({ name, label, children, t }) {

    const { control, formState: { errors } } = useFormContext();
    const [expanded, setExpanded] = React.useState("");
    let nameForField = USER_DATA_FIELD.agreeTC;
    if(name){
        nameForField = name;
    }
    function handlePanelChange(panel) {
        return (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        }
    };

    return (
        <React.Fragment>
            <div>
                <FormControl error={errors[nameForField] != null} component="div">
                    <FormHelperText id="my-helper-text">{errors[nameForField] ? t(errors[nameForField].message) : null}</FormHelperText>
                </FormControl>
            </div>

            <TAndCAccordion expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')}>
                <TAndCAccordionSummary expandIcon={<StyledExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                    <Controller
                        name={nameForField}
                        control={control}
                        render={({ field }) => (

                            <ReadAndAgreeCheckBoxLabel onClick={(event) => { event.stopPropagation(); }}
                                aria-label={nameForField}
                                control={
                                    <Checkbox data-cy="agreeServiceTerms"
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        onClick={(event) => { event.stopPropagation(); }}
                                        checked={field.value} sx={{
                                            color: 'primary.contrastText',
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 1,
                                            paddingRight: 1,
                                            '&.Mui-checked': {
                                                color: 'primary.contrastText'
                                            },
                                        }} />
                                }
                                label={label}
                            />
                        )}
                    />
                </TAndCAccordionSummary>

                <TAndCAccordionDetails>
                    <Typography color="primary" variant="body2">
                        {children}
                    </Typography>
                </TAndCAccordionDetails>
            </TAndCAccordion>
        </React.Fragment >
    );
}