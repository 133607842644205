import React from 'react';
import debounce from 'lodash.debounce';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, Sidebar, Search, ConversationList, Conversation, MessageInput, ChatContainer, ConversationHeader, Message, MessageList, Avatar, MessageSeparator } from "@chatscope/chat-ui-kit-react";
import siteIcon from "./../../asset/img/site_icon.png";
import { Paper, Typography, Link } from "@mui/material";
import { useMessageManagementState, getCentreBroadcastList, readChatMessages, getConversations, getChatMessages, getConversationId, getUnreadMessagesCount } from "./../../redux/MessageManagement";
import { useProfileState, getStudentProfileInfo, getTutorProfileInfo } from "./../../redux/ProfileManagement";
import { useAuthenticationState, updateCurrentRole, changeRole } from "./../../redux/authentication";
import { getCentreInfo, useCentreManagementState } from "./../../redux/CentreManagement";
import { getClassInfo, getTutorActiveClass, useClassesManagementState } from "./../../redux/ClassesManagement";
import { useI18NControlState } from "./../../redux/I18NControl";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { nameInBracketStyle } from "./../../util/DisplayUtil";
import LoadingPage from "./LoadingPage";
import EmptyAvatar from "./../../asset/portrait/empty_avatar.png";
import MessageNotificationSound from "./../../asset/notification_sound/message.mp3";

function parseTime(date) {
    return `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
}

function MessageDetails(message, recipient, language) {
    const direction = message.from == recipient ? "incoming" : "outgoing";
    const createdAt = new Date(message.createdAt);
    const sentDate = createdAt.toLocaleDateString(language, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    const sentTime = `${message.announcement ? String.fromCodePoint(0x1F4E2) : ""} ${parseTime(createdAt)}`;

    return({
        id: message._id,
        model: {
            message: message.content,
            direction: direction,
            position: "single"
        },
        createdAt: createdAt,
        sentDate: sentDate,
        read: message.read,
        sentTime: sentTime
    })
}

function ConversationDetails(conversation, t, conversationUnreadCountMap) {
    const name = conversation.recipient.centreName || 
    (conversation.recipient.studentEngName ? 
        nameInBracketStyle(conversation.recipient.studentChiName, conversation.recipient.studentEngName) : 
        nameInBracketStyle(conversation.recipient.tutorChiName, conversation.recipient.tutorEngName));
    const lastMessageTime = new Date(conversation.lastMessage.createdAt);
    let lastActivityTime;
    if (onSameDay(lastMessageTime, new Date())) {
        lastActivityTime = `${lastMessageTime.getHours() < 10 ? `0${lastMessageTime.getHours()}` : lastMessageTime.getHours()}:
        ${lastMessageTime.getMinutes() < 10 ? `0${lastMessageTime.getMinutes()}` : lastMessageTime.getMinutes()}`;
    } else {
        lastActivityTime = `${lastMessageTime.getFullYear()}-${lastMessageTime.getMonth()+1 < 10 ? `0${lastMessageTime.getMonth()+1}` : lastMessageTime.getMonth()+1 }-${lastMessageTime.getDate() < 10 ? `0${lastMessageTime.getDate()}` : lastMessageTime.getDate()}`;
    }
    const recipient = conversation.recipient.roleId || conversation.recipient.centreId;
    const lastSenderName = conversation.lastMessage.from == recipient ? name : t("messenger:label-you");
    const avatar = conversation.recipient.avatar;
    const unreadDot = conversationUnreadCountMap.get(conversation._id) > 0 ? true : false;
    
    return ({
        id: conversation._id,
        name: name,
        info: conversation.lastMessage.content,
        unreadDot: unreadDot,
        lastActivityTime: lastActivityTime,
        lastSenderName: lastSenderName,
        lastMessageTime: lastMessageTime,
        avatar: avatar
    })
}

function onSameDay(date1, date2) {
    return (date1.getFullYear() == date2.getFullYear() &&
            date1.getMonth() == date2.getMonth() &&
            date1.getDate() == date2.getDate())
}

export default function ChatroomPage({socket, notificationCount, setNotificationCount}) {
    const { t } = useTranslation(["messenger", "general"]);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [searchText, setSearchText] = React.useState("");
    const { messageType } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const debouncedGetStudentProfileInfo = React.useMemo(() => debounce(getStudentProfileInfo, 50), []);
    const debouncedGetTutorProfileInfo = React.useMemo(() => debounce(getTutorProfileInfo, 50), []);
    const debouncedGetCentreInfo = React.useMemo(() => debounce(getCentreInfo, 50), []);
    const debouncedGetChatMessages = React.useMemo(() => debounce(getChatMessages, 500), []);
    const debouncedReadChatMessages = React.useMemo(() => debounce(readChatMessages, 500), []);
    const debouncedUpdateCurrentRole = React.useMemo(() => debounce(updateCurrentRole, 500), []);
    const debouncedChangeRole = React.useMemo(() => debounce(changeRole, 500), []);
    const debouncedGetConversations= React.useMemo(() => debounce(getConversations, 500), []);
    const debouncedGetConversationId= React.useMemo(() => debounce(getConversationId, 500), []);
    const debouncedGetClassProfileInfo = React.useMemo(() => debounce(getClassInfo, 50), []);
    const debouncedGetTutorActiveClass = React.useMemo(() => debounce(getTutorActiveClass, 50), []);
    const debouncedGetCentreBroadcastList = React.useMemo(() => debounce(getCentreBroadcastList, 50), []); 
    const debouncedGetUnreadMessagesCount = React.useMemo(() => debounce(getUnreadMessagesCount, 500), []);   
    
    const TARGET_STUDENT = useProfileState().studentProfileInfo;
    const TARGET_TUTOR = useProfileState().tutorProfileInfo;
    const TARGET_CENTRE = useCentreManagementState().currentCentre;
    const CHAT_MESSAGES = useMessageManagementState().messages || [];
    const ROLE_CONVERSATIONS = useMessageManagementState().roleConversations || [];
    const CENTRE_CONVERSATIONS = useMessageManagementState().centreConversations || [];
    const CONVERSATION_ID = useMessageManagementState().currentConversationId || null;
    const CURRENT_ROLE = useAuthenticationState().currentRole;
    const AUTH_REQUEST = useAuthenticationState().request;
    const USER_ID = useAuthenticationState().userId;
    const USERNAME = useAuthenticationState().username;
    const USER_ROLES = useAuthenticationState().roles;
    const CLASS_INFO = useClassesManagementState().currentClass || {};
    const TUTOR_ACTIVE_CLASSES = useClassesManagementState().tutorActiveClasses || {};
    const I18NLANG = useI18NControlState().lang || "";
    const CENTRE_BROADCAST_LIST = useMessageManagementState().centreBroadcastList || [];
    const CONVERSATION_UNREAD_COUNT = useMessageManagementState().conversationUnreadCount || [];
    
    const [conversationId, setConversationId] = React.useState("");
    const [recipient, setRecipient] = React.useState("");
    const [broadcastRecipients, setBroadcastRecipients] = React.useState(new Set());
    const [conversationList, setConversationList] = React.useState([]);
    const [conversationMap, setConversationMap] = React.useState(new Map());
    const [conversationHeader, setConversationHeader] = React.useState({});
    const [messageList, setMessageList] = React.useState([]);
    const [messageTypeParam, setMessageTypeParam] = React.useState(messageType);
    const [enableChat, setEnableChat] = React.useState(false);
    const [sidebarVisible, setSidebarVisible] = React.useState(window.innerWidth < 900);
    const [sidebarStyle, setSidebarStyle] = React.useState({});
    const [chatContainerStyle, setChatContainerStyle] = React.useState({});
    const [conversationContentStyle, setConversationContentStyle] = React.useState({});
    const [conversationAvatarStyle, setConversationAvatarStyle] = React.useState({});
    const [focused, setFocused] = React.useState(true);
  
    const centreRoleMap = React.useRef(new Map());
    const conversationUnreadCountMap = React.useRef(new Map());
    const myCentres = React.useRef([]);
    const myRoles = React.useRef([]);
    const messageListDOM = React.useRef(null);
    const changingRole = React.useRef(false);

    React.useEffect(() => {
        window.history.replaceState(null, "", "/message");
        const map = new Map();
        const roles = [];
        const centres =[];
        USER_ROLES.forEach((role) => {
            if (role && (role.status == "active" || role.status == "pending")) {
                if (role.role == "centre_admin") {
                    map.set(role.centreId[0], role._id);
                    centres.push(role.centreId[0]);
                } else if (role.role == "student" || role.role == "tutor") {
                    roles.push(role._id);
                }
            }
        });
        myRoles.current = roles;
        myCentres.current = centres;
        centreRoleMap.current = map;

        if (searchParams && messageTypeParam) {
            setSidebarVisible(false);
            if (messageTypeParam == "broadcast") {
                if (searchParams.get("centre")) {
                    const broadcastCentreId = searchParams.get("centre");
                    if (CURRENT_ROLE.roleName != "centre_admin" || CURRENT_ROLE.centreId != broadcastCentreId) {
                        window.location.replace("/message");
                    }
                    setEnableChat(true);
                    setConversationId("");
                    debouncedGetCentreInfo({ centreId: broadcastCentreId });
                    debouncedGetCentreBroadcastList({ centreId: broadcastCentreId });
                } else if (searchParams.get("class")) {
                    const broadcastClassId = searchParams.get("class");
                    if (CURRENT_ROLE.roleName != "centre_admin" && CURRENT_ROLE.roleName != "tutor") {
                        window.location.replace("/message");
                    }
                    setEnableChat(true);
                    setConversationId("");
                    debouncedGetClassProfileInfo({ classId: broadcastClassId });
                } else {
                        window.location.replace("/message");
                }
            } else if (messageTypeParam == "direct") {
                if (searchParams.get("centre")) {
                    const directCentreId = searchParams.get("centre");
                    if (CURRENT_ROLE.centreId != directCentreId ||
                        (CURRENT_ROLE.roleName != "student" && CURRENT_ROLE.roleName != "tutor")) {
                        window.location.replace("/message");
                    }
                    if (myCentres.current.includes(directCentreId)) {
                        enqueueSnackbar(t("messenger:warning-recipient-same_account-centre"), {
                            "variant": "warning"
                        })
                    } else {
                        setEnableChat(true);
                        debouncedGetCentreInfo({ centreId: directCentreId });
                        debouncedGetConversationId({ from: CURRENT_ROLE.roleId.toString(), to: directCentreId })
                    }
                } else if (searchParams.get("tutor")) {
                    const directTutorRoleId = searchParams.get("tutor");
                    if (CURRENT_ROLE.roleName == "platform_admin") {
                        window.location.replace("/message");
                    }
                    if (myRoles.current.includes(directTutorRoleId)) {
                        enqueueSnackbar(t("messenger:warning-recipient-same_account-tutor"), {
                            "variant": "warning"
                        });
                    } else {
                        setEnableChat(true);
                        debouncedGetTutorProfileInfo({ profileId: directTutorRoleId });
                        if (CURRENT_ROLE.roleName == "centre_admin") {
                            debouncedGetConversationId({ from: CURRENT_ROLE.centreId, to: directTutorRoleId });
                        } else {
                            debouncedGetConversationId({ from: CURRENT_ROLE.roleId.toString(), to: directTutorRoleId });
                        }
                    }
                } else if (searchParams.get("student")) {
                    const directStudentRoleId = searchParams.get("student");
                    if ((CURRENT_ROLE.roleName != "centre_admin" && CURRENT_ROLE.roleName != "tutor")) {
                        window.location.replace("/message");
                    }
                    if (myRoles.current.includes(directStudentRoleId)) {
                        enqueueSnackbar(t("messenger:warning-recipient-same_account-student"), {
                            "variant": "warning"
                        });
                    } else {
                        setEnableChat(true);
                        debouncedGetStudentProfileInfo({ profileId: directStudentRoleId });
                        if (CURRENT_ROLE.roleName == "tutor") {
                            debouncedGetTutorActiveClass({ enrolmentId: CURRENT_ROLE.roleId });
                        }
                        if (CURRENT_ROLE.roleName == "centre_admin") {
                            debouncedGetConversationId({ from: CURRENT_ROLE.centreId, to: directStudentRoleId })
                        } else {
                            debouncedGetConversationId({ from: CURRENT_ROLE.roleId.toString(), to: directStudentRoleId })
                        }
                    }
                } else {
                    window.location.replace("/message");
                }
            } else {
                window.location.replace("/message");
            }
        } else if (searchParams.toString() || messageTypeParam) {
            window.location.replace("/message");
        }
        debouncedGetUnreadMessagesCount({ username: USERNAME });
        debouncedGetConversations({ username: USERNAME });
    }, []);

    React.useEffect(() => {
        if (CLASS_INFO._id) {
            if (CLASS_INFO.centreId != CURRENT_ROLE.centreId || 
                (CURRENT_ROLE.roleName == "tutor" && !CLASS_INFO.tutors.includes(CURRENT_ROLE.roleId))) {
                    window.location.replace("/message");
            }
            const broadcastTo = [...CLASS_INFO.students, ...CLASS_INFO.tutors].filter(r => !myRoles.current.includes(r));
            setBroadcastRecipients(new Set(broadcastTo));
            setRecipient("");
            setConversationHeader({name: t("messenger:label-broadcast_recipient"), centre: CLASS_INFO.name});
        }
    }, [CLASS_INFO]);

    React.useEffect(() => {
        if (CENTRE_BROADCAST_LIST.length > 0) {
            const broadcastTo = CENTRE_BROADCAST_LIST.filter(s => !myRoles.current.includes(s._id)).map(s => s._id);
            setBroadcastRecipients(new Set(broadcastTo));
        }
        setRecipient("");
    }, [CENTRE_BROADCAST_LIST]);

    React.useEffect(() => {
        if (TARGET_CENTRE?._id) {
            if (messageTypeParam == "direct") {
                setRecipient(TARGET_CENTRE._id);
                setBroadcastRecipients(new Set());
                setConversationHeader({name: TARGET_CENTRE.displayName, centre: "", link: `/centre/${TARGET_CENTRE._id}`, avatar: TARGET_CENTRE.logo});
            } else if (messageTypeParam == "broadcast") {
                setConversationHeader({name: t("messenger:label-broadcast_recipient"), centre: TARGET_CENTRE.displayName});
            }
        }
    }, [TARGET_CENTRE]);

    React.useEffect(() => {
        if (TARGET_TUTOR?._id) {
            if (searchParams.get("tutor")) {
                if (TARGET_TUTOR.centreId != CURRENT_ROLE.centreId || 
                    (CURRENT_ROLE.roleName == "tutor" && TARGET_TUTOR.status != "active")) {
                        window.location.replace("/message");
                }
                setRecipient(TARGET_TUTOR.enrolmentId);
                setBroadcastRecipients(new Set());
                setConversationHeader({name: `${nameInBracketStyle(TARGET_TUTOR.chiName, TARGET_TUTOR.engName)}`, centre: `${TARGET_TUTOR.centreDisplayName}`, link: `/tutor/${TARGET_TUTOR.enrolmentId}`, avatar: TARGET_TUTOR.photo?.files});
            } else if (TARGET_TUTOR.enrolmentId != CURRENT_ROLE.roleId) {
                enqueueSnackbar(`${t("messenger:info-chat_role",{role:nameInBracketStyle(TARGET_TUTOR.chiName, TARGET_TUTOR.engName)})}`, {
                    "variant": "info",
                    "autoHideDuration": 2000
                });
            }
        }
    }, [TARGET_TUTOR, searchParams]);
    
    React.useEffect(() => {
        if (TARGET_STUDENT?._id) {
            if (searchParams.get("student")) {
                if (TARGET_STUDENT.centreId != CURRENT_ROLE.centreId || 
                    (CURRENT_ROLE.roleName == "tutor" && !TUTOR_ACTIVE_CLASSES.map(c => c.students).flat().includes(TARGET_STUDENT.enrolmentId) &&
                    !TUTOR_ACTIVE_CLASSES.map(c => c.pendingStudents).flat().includes(TARGET_STUDENT.enrolmentId))) {
                        window.location.replace("/message");
                }
                setRecipient(TARGET_STUDENT.enrolmentId);
                setBroadcastRecipients(new Set());
                setConversationHeader({name: `${nameInBracketStyle(TARGET_STUDENT.chiName, TARGET_STUDENT.engName)}`, centre: `${TARGET_STUDENT.centreDisplayName}`, link: `/tutor/${TARGET_STUDENT.enrolmentId}`, avatar: TARGET_STUDENT.photo?.files});
            } else if (TARGET_STUDENT.enrolmentId != CURRENT_ROLE.roleId) {
                enqueueSnackbar(`${t("messenger:info-chat_role",{role:nameInBracketStyle(TARGET_STUDENT.chiName, TARGET_STUDENT.engName)})}`, {
                    "variant": "info",
                    "autoHideDuration": 2000
                });
            }
        }
    }, [TARGET_STUDENT, TUTOR_ACTIVE_CLASSES, searchParams]);

    React.useEffect(() => {
        if (CONVERSATION_ID) {
            setConversationId(CONVERSATION_ID);
        }
    }, [CONVERSATION_ID]);

    React.useEffect(() => {
        const list = [];
        CENTRE_CONVERSATIONS.forEach((conversation) => {
            const r = myCentres.current.includes(conversation.first.centreId) ? conversation.second : conversation.first;
            const myCentre = r == conversation.second ? conversation.first.centreId : conversation.second.centreId;
            if (r.roleId && (r.status == "active" || r.status == "pending")) {      // recipient has to be tutor/student
                list.push({
                    "_id": conversation._id, 
                    "myRole": centreRoleMap.current.get(myCentre),
                    "myCentre": myCentre,
                    "recipient": r, 
                    "lastMessage": conversation.lastMessage,
                    "lastMessageTime": conversation.lastMessageTime
                });
            }
        });
        ROLE_CONVERSATIONS.forEach((conversation) => {
            const r = myRoles.current.includes(conversation.first.roleId) ? conversation.second : conversation.first;
            const myRole = r == conversation.second ? conversation.first.roleId : conversation.second.roleId;
            if ((r.roleId && (r.status == "active" || r.status == "pending")) ||    // recipient is tutor or student
                (r.centreId && r.centreStatus == "active")) {                       // recipient is centre
                list.push({
                    "_id": conversation._id, 
                    "myRole": myRole,
                    "myCentre": "",
                    "recipient": r, 
                    "lastMessage": conversation.lastMessage,
                    "lastMessageTime": conversation.lastMessageTime
                });
            }
        });
        setConversationList(list.sort((a, b) => {
            if (!a.lastMessageTime) return 1;
            if (!b.lastMessageTime) return -1;
            return a.lastMessageTime > b.lastMessageTime ? -1 : 1
        }));
        setConversationMap(
            new Map(list.map(convo => 
                [convo._id, {
                    myRole: convo.myRole, 
                    myCentre: convo.myCentre, 
                    recipient: convo.recipient
            }])
        ));  
    }, [CENTRE_CONVERSATIONS, ROLE_CONVERSATIONS]);

    React.useEffect(() => {
        if (conversationMap?.get(conversationId)) {
            const conversation = conversationMap.get(conversationId);
            const r = conversation.recipient;

            if (r.centreName) {
                const name = r.centreName;
                const centre = "";
                const link = `/centre/${r.centreId}`;
                const avatar = r.avatar;
                setConversationHeader({ name: name, centre: centre, link: link, avatar: avatar });
            } else if (r.studentEngName) {
                const name = nameInBracketStyle(r.studentChiName, r.studentEngName);
                const centre = r.profileCentreName;
                const link = `/student/${r.roleId}`;
                const avatar = r.avatar;
                setConversationHeader({ name: name, centre: centre, link: link, avatar: avatar});
            } else if (r.tutorEngName) {
                const name = nameInBracketStyle(r.tutorChiName, r.tutorEngName);
                const centre = r.profileCentreName;
                const link = `/tutor/${r.roleId}`;
                const avatar = r.avatar;
                setConversationHeader({ name: name, centre: centre, link: link, avatar: avatar });
            }

            setRecipient(r.roleId || r.centreId);
            debouncedGetChatMessages({ conversationId: conversationId });
            debouncedReadChatMessages({ conversationId: conversationId, from: r.roleId || r.centreId});
        }
    }, [conversationId, conversationMap]);

    React.useEffect(() => {
        const messageDetails = [];

        CHAT_MESSAGES.forEach((message) => {
            messageDetails.push(MessageDetails(message, recipient, I18NLANG));
        })
        
        for (let i = 1; i < messageDetails.length; i++) {
            if (messageDetails[i-1].sentDate == messageDetails[i].sentDate) {
                messageDetails[i].displayDate = "";
            }
        }

        const list = [];
        let newMessageFound = false;
        messageDetails.forEach((details) => {
            if (!newMessageFound && !details.read && details.model.direction == "incoming") {
                newMessageFound = true;
                list.push({ 
                    separator: true, content: t("messenger:label-new_messages"), as: "h2", key: "separator_new_message"})
            } else if (details.displayDate != "") {
                list.push({ 
                    separator: true, content: details.sentDate, key: `separator_${details.sentDate}`})
            }
            list.push({
                separator: false, model:  details.model, sentTime:  details.sentTime, key:  details.id 
            })
        })
        setMessageList(list);
    }, [CHAT_MESSAGES]);

    React.useEffect(() => {
        CONVERSATION_UNREAD_COUNT.forEach((convo) => {
            conversationUnreadCountMap.current.set(convo._id, convo.count);
        })
    }, [CONVERSATION_UNREAD_COUNT]);

    React.useEffect(() => {
        if (!socket.hasListeners("BROADCAST_MSG_TO_LIST")) {
            socket.on("BROADCAST_MSG_TO_LIST", (msg) => {
                const details = MessageDetails(msg, "", I18NLANG);
                setMessageList(currentList => [ ...currentList, {
                    separator: false, model:  details.model, sentTime:  details.sentTime, key:  details.id 
                } ]);
                debouncedGetConversations({ username: USERNAME });
            });
        }

    }, []);

    React.useEffect(() => {
        if (socket.hasListeners("INDIVIDUAL_MSG")) {
            socket.off("INDIVIDUAL_MSG");
        }
        socket.on('INDIVIDUAL_MSG', function([msg, sender]) {
            const conversationIndex = conversationList.findIndex((c => c._id == msg.conversation));
            if (conversationIndex >= 0) {
                const conversationListCopy = [...conversationList];
                const conversationListBefore = conversationList.slice(0, conversationIndex);
                const conversationListAfter = conversationList.slice(conversationIndex + 1);
                const updatedConversation = {...conversationList[conversationIndex]};
                if (msg.conversation == conversationId) {    
                    const details = MessageDetails(msg, recipient, I18NLANG);
                    setMessageList(currentList => [ ...currentList, {
                        separator: false, model:  details.model, sentTime:  details.sentTime, key:  details.id 
                    } ]);
                    if (!focused && !sender) {
                        new Audio(MessageNotificationSound).play();
                        const thisConversationUnreadCount = conversationUnreadCountMap.current.get(conversationId) || 0;
                        conversationUnreadCountMap.current.set(conversationId, thisConversationUnreadCount + 1);
                    } else if (messageListDOM.current && chatContainerStyle.display != "none" && msg.to != recipient) {
                        msg.read = true;
                        socket.emit('READ_MSG', { messageId: msg._id });   
                    }
                } else if (!sender) {
                    const thisConversationUnreadCount = conversationUnreadCountMap.current.get(msg.conversation) || 0;
                    conversationUnreadCountMap.current.set(msg.conversation, thisConversationUnreadCount + 1);
                    new Audio(MessageNotificationSound).play();
                }
                updatedConversation.lastMessage = msg;
                conversationListCopy[conversationIndex] = updatedConversation;
                setConversationList([ ...[updatedConversation], ...conversationListBefore, ...conversationListAfter]);
            } else {
                debouncedGetUnreadMessagesCount({ username: USERNAME });
                debouncedGetConversations({ username: USERNAME });
                if (sender) {
                    setConversationId(msg.conversation)
                    debouncedGetChatMessages({conversationId: msg.conversation})
                } else {
                    new Audio(MessageNotificationSound).play();
                }
            }
        });
    }, [recipient, conversationList, chatContainerStyle, focused])

    React.useEffect(() => {
        window.addEventListener("focus", () => {setFocused(true)});
        window.addEventListener("blur", () => {setFocused(false)});
    }, [conversationUnreadCountMap]);

    React.useEffect(() => {
        if (focused && conversationMap?.get(conversationId)) {
            const conversation = conversationMap.get(conversationId);
            const r = conversation.recipient;
            debouncedReadChatMessages({ conversationId: conversationId, from: r.roleId || r.centreId});
            const thisConversationUnreadCount = conversationUnreadCountMap.current.get(conversationId) || 0;
            setNotificationCount(notificationCount - thisConversationUnreadCount);
            conversationUnreadCountMap.current.delete(conversationId);
        }
    }, [conversationId, conversationMap, focused]);

    const sendMessage = (text) => {
        text = new DOMParser().parseFromString(text, "text/html").body.textContent.trim();
        if (text != "") {
            if (broadcastRecipients.size > 0) {
                let from;
                if (CURRENT_ROLE.roleName == "centre_admin") {
                    from = {
                        role: null,
                        centre: CURRENT_ROLE.centreId
                    }
                } else if (CURRENT_ROLE.roleName == "tutor") {
                    from = {
                        role: CURRENT_ROLE.roleId,
                        centre: null
                    }
                }
                socket.emit('BROADCAST_MSG_TO_LIST', {
                    from: from,
                    message: text,
                    recipients: [...broadcastRecipients]
                });
            } else if (messageTypeParam == "broadcast") {
                enqueueSnackbar(t("messenger:warning-recipient-empty_list"), {
                    "variant": "warning"
                });
            } else {
                if (!conversationId) {
                    let from, to;
                    if (CURRENT_ROLE.roleName == "centre_admin") {
                        from = {
                            role: null,
                            centre: CURRENT_ROLE.centreId
                        }
                    } else if (CURRENT_ROLE.roleName == "student" || CURRENT_ROLE.roleName == "tutor") {
                        from = {
                            role: CURRENT_ROLE.roleId,
                            centre: null
                        }
                    }
                    if (searchParams.get("centre")) {
                        to = {
                            role: null,
                            centre: recipient
                        }
                        socket.emit('NEW_CONVERSATION', {
                            from: from,
                            to: to,
                            message: text
                        });
                    } else {
                        to = {
                            role: recipient,
                            centre: null
                        }
                        socket.emit('NEW_CONVERSATION', {
                            from: from,
                            to: to,
                            message: text
                        });
                    }
                } else {
                    const from = conversationMap.get(conversationId).myCentre == "" ? 
                        conversationMap.get(conversationId).myRole : conversationMap.get(conversationId).myCentre
                
                    const messageListCopy = messageList;
                    const newMessageSeparator = messageList.findIndex(m => m.key == "separator_new_message");
                    if (newMessageSeparator > 0) {
                        messageListCopy.splice(newMessageSeparator, 1);
                    }
                    setMessageList([...messageListCopy]);
            
                    socket.emit('INDIVIDUAL_MSG', {
                        content: text,
                        to: recipient,
                        from: from,
                        conversationId: conversationId
                    });
                }
            }
        }
    }

    const clickOnHeader = (e) => {
        e.preventDefault();
        if (conversationMap?.get(conversationId)?.myRole && CURRENT_ROLE.roleId != conversationMap.get(conversationId).myRole) {
            debouncedChangeRole({ role: conversationMap?.get(conversationId)?.myRole });
            debouncedUpdateCurrentRole();
            changingRole.current = true;
        }
    }

    React.useEffect(() => {
        if (changingRole.current && !AUTH_REQUEST.updating) {
            changingRole.current = false;
            if (AUTH_REQUEST.success) {
                enqueueSnackbar(t("messenger:success-switch_role"), {
                    "variant": "info"
                });
                navigate(conversationHeader.link);                
            } else {
                enqueueSnackbar(t("general:error-unknown"), {
                    "variant": "error"
                });
            }
        }
    }, [AUTH_REQUEST, conversationHeader]);

    const clickOnConversation = (id) => {
        const thisConversationUnreadCount = conversationUnreadCountMap.current.get(id) || 0;
        setNotificationCount(notificationCount - thisConversationUnreadCount);
        conversationUnreadCountMap.current.delete(id);
            
        if (sidebarVisible) {
            setSidebarVisible(false);
        }
        searchParams.delete("class");
        searchParams.delete("centre");
        searchParams.delete("student");
        searchParams.delete("tutor");
        setSearchParams(searchParams);
        setMessageTypeParam(null);
        setBroadcastRecipients(new Set());
        setEnableChat(true);
        setConversationId(id);
        if (id != conversationId) {
            setMessageList([]);
        }
        const roleId = conversationMap.get(id).myRole;
        if (conversationMap.get(id).myCentre && !(CURRENT_ROLE.roleName == "centre_admin" && CURRENT_ROLE.centreId == conversationMap.get(id).myCentre)) {   
            enqueueSnackbar(`${t("messenger:info-chat_role",{role:conversationMap.get(id).recipient.profileCentreName})}`, {
                "variant": "info",
                "autoHideDuration": 2000
            });
        } else if (roleId) {   
            debouncedGetStudentProfileInfo({profileId: roleId});
            debouncedGetTutorProfileInfo({profileId: roleId});
        } 
    }

    const handleBackClick = () => {
        setSidebarVisible(!sidebarVisible);
        setMessageList([]);
        setConversationId("");
    }
    React.useEffect(() => {
        if (sidebarVisible) {
          setSidebarStyle({
            display: "flex",
            flexBasis: "auto",
            width: "100%",
            maxWidth: "100%"
          });
          setConversationContentStyle({
            display: "flex"
          });
          setConversationAvatarStyle({
            marginRight: "1em"
          });
          setChatContainerStyle({
            display: "none"
          });
        } else {
          setSidebarStyle(window.innerWidth < 900 ?
            {display: "none"} : {});
          setConversationContentStyle({});
          setConversationAvatarStyle({});
          setChatContainerStyle({});
        }
      }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setConversationAvatarStyle, setSidebarStyle, setChatContainerStyle]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <div style={{
                height: "100%",
                position: "single",
                paddingTop: "24px",
                paddingBottom: "24px",
                boxSizing: "border-box"
            }}>
                <Paper elevation={10} sx={{
                    height: "100%",
                    borderRadius: "10px"
                }}>
                    <MainContainer responsive style={{
                        borderRadius: "10px"
                    }}>
                        <Sidebar position="left" scrollable={false} style={sidebarStyle}>
                            <Search placeholder={t("general:label-search")} value={searchText} onChange={v => setSearchText(v)} onClearClick={() => setSearchText("")}/>
                            <ConversationList>
                                {conversationList.map((conversation) => {
                                    const conversationDetails = ConversationDetails(conversation, t, conversationUnreadCountMap.current);
                                    if (conversationDetails.name.toLowerCase().includes(searchText.toLocaleLowerCase())) {
                                        return <Conversation 
                                            key={conversationDetails.id}
                                            unreadDot={conversationDetails.unreadDot}
                                            lastActivityTime={conversationDetails.lastActivityTime}
                                            style={{borderBottom: "1px solid #ccc"}}
                                            onClick={() => clickOnConversation(conversationDetails.id)}>
                                                <Conversation.Content 
                                                    name={conversationDetails.name}
                                                    info={conversationDetails.info}
                                                    lastSenderName={conversationDetails.lastSenderName}
                                                    style={conversationContentStyle}
                                                />
                                                <Avatar src={conversationDetails.avatar || EmptyAvatar} style={conversationAvatarStyle}/>
                                            </Conversation>
                                    }
                                })}
                            </ConversationList>
                        </Sidebar>
                        <ChatContainer style={chatContainerStyle}>
                            {enableChat && (conversationId || recipient || messageTypeParam == "broadcast") && <ConversationHeader>
                                <ConversationHeader.Back onClick={handleBackClick}/>
                                <Avatar src={conversationHeader.avatar || EmptyAvatar}/>
                                <ConversationHeader.Content>
                                    <Link href={conversationHeader.link ? "" : undefined}
                                        onClick={clickOnHeader}
                                        underline="none" 
                                        variant="h6" 
                                        sx={{lineHeight: 1,
                                            fontWeight: 700,
                                            color: "black",
                                            paddingTop: "4px",
                                            width: "fit-content"}}
                                    >
                                        {conversationHeader.name}
                                    </Link>
                                    <Typography variant="subtitle2" display="block" sx={{color: "grey"}}>
                                        {conversationHeader.centre}
                                    </Typography>
                                </ConversationHeader.Content>
                            </ConversationHeader>}
                            <MessageList ref={messageListDOM}>                          
                                {enableChat && (conversationId || recipient || messageTypeParam == "broadcast") && messageList.map((item, index, original) => {
                                    if (item.key != original[index - 1]) {
                                        if (item.separator) {
                                            return (<MessageSeparator key={item.key} content={item.content} as={item.as}/>)
                                        } else {
                                            return (
                                                <Message key={item.key} model={item.model}>
                                                    <Message.Footer key={`footer_${item.key}`} sentTime={item.sentTime}/>
                                                </Message>
                                            )
                                        }
                                    }
                                })}
                                {!enableChat && !(conversationId || recipient || messageTypeParam == "broadcast") && <MessageList.Content style={{
                                    display: "flex",
                                    "flexDirection": "column",
                                    "justifyContent": "center",
                                    height: "100%",
                                    textAlign: "center",
                                    fontSize: "1.2em"
                                }}>
                                    <img style={{
                                        width: "auto",
                                        objectFit: "contain",
                                        objectPosition: "50% 50%",
                                        height: "225px",
                                        margin: "10px"
                                        }}
                                        src={siteIcon}/>
                                </MessageList.Content>}
                            </MessageList>
                            {enableChat && (conversationId || recipient || messageTypeParam == "broadcast") && <MessageInput 
                                        onSend={sendMessage}
                                        attachButton={false} 
                                        placeholder={t("messenger:label-type_message")} />}
                        </ChatContainer>
                    </MainContainer>
                </Paper>
            </div>
        </React.Suspense>
    );
}
