import React from "react";
import { FormLabel } from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  "marginTop": theme.spacing(1),
  "marginLeft": theme.spacing(0.5),
  "paddingBottom": theme.spacing(1),
  "fontWeight": "bold",
  "color": "#AAAAAA"
}));

export default function SectionLabel({ children }) {
  return (<StyledFormLabel component="legend">
    {children}
  </StyledFormLabel>);
}