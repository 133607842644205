import React from "react";
import {
    Grid
} from "@mui/material";
import debounce from 'lodash.debounce';
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LoadingPage from "./LoadingPage";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import ClassAboutDetailPage from "./class/ClassAboutDetailPage";
import ClassStudentPage from "./class/ClassStudentPage";
import ClassPageHeader from "./class/header/ClassPageHeader";
import { getClassInfo, useClassesManagementState } from "./../../redux/ClassesManagement";
import { pushRedirect } from "./../../redux/Redirect";
import { useAuthenticationState } from "./../../redux/authentication";
import { subject } from "@casl/ability";
import ClassSchedulePage from "./class/ClassSchedulePage";
import ClassNotePage from "./class/ClassNotePage";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export default function ClassDetail() {


    const ABILITY = useAuthenticationState().ability;
    const CLASS_INFO = useClassesManagementState().currentClass;

    let isClosed = CLASS_INFO.status == "closed";
    let isPending = CLASS_INFO.status == "pending";
    let isActive = CLASS_INFO.status == "active";
    const ALL_TAB = [
        { "tab_element": (<ClassAboutDetailPage />), "tab_index": "about", "tab_label": "general:tab_name-about", permission: !isClosed },
        { "tab_element": (<ClassStudentPage />), "tab_index": "student", "tab_label": "general:tab_name-students", permission: !isPending && !isClosed && ABILITY.can("update", subject("class", CLASS_INFO)) },
        { "tab_element": (<ClassSchedulePage />), "tab_index": "schedule", "tab_label": "general:tab_name-sessions", permission: isActive },
        { "tab_element": (<ClassNotePage />), "tab_index": "note", "tab_label": "class:tab_name-notes", permission: ABILITY.can("read", subject("class_notes", { centreId: CLASS_INFO.centreId, students: CLASS_INFO.students })) },
    ];

    let TAB_ELEMENTS = ALL_TAB.filter(element => element.permission == true);

    let { classId } = useParams();
    let [searchParams] = useSearchParams();
    const CLASS_REQUEST = useClassesManagementState().request;
    const SEARCH_PARAM_TAB = searchParams.get("tab") || "about";
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const debouncedGetClassProfileInfo = React.useMemo(() => debounce(getClassInfo, 50), []);

    const ACTION_IN_CHECK = {
        "GET_CLASS_INFO": true,
        "REMOVE_STUDENT_FROM_CLASS": true,
        "APPLY_CLASS": true
    };

    React.useEffect(() => {
        if (classId) {
            debouncedGetClassProfileInfo({ classId: classId });
        }
    }, []);


    React.useEffect(() => {
        if (classId) {
            debouncedGetClassProfileInfo({ classId: classId });
        }
    }, [classId]);

    React.useEffect(() => {

        if (ACTION_IN_CHECK[CLASS_REQUEST.lastAction]) {
            if (CLASS_REQUEST.status === "REQUEST_OBJECT_NOT_FOUND") {
                pushRedirect({ path: "/" });
            }
            if (CLASS_REQUEST.status === "REFRESH_DATA_REQUIRE") {
                debouncedGetClassProfileInfo({ classId: classId });
            }
        }
    }, [CLASS_REQUEST]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item  xs={12} md={11} lg={10} xl={8}>
                        <Row container item xs={12}>
                            <ClassPageHeader TAB_ELEMENTS={TAB_ELEMENTS}
                                value={value} setValue={setValue} />
                        </Row>
                        <Grid sx={{ marginTop: 2 }} container item xs={12} md={12}>
                            {
                                TAB_ELEMENTS.map(
                                    (element, index) => (
                                        <TabPanel key={`element_${index}`} value={value} index={element.tab_index}>
                                            {element.tab_element}
                                        </TabPanel>
                                    )
                                )
                            }
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
        </React.Suspense>
    );
}
