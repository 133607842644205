import React from "react";
import {
    Typography, Grid,
    IconButton, Paper, Stack,
    Tabs, Tab,
    Breadcrumbs, Link
} from "@mui/material";
import { useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { subject } from "@casl/ability";
import { TabContainer } from "./../../../../component/common/Containers";
import { SpaceHolder } from "./../../../../component/common/Others";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAuthenticationState } from "./../../../../redux/authentication";
import { useClassSessionManagementState, deleteClassSession, confirmClassSession } from "./../../../../redux/ClassSessionManagement";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import { Can } from "./../../../../casl/authorization";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmActionDialog from "./../../../../component/common/ConfirmActionDialog";
import ClassSessionDialog from "./../../../page/class/dialog/ClassSessionDialog";

const LogoContainer = styled(Grid)(({ theme }) => ({
    "paddingBottom": theme.spacing(1),
    "paddingRight": theme.spacing(2)
}));

const ButtonGrid = styled(Grid)(({ theme }) => ({

}));


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ShowInStatus({ children, status }) {
    const CLASS_SESSION_INFO = useClassSessionManagementState().currentSession || {};

    if (CLASS_SESSION_INFO.status == status) {
        return children;
    }
    return null;
}

function Buttons({ triggerClassMenu }) {
    const CLASS_INFO_SESSION = useClassSessionManagementState().currentSession || {};
    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;

    return (
        <React.Fragment>
            {
                ABILITY.can("publish", subject("class_session", CLASS_INFO_SESSION)) ||
                    ABILITY.can("delete", subject("class_session", CLASS_INFO_SESSION)) ?
                    (<IconButton
                        sx={{
                            marginTop: 2,
                            backgroundColor: "#DDDDDD",
                        }}
                        color="primary"
                        aria-label="upload picture" component="label"
                        onClick={triggerClassMenu}
                    >
                        <MoreHorizIcon />
                    </IconButton>) : null
            }
        </React.Fragment>
    );
}

export default function ClassSessionPageHeader({
    TAB_ELEMENTS, value, setValue }) {
    const { t } = useTranslation(["general","session"]);
    let { sessionId } = useParams();
    const CLASS_SESSION_INFO = useClassSessionManagementState().currentSession || {};
    const CLASS = CLASS_SESSION_INFO.class || {};
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);

    const LOCATION = useLocation();
    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;


    const triggerEditDialog = (event) => {
        event.preventDefault();
        setEditDialogOpen(true);
    }

    const handleTabsChange = (event, newValue) => {
        setValue(newValue);
        navigate({
            pathname: ("/class_session/" + sessionId),
            search: createSearchParams({
                tab: newValue
            }).toString(),
        }, { state: LOCATION.state });
    };

    const confirmSession = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        confirmClassSession({ classId: CLASS_SESSION_INFO.classId, id: CLASS_SESSION_INFO._id });
    }

    const deleteSession = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    const triggerDeleteClassSession = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        deleteClassSession({ classId: CLASS_SESSION_INFO.classId, id: CLASS_SESSION_INFO._id });
    }

    const triggerClassMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    const navigate = useNavigate();

    const navigateToClass = () => {
        navigate("/class/" + CLASS_SESSION_INFO.classId);
    }

    return (
        <React.Fragment>
            <Paper sx={{ width: "100%", borderRadius: "10px" }}>
                <Grid container item sm={12} xs={11.5}>
                    <SpaceHolder container item sm={0.5} xs={0.5} />
                    <Grid sx={{ marginTop: 3 }} container item sm={11} xs={11.5}>
                        <LogoContainer container alignItems="flex-start" item xs={11} sm={10}
                            justifyContent="flex-start"  >
                            <Stack direction="row" justifyContent="flex-start"
                                alignItems="center" spacing={2}>
                                <Stack spacing={1}>
                                    <Stack alignItems="center" direction="row">
                                        <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>{t("session:session_name", { date: moment(CLASS_SESSION_INFO.date).format('ll') })}</Typography>
                                        <Can I="update" this={subject("class_session", CLASS_SESSION_INFO)} field="name" ability={ABILITY} >
                                            <IconButton
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: "#DDDDDD",
                                                }}
                                                color="primary"
                                                aria-label="upload picture" component="label"
                                                onClick={triggerEditDialog}
                                            >
                                                <EditIcon sx={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Can>
                                    </Stack>
                                    <Breadcrumbs sx={{ display: { xs: "block", sm: "block" }, cursor: "pointer"  }} separator={<NavigateNextIcon fontSize="small" />}>
                                        <Link underline="hover" onClick={navigateToClass} color="secondary">
                                            <Typography color="secondary">{CLASS.name}</Typography>
                                        </Link>
                                        <Typography color="secondary">{t("session:page_title-" + CLASS_SESSION_INFO.status)}</Typography>
                                    </Breadcrumbs>
                                </Stack>
                            </Stack>
                        </LogoContainer>
                        <ButtonGrid container alignItems="flex-start" justifyContent="right"
                            item xs={1} sm={2}>
                            <Buttons triggerClassMenu={triggerClassMenu} />
                        </ButtonGrid>
                        <Grid container item sm={12}>
                            <TabContainer>
                                <Tabs value={value} onChange={handleTabsChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    aria-label="class_settings_tabs">
                                    {
                                        TAB_ELEMENTS.map(
                                            (element, index) => <Tab sx={{
                                                textTransform: "none"
                                            }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                        )
                                    }
                                </Tabs>
                            </TabContainer>
                        </Grid>
                    </Grid>
                    <SpaceHolder container item sm={0.5} />
                </Grid>
            </Paper>
            <ContextualMenu id={"admin-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
                <ShowInStatus status="pending" >
                    <ContextualMenuItem
                        permission={{
                            I: "confirm",
                            this: subject("class_session", CLASS_SESSION_INFO),
                            ability: ABILITY
                        }}
                        onClick={confirmSession}
                        title={t("general:button-publish")}>
                        <CheckCircleOutlineIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    <ContextualMenuItem
                        permission={{
                            I: "delete",
                            this: subject("class_session", CLASS_SESSION_INFO),
                            ability: ABILITY
                        }}
                        onClick={deleteSession}
                        title={t("general:button-delete")}>
                        <CancelIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ShowInStatus>
                <ShowInStatus status="active" >
                    <ContextualMenuItem
                        permission={{
                            I: "delete",
                            this: subject("class_session", CLASS_SESSION_INFO),
                            ability: ABILITY
                        }}
                        onClick={deleteSession}
                        title={t("general:button-delete_entity",{entity:t("attribute:session").toLowerCase()})}>
                        <DeleteIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ShowInStatus>
            </ContextualMenu>
            <ClassSessionDialog
                title={t("general:dialog_title-edit_entity_info", { entity: t("attribute:session") })}
                currentSession={CLASS_SESSION_INFO}
                isDialogOpen={editDialogOpen}
                setIsDialogOpen={setEditDialogOpen} />
            <ConfirmActionDialog
                title={t("general:dialog_title-delete_entity", { entity: t("attribute:session") })}
                actionName={t("general:button-delete")}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                confirmOnClick={triggerDeleteClassSession}
            />
        </React.Fragment >
    )
}