import React from "react";
import {useTranslation} from "react-i18next";
import {Toolbar, Typography,Tooltip,IconButton} from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { alpha } from '@mui/material/styles';

export default function EnhancedTableToolbar({ numSelected, markAsPresentHandler, markAsAbsentHandler }) {
	const { t } = useTranslation();
	if (numSelected > 0) {
		return (
			<Toolbar
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						{numSelected} selected
					</Typography>
				) : null}
				{numSelected > 0 ? (
					<React.Fragment>
						Attendance:
						<Tooltip title={t("session:button-mark_as_present")}>
							<IconButton onClick={markAsPresentHandler}>
								<TaskAltIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title={t("session:button-mark_as_absent")}>
							<IconButton onClick={markAsAbsentHandler}>
								<HighlightOffIcon />
							</IconButton>
						</Tooltip>
					</React.Fragment>
				) : null}
			</Toolbar>
		);
	}
	return null;
}