import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
	Paper, Grid, Typography, IconButton
} from "@mui/material";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from '@mui/icons-material/Visibility';

import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';

import { subject } from "@casl/ability";

import { getCentreInfo, useCentreManagementState } from "./../../../redux/CentreManagement";
import { getStudentBill, deleteStudentBill, markAsPaidStudentBill, useStudentBillManagementState } from "./../../../redux/StudentBillManagement";
import { useProfileState } from "./../../../redux/ProfileManagement";
import { useAuthenticationState } from "./../../../redux/authentication";

import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import ContextualMenu from "./../../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../../component/common/ConfirmActionDialog";

import PayBillDilaog from "./../bill/dialog/PayBillDialog";
import ClassBillDialog from "./../class/dialog/ClassBillDialog";
import StudentViewBillDialog from "./dialog/StudentViewBillDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 900
}));

export default function StudentBillPage() {
	const { t } = useTranslation(["bill", "centre", "general"]);

    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;

	const CENTRE = useCentreManagementState().currentCentre;
	const STUDENT_PROFILE_INFO = useProfileState().studentProfileInfo || {};

	const STUDENT_BILLS = useStudentBillManagementState().studentBills || [];
	const STUDENT_BILLS_COUNT = useStudentBillManagementState().studentBillsCount || 0;
	const STUDENT_BILLS_REQUEST = useStudentBillManagementState().request;

	const [selectedBill, setSelectedBill] = React.useState(null);
	const [billAnchorEl, setBillAnchorEl] = React.useState(null);

	const [viewBillDialogOpen, setViewBillDialogOpen] = React.useState(false);

	const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = React.useState(false);
	const [editBillDialogOpen, setEditBillDialogOpen] = React.useState(false);
	const [deleteBillDialogOpen, setDeleteBillDialogOpen] = React.useState(false);
	const [payBillDialogOpen, setPayBillDialogOpen] = React.useState(false);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [searchString, setSearchString] = React.useState(null);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const toggleBillContextualMenu = (event, billFromOnClick) => {
		event.preventDefault();
		setSelectedBill(billFromOnClick);
		setBillAnchorEl(event.currentTarget);
	};

	const openViewBillDialog = (event) => {
		event.preventDefault();
		setBillAnchorEl(false);
		setViewBillDialogOpen(true);
	}

	
	
	const triggerMarkAsPaidDialog = (event) => {
		event.preventDefault();
		setBillAnchorEl(false);
		setMarkAsPaidDialogOpen(true);
	}

	const openEditBillDialog = (event) => {
		event.preventDefault();
		setBillAnchorEl(false);
		setEditBillDialogOpen(true);
	}

	const triggerDeleteBillDialog = (event) => {
		event.preventDefault();
		setBillAnchorEl(false);
		setDeleteBillDialogOpen(true);
	}

	const openPayBillDialog = (event) => {
		event.preventDefault();
		setBillAnchorEl(false);
		setPayBillDialogOpen(true);
	}

	const confirmDeleteStudentBill = (event) => {
		setDeleteBillDialogOpen(false);
		deleteStudentBill({ billId: selectedBill._id });
	}

	const confirmMarkAsPaidStudentBill = (event) => {
		setMarkAsPaidDialogOpen(false);
		markAsPaidStudentBill({ billId: selectedBill._id });
	}

	let { enrolmentId } = useParams();

	let deboundedGetStudentBill = React.useMemo(() => debounce(getStudentBill, 50), []);
	
	const ACTION_IN_CHECK = {
        "UPDATE_STUDENT_BILL": true,
        "DELETE_STUDENT_BILL": true,
        "MARK_AS_PAID_STUDENT_BILL": true
    };

  	React.useEffect(() => {
        if (ACTION_IN_CHECK[STUDENT_BILLS_REQUEST.lastAction]) {
            if (STUDENT_BILLS_REQUEST.status === "REFRESH_DATA_REQUIRE") {
                deboundedGetStudentBill({
					enrolmentId: enrolmentId,
					searchCriteria: {
						search: searchString, page: page, rowsPerPage: rowsPerPage
					}
				});
            }
        }
    }, [STUDENT_BILLS_REQUEST]);

	React.useEffect(() => {
		getCentreInfo({ centreId: CENTRE._id });
		deboundedGetStudentBill({
			enrolmentId: enrolmentId,
			searchCriteria: {
				search: searchString, page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [])

	React.useEffect(() => {
		getCentreInfo({ centreId: CENTRE._id });
		deboundedGetStudentBill({
			enrolmentId: enrolmentId,
			searchCriteria: {
				search: searchString, page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [enrolmentId, searchString, page, rowsPerPage])

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-bills")}</Typography>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>

			<TableContainer sx={{ borderRadius: "10px", }} component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow  >
							<BoldTableCell >{t("attribute:class")}</BoldTableCell>
							<BoldTableCell >{t("attribute:bill-due_date")}</BoldTableCell>
							<BoldTableCell >{t("attribute:bill-status")}</BoldTableCell>
							<BoldTableCell align="right"></BoldTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{STUDENT_BILLS.map((row) => {
							let rowStatus = t("attribute:bill-status-"+row.status);
							if(row.status =="unpaid" && new Date(row.dueDate) < new Date() ){
								rowStatus = t("attribute:bill-status-due");
							}
							return (
								<TableRow key={row._id}>
									<TableCell component="th" scope="row">
										{row.class.name}
									</TableCell>
									<TableCell >{moment(row.dueDate).format("LL")}</TableCell>
									<TableCell >{rowStatus}</TableCell>
									<TableCell align="right">
										<IconButton onClick={(event) => { toggleBillContextualMenu(event, row) }} aria-label="delete">
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={t("general:label-rows_per_page")}
					rowsPerPageOptions={[1, 5, 10, 25]}
					component="div"
					count={STUDENT_BILLS_COUNT}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>

			{selectedBill ? (
			<>
			<ContextualMenu id={"admin-menu"} anchorEl={billAnchorEl} setAnchorEl={setBillAnchorEl}>
				<ContextualMenuItem
					onClick={openViewBillDialog}
					title={t("general:button-view_download")}>
					<VisibilityIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					onClick={openPayBillDialog}
					permission={{
						I: "pay",
						this: subject("bill", { "centreId": selectedBill.centreId,studentId:selectedBill.studentId, "status": selectedBill.status }),
						ability: ABILITY
					}}
					title={t("bill:button-pay_bill")}>
					<PaymentIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				{ selectedBill.status == "unpaid" ? (
				<>
				<ContextualMenuItem
					permission={{
						I: "update",
						this: subject("bill", { "centreId": selectedBill.centreId, "status": selectedBill.status }),
						ability: ABILITY
					}}
					onClick={triggerMarkAsPaidDialog}
					title={t("bill:button-mark_as_paid")}>
					<CheckIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					permission={{
						I: "update",
						this: subject("bill", { "centreId": selectedBill.centreId, "status": selectedBill.status }),
						ability: ABILITY
					}}
					onClick={openEditBillDialog}
					title={t("general:button-edit")}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					permission={{
						I: "delete",
						this: subject("bill", { "centreId": selectedBill.centreId, "status": selectedBill.status }),
						ability: ABILITY
					}}
					onClick={triggerDeleteBillDialog}
					title={t("general:button-delete")}>
					<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				</>
				) : null }
			</ContextualMenu>
			<StudentViewBillDialog
                	bill={selectedBill}
					currentClassId={selectedBill.classId}
					student={STUDENT_PROFILE_INFO}
                	isDialogOpen={viewBillDialogOpen}
                	setIsDialogOpen={setViewBillDialogOpen} 
					 />
			<ClassBillDialog
					currentBill={selectedBill}
					currentClassId={selectedBill.classId}
                	isDialogOpen={editBillDialogOpen}
                	setIsDialogOpen={setEditBillDialogOpen} />
			</>
			): null }
			<PayBillDilaog
            		bill={selectedBill}
            		isDialogOpen={payBillDialogOpen}
            		setIsDialogOpen={setPayBillDialogOpen}
            />
			<ConfirmActionDialog
				title={t("general:dialog_title-delete_entity", { entity: t("attribute:bill") })}
				actionName={t("general:button-delete")}
				open={deleteBillDialogOpen}
				setOpen={setDeleteBillDialogOpen}
				confirmOnClick={confirmDeleteStudentBill}
			/>

			<ConfirmActionDialog
				title={t("bill:dialog_title-mark_as_paid")}
				actionName={t("bill:button-mark_as_paid")}
				open={markAsPaidDialogOpen}
				setOpen={setMarkAsPaidDialogOpen}
				confirmOnClick={confirmMarkAsPaidStudentBill}
			/>
		</React.Fragment >
	);
}