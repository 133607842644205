import React from 'react';
import debounce from 'lodash.debounce';
import {
    TextField, InputAdornment, Autocomplete,
    FormControl, Divider,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Grid, Button, Stack,
    Collapse, Pagination,
    IconButton
} from "@mui/material";
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, FormProvider } from "react-hook-form";
import LoadingPage from "./LoadingPage";
import { createAddCentreFormSchema, INITIAL_CENTRE_FORM } from "./../form/model/CentreFormModel"
import CentreCard from "./../page/centre/CentreCard";
import FilterListIcon from '@mui/icons-material/FilterList';
import { SuccessButton } from "./../../component/common/Buttons";
import { DialogFixedForm } from "./../../component/common/Forms";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer } from "./../../component/common/Containers";
import CentreFieldList from "./../../component/form/centreComponent/CentreFieldList";
import { useOptionsState } from "./../../redux/Options";
import { useAuthenticationState } from "./../../redux/authentication";
import { addCentre, getCentres, useCentreManagementState } from "./../../redux/CentreManagement";
import { convertOptionsForAutoComplete } from "./../../util/AutoCompleteUtil";
import GeneralHeader from "./../../component/common/GeneralHeader";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const NarrowRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

export default function CentreList() {

    const { t } = useTranslation();
    const [searchString, setSearchString] = React.useState(null);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const [districtFilterValue, setDistrictFilterValue] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 10;
    const OPTIONS_STATE = useOptionsState();

    const OPTIONS = {
        DISTRICT: OPTIONS_STATE.district.options
    };

    const VALID_VALUES = {
        DISTRICT: OPTIONS_STATE.district.validValues
    };

    const updateSearchString = (event) => {
        event.preventDefault();
        setSearchString(event.target.value);
    };

    const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

    const ADD_CENTRE_FORM = useForm({
        resolver: yupResolver(createAddCentreFormSchema(VALID_VALUES.DISTRICT)),
        defaultValue: INITIAL_CENTRE_FORM
    });
    const CENTRES = useCentreManagementState().centres || [];
    const CENTRE_COUNT = useCentreManagementState().centreCount;
    const CENTRE_FILTER_FORM = useForm({ districtFilter: [] });
    const ABILITY = useAuthenticationState().ability;

    const openAddCentreDialog = (event) => {
        event.preventDefault();
        setIsDialogOpen(true);
    }

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setIsDialogOpen(false);
    }

    const toggleFilter = (event) => {
        event.preventDefault();
        setIsFilterOpen((prev) => !prev);
    }

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setDistrictFilterValue(newValue);
    };


    const dialogAddCentre = (data) => {
        addCentre({ centre: data });
        setIsDialogOpen(false);
    }


    let deboundedGetCentres = React.useMemo(() => debounce(getCentres, 50), []);


    React.useEffect(() => {
        deboundedGetCentres({ search: searchString, district: districtFilterValue, page: page, rowsPerPage: rowsPerPage });
    }, []);


    React.useEffect(() => {
        deboundedGetCentres({ search: searchString, district: districtFilterValue, page: page, rowsPerPage: rowsPerPage });
    }, [page, searchString, districtFilterValue, rowsPerPage]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <GeneralHeader title={t("centre:page_title-list")}
                            displayButton={ABILITY.can("add", "centre")}
                            onClickHandler={openAddCentreDialog} />
                        <Row container item md={12}>
                            <Stack sx={{ width: "100%" }} spacing={1}>
                                <TextField
                                    id="outlined-start-adornment"
                                    fullWidth
                                    onChange={(event) => { searchFieldOnChange(event) }}
                                    placeholder={t("general:label-search")}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                <IconButton
                                                    name="filterToggle"
                                                    aria-label="toggle filter"
                                                    onClick={toggleFilter}
                                                    onMouseDown={(event) => { event.preventDefault() }}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />

                                <Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
                                    <Stack >
                                        <StyledFormControl fullWidth variant="outlined">
                                            <Controller
                                                name="districtFilter"
                                                defaultValue={districtFilterValue}
                                                control={CENTRE_FILTER_FORM.control}
                                                render={
                                                    ({ field }) =>
                                                        <Autocomplete
                                                            multiple
                                                            id="centre_district_filter"
                                                            options={convertOptionsForAutoComplete(OPTIONS.DISTRICT)}
                                                            getOptionLabel={(option) => t(`attribute:${option}`)}
                                                            defaultValue={districtFilterValue}
                                                            value={districtFilterValue}
                                                            onChange={handleChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    label={t("attribute:centre-district")}
                                                                    placeholder={t("attribute:centre-district")}
                                                                />
                                                            )}
                                                        />
                                                }
                                            />
                                        </StyledFormControl>
                                    </Stack>
                                </Collapse>

                            </Stack>
                            <NarrowRow container item md={12} >
                                <Grid container justifyContent="center" item sm={12} md={12}>
                                    {CENTRES.map(
                                        (currentCentre) =>
                                            <Grid key={currentCentre._id + currentCentre.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
                                                <CentreCard key={currentCentre._id + currentCentre.displayName} currentCentre={currentCentre} />
                                            </Grid>)
                                    }
                                    <Pagination
                                        onChange={(event, pageNumber) => {
                                            event.preventDefault();
                                            setPage(pageNumber - 1);
                                        }}
                                        count={Math.ceil(CENTRE_COUNT / rowsPerPage)}
                                    />
                                </Grid>
                            </NarrowRow>

                        </Row>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isDialogOpen}
                onClose={handleCloseDialog}
                scroll="paper"
                aria-labelledby="centreAdmin-dialog-title"
                aria-describedby="centreAdmin-dialog-description"
            >
                <DialogFixedForm
                    onSubmit={ADD_CENTRE_FORM.handleSubmit(dialogAddCentre)} noValidate>
                    <DialogTitle id="centreAdmin-dialog-title">
                        {t("general:dialog_title-add_entity", { entity: t("attribute:centre") })}
                    </DialogTitle>
                    <DialogContent dividers>
                        <FormProvider {...ADD_CENTRE_FORM}>
                            <CentreFieldList showLabel />
                        </FormProvider>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button onClick={handleCloseDialog} variant="outlined" autoFocus>
                                {t("general:button-cancel")}
                            </Button>
                            <SuccessButton type="onSubmit" variant="contained" >
                                {t("general:button-add")}
                            </SuccessButton>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </Dialog>
        </React.Suspense>
    );
}
