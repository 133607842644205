import React from 'react';
import { useTranslation } from "react-i18next";
import {
	Grid, IconButton, Tooltip, Box
} from "@mui/material";
import { SpaceHolder } from "./../common/Others";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CanUpdateCentre from "./../../component/acl/CanUpdateCentre";
export default function CarouselImage({ value, triggerImagesMenu, centreId }) {
	const { t } = useTranslation("general");

	return (
		<React.Fragment>
			<Box key={value.filename + "_container"}
				sx={{
					display: { xs: "block", "sm": "block" },
					maxHeight: { xs: "200px", sm: "300px" }
				}}>
				<CanUpdateCentre centreId={centreId} >
					<Grid style={{
						position: "absolute",
						top: "0px",
						"right": "0px",
						"zIndex": "15",
					}} container item xs={12} sm={12} >
						<SpaceHolder container item xs={0.5} sm={0.5} />
						<Grid justifyContent="flex-end" container item xs={11} sm={11} >
							<Tooltip title={t("general:button-more")}>
								<IconButton
									onClick={(event) => { triggerImagesMenu(event, value) }}
									sx={{
										'&:hover': {
											backgroundColor: "#AAAAAA",
											opacity: "1"
										},
										marginTop: 2,
										backgroundColor: "#AAAAAA",
										opacity: "0.5"
									}} aria-label="more">
									<MoreHorizIcon />
								</IconButton>
							</Tooltip>
						</Grid>
						<SpaceHolder container item xs={0.5} sm={0.5} />
					</Grid>
				</CanUpdateCentre >
				<Box component="img" alt={value.filename} sx={{
					height: { xs: "200px", sm: "400px" },
					width: "100%",
					objectFit: "cover",
					objectPosition: "centre",
					"MozUserSelect": "none",
					"KhtmlUserSelect": "none",
					"WebkitUserSelect": "none",
					"msUserSelect": "none",
					"userSelect": "none",
					borderRadius: "10px 10px 0px 0px"
				}} src={value.src} />
			</Box>
		</React.Fragment>
	);
}
