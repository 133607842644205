import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import LimitedTextLabel from "./../../component/display/LimitedTextLabel";
import {
	Grid, Typography
} from "@mui/material";
const AttributeLabel = styled(Typography)(({ theme }) => ({
	color: "#BBBBBB",
	fontWeight: "bold"
}));

function SubjectDisplayer({ variant, content }) {
	const { t } = useTranslation("attribute", "general");
	const SEPARATOR = t("general:separator");
	if (content && typeof content != "string") {
		if (content.length == 0) {
			return null;
		}
		if (content.length == 1) {
			return (<Typography variant={variant} >{t(content, { ns: "attribute" })}</Typography>);
		} else if (content.length > 1) {
			let joinedString = content.map(
				(currentString, index) => (t(currentString, { ns: "attribute" }))
			).join(SEPARATOR);
			let result = (
				<LimitedTextLabel linelimited={20} variant={variant}>
					{
						joinedString
					}
				</LimitedTextLabel>
			)
			return (<Grid container>{result}</Grid>);
		}
	}
	return (<Grid container>{t(content, { ns: "attribute" })}</Grid>);

}

export default function ContentRow({ content, title, needI18Nhandle }) {
	return (<Grid sx={{ marginBottom: 3 }} alignItems="center" justifyContent="space-between" container item xs={12}>
		{title ? (<Grid alignItems="center" container item xs={12}>
			<AttributeLabel component="div">
				{title}
			</AttributeLabel>
		</Grid>) : null}
		{needI18Nhandle ?
			(<SubjectDisplayer variant="body1" content={content} />) :
			(<Grid alignItems="center" justifyContent="space-between" container item xs={12}>
				<Typography sx={{ width: "100%", whiteSpace: "pre-wrap", wordWrap: "break-word" }} variant="body1" component="pre">
					{content}
				</Typography>
			</Grid>
			)}
	</Grid>)
}