import React from 'react';
import { FormControl, FormLabel, TextField, FormHelperText } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import { Controller } from "react-hook-form";
import { CENTRE_PROFILE_FIELD } from "./../model/CentreFormModel";
import { useOptionsState } from "./../../../redux/Options";
import { useFormContext } from "react-hook-form";
import SectionLabel from "./../../display/styled/SectionLabel";
import DropdownItems from "./../commonComponent/DropdownItems";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

export default function CentrePublicFieldsSection({ currentCentre, showLabel }) {

    const {
        t
    } = useTranslation("attribute");
    const OPTIONS = {
        DISTRICT: useOptionsState().district.options
    };
    const { control, formState: { errors } } = useFormContext();

    return (
        <React.Fragment>
            {showLabel ? (<SectionLabel>{t("centre:section_title-public_info")}</SectionLabel>
            ) : null}
            <Controller
                name={CENTRE_PROFILE_FIELD.displayName}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:centre-display_name")}
                        helperText={errors[CENTRE_PROFILE_FIELD.displayName] ? t(errors[CENTRE_PROFILE_FIELD.displayName].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.displayName] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_PROFILE_FIELD.district}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="districtSelect"
                        error={errors[CENTRE_PROFILE_FIELD.district] != null}>
                        <DropdownItems field={field}
                            options={OPTIONS.DISTRICT}
                            labelId="centre-district-label"
                            labelText={t("attribute:centre-district")} />
                        {errors[CENTRE_PROFILE_FIELD.district] != null &&
                            <FormHelperText>{t(errors[CENTRE_PROFILE_FIELD.district].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />
            <Controller
                name={CENTRE_PROFILE_FIELD.address}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:centre-address")}
                        helperText={errors[CENTRE_PROFILE_FIELD.address] ? t(errors[CENTRE_PROFILE_FIELD.address].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.address] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_PROFILE_FIELD.phone}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:centre-phone_number")}
                        helperText={errors[CENTRE_PROFILE_FIELD.phone] ? t(errors[CENTRE_PROFILE_FIELD.phone].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.phone] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_PROFILE_FIELD.openinghour}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:centre-opening_hours")}
                        helperText={errors[CENTRE_PROFILE_FIELD.openinghour] ? t(errors[CENTRE_PROFILE_FIELD.openinghour].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.openinghour] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={CENTRE_PROFILE_FIELD.description}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:centre-description")}
                        helperText={errors[CENTRE_PROFILE_FIELD.description] ? t(errors[CENTRE_PROFILE_FIELD.description].message) : null}
                        error={errors[CENTRE_PROFILE_FIELD.description] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
