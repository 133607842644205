import React from 'react';
import { FormControl, TextField, FormHelperText } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from "react-hook-form";
import { STUDENT_PROFILE_FIELD } from "./../model/StudentFormModel";
import { useOptionsState } from "./../../../redux/Options";
import { useFormContext } from "react-hook-form";
import DropdownItems from "./../commonComponent/DropdownItems";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

export default function StudentFieldList({ currentStudent }) {

    const {
        t
    } = useTranslation("attribute");
    const OPTIONS = {
        GENDER: useOptionsState().gender.options,
        GRADE: useOptionsState().grade.options,
        ALIAS: useOptionsState().alias.options,
        RELATIONSHIP: useOptionsState().relationship.options,
        DISTRICT: useOptionsState().district.options
    };
    const { control, formState: { errors }, setValue, reset } = useFormContext();

    React.useEffect(() => {
        if (currentStudent) {
            let contactPersons = {};
            if (currentStudent.contactPerson) {
                for (let i = 0; i < currentStudent.contactPerson.length; i++) {
                    contactPersons[STUDENT_PROFILE_FIELD[`contactPerson${i + 1}Name`]] = currentStudent.contactPerson[i].name;
                    contactPersons[STUDENT_PROFILE_FIELD[`contactPerson${i + 1}Contact`]] = currentStudent.contactPerson[i].phone;
                }
            }
            let studentForResetValue = Object.assign({}, currentStudent, contactPersons)
            reset(studentForResetValue)
        }
    }, [currentStudent])

    return (
        <React.Fragment>
            <Controller
                name={STUDENT_PROFILE_FIELD.engName}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-name-english")}
                        helperText={errors[STUDENT_PROFILE_FIELD.engName] ? t(errors[STUDENT_PROFILE_FIELD.engName].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.engName] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={STUDENT_PROFILE_FIELD.chiName}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-name-chinese")}
                        helperText={errors[STUDENT_PROFILE_FIELD.chiName] ? t(errors[STUDENT_PROFILE_FIELD.chiName].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.chiName] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.gender}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="genderSelect"
                        error={errors[STUDENT_PROFILE_FIELD.gender] != null}>
                        <DropdownItems field={field}
                            options={OPTIONS.GENDER}
                            labelId="guardian-gender-label"
                            labelText={t("attribute:student-gender")} />
                        {errors[STUDENT_PROFILE_FIELD.gender] != null &&
                            <FormHelperText>{t(errors[STUDENT_PROFILE_FIELD.gender].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />
            
            <Controller
                name={STUDENT_PROFILE_FIELD.birthday}
                defaultValue={""}
                control={control}
                render={({field}) => (
                    <DatePicker
                        {...field}
                        fullWidth
                        disableFuture
                        openTo="year"
                        views={["year", "month", "day"]}
                        id={STUDENT_PROFILE_FIELD.birthday}
                        label={t("attribute:student-birthday")}
                        error={errors[STUDENT_PROFILE_FIELD.birthday] != null}
                        format="DD/MM/yyyy"
                        onChange={(newValue) => {
                            setValue(STUDENT_PROFILE_FIELD.birthday, newValue);
                        }}
                        renderInput={(params) => <StyledTextField 
                            {...params} 
                            fullWidth
                            error={errors[STUDENT_PROFILE_FIELD.birthday]}
                            helperText={errors[STUDENT_PROFILE_FIELD.birthday] ? t(errors[STUDENT_PROFILE_FIELD.birthday].message) : null}
                            />}
                    />
                )}
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.school}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        helperText={errors[STUDENT_PROFILE_FIELD.school] ? t(errors[STUDENT_PROFILE_FIELD.school].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.school] != null}
                        label={t("attribute:student-school")}
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.grade}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="gradeSelect"
                        error={errors[STUDENT_PROFILE_FIELD.grade] != null}>
                        <DropdownItems field={field}
                            options={OPTIONS.GRADE}
                            labelId="guardian-grade-label"
                            labelText={t("attribute:student-grade")} />
                        {errors[STUDENT_PROFILE_FIELD.grade] != null &&
                            <FormHelperText>{t(errors[STUDENT_PROFILE_FIELD.grade].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.district}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledFormControl fullWidth variant="outlined"
                        data-cy="districtSelect"
                        error={errors[STUDENT_PROFILE_FIELD.district] != null}>
                        <DropdownItems field={field}
                            options={OPTIONS.DISTRICT}
                            labelId="guardian-district-label"
                            labelText={t("attribute:student-district")} />
                        {errors[STUDENT_PROFILE_FIELD.district] != null &&
                            <FormHelperText>{t(errors[STUDENT_PROFILE_FIELD.district].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.remarks}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:student-remarks")}
                        helperText={errors[STUDENT_PROFILE_FIELD.remarks] ? t(errors[STUDENT_PROFILE_FIELD.remarks].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.remarks] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.guardian_alias}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) =>
                        <StyledFormControl fullWidth variant="outlined"
                            data-cy="aliasSelect"
                            error={errors[STUDENT_PROFILE_FIELD.guardian_alias] != null}>
                            <DropdownItems field={field}
                                options={OPTIONS.ALIAS}
                                labelId="guardian-alias-label"
                                labelText={t("attribute:student-guardian-alias")} />
                            {errors[STUDENT_PROFILE_FIELD.guardian_alias] != null &&
                                <FormHelperText>{t(errors[STUDENT_PROFILE_FIELD.guardian_alias].message)}</FormHelperText>
                            }
                        </StyledFormControl>
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.guardian_name}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-guardian-name")}
                        helperText={errors[STUDENT_PROFILE_FIELD.guardian_name] ? t(errors[STUDENT_PROFILE_FIELD.guardian_name].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.guardian_name] != null}
                        {...field}
                    />
                }
            />


            <Controller
                name={STUDENT_PROFILE_FIELD.guardian_relationship}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledFormControl
                        fullWidth variant="outlined"
                        data-cy="relationshipSelect"
                        error={errors[STUDENT_PROFILE_FIELD.guardian_relationship] != null}>
                        <DropdownItems field={field}
                            options={OPTIONS.RELATIONSHIP}
                            labelId="guardian-relationship-label"
                            labelText={t("attribute:student-guardian-relationship")} />
                        {errors[STUDENT_PROFILE_FIELD.guardian_relationship] != null &&
                            <FormHelperText>{t(errors[STUDENT_PROFILE_FIELD.guardian_relationship].message)}</FormHelperText>
                        }
                    </StyledFormControl>
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.guardian_phone}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-guardian-phone")}
                        helperText={errors[STUDENT_PROFILE_FIELD.guardian_phone] ? t(errors[STUDENT_PROFILE_FIELD.guardian_phone].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.guardian_phone] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.contactPerson1Name}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-emergency_contact_1-name")}
                        helperText={errors[STUDENT_PROFILE_FIELD.contactPerson1Name] ? t(errors[STUDENT_PROFILE_FIELD.contactPerson1Name].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.contactPerson1Name] != null
                        }
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.contactPerson1Contact}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-emergency_contact_1-phone")}
                        helperText={errors[STUDENT_PROFILE_FIELD.contactPerson1Contact] ? t(errors[STUDENT_PROFILE_FIELD.contactPerson1Contact].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.contactPerson1Contact] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.contactPerson2Name}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-emergency_contact_2-name")}
                        helperText={errors[STUDENT_PROFILE_FIELD.contactPerson2Name] ? t(errors[STUDENT_PROFILE_FIELD.contactPerson2Name].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.contactPerson2Name] != null}
                        {...field}
                    />
                }
            />

            <Controller
                name={STUDENT_PROFILE_FIELD.contactPerson2Contact}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:student-emergency_contact_2-phone")}
                        helperText={errors[STUDENT_PROFILE_FIELD.contactPerson2Contact] ? t(errors[STUDENT_PROFILE_FIELD.contactPerson2Contact].message) : null}
                        error={errors[STUDENT_PROFILE_FIELD.contactPerson2Contact] != null}
                        {...field}
                    />
                }
            />

        </React.Fragment>
    );
}
