import React, { useEffect } from 'react';
import { Grid, Typography, Paper, Button, IconButton, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import siteIcon from "./../../asset/img/site_icon.png";
import LanguageSwitcher from "./../I18N/LanguageSwitcher";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuthenticationState, recoverPassword, setUpdating } from "./../../redux/authentication";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingPage from "./../page/LoadingPage";
import {NeedHelp,SiteIcon,ContactUs,PageTitle} from "./../../component/common/Others";
import {LogoutArrow} from "./../../component/common/Containers";
import { NonDashboardRootContainer } from "./../../component/common/Containers";

const ContentGrid = styled(Grid)(({ theme }) => ({
  "marginTop": "10px",
  "textAlign": "center"
}));

const CentreAlignedContainer = styled(Grid)(({ theme }) => ({
  display: "grid",
  placeItems: "center"
}));

const ContentContainer = styled(Grid)(({ theme }) => ({
  "paddingLeft": "10px",
  "paddingRight": "10px",
  "marginBottom": "35px",
  display: "grid",
  placeItems: "center"
}));

const Title = styled(Grid)(({ theme }) => ({
  "paddingTop": "25px",
  "marginBottom": "25px",
  display: "grid",
  placeItems: "center"
}));

const MinWidthTextField = styled(TextField)(({ theme }) => ({
  "minWidth": "300px"
}));

const HomeButton = styled(RouterLink)(({ theme }) => ({
  "textDecoration": "none"
}));

function ContentSection({ t, email, mailtoUrl, confirm, handleSubmit, errors, control }) {
  const RESPONSE_MESSAGE = useAuthenticationState().request.message;
  return (
    <Grid container item xs={12} md={12} xl={12}>
      <ContentGrid item xs={1} md={3} xl={3}>
      </ContentGrid>
      <ContentGrid item xs={10} md={6} xl={6}>
        <Paper elevation={10}>
          <CentreAlignedContainer id="CompanyLogoContainer" key="CompanyLogoContainer" container item xs={12} md={12} xl={12}>
            <SiteIcon id="CompanyLogo" key="CompanyLogo" alt="Company Logo" src={siteIcon} />
          </CentreAlignedContainer>
          <ContentContainer container xs={12} md={12} xl={12}>
            <Title container>
              <PageTitle variant="h5">
                {t("forget_password:page_title-enter_email")}
              </PageTitle>
            </Title>
            <Typography variant="body1">
              {t("forget_password:instruction-enter_email")}
            </Typography>
            <form onSubmit={handleSubmit(confirm)} noValidate>
              <Controller
                name="email"
                defaultValue={''}
                control={control}
                render={
                  ({ field }) => <MinWidthTextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    label={t("attribute:user-email_address")}
                    helperText={errors.email ? t(errors.email.message) : null}
                    error={errors.email ? true : false}
                    type="string"
                  />
                }
              />
              <Typography component="h2" variant="body2" color="error">
                {t(RESPONSE_MESSAGE)}
              </Typography>
              <div><br/></div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {t("general:button-submit")}
              </Button>
            </form>
          </ContentContainer>
          <NeedHelp container item xs={12} md={12} xl={12}>
            <Typography variant="body1">
              {t("general:help-need_help")}
              <ContactUs href={mailtoUrl}>
                {t("general:link-contact_us")}
              </ContactUs>
            </Typography>
          </NeedHelp>
        </Paper>
      </ContentGrid>
    </Grid>
  );
}

function TitleSection() {
  return (
    <React.Fragment>
      <Grid container item xs={1} md={1} xl={1}>
        <LogoutArrow item xs={12} md={12} xl={12}>
          <RouterLink to='/'>
            <IconButton data-cy='logoutArrow' size="small">
              <ArrowBackIcon />
            </IconButton>
          </RouterLink>
        </LogoutArrow>
      </Grid>
      <Grid container item xs={11} md={11} xl={11}>
        <ContentGrid item xs={12} md={12} xl={12}>
          <Typography variant="body2" component="span" color="textSecondary" align="right">
            <LanguageSwitcher disableContrast={true}></LanguageSwitcher>
          </Typography>
        </ContentGrid>
      </Grid>
    </React.Fragment>
  );
}

function CompletedSection({ t, mailtoUrl }) {
  return (
    <Grid container item xs={12} md={12} xl={12}>
      <ContentGrid item xs={1} md={3} xl={3}>
      </ContentGrid>
      <ContentGrid item xs={10} md={6} xl={6}>
        <Paper elevation={10}>
          <CentreAlignedContainer id="CompanyLogoContainer" key="CompanyLogoContainer" container item xs={12} md={12} xl={12}>
            <SiteIcon id="CompanyLogo" key="CompanyLogo" alt="Company Logo" src={siteIcon} />
          </CentreAlignedContainer>
          <ContentContainer container xs={12} md={12} xl={12}>
            <Title container>
              <PageTitle variant="h5">
                {t("forget_password:page_title-check_email")}
              </PageTitle>
            </Title>
            <Typography variant="body1">
              {t("forget_password:instruction-check_email-1")}
            </Typography>
            <Typography variant="body1">
              {t("forget_password:instruction-check_email-2")}
            </Typography>
            <ContentGrid item xs={12} md={12} xl={12}>
            </ContentGrid>
            <ContentGrid item xs={12} md={12} xl={12}>
              <HomeButton to='/'>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t("forget_password:button-return")}
                </Button>
              </HomeButton>
            </ContentGrid>
          </ContentContainer>
          <NeedHelp container item xs={12} md={12} xl={12}>
            <Typography variant="body1">
              {t("general:help-need_help")}
              <ContactUs href={mailtoUrl}>
                {t("general:link-contact_us")}
              </ContactUs>
            </Typography>
          </NeedHelp>
        </Paper>
      </ContentGrid>
    </Grid>
  );
}


const ForgetPasswordSchema = yup.object().shape({
  email: yup.string().email("general:validation-email").required("general:validation-mandatory"),
});

export default function ForgetPasswordPage() {

  const { t } = useTranslation(["forget_password", "general"]);
  const CS_EMAIL = "help@itutor.com";
  const SUBJECT = t("forget_password:contact_subject");
  const MAIL_TO_URL = `mailto:${CS_EMAIL}?subject=${SUBJECT}`;
  const [requested, setRequested] = React.useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(ForgetPasswordSchema)
  });

  useEffect(() => {
    setUpdating({
      updating: false,
      error: null
    });
  }, []);

  function confirm(data) {
    recoverPassword({ email: data.email });
    setRequested(true);
  }

  return (
    <NonDashboardRootContainer container>

      <TitleSection></TitleSection>
      <React.Suspense fallback={<LoadingPage />}>

        {requested ?
          (<CompletedSection t={t} mailtoUrl={MAIL_TO_URL} ></CompletedSection>) :
          (<ContentSection t={t} mailtoUrl={MAIL_TO_URL} errors={errors} control={control} handleSubmit={handleSubmit} confirm={confirm}></ContentSection>)}
      </React.Suspense>
    </NonDashboardRootContainer>
  );
}