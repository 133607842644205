import React from "react";
import {
    Grid, Stack, TextField,
    Dialog, DialogTitle, DialogContent, 
    InputAdornment, Pagination
} from "@mui/material";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useClassSessionManagementState, getClassStudentForClassSession, addStudentToClassSession } from "./../../../../redux/ClassSessionManagement";
import StudentCard from "./../../../page/student/StudentCard";

const NarrowRow = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

export default function ClassSessionAddStudentDialog({
    title, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);
    const { sessionId } = useParams();
    const CLASS_STUDENT_LIST = useClassSessionManagementState().classStudentList || [];
    const STUDENT_COUNT = useClassSessionManagementState().classStudentCount || 0;
    const [searchString, setSearchString] = React.useState(null);
    const rowsPerPage = 10;
    const [page, setPage] = React.useState(0);


    const deboundedGetCentreStudentForClassSession = React.useMemo(() => debounce(getClassStudentForClassSession, 50), []);
    React.useEffect(() => {
        if (isDialogOpen == false) {
            setSearchString(null);
        }

    }, [isDialogOpen]);

    React.useEffect(() => {
        if (isDialogOpen) {
            deboundedGetCentreStudentForClassSession({
                sessionId: sessionId,
                searchCriteria: {
                    search: searchString, page: page, rowsPerPage: rowsPerPage
                }
            })
        }
    }, [isDialogOpen, searchString, page, rowsPerPage, sessionId]);

    const updateSearchString = (event) => {
        event.preventDefault();
        setSearchString(event.target.value);
    };
    const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const addStudentToClassOnClick = (event, selectedStudent) => {
        event.preventDefault();
        addStudentToClassSession({ sessionId: sessionId, students: selectedStudent._id })
        setIsDialogOpen(false);

    }



    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Stack sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
                        <TextField
                            id="outlined-start-adornment"
                            fullWidth
                            onChange={(event) => { searchFieldOnChange(event) }}
                            placeholder={t("general:label-search")}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                            }}
                        />
                    </Stack>
                </Stack>
                <NarrowRow spacing={0} >
                    <Grid container justifyContent="center" item sm={12} md={12}>
                        {CLASS_STUDENT_LIST.map(
                            (studentEnrolment, index) => {
                                let currentStudent = studentEnrolment.profile;
                                return (<Grid key={currentStudent._id + currentStudent.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
                                    <StudentCard index={index} key={currentStudent._id + currentStudent.displayName}
                                        cardOnClick={(event) => {
                                            addStudentToClassOnClick(event, studentEnrolment);
                                        }}
                                        currentStudent={currentStudent} />
                                </Grid>)
                            })
                        }
                        <Pagination
                            onChange={(event, pageNumber) => {
                                event.preventDefault();
                                setPage(pageNumber - 1);
                            }}
                            count={Math.ceil(STUDENT_COUNT / rowsPerPage)}
                        />
                    </Grid>
                </NarrowRow>
            </DialogContent>
        </Dialog >
    )
}