import React from "react";
import {
    Grid, Button, 
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DialogFixedForm } from "./../../../../component/common/Forms";
import CentreFacilityFieldList from "./../../../../component/form/centreComponent/CentreFacilityFieldList";
import { createCentreFacilityFormSchema, INITIAL_CENTRE_FACILITY_FORM } from "./../../../../component/form/model/CentreFormModel";
import { addCentreFacility,  updateCentreFacility } from "./../../../../redux/FacilityManagement";
import { yupResolver } from "@hookform/resolvers/yup";




export default function CentreFacilityDialog({
    title, currentFacility, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);

    const { centreId } = useParams();

    const CENTRE_FACILITY_FORM_METHOD = useForm({
        resolver: yupResolver(createCentreFacilityFormSchema())
    });

    React.useEffect(() => {
        if (currentFacility) {
            CENTRE_FACILITY_FORM_METHOD.reset(currentFacility);
        }else{
            CENTRE_FACILITY_FORM_METHOD.reset(INITIAL_CENTRE_FACILITY_FORM);
        }

    }, [isDialogOpen])


    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const submitMethod = (data) => {
        if (currentFacility) {
            editCentreFacility(data);
        } else {
            addNewCentreFacility(data);
        }
    }

    const editCentreFacility = (data) => {
        data.centreId = centreId;
        data._id = currentFacility._id;
        updateCentreFacility(data);
        setIsDialogOpen(false);
    }

    const addNewCentreFacility = (data) => {
        data.centreId = centreId;
        addCentreFacility(data);
        setIsDialogOpen(false);
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...CENTRE_FACILITY_FORM_METHOD} >
                <DialogFixedForm control={CENTRE_FACILITY_FORM_METHOD.control}
                    errors={CENTRE_FACILITY_FORM_METHOD.errors}
                    onSubmit={CENTRE_FACILITY_FORM_METHOD.handleSubmit(submitMethod
                    )}>
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <CentreFacilityFieldList />
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button autoFocus onClick={closeDialog} variant="outlined">
                                {t("general:button-cancel")}
                            </Button>
                            <Button type="submit" variant="contained" >
                                {t("general:button-save")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </FormProvider>
        </Dialog >
    )
}