import {
    createReduxModule
} from "hooks-for-redux";
import {
    getCentreFacilityRequest,
    addCentreFacilityRequest,
    updateCentreFacilityRequest,
    deleteCentreFacilityRequest,
    getFacilityInfoRequest,
    uploadFacilityLogoRequest,
    uploadFacilityPhotosRequest,
    deleteFacilityPhotoRequest
} from "./../api/centreAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        centreId: null,
        searchCriteria: {},
        facilities: [],
        currentFacility: {},
        centreFacilityToBeSaved: null,
        centreFacilityToBeDeleted: null,
        centreFacilityCount: 0,
        centreFacilitySearchCriteria: null,
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "ADD_FACILITY": "ADD_FACILITY",
    "GET_FACILITY": "GET_FACILITY",
    "UPDATE_FACILITY": "UPDATE_FACILITY",
    "DELETE_FACILITY": "DELETE_FACILITY",
    "GET_FACILITY_INFO": "GET_FACILITY_INFO",
    "UPLOAD_LOGO": "UPLOAD_LOGO",
    "UPLOAD_PHOTO": "UPLOAD_PHOTO",
    "DELETE_PHOTO": "DELETE_PHOTO"
};

export const [useFacilityManagementState, {
    setUpdating,
    getCentreFacility, getCentreFacilitySuccess, getCentreFacilityFail,
    addCentreFacility, addCentreFacilitySuccess, addCentreFacilityFail,
    updateCentreFacility, updateCentreFacilitySuccess, updateCentreFacilityFail,
    deleteCentreFacility, deleteCentreFacilitySuccess, deleteCentreFacilityFail,
    getFacilityInfo, getFacilityInfoSuccess, getFacilityInfoFail,
    uploadFacilityLogo, uploadFacilityLogoSuccess, uploadFacilityLogoFail,
    uploadFacilityPhotos, uploadFacilityPhotosSuccess, uploadFacilityPhotosFail,
    deleteFacilityPhoto, deleteFacilityPhotoSuccess, deleteFacilityPhotoFail,
},
    facilityManagementState
] = createReduxModule(
    "facilityManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getCentreFacility: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_FACILITY),
        centreId: payload.centreId,
        centreFacilitySearchCriteria: payload
    }),
    getCentreFacilitySuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        facilities: payload.data,
        centreFacilityCount: payload.count
    }),
    getCentreFacilityFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentreFacility: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_FACILITY),
        centreFacilityToBeSaved: payload
    }),
    addCentreFacilitySuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreFacilityToBeSaved: null
    }),
    addCentreFacilityFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateCentreFacility: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_FACILITY),
        centreFacilityToBeSaved: payload
    }),
    updateCentreFacilitySuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreFacilityToBeSaved: null
    }),
    updateCentreFacilityFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteCentreFacility: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_FACILITY),
        centreFacilityToBeDeleted: payload
    }),
    deleteCentreFacilitySuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreFacilityToBeDeleted: null
    }),
    deleteCentreFacilityFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getFacilityInfo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_FACILITY_INFO),
        facilityId: payload.facilityId
    }),
    getFacilityInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentFacility: payload.data
    }),
    getFacilityInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    uploadFacilityLogo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPLOAD_LOGO),
        logoToBeUploaded: payload
    }),
    uploadFacilityLogoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        logoToBeUploaded: null
    }),
    uploadFacilityLogoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    uploadFacilityPhotos: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPLOAD_PHOTO),
        photosToBeUploaded: payload
    }),
    uploadFacilityPhotosSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        photosToBeUploaded: null
    }),
    uploadFacilityPhotosFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteFacilityPhoto: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_PHOTO),
        photoToBeDeleted: payload
    }),
    deleteFacilityPhotoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        photoToBeDeleted: null
    }),
    deleteFacilityPhotoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
});


const UPDATE_ACTION_HANDLER = {
    "ADD_FACILITY": {
        handler: async (currentState) => {
            let result = await addCentreFacilityRequest(currentState.centreFacilityToBeSaved);
            if (result) {
                if (result.success) {
                    addCentreFacilitySuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreFacility(currentState.centreFacilitySearchCriteria);
                    pushRedirect({
                        path: `/facility/${result.data._id}`
                    });
                } else {
                    addCentreFacilityFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentreFacilityFail
    },
    "GET_FACILITY": {
        handler: async (currentState) => {
            let result = await getCentreFacilityRequest(currentState.centreId, currentState.centreFacilitySearchCriteria);
            if (result) {
                if (result.success) {
                    getCentreFacilitySuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "count": result.count
                    });
                } else {
                    getCentreFacilityFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreFacilityFail
    },
    "UPDATE_FACILITY": {
        handler: async (currentState) => {
            let result = await updateCentreFacilityRequest(currentState.centreFacilityToBeSaved);
            if (result) {
                if (result.success) {
                    updateCentreFacilitySuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getFacilityInfo({
                        facilityId: currentState.facilityId
                    });
                } else {
                    updateCentreFacilityFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateCentreFacilityFail
    }, "GET_FACILITY_INFO": {
        handler: async (currentState, action) => {
            let result = await getFacilityInfoRequest(currentState.facilityId);
            if (result) {
                if (result.success) {
                    getFacilityInfoSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getFacilityInfoFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getFacilityInfoFail
    }, "UPLOAD_LOGO": {
        handler: async (currentState) => {
            let result = await uploadFacilityLogoRequest(currentState.logoToBeUploaded);
            if (result) {
                if (result.success) {
                    uploadFacilityLogoSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getFacilityInfo({
                        facilityId: currentState.facilityId
                    });
                } else {
                    uploadFacilityLogoFail({
                        "message": "Facility:fail_to_upload",
                        error: result.error
                    });
                }
            }
        },
        failHandler: uploadFacilityLogoFail
    },
    "UPLOAD_PHOTO": {
        handler: async (currentState) => {
            let result = await uploadFacilityPhotosRequest(currentState.photosToBeUploaded);
            if (result) {
                if (result.success) {
                    uploadFacilityPhotosSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getFacilityInfo({
                        facilityId: currentState.facilityId
                    });
                } else {
                    uploadFacilityPhotosFail({
                        "message": "Facility:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: uploadFacilityPhotosFail
    },
    "DELETE_PHOTO": {
        handler: async (currentState) => {
            let result = await deleteFacilityPhotoRequest(currentState.photoToBeDeleted);
            if (result) {
                if (result.success) {
                    deleteFacilityPhotoSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getFacilityInfo({
                        facilityId: currentState.facilityId
                    });
                } else {
                    deleteFacilityPhotoFail({
                        "message": "Facility:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteFacilityPhotoFail
    }, "DELETE_FACILITY": {
        handler: async (currentState) => {
            let result = await deleteCentreFacilityRequest(currentState.centreFacilityToBeDeleted);
            if (result) {
                if (result.success) {
                    let centreId = currentState.centreFacilityToBeDeleted.centreId;
                    deleteCentreFacilitySuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    pushRedirect({
                        path: `/centre/${centreId}?tab=facility`
                    })
                } else {
                    deleteCentreFacilityFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteCentreFacilityFail
    },
};

facilityManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});