import * as YUP from "yup";
import MOMENT from "moment";
function createClassSesssionFormSchema() {
  return YUP.object().shape({
    date: YUP.date(),
    startTime: YUP.date().required("general:validation-mandatory"),
    endTime: YUP.date().required("general:validation-mandatory").test("isLaterThenStartTime", () => {
      return "general:validation-endTime_greater_than_startTime";
    }, async (value, testContext) => {
      let startTimeInDate = MOMENT(testContext.parent.startTime).second(0).millisecond(0);
      let endTimeInDate = MOMENT(value).second(0).millisecond(0);
      return (endTimeInDate.isSameOrAfter(startTimeInDate.toISOString()));
    }),
    remarks: YUP.string().nullable(),
  });
}

function createClassSesssionProgressFormSchema() {
  return YUP.object().shape({
    progress: YUP.string().nullable(),
  });
}

const CLASS_SESSION_FIELD = {
  date: "date",
  startTime: "startTime",
  endTime: "endTime",
  progress: "progress",
  remarks: "remarks"
}

const INIT_CLASS_SESSION = {
  date: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  progress: "",
  remarks: "",
}

const INIT_CLASS_SESSION_PROGRESS_FORM = {
  progress: ""
}

export {
  INIT_CLASS_SESSION,
  createClassSesssionFormSchema,
  CLASS_SESSION_FIELD,
  INIT_CLASS_SESSION_PROGRESS_FORM,
  createClassSesssionProgressFormSchema
};