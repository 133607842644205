import React from 'react';
import { styled } from '@mui/material/styles';
import { Navigate } from "react-router-dom";
import { useAuthenticationState } from "./../../redux/authentication";
import { useProfileState } from "./../../redux/ProfileManagement";
import { Trans, useTranslation } from "react-i18next";

const Header = styled('h2')(({ theme }) => ({
    margin: '0px'
}));

export default function Home() {

    const AUTH_STATE = useAuthenticationState();
    const STUDENT_PROFILES = useProfileState().studentProfiles || [];
    const TUTOR_PROFILES = useProfileState().tutorProfiles || [];
    //currently don't have any logic.
    return null;
}
