import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
	Paper, Grid, IconButton,
	Typography, Fab, Box, Link
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { SpaceHolder } from "./../../common/Others";
import debounce from 'lodash.debounce';
import { useParams } from "react-router-dom";
import { Can } from "./../../..//casl/authorization";
import { subject } from "@casl/ability";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useClassesManagementState, getClassNotes, deleteClassNote } from "./../../../redux/ClassesManagement";
import ClassNotesDialog from "./../../page/class/dialog/ClassNotesDialog";
import MOMENT from "moment";
import ContextualMenu from "./../../common/ContextualMenu";
import { ContextualMenuItem } from "./../../common/Buttons";
import ConfirmActionDialog from "./../../common/ConfirmActionDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5)
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 900
}));

const ACTION_IN_CHECK = {
	UPLOAD_CLASS_NOTES: true,
	DELETE_CLASS_NOTES: true
}

export default function ClassSchedulePage() {
	const { t } = useTranslation();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedRow, setSelectedRow] = React.useState(null);
	const [addClassNotesDialog, setAddClassNotesDialog] = React.useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const { classId } = useParams();
	const CLASS_INFO = useClassesManagementState().currentClass;
	const CLASS_REQUEST = useClassesManagementState().request;
	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;
	const FILE_LIST = useClassesManagementState().classNotes || [];
	const FILE_COUNT = FILE_LIST.length;

	const toggleDeleteDialog = (event) => {
		event.preventDefault();
		setDeleteDialogOpen(true);
		setAnchorEl(null);
	}

	const triggerAddDialog = (event) => {
		event.preventDefault();
		setAddClassNotesDialog(true);
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const triggerDeleteHomeworkListFile = (event) => {
		event.preventDefault();
		deleteClassNote({
			classId: classId,
			file: selectedRow
		})
	}

	const toggleRowContextualMenu = (event, currentRow) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setSelectedRow(currentRow);
	};


	let deboundedGetClassNotes = React.useMemo(() => debounce(getClassNotes, 50), []);

	React.useEffect(() => {
		if (ACTION_IN_CHECK[CLASS_REQUEST.lastAction]) {
			if (CLASS_REQUEST.status === "REFRESH_DATA_REQUIRE") {
				deboundedGetClassNotes({
					classId: classId
				});
			}
		}
	}, [CLASS_REQUEST]);

	React.useEffect(() => {
		deboundedGetClassNotes({
			classId: classId
		});
	}, []);

	React.useEffect(() => {
		deboundedGetClassNotes({
			classId: classId
		});
	}, [classId]);
	return (
		<React.Fragment>
			<Paper sx={{ marginTop: 0, marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-notes")}</Typography>
							<Box>
								<Can I="update" this={subject("class", CLASS_INFO)} ability={ABILITY}>
									<Can I="create" this={"class_session"} ability={ABILITY}>
										<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddDialog} aria-label="comment">
											<AddIcon />
										</Fab>
									</Can>
								</Can>
							</Box>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>

			<TableContainer sx={{ borderRadius: "10px", }} component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow  >
							<BoldTableCell >{t("attribute:note-filename")}</BoldTableCell>
							<BoldTableCell >{t("attribute:note-upload_date")}</BoldTableCell>
							<BoldTableCell align="right"></BoldTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{FILE_LIST.slice((page) * rowsPerPage, (page) * rowsPerPage + rowsPerPage).map((row) => {
							return (
								<TableRow key={row.filename + "_" + row.createdTime}>
									<TableCell >{row.filename}</TableCell>
									<TableCell >{MOMENT(row.createdTime).format("lll")}</TableCell>
									<TableCell align="right">
										<IconButton onClick={(event) => { toggleRowContextualMenu(event, row) }} aria-label="delete">
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={t("general:label-rows_per_page")}
					rowsPerPageOptions={[1, 5, 10, 25]}
					component="div"
					count={FILE_COUNT}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
			<ContextualMenu id="post_popover_menu" anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				{ABILITY.can("read", subject("class_notes", { centreId: CLASS_INFO.centreId, students: CLASS_INFO.students })) ?
					(< Link underline="none" color="secondary" href={selectedRow ? selectedRow.url : ""} download>
						<ContextualMenuItem title={t("general:button-download")}>
							<FileDownloadIcon sx={{ mr: 0 }} fontSize="small" />
						</ContextualMenuItem>
					</Link>) : null
				}
				{ABILITY.can("delete", subject("class_notes", { centreId: CLASS_INFO.centreId, students: CLASS_INFO.students })) ?
					(<ContextualMenuItem title={t("general:button-delete")} onClick={toggleDeleteDialog}>
						<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
					</ContextualMenuItem>
					) : null
				}

			</ContextualMenu>
			<ConfirmActionDialog
				title={t("general:dialog_title-delete_file")}
				actionName={t("general:button-delete")}
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				confirmOnClick={triggerDeleteHomeworkListFile}
			/>
			<ClassNotesDialog
				open={addClassNotesDialog}
				setOpen={setAddClassNotesDialog} />

		</React.Fragment >
	);
}
