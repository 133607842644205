import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export async function uploadHomeworkListRequest(homeworkListToBeUploaded: object): Promise<UpdateResultResponse> {
    const url = "/homework/list/save";

    try {
        const { data } = await axios.post<UpdateResultResponse>(url, homeworkListToBeUploaded);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function deleteHomeworkListRequest(homeworkListToBeDeleted: object): Promise<UpdateResultResponse> {
    const url = "/homework/list/delete";

    try {
        const { data } = await axios.post<UpdateResultResponse>(url, homeworkListToBeDeleted);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function getHomeworkListRequest(enrolmentId: string): Promise<UpdateResultResponse> {
    const url = `/homework/list/${enrolmentId}`;

    try {
        const { data } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err;
    }
}
