import {
    createReduxModule
} from "hooks-for-redux";
import {
    addClassRequest,
    addFacilityToClassRequest,
    addTutorToClassRequest,
    applyClassRequest,
    deleteClassRequest,
    deleteFacilityFromClassRequest,
    deleteTutorFromClassRequest,
    getClassesRequest,
    getClassFacilityRequest,
    getClassInfoRequest,
    getClassStudentRequest,
    getClassTutorRequest,
    publishClassRequest,
    removeClassRequest,
    updateClassRequest,
    addStudentToClassRequest,
    removeStudentFromClassRequest,
    acceptStudentClassEnrolmentRequest,
    removeStudentClassEnrolmentRequest,
    getStudentForEnrolmentRequest,
    getStudentActiveClassRequest,
    getTutorActiveClassRequest,
    uploadClassNotesRequest,
    getClassNotesRequest,
    deleteClassNoteRequest
} from "./../api/centreAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        centreId: null,
        searchCriteria: {},
        activeClasses: [],
        activeClassesrCount: 0,
        classTutorList: [],
        classTutorCount: 0,
        activeStudents: [],
        activeStudentsCount: 0,
        classPendingStudents: [],
        pendingClasses: [],
        currentClass: {},
        tutorsToBeAdded: [],
        centreClassToBeSaved: null,
        classToBeDeleted: null,
        classToBeRemoved: null,
        classCentreStudents: [],
        classCentreStudentsCount: 0,
        centreClassCount: 0,
        centreClassSearchCriteria: null,
        classEnrolmentId: null,
        studentActiveClasses: [],
        tutorNameMap:{},
        tutorActiveClasses: [],
        tutorPendingClasses: [],
        classNotes: [],
        classNoteToBeDeleted: null,
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "ADD_CLASS": "ADD_CLASS",
    "GET_CLASS": "GET_CLASS",
    "UPDATE_CLASS": "UPDATE_CLASS",
    "DELETE_CLASS": "DELETE_CLASS",
    "PUBLISH_CLASS": "PUBLISH_CLASS",
    "REMOVE_CLASS": "REMOVE_CLASS",
    "APPLY_CLASS": "APPLY_CLASS",
    "GET_CLASS_INFO": "GET_CLASS_INFO",
    "GET_CENTRE_TUTOR": "GET_CENTRE_TUTOR",
    "GET_CENTRE_FACILITY": "GET_CENTRE_FACILITY",
    "GET_CLASS_STUDENT": "GET_CLASS_STUDENT",
    "ADD_TUTOR_TO_CLASS": "ADD_TUTOR_TO_CLASS",
    "ADD_FACILITY_TO_CLASS": "ADD_FACILITY_TO_CLASS",
    "DELETE_TUTOR_FROM_CLASS": "DELETE_TUTOR_FROM_CLASS",
    "DELETE_FACILITY_FROM_CLASS": "DELETE_FACILITY_FROM_CLASS",
    "ACCEPT_STUDENT_CLASS_ENROLMENT": "ACCEPT_STUDENT_CLASS_ENROLMENT",
    "REMOVE_STUDENT_CLASS_ENROLMENT": "REMOVE_STUDENT_CLASS_ENROLMENT",
    "ADD_STUDENT_TO_CLASS": "ADD_STUDENT_TO_CLASS",
    "REMOVE_STUDENT_FROM_CLASS": "REMOVE_STUDENT_FROM_CLASS",
    "GET_CENTRE_STUDENT": "GET_CENTRE_STUDENT",
    "GET_STUDENT_ACTIVE_CLASS": "GET_STUDENT_ACTIVE_CLASS",
    "GET_TUTOR_ACTIVE_CLASS": "GET_TUTOR_ACTIVE_CLASS",
    "UPLOAD_CLASS_NOTES": "UPLOAD_CLASS_NOTES",
    "GET_CLASS_NOTES": "GET_CLASS_NOTES",
    "DELETE_CLASS_NOTES": "DELETE_CLASS_NOTES"
};

export const [useClassesManagementState, {
    setUpdating,
    getCentreClasses, getCentreClassesSuccess, getCentreClassesFail,
    addCentreClass, addCentreClassSuccess, addCentreClassFail,
    updateCentreClass, updateCentreClassSuccess, updateCentreClassFail,
    getClassInfo, getClassInfoSuccess, getClassInfoFail,
    getCentreTutorForClass, getCentreTutorForClassSuccess, getCentreTutorForClassFail,
    getClassFacility, getClassFacilitySuccess, getClassFacilityFail,
    getClassStudent, getClassStudentSuccess, getClassStudentFail,
    publishClass, publishClassSuccess, publishClassFail,
    deleteClass, deleteClassSuccess, deleteClassFail,
    removeClass, removeClassSuccess, removeClassFail,
    applyClass, applyClassSuccess, applyClassFail,
    addTutorToClass, addTutorToClassSuccess, addTutorToClassFail,
    deleteTutorFromClass, deleteTutorFromClassSuccess, deleteTutorFromClassFail,
    addFacilityToClass, addFacilityToClassSuccess, addFacilityToClassFail,
    deleteFacilityFromClass, deleteFacilityFromClassSuccess, deleteFacilityFromClassFail,
    addStudentToClass, addStudentToClassSuccess, addStudentToClassFail,
    removeStudentFromClass, removeStudentFromClassSuccess, removeStudentFromClassFail,
    acceptStudentEnrolment, acceptStudentEnrolmentSuccess, acceptStudentEnrolmentFail,
    removeStudentEnrolment, removeStudentEnrolmentSuccess, removeStudentEnrolmentFail,
    getCentreStudentForClass, getCentreStudentForClassSuccess, getCentreStudentForClassFail,
    getStudentActiveClass, getStudentActiveClassSuccess, getStudentActiveClassFail,
    getTutorActiveClass, getTutorActiveClassSuccess, getTutorActiveClassFail,
    uploadClassNotes, uploadClassNotesSuccess, uploadClassNotesFail,
    getClassNotes, getClassNotesSuccess, getClassNotesFail,
    deleteClassNote, deleteClassNoteSuccess, deleteClassNoteFail
},
    classesManagementState
] = createReduxModule(
    "classesManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getCentreClasses: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS),
        centreId: payload.centreId,
        centreClassSearchCriteria: payload.searchCriteria
    }),
    getCentreClassesSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        activeClasses: payload.data.activeClass,
        activeClassesrCount: payload.data.classCount,
        pendingClasses: payload.data.pendingClass,
        tutorNameMap: payload.data.tutorNameMap,
        centreClassCount: payload.count
    }),
    getCentreClassesFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addCentreClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_CLASS),
        centreClassToBeSaved: payload
    }),
    addCentreClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreClassToBeSaved: null
    }),
    addCentreClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateCentreClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_CLASS),
        centreClassToBeSaved: payload
    }),
    updateCentreClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        centreClassToBeSaved: null,
        currentClass: payload.data
    }),
    updateCentreClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassInfo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_INFO),
        classId: payload.classId
    }),
    getClassInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentClass: payload.data
    }),
    getClassInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentreTutorForClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_TUTOR),
        classId: payload.classId,
        searchCriteria: payload.searchCriteria
    }),
    getCentreTutorForClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classTutorList: payload.data.centreTutors,
        classTutorCount: payload.data.tutorCount
    }),
    getCentreTutorForClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassFacility: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_FACILITY),
        classId: payload.classId,
        searchCriteria: payload.searchCriteria
    }),
    getClassFacilitySuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classFacilityList: payload.data.centreFacilities,
        classFacilityCount: payload.data.tutorCount
    }),
    getClassFacilityFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassStudent: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_STUDENT),
        classId: payload.classId,
        searchCriteria: payload.searchCriteria
    }),
    getClassStudentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        activeStudents: payload.data.activeStudents,
        activeStudentsCount: payload.data.activeStudentsCount,
        classPendingStudents: payload.data.pendingStudents,
        studentBillStatus: payload.data.studentBillStatus
    }),
    getClassStudentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    publishClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.PUBLISH_CLASS),
        classId: payload.classId
    }),
    publishClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    publishClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_CLASS),
        classToBeDeleted: payload.classId
    }),
    deleteClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    deleteClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_CLASS),
        classToBeRemoved: payload.classId
    }),
    removeClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    removeClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    applyClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.APPLY_CLASS),
        classId: payload.classId,
        studentId: payload.studentId
    }),
    applyClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    applyClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addTutorToClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_TUTOR_TO_CLASS),
        classId: payload.classId,
        tutorsToBeAdded: payload.tutors
    }),
    addTutorToClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentClass: payload.data.class
    }),
    addTutorToClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteTutorFromClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_TUTOR_FROM_CLASS),
        tutorToBeDeleted: payload.enrolmentId,
        classId: payload.classId
    }),
    deleteTutorFromClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentClass: payload.data
    }),
    deleteTutorFromClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addFacilityToClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_FACILITY_TO_CLASS),
        classId: payload.classId,
        facilityId: payload.facilityId
    }),
    addFacilityToClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentClass: payload.data.class
    }),
    addFacilityToClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteFacilityFromClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_FACILITY_FROM_CLASS),
        facilityToBeDeleted: payload.facilityId,
        classId: payload.classId
    }),
    deleteFacilityFromClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentClass: payload.data
    }),
    deleteFacilityFromClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addStudentToClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_STUDENT_TO_CLASS),
        classId: payload.classId,
        studentsToBeAdd: payload.students
    }),
    addStudentToClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    addStudentToClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeStudentFromClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_STUDENT_FROM_CLASS),
        studentToBeDeleted: payload.enrolmentId,
        classId: payload.classId
    }),
    removeStudentFromClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    removeStudentFromClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    acceptStudentEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ACCEPT_STUDENT_CLASS_ENROLMENT),
        classId: payload.classId,
        roleEnrolmentId: payload.roleEnrolmentId
    }),
    acceptStudentEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classPendingStudents: payload.data.pendingStudents
    }),
    acceptStudentEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeStudentEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_STUDENT_CLASS_ENROLMENT),
        classId: payload.classId,
        roleEnrolmentId: payload.roleEnrolmentId
    }),
    removeStudentEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classPendingStudents: payload.data.pendingStudents
    }),
    removeStudentEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getCentreStudentForClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CENTRE_STUDENT),
        classId: payload.classId,
        searchCriteria: payload.searchCriteria
    }),
    getCentreStudentForClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classCentreStudents: payload.data.activeStudents,
        classCentreStudentsCount: payload.data.activeStudentsCount
    }),
    getCentreStudentForClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getStudentActiveClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_STUDENT_ACTIVE_CLASS),
        roleEnrolmentId: payload.enrolmentId
    }),
    getStudentActiveClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentActiveClasses: payload.data.studentActiveClasses
    }),
    getStudentActiveClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getTutorActiveClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_TUTOR_ACTIVE_CLASS),
        roleEnrolmentId: payload.enrolmentId
    }),
    getTutorActiveClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        tutorActiveClasses: payload.data.tutorActiveClasses,
        tutorPendingClasses: payload.data.tutorPendingClasses
    }),
    getTutorActiveClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    uploadClassNotes: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPLOAD_CLASS_NOTES),
        notesToBeUpload: payload.formData
    }),
    uploadClassNotesSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {

    }),
    uploadClassNotesFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassNotes: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_NOTES),
        classId: payload.classId
    }),
    getClassNotesSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classNotes: payload.data
    }),
    getClassNotesFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteClassNote: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_CLASS_NOTES),
        classNoteToBeDeleted: payload
    }),
    deleteClassNoteSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    deleteClassNoteFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
});


const UPDATE_ACTION_HANDLER = {
    "ADD_CLASS": {
        handler: async (currentState) => {
            let result = await addClassRequest(currentState.centreClassToBeSaved);
            if (result) {
                if (result.success) {
                    addCentreClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    pushRedirect({
                        path: `/class/${result.data._id}`
                    });
                } else {
                    addCentreClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addCentreClassFail
    },
    "GET_CLASS": {
        handler: async (currentState) => {
            let result = await getClassesRequest(currentState.centreId, currentState.centreClassSearchCriteria);
            if (result) {
                if (result.success) {
                    getCentreClassesSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "count": result.count
                    });
                } else {
                    getCentreClassesFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreClassesFail
    },
    "UPDATE_CLASS": {
        handler: async (currentState) => {
            let result = await updateClassRequest(currentState.centreClassToBeSaved);
            if (result) {
                if (result.success) {
                    updateCentreClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    updateCentreClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateCentreClassFail
    }, "GET_CLASS_INFO": {
        handler: async (currentState, action) => {
            let result = await getClassInfoRequest(currentState.classId);
            if (result) {
                if (result.success) {
                    getClassInfoSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassInfoFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassInfoFail
    }, "GET_CENTRE_TUTOR": {
        handler: async (currentState, action) => {
            let result = await getClassTutorRequest(currentState.classId, currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    getCentreTutorForClassSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "status": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getCentreTutorForClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreTutorForClassFail
    }, "GET_CENTRE_STUDENT": {
        handler: async (currentState, action) => {
            let result = await getStudentForEnrolmentRequest(currentState.classId, currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    getCentreStudentForClassSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "status": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getCentreStudentForClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getCentreStudentForClassFail
    }, "GET_CENTRE_FACILITY": {
        handler: async (currentState, action) => {
            let result = await getClassFacilityRequest(currentState.classId, currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    getClassFacilitySuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "status": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassFacilityFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassFacilityFail
    }, "GET_CLASS_STUDENT": {
        handler: async (currentState, action) => {
            let result = await getClassStudentRequest(currentState.classId, currentState.searchCriteria);
            if (result) {
                if (result.success) {
                    getClassStudentSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "status": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassStudentFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassStudentFail
    }, "PUBLISH_CLASS": {
        handler: async (currentState) => {
            let result = await publishClassRequest({ classId: currentState.classId });
            if (result) {
                if (result.success) {
                    publishClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getCentreClasses({
                        centreId: currentState.centreId, searchCriteria: currentState.centreClassSearchCriteria
                    });
                    getClassInfo({
                        classId: currentState.classId
                    });

                } else {
                    publishClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: publishClassFail
    }, "DELETE_CLASS": {
        handler: async (currentState) => {
            let result = await deleteClassRequest({ classId: currentState.classToBeDeleted });
            if (result) {
                if (result.success) {
                    deleteClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getClassInfo({
                        classId: currentState.classId
                    });
                } else {
                    deleteClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteClassFail
    }, "REMOVE_CLASS": {
        handler: async (currentState) => {
            let result = await removeClassRequest({ classId: currentState.classToBeRemoved });
            if (result) {
                if (result.success) {
                    removeClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    getClassInfo({
                        classId: currentState.classId
                    });
                } else {
                    removeClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeClassFail
    }, "APPLY_CLASS": {
        handler: async (currentState, action) => {
            let result = await applyClassRequest({ classId: currentState.classId, studentId: currentState.studentId });
            if (result) {
                if (result.success) {
                    applyClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    applyClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: applyClassFail
    }, "ADD_TUTOR_TO_CLASS": {
        handler: async (currentState) => {
            let result = await addTutorToClassRequest({ classId: currentState.classId, tutorsToBeAdded: currentState.tutorsToBeAdded });
            if (result) {
                if (result.success) {
                    addTutorToClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    addTutorToClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addTutorToClassFail
    }, "ADD_FACILITY_TO_CLASS": {
        handler: async (currentState) => {
            let result = await addFacilityToClassRequest({ classId: currentState.classId, facilityId: currentState.facilityId });
            if (result) {
                if (result.success) {
                    addFacilityToClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    addFacilityToClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addFacilityToClassFail
    }, "DELETE_TUTOR_FROM_CLASS": {
        handler: async (currentState) => {
            let result = await deleteTutorFromClassRequest({ classId: currentState.classId, enrolmentId: currentState.tutorToBeDeleted });
            if (result) {
                if (result.success) {
                    deleteTutorFromClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    deleteTutorFromClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteTutorFromClassFail
    }, "DELETE_FACILITY_FROM_CLASS": {
        handler: async (currentState) => {
            let result = await deleteFacilityFromClassRequest({ classId: currentState.classId, facilityId: currentState.facilityToBeDeleted });
            if (result) {
                if (result.success) {
                    deleteFacilityFromClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    deleteFacilityFromClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteFacilityFromClassFail
    }, "ADD_STUDENT_TO_CLASS": {
        handler: async (currentState, action) => {
            let result = await addStudentToClassRequest({
                classId: currentState.classId,
                students: currentState.studentsToBeAdd
            });
            if (result) {
                if (result.success) {
                    addStudentToClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    addStudentToClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addStudentToClassFail
    }, "ACCEPT_STUDENT_CLASS_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await acceptStudentClassEnrolmentRequest({
                classId: currentState.classId,
                roleEnrolmentId: currentState.roleEnrolmentId
            });
            if (result) {
                if (result.success) {
                    acceptStudentEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    acceptStudentEnrolmentFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: acceptStudentEnrolmentFail
    }, "REMOVE_STUDENT_CLASS_ENROLMENT": {
        handler: async (currentState, action) => {
            let result = await removeStudentClassEnrolmentRequest({
                classId: currentState.classId,
                roleEnrolmentId: currentState.roleEnrolmentId
            });
            if (result) {
                if (result.success) {
                    removeStudentEnrolmentSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    removeStudentEnrolmentFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeStudentEnrolmentFail
    }, "REMOVE_STUDENT_FROM_CLASS": {
        handler: async (currentState, action) => {
            let result = await removeStudentFromClassRequest({
                classId: currentState.classId,
                roleEnrolmentId: currentState.studentToBeDeleted,
                searchCriteria: currentState.searchCriteria
            });
            if (result) {
                if (result.success) {
                    removeStudentFromClassSuccess({
                        "success": true,
                        "error": result.error,
                        "action": action,
                        "requestStatus": result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    removeStudentFromClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeStudentFromClassFail
    }, "GET_STUDENT_ACTIVE_CLASS": {
        handler: async (currentState, action) => {
            let result = await getStudentActiveClassRequest(currentState.roleEnrolmentId);
            if (result) {
                if (result.success) {
                    getStudentActiveClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    getStudentActiveClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getStudentActiveClassFail
    }, "GET_TUTOR_ACTIVE_CLASS": {
        handler: async (currentState, action) => {
            let result = await getTutorActiveClassRequest(currentState.roleEnrolmentId);
            if (result) {
                if (result.success) {
                    getTutorActiveClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    getTutorActiveClassFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getTutorActiveClassFail
    }, "UPLOAD_CLASS_NOTES": {
        handler: async (currentState, action) => {
            let result = await uploadClassNotesRequest(currentState.notesToBeUpload);
            if (result) {
                if (result.success) {
                    uploadClassNotesSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    uploadClassNotesFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: uploadClassNotesFail
    }, "GET_CLASS_NOTES": {
        handler: async (currentState, action) => {
            let result = await getClassNotesRequest(currentState.classId);
            if (result) {
                if (result.success) {
                    getClassNotesSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    getClassNotesFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassNotesFail
    }, "DELETE_CLASS_NOTES": {
        handler: async (currentState, action) => {
            let result = await deleteClassNoteRequest(currentState.classNoteToBeDeleted);
            if (result) {
                if (result.success) {
                    deleteClassNoteSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    deleteClassNoteFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteClassNoteFail
    },
};

classesManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});