import React from "react";
import {
    Grid, Button, TextField,
    Stack, Box,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import { FormProvider, useForm, Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ProfileContainer } from "./../../../component/common/Containers";
import { SuccessButton } from "./../../../component/common/Buttons";
import { useCentreManagementState } from "./../../../redux/CentreManagement";
import { addCentreEnrolment, useEnrolmentState } from "./../../../redux/Enrolment";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useProfileState } from "./../../../redux/ProfileManagement";
import TermsAndConditionsAccordion from "./../../../component/form/commonComponent/TermsAndConditionsAccordion";
import SanitizeHtmlWrapper from "./../../../component/display/SanitizeHtmlWrapper";
import StudentCard from "./../../page/student/StudentCard";
import TutorCard from "./../../page/tutor/TutorCard";
import { DialogFixedForm } from "./../../../component/common/Forms";
import { createCentreEnrolmentFormSchema, INITIAL_CENTRE_ENROLMENT_FORM, CENTRE_ENROLMENT_FIELD } from "./../../../component/form/model/CentreFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import SectionLabel from "./../../display/styled/SectionLabel";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const STUDENT_TYPE = "student";
const TUTOR_TYPE = "tutor";

function EnrolmentCentreList({ onClickHandler }) {
    const { t } = useTranslation("general");
    const STUDENT_LIST = useProfileState().studentProfiles || [];
    const TUTOR_LIST = useProfileState().tutorProfiles || [];
    const { centreId } = useParams();

    const REGISTERED_PROFILE = useAuthenticationState().registeredEnrolmentMap[centreId] || {};
    const CENTRE_PENDING_ENROLMENT = useEnrolmentState().pendingEnrolmentMap[centreId] || {};

    const PROFILE_SHOULD_BE_HIDDEN = Object.assign({}, REGISTERED_PROFILE, CENTRE_PENDING_ENROLMENT);

    const STUDENT_PROFILE_TO_BE_SHOW = STUDENT_LIST.filter((value) => (!PROFILE_SHOULD_BE_HIDDEN[value._id]));
    const TUTOR_PROFILE_TO_BE_SHOW = TUTOR_LIST.filter((value) => (!PROFILE_SHOULD_BE_HIDDEN[value._id]));

    const STUDENT_PROFILE_IN_PENDING = STUDENT_LIST.filter((value) => (CENTRE_PENDING_ENROLMENT[value._id]));
    const TUTOR_PROFILE_IN_PENDING = TUTOR_LIST.filter((value) => (CENTRE_PENDING_ENROLMENT[value._id]));

    return (<React.Fragment>
        <DialogContent dividers data-cy="edit_dialog_content">
            {Object.entries(CENTRE_PENDING_ENROLMENT).length > 0 ?
                <SectionLabel>{t("centre:section_title-pending_approval")}</SectionLabel>
                : null
            }
            {STUDENT_PROFILE_IN_PENDING.map((currentStudent, index) => <Grid key={currentStudent._id + "_container_grid"} container >
                <Grid item xs={12} md>
                    <StudentCard disabled={true} key={currentStudent._id} currentStudent={currentStudent} index={index}></StudentCard>
                </Grid>
            </Grid>)}
            {TUTOR_PROFILE_IN_PENDING.map((currentTutor, index) => <ProfileContainer key={currentTutor._id + "_container_grid"} container spacing={3}  >
                <Grid item xs={12} md>
                    <TutorCard disabled={true} key={currentTutor._id} currentTutor={currentTutor} index={index}></TutorCard>
                </Grid>
            </ProfileContainer>)}

            {STUDENT_PROFILE_TO_BE_SHOW.length > 0 ?
                <SectionLabel>{t("attribute:student_profile")}</SectionLabel>
                : null
            }
            {STUDENT_PROFILE_TO_BE_SHOW.map((currentStudent, index) => <Grid key={currentStudent._id + "_container_grid"} container >
                <Grid item xs={12} md>
                    <StudentCard key={currentStudent._id} currentStudent={currentStudent} cardOnClick={() => { onClickHandler(STUDENT_TYPE, currentStudent) }} index={index}></StudentCard>
                </Grid>
            </Grid>)}
            {TUTOR_PROFILE_TO_BE_SHOW.length > 0 ?
                <SectionLabel>{t("attribute:tutor_profile")}</SectionLabel>
                : null
            }
            {TUTOR_PROFILE_TO_BE_SHOW.map(
                (currentTutor, index) =>
                    <ProfileContainer key={currentTutor._id + "_container_grid"} container spacing={3}  >
                        <Grid item xs={12} md>
                            <TutorCard key={currentTutor._id} currentTutor={currentTutor} cardOnClick={() => { onClickHandler(TUTOR_TYPE, currentTutor) }} index={index}></TutorCard>
                        </Grid>
                    </ProfileContainer>
            )
            }
        </DialogContent>
    </React.Fragment >)
}

function CustomInputField() {
    const { control, formState: { errors } } = useFormContext();
    const { t } = useTranslation();
    const CENTRE = useCentreManagementState().currentCentre;
    const CUSTOM_FIELD = CENTRE.customFields || [];
    return (
        CUSTOM_FIELD.map((value) => {
            return (<Controller
                key={`textfield_controller_${value._id}`}
                name={CENTRE_ENROLMENT_FIELD.customField + `.${value._id}`}
                control={control}
                render={({ field }) => (
                    <StyledTextField
                        key={`textfield_${value._id}`}
                        variant="outlined" fullWidth
                        label={value.name}
                        helperText={errors[CENTRE_ENROLMENT_FIELD.customField] ? t(errors[CENTRE_ENROLMENT_FIELD.customField].message) : null}
                        error={errors[CENTRE_ENROLMENT_FIELD.customField] != null}
                        {...field}
                    />
                )}
            />);
        })
    );
}

function EnrolmentForm({ type, currentSelected, closeDialog, setSelectedProfile, setSelectedProfileType }) {
    const { t } = useTranslation(["general", "config"]);
    const IS_STUDENT_TYPE = (type === STUDENT_TYPE);
    const CENTRE = useCentreManagementState().currentCentre;
    const CUSTOM_FIELD = CENTRE.customFields || [];
    const cardOnClick = (event) => {
        event.preventDefault();
        setSelectedProfile(null);
        setSelectedProfileType(null);
    }
    if (currentSelected) {
        return (
            <React.Fragment>
                <DialogContent dividers data-cy="edit_dialog_content">
                    <Stack>
                        <SectionLabel>{t("enrolment:section_title-selected_profile")}</SectionLabel>
                        <Box sx={{ marginTop: 1 }}>
                            {IS_STUDENT_TYPE ?
                                <StudentCard cardOnClick={cardOnClick} key={"selected_student" + currentSelected._id} currentStudent={currentSelected} index={0} /> :
                                <TutorCard cardOnClick={cardOnClick} key={"selected_tutor" + currentSelected._id} currentTutor={currentSelected} index={0} />
                            }
                        </Box>
                        {IS_STUDENT_TYPE && CUSTOM_FIELD.length > 0 ? (
                            <React.Fragment>
                                <SectionLabel>{t("enrolment:section_title-additional_info")}</SectionLabel>
                                <CustomInputField />
                            </React.Fragment>
                        ) :
                            null}
                        <TermsAndConditionsAccordion label={t("enrolment:checkbox-agree_tc")} t={t}>
                            <SanitizeHtmlWrapper url={t("config:path-enrolment_tc")} />
                        </TermsAndConditionsAccordion>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button autoFocus data-cy={"centre_enrolment_card_cancel"} onClick={closeDialog} variant="outlined">
                            {t("general:button-cancel")}
                        </Button>
                        <SuccessButton type="submit" data-cy={"centre_enrolment_card_save"} variant="contained" >
                            {t("general:button-submit")}
                        </SuccessButton>
                    </Grid>
                </DialogActions>
            </React.Fragment>)
    } else { return null }

}


export default function CentreEnrolmentDialog({
    isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);
    const CENTRE = useCentreManagementState().currentCentre;

    const { centreId } = useParams();

    const [selectedProfile, setSelectedProfile] = React.useState(null);
    const [selectedProfileType, setSelectedProfileType] = React.useState(null);
    const ENROLMENT_FORM_METHOD = useForm({
        resolver: yupResolver(createCentreEnrolmentFormSchema()),
        defaultValue: INITIAL_CENTRE_ENROLMENT_FORM
    });

    React.useEffect(() => {
        if (isDialogOpen === true) {
            setSelectedProfile(null);
            setSelectedProfileType(null);
            ENROLMENT_FORM_METHOD.reset(INITIAL_CENTRE_ENROLMENT_FORM)
        }
    }, [isDialogOpen])


    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const onClickHandler = (type, currentSelected) => {
        setSelectedProfileType(type)
        setSelectedProfile(currentSelected);
    }

    const enrolCentre = (data) => {
        data.centre = centreId;
        data.enrolmentType = selectedProfileType;
        data.profileId = selectedProfile._id;
        addCentreEnrolment(data);
        setIsDialogOpen(false);
    }

    let content = (<EnrolmentCentreList onClickHandler={onClickHandler} />);
    let title = t("enrolment:dialog_title-select_profile");

    if (selectedProfile) {
        title = t("general:dialog_title-apply_entity", { entity: CENTRE.displayName });
        content = (<EnrolmentForm setSelectedProfile={setSelectedProfile} setSelectedProfileType={setSelectedProfileType} type={selectedProfileType} currentSelected={selectedProfile} closeDialog={closeDialog} />)
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth={"md"}
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...ENROLMENT_FORM_METHOD} >
                <DialogFixedForm control={ENROLMENT_FORM_METHOD.control}
                    errors={ENROLMENT_FORM_METHOD.errors}
                    onSubmit={ENROLMENT_FORM_METHOD.handleSubmit(enrolCentre)}>
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    {content}
                </DialogFixedForm>
            </FormProvider>
        </Dialog >
    )
}