import { createReduxModule } from "hooks-for-redux"
import {
    addStudent,
    addTutor,
    getStudent,
    saveStudent,
    getTutor,
    saveTutor,
    getTutorInfo,
    getStudentInfo,
} from "./../api/accountAPI.tsx";
import {
    updateStudentAdditionInfoRequest
} from "./../api/enrolmentAPI";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";


function initialState() {
    return {
        updateAction: [],
        request: initalRequestState(),
        username: "",
        tutorEnrolmentId: "",
        studentEnrolmentId: "",
        idNameMap: {},
        studentToBeAdded: {},
        studentToBeAddedInFormData: {},
        tutorToBeAdded: {},
        tutorToBeAddedInFormData: {},
        studentProfiles: null,
        studentProfileInfo: {},
        tutorProfiles: null,
        tutorProfileInfo: {},
    }
}

export const UPDATE_ACTION = {
    "ADD_STUDENT_PROFILE": "ADD_STUDENT_PROFILE",
    "GET_STUDENT_PROFILE": "GET_STUDENT_PROFILE",
    "SAVE_STUDENT_PROFILE": "SAVE_STUDENT_PROFILE",
    "GET_STUDENT_PROFILE_INFO": "GET_STUDENT_PROFILE_INFO",
    "ADD_TUTOR_PROFILE": "ADD_TUTOR_PROFILE",
    "GET_TUTOR_PROFILE": "GET_TUTOR_PROFILE",
    "SAVE_TUTOR_PROFILE": "SAVE_TUTOR_PROFILE",
    "GET_TUTOR_PROFILE_INFO": "GET_TUTOR_PROFILE_INFO",
    UPDATE_STUDENT_ADDITIONAL_INFO: "UPDATE_STUDENT_ADDITIONAL_INFO"
};

function refreshNameIdMapAction(state, payload) {
    let result = {};
    let studentProfiles = (state.studentProfiles) || [];
    let tutorProfiles = (state.tutorProfiles) || [];
    for (let profile of studentProfiles) {
        result[profile._id] = { chiName: profile.chiName, engName: profile.engName };
    }

    for (let profile of tutorProfiles) {
        result[profile._id] = { chiName: profile.chiName, engName: profile.engName };
    }
    return result;
}

export const [useProfileState, {
    addStudentProfile, addStudentProfileSuccess, addStudentProfileFail,
    getStudentProfile, getStudentProfileSuccess, getStudentProfileFail,
    saveStudentProfile, saveStudentProfileSuccess, saveStudentProfileFail,
    addTutorProfile, addTutorProfileSuccess, addTutorProfileFail,
    getTutorProfile, getTutorProfileSuccess, getTutorProfileFail,
    saveTutorProfile, saveTutorProfileSuccess, saveTutorProfileFail,
    getTutorProfileInfo, getTutorProfileInfoSuccess, getTutorProfileInfoFail,
    getStudentProfileInfo, getStudentProfileInfoSuccess, getStudentProfileInfoFail,
    updateStudentAdditionalInfo, updateStudentAdditionalInfoSuccess, updateStudentAdditionalInfoFail,
    refreshNameIdMap,
    setUpdating,
    clearUsername
},
    profileState
] = createReduxModule(
    "profileState",
    initialState(), {
    addStudentProfile: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_STUDENT_PROFILE),
        studentToBeAdded: payload.student,
        studentToBeAddedInFormData: payload.studentInForm
    }),
    addStudentProfileSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentToBeAdded: {},
        studentToBeAddedInFormData: {},
    }),
    addStudentProfileFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getStudentProfile: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_STUDENT_PROFILE),
        username: payload.username,
    }),
    getStudentProfileSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentProfiles: payload.data
    }),
    getStudentProfileFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    saveStudentProfile: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SAVE_STUDENT_PROFILE),
        studentToBeAdded: payload.student,
        studentToBeAddedInFormData: payload.studentInForm
    }),
    saveStudentProfileSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentToBeAdded: {},
        studentToBeAddedInFormData: {}
    }),
    saveStudentProfileFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addTutorProfile: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_TUTOR_PROFILE),
        tutorToBeAdded: payload.tutor,
        tutorToBeAddedInFormData: payload.tutorInForm
    }),
    addTutorProfileSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        tutorToBeAdded: {},
        tutorToBeAddedInFormData: {}
    }),
    addTutorProfileFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getTutorProfile: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_TUTOR_PROFILE),
        username: payload.username,
    }),
    getTutorProfileSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        tutorProfiles: payload.data
    }),
    getTutorProfileFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    saveTutorProfile: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SAVE_TUTOR_PROFILE),
        tutorToBeAdded: payload.tutor,
        tutorToBeAddedInFormData: payload.tutorInForm
    }),
    saveTutorProfileSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        tutorToBeAdded: {},
        tutorToBeAddedInFormData: {},
    }),
    saveTutorProfileFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getTutorProfileInfo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_TUTOR_PROFILE_INFO),
        tutorEnrolmentId: payload.profileId,
    }),
    getTutorProfileInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        tutorProfileInfo: payload.data
    }),
    getTutorProfileInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getStudentProfileInfo: (state, payload) => Object.assign({}, state, {
        request: initalRequestState(),
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_STUDENT_PROFILE_INFO),
        studentEnrolmentId: payload.profileId,
    }),
    getStudentProfileInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentProfileInfo: payload.data
    }),
    getStudentProfileInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateStudentAdditionalInfo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_STUDENT_ADDITIONAL_INFO),
        request: initalRequestState(),
        studentAdditionalInfoToBeUpdated: {
            enrolmentId: payload.enrolmentId,
            customField: payload.customField
        }
    }),
    updateStudentAdditionalInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentAdditionalInfoToBeUpdated: {}
    }),
    updateStudentAdditionalInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    refreshNameIdMap: (state, payload) => Object.assign({}, state, {
        idNameMap: refreshNameIdMapAction(state, payload)
    }),
    clearUsername: (state, payload) => Object.assign({}, state, { username: "" })
});

const UPDATE_ACTION_HANDLER = {
    "ADD_STUDENT_PROFILE": {
        handler: async (currentState) => {
            var student = currentState.studentToBeAddedInFormData;
            var result = await addStudent(student);
            if (result) {
                if (result.success) {
                    addStudentProfileSuccess({
                        "success": true,
                        "message": result.message
                    });
                    getStudentProfile({ username: currentState.username });
                } else {
                    addStudentProfileFail({
                        "success": false,
                        "message": "add student profile failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addStudentProfileFail
    },
    "GET_STUDENT_PROFILE": {
        handler: async (currentState) => {
            var result = await getStudent(currentState.username);
            if (result) {
                if (result.success) {
                    getStudentProfileSuccess({
                        "success": true,
                        "data": result.result,
                        "message": result.message
                    });
                    refreshNameIdMap();
                } else {
                    getStudentProfileFail({
                        "message": "get student profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getStudentProfileFail
    },
    "SAVE_STUDENT_PROFILE": {
        handler: async (currentState) => {
            var result = await saveStudent(currentState.studentToBeAddedInFormData);
            if (result) {
                if (result.success) {
                    saveStudentProfileSuccess({
                        "success": true,
                        "message": result.message
                    });
                    getStudentProfile({ username: currentState.username });
                } else {
                    saveStudentProfileFail({
                        "message": "save student profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: saveStudentProfileFail
    },
    "ADD_TUTOR_PROFILE": {
        handler: async (currentState) => {
            var formData = new FormData();
            var tutor = currentState.tutorToBeAdded;
            if (tutor && tutor.photo && tutor.photo.file) {
                formData.append("tutorPhoto", tutor.photo.file, "tutor");
            }
            formData.append("tutor", JSON.stringify(tutor));
            formData.append("username", currentState.username);
            var result = await addTutor(formData);
            if (result) {
                if (result.success) {
                    addTutorProfileSuccess({
                        "success": true,
                        "message": result.message
                    });
                    getTutorProfile({ username: currentState.username });
                } else {
                    addTutorProfileFail({
                        "message": "resend email request fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addTutorProfileFail
    },
    "GET_TUTOR_PROFILE": {
        handler: async (currentState) => {
            let result = await getTutor(currentState.username);
            if (result) {
                if (result.success) {
                    getTutorProfileSuccess({
                        "success": true,
                        "data": result.result,
                        "message": result.message
                    });
                    refreshNameIdMap();
                } else {
                    getTutorProfileFail({
                        "message": "get tutor profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getTutorProfileFail
    },
    "SAVE_TUTOR_PROFILE": {
        handler: async (currentState) => {
            let result = await saveTutor(currentState.tutorToBeAddedInFormData);
            if (result) {
                if (result.success) {
                    saveTutorProfileSuccess({
                        "success": true,
                        "message": result.message
                    });
                    getTutorProfile({ username: currentState.username });
                } else {
                    saveTutorProfileFail({
                        "message": "save tutor profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: saveTutorProfileFail
    },
    "GET_TUTOR_PROFILE_INFO": {
        handler: async (currentState, action) => {
            let result = await getTutorInfo(currentState.tutorEnrolmentId);
            if (result) {
                if (result.success) {
                    getTutorProfileInfoSuccess({
                        "success": true,
                        action: action,
                        "data": result.result,
                        "message": result.message
                    });
                } else {
                    getTutorProfileInfoFail({
                        "message": "get tutor profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getTutorProfileInfoFail
    }, "GET_STUDENT_PROFILE_INFO": {
        handler: async (currentState, action) => {
            let result = await getStudentInfo(currentState.studentEnrolmentId);
            if (result) {
                if (result.success) {
                    getStudentProfileInfoSuccess({
                        requestStatus: result.status,
                        action: action,
                        "success": true,
                        "data": result.result,
                        "message": result.message
                    });
                } else {
                    getStudentProfileInfoFail({
                        "message": "get student profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getStudentProfileInfoFail
    },
    "UPDATE_STUDENT_ADDITIONAL_INFO": {
        handler: async (currentState) => {
            let result = await updateStudentAdditionInfoRequest(currentState.studentAdditionalInfoToBeUpdated);
            if (result) {
                if (result.success) {
                    updateStudentAdditionalInfoSuccess({
                        "success": true,
                        "message": result.message
                    });
                    getStudentProfileInfo({ profileId: currentState.studentEnrolmentId });
                } else {
                    updateStudentAdditionalInfoFail({
                        "message": "save tutor profile fail",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateStudentAdditionalInfoFail
    },
}

profileState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});
