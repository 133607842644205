import React from 'react';
import {
    CardActionArea, Card, CardContent,
    Stack, Box, IconButton
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import EmptyAvatar from "./../../../asset/portrait/empty_avatar.png";
import LimitedTextLabel from "./../../../component/display/LimitedTextLabel";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SchoolIcon from '@mui/icons-material/School';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useMediaQuery from '@mui/material/useMediaQuery';
import { nameInBracketStyle } from "./../../../util/DisplayUtil";

const Portrait = styled("img")(({ theme }) => ({
    borderRadius: "10px",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    objectPosition: "50% 50%",
}));

export default function StudentCard({ currentStudent, index, cardOnClick, moreButtonOnClick, disabled = false }) {
    const { t } = useTranslation(["account", "attribute"]);
    const [studentPhotoPreview, setStudentPhotoPreview] = React.useState(EmptyAvatar);
    if (!cardOnClick) {
        cardOnClick = (event) => { event.preventDefault() };
    }
    React.useEffect(() => {
        if (currentStudent && currentStudent.photo && currentStudent.photo.file) {
            setStudentPhotoPreview(currentStudent.photo.file);
        }
    }, [currentStudent]);


    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let displayNameStyle = { fontWeight: "bold", paddingLeft: 0.5 };
    let cardContentStyle = { paddingLeft: 2 };
    let stackStyle = {};
    let contentVariant = "body2";

    if (smDown) {
        cardContentStyle = { width: "100%", paddingLeft: 2 };
        stackStyle = { width: "100%" };
        displayNameStyle = { paddingLeft: 0.5 }
        contentVariant = "caption";
    }

    let cardStyle = { display: { md: "block" }, width: "100%", marginTop: 0, marginBottom: 2, borderRadius: '10px' };
    if (disabled) {
        cardStyle.backgroundColor ="#EEEEEE";
    }

    return (
        <React.Fragment>
            <Card sx={cardStyle}>
                <CardActionArea component="span" disabled={disabled} disableTouchRipple data-cy={"edit_student_btn_" + (index + 1)} onClick={(event) => { cardOnClick.call(this, event, currentStudent) }}>
                    <CardContent sx={cardContentStyle} >
                        <Stack direction="row" sx={stackStyle} spacing={1.5} >
                            <Box>
                                <Portrait alt={"Student" + (index + 1)} src={studentPhotoPreview} />
                            </Box>
                            <Stack sx={{ width: "100%" }} justifyContent="center" alignItems="flex-start" spacing={1}>
                                <Stack sx={{ width: "100%" }} justifyContent="space-between" alignItems="center" direction="row">
                                    <LimitedTextLabel component="div" customstyle={displayNameStyle} variant="h6">
                                        {nameInBracketStyle(currentStudent.chiName, currentStudent.engName)}
                                    </LimitedTextLabel>
                                    {moreButtonOnClick ?
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation();
                                            moreButtonOnClick.call(this, event, currentStudent);
                                        }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                        : null}

                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <SchoolIcon color="disabled" fontSize="small" />
                                    <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                        {currentStudent.school}
                                    </LimitedTextLabel>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <AccessibilityNewIcon color="disabled" fontSize="small" />
                                    <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                        {t(currentStudent.grade, { ns: "attribute" })}
                                    </LimitedTextLabel>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}