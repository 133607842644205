import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Paper, Grid,
	Typography, Fab,
	IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from "@mui/icons-material/Edit";
import SmsIcon from "@mui/icons-material/Sms";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SpaceHolder } from "./../../common/Others";
import { useParams } from "react-router-dom";
import { useAuthenticationState } from "./../../../redux/authentication";
import { useClassSessionManagementState, removeTutorFromClassSession, removeFacilityFromClassSession } from "./../../../redux/ClassSessionManagement";
import ContentRow from "./../../common/ContentRow";
import TutorCard from "./../../page/tutor/TutorCard";
import CentreFacilityCard from "./../../page/centreDetail/CentreFacilityCard";
import moment from "moment";
import { ContextualMenuItem } from "./../../../component/common/Buttons";
import ContextualMenu from "./../../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../../component/common/ConfirmActionDialog";
import { Can } from "./../../..//casl/authorization";
import { subject } from "@casl/ability";
import { isPlatformAdmin, isCentreAdmin } from "./../../../util/ACLUtils";
import ClassSessionAddTutorDialog from "./dialog/ClassSessionAddTutorDialog";
import ClassSessionAddFacilityDialog from "./dialog/ClassSessionAddFacilityDialog";
import ClassSessionDialog from "./../../page/class/dialog/ClassSessionDialog";
const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

export default function ClassSessionAboutPage() {
	const CLASS_SESSION_INFO = useClassSessionManagementState().currentSession || {};
	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;
	const { sessionId } = useParams();
	const TUTOR_LIST = CLASS_SESSION_INFO.activeTutorList || [];
	const FACILITY_LIST = CLASS_SESSION_INFO.activeFacilityList || [];
	const { t } = useTranslation(["centre", "general"]);

	const [classAnchorEl, setClassAnchorEl] = React.useState(null);
	const [editDialogOpen, setEditDialogOpen] = React.useState(false);

	const [addTutorDialogOpen, setAddTutorDialogOpen] = React.useState(false);
	const [deleteTutorDialogOpen, setDeleteTutorDialogOpen] = React.useState(false);
	const [selectedTutor, setSelectedTutor] = React.useState(null);
	const [tutorAnchorEl, setTutorAnchorEl] = React.useState(null);

	const [selectedFacility, setSelectedFacility] = React.useState(null);
	const [facilityAnchorEl, setFacilityAnchorEl] = React.useState(null);
	const [addFacilityDialogOpen, setAddFacilityDialogOpen] = React.useState(false);
	const [deleteFacilityDialogOpen, setDeleteFacilityDialogOpen] = React.useState(false);

	const canRemoveTutor = ABILITY.can("update", subject("class", CLASS_SESSION_INFO.class || null), "tutors") && 
		(isPlatformAdmin(AUTH_STATE.currentRole) || isCentreAdmin(AUTH_STATE.currentRole));
	const canMessageTutor = (currentTutor) => ["student", "tutor", "centre_admin"].includes(AUTH_STATE.currentRole.roleName) && 
	CLASS_SESSION_INFO.centreId == AUTH_STATE.currentRole.centreId; 

	const triggerAddFacilityDialog = (event) => {
		event.preventDefault();
		setAddFacilityDialogOpen(true);
	};
	const triggerAddTutorDialog = (event) => {
		event.preventDefault();
		setAddTutorDialogOpen(true);
	};

	const removeTutorFromClass = (event) => {
		event.preventDefault();
		setTutorAnchorEl(null);
		setDeleteTutorDialogOpen(true);
	}

	const removeFacilityFromClass = (event) => {
		event.preventDefault();
		setFacilityAnchorEl(null);
		setDeleteFacilityDialogOpen(true);
	}

	const editClassInfo = (event) => {
		event.preventDefault();
		setClassAnchorEl(null);
		setEditDialogOpen(true);
	}

	const confirmRemoveTutorFromClass = (event) => {
		setDeleteTutorDialogOpen(false);
		removeTutorFromClassSession({ sessionId: sessionId, tutors: selectedTutor.enrolmentId });
	}

	const confirmRemoveFacilityFromClass = (event) => {
		setDeleteFacilityDialogOpen(false);
		removeFacilityFromClassSession({ sessionId: sessionId, facilities: selectedFacility._id })
	}

	const toggleClassContextualMenu = (event) => {
		event.preventDefault();
		setClassAnchorEl(event.currentTarget);
	}

	const toggleTutorContextualMenu = (event, tutorFromOnclick) => {
		event.preventDefault();
		setSelectedTutor(tutorFromOnclick);
		setTutorAnchorEl(event.currentTarget);
	};

	const toggleFacilityContextualMenu = (event, facilityFromOnclick) => {
		event.preventDefault();
		setSelectedFacility(facilityFromOnclick);
		setFacilityAnchorEl(event.currentTarget);
	};

	const openNewTutorTab = (event, currentTutor) => {
		event.preventDefault();
		window.open(`/tutor/${currentTutor.enrolmentId}`, "_blank");
	}

	const openNewFacilityTab = (event, currentFacility) => {
		event.preventDefault();
		window.open(`/facility/${currentFacility._id}`, "_blank");
	}

	return (
		<React.Fragment>
			<Paper sx={{ borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-about")}</Typography>
							<Can I="update" this={subject("class_session", CLASS_SESSION_INFO)} field="name" ability={ABILITY} >
								<IconButton onClick={toggleClassContextualMenu}
									aria-label="comment">
									<MoreHorizIcon />
								</IconButton>
							</Can>
						</HeaderRow>
						<ContentRow title={t("attribute:session-date")} content={moment(CLASS_SESSION_INFO.date).format("LL")} />
						<ContentRow title={t("attribute:class-time")} content={moment(CLASS_SESSION_INFO.startTime).format('hh:mm A') + t("general:time-separator") + moment(CLASS_SESSION_INFO.endTime).format('hh:mm A')} />
						<ContentRow title={t("attribute:session-remarks")} content={CLASS_SESSION_INFO.remarks} />
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>

			<Paper sx={{ marginTop: 2, marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-tutors")}</Typography>
							<Can I="update" this={subject("class_session", CLASS_SESSION_INFO)} field="tutors" ability={ABILITY}>
								<Fab color="primary" size="small" onClick={triggerAddTutorDialog} aria-label="comment">
									<AddIcon />
								</Fab>
							</Can>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			{
				TUTOR_LIST.map((tutorEnrolment, index) => {
					let currentTutor = tutorEnrolment.profile;
					return (<Grid key={currentTutor._id + currentTutor.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
						<TutorCard index={index}
							key={currentTutor._id + currentTutor.displayName}
							cardOnClick={openNewTutorTab}
							currentTutor={currentTutor}
							moreButtonOnClick={canRemoveTutor || canMessageTutor(tutorEnrolment.profile) ? toggleTutorContextualMenu : null} />
					</Grid>)
				})
			}
			<Paper sx={{ marginBottom: 2, borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-facilities")}</Typography>
							<Can I="update" this={subject("class_session", CLASS_SESSION_INFO)} field="facilities" ability={ABILITY}>
								<Fab color="primary" size="small" onClick={triggerAddFacilityDialog} aria-label="comment">
									<AddIcon />
								</Fab>
							</Can>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			{
				FACILITY_LIST.map((currentFacility, index) => {
					return (<Grid key={currentFacility._id + currentFacility.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
						<CentreFacilityCard index={index}
							key={currentFacility._id + currentFacility.displayName}
							handleOnClick={openNewFacilityTab}
							currentFacility={currentFacility}
							moreButtonOnClick={ABILITY.can("update", subject("class_session", CLASS_SESSION_INFO), "facilities") ? toggleFacilityContextualMenu : null} />
					</Grid>)
				})
			}
			<ContextualMenu id={"tutor-menu"} anchorEl={tutorAnchorEl} setAnchorEl={setTutorAnchorEl} >
				{canMessageTutor(selectedTutor) && <ContextualMenuItem
						onClick={(e) => window.location.href = (`/message/direct?tutor=${selectedTutor.enrolmentId}`)}
						title={t("general:button-chat")}>
						<SmsIcon sx={{ mr: 0 }} fontSize="small" />
					</ContextualMenuItem>}
				{canRemoveTutor &&<ContextualMenuItem
					onClick={removeTutorFromClass}
					title={t("session:button-remove")}>
					<RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>}
			</ContextualMenu>
			<ContextualMenu id={"facility-menu"} anchorEl={facilityAnchorEl} setAnchorEl={setFacilityAnchorEl} >
				<ContextualMenuItem
					onClick={removeFacilityFromClass}
					title={t("session:button-remove")}>
					<RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ContextualMenu id={"class-menu"} anchorEl={classAnchorEl} setAnchorEl={setClassAnchorEl} >
				<ContextualMenuItem
					onClick={editClassInfo}
					title={t("general:button-edit")}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ClassSessionDialog
				title={t("general:dialog_title-edit_entity_info", { entity: t("attribute:session") })}
				currentSession={CLASS_SESSION_INFO}
				isDialogOpen={editDialogOpen}
				setIsDialogOpen={setEditDialogOpen} />
			<ConfirmActionDialog
				title={t("general:dialog_title-remove_entity", { entity: t("attribute:tutor") })}
				actionName={t("general:button-remove")}
				open={deleteTutorDialogOpen}
				setOpen={setDeleteTutorDialogOpen}
				confirmOnClick={confirmRemoveTutorFromClass}
			/>
			<ConfirmActionDialog
				title={t("general:dialog_title-remove_entity", { entity: t("attribute:facility") })}
				actionName={t("general:button-remove")}
				open={deleteFacilityDialogOpen}
				setOpen={setDeleteFacilityDialogOpen}
				confirmOnClick={confirmRemoveFacilityFromClass}
			/>
			<ClassSessionAddTutorDialog
				title={t("general:dialog_title-select_entity", { entity: t("attribute:tutor") })}
				isDialogOpen={addTutorDialogOpen}
				setIsDialogOpen={setAddTutorDialogOpen} />
			<ClassSessionAddFacilityDialog
				title={t("general:dialog_title-select_entity", { entity: t("attribute:facility") })}
				isDialogOpen={addFacilityDialogOpen}
				setIsDialogOpen={setAddFacilityDialogOpen} />

		</React.Fragment >
	);
}
