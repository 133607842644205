import React from 'react';
import { useTranslation } from "react-i18next";
import { INIT_CLASS_SESSION, CLASS_SESSION_FIELD } from "./../model/ClassFormModel";
import { TextField } from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useFormContext, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

export default function ClassSessionFieldList({  currentSession }) {

    const { reset } = useFormContext();
    const { control, formState: { errors }, setValue } = useFormContext();
    const { t } = useTranslation();
    React.useEffect(() => {
        if (currentSession) {
            reset(currentSession);
        } else {
            reset(INIT_CLASS_SESSION)
        }
    }, [currentSession])

    return (
        <React.Fragment>
            <Controller
                name={CLASS_SESSION_FIELD.date}
                control={control}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        fullWidth
                        openTo="day"
                        views={["year", "month", "day"]}
                        id={CLASS_SESSION_FIELD.date}
                        format="DD/MM/yyyy"
                        label={t("attribute:session-date")}
                        onChange={(newValue) => {
                            setValue(CLASS_SESSION_FIELD.date, newValue);
                        }}
                        renderInput={(params) => <StyledTextField {...params} fullWidth
                            helperText={errors[CLASS_SESSION_FIELD.date] ? t(errors[CLASS_SESSION_FIELD.date].message) : null}
                            error={errors[CLASS_SESSION_FIELD.date] != null}
                        />}
                    />
                )}
            />
            <Controller
                name={CLASS_SESSION_FIELD.startTime}
                defaultValue={""}
                control={control}
                render={({ field }) => (
                    <TimePicker
                        {...field}
                        fullWidth
                        disableFuture
                        id={CLASS_SESSION_FIELD.startTime}
                        label={t("attribute:session-time-start")}
                        error={errors[CLASS_SESSION_FIELD.startTime] != null}
                        onChange={(newValue) => {
                            setValue(CLASS_SESSION_FIELD.startTime, newValue);
                        }}
                        renderInput={(params) => <StyledTextField
                            {...params}
                            fullWidth
                            error={errors[CLASS_SESSION_FIELD.startTime]}
                            helperText={errors[CLASS_SESSION_FIELD.startTime] ? t(errors[CLASS_SESSION_FIELD.startTime].message) : null}
                        />}
                    />
                )}
            />
            <Controller
                name={CLASS_SESSION_FIELD.endTime}
                defaultValue={""}
                control={control}
                render={({ field }) => (
                    <TimePicker
                        {...field}
                        fullWidth
                        disableFuture
                        id={CLASS_SESSION_FIELD.endTime}
                        label={t("attribute:class-time-end")}
                        error={errors[CLASS_SESSION_FIELD.endTime] != null}
                        onChange={(newValue) => {
                            setValue(CLASS_SESSION_FIELD.endTime, newValue);
                        }}
                        renderInput={(params) => <StyledTextField
                            {...params}
                            fullWidth
                            error={errors[CLASS_SESSION_FIELD.endTime]}
                            helperText={errors[CLASS_SESSION_FIELD.endTime] ? t(errors[CLASS_SESSION_FIELD.endTime].message) : null}
                        />}
                    />
                )}
            />
            
            <Controller
                name={CLASS_SESSION_FIELD.remarks}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:session-remarks")}
                        helperText={errors[CLASS_SESSION_FIELD.remarks] != null ? t(errors[CLASS_SESSION_FIELD.remarks].message) : null}
                        error={errors[CLASS_SESSION_FIELD.remarks] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
