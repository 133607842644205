import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Paper, Grid, IconButton,
	Typography, Fab, Box, Stack, Pagination
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SpaceHolder } from "./../../common/Others";
import debounce from 'lodash.debounce';
import { useParams } from "react-router-dom";
import { getHomeworkList, deleteHomeworkList, useHomeworkManagementState } from "./../../../redux/Homework";
import StudentHomeworkDialog from "./../../page/student/dialog/StudentHomeworkDialog";
import ContextualMenu from "./../../common/ContextualMenu";
import { ContextualMenuItem } from "./../../common/Buttons";
import MOMENT from "moment";
import ConfirmActionDialog from "./../../common/ConfirmActionDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5)
}));

function HomeworkImageGrid({ title, url, currentFile, moreButtonOnClickHandler }) {
	const openImage = (event, file) => {
		event.preventDefault();
		window.open(file.url, "_blank");
	}
	return (
		<Paper sx={{ width: "100%", mt: 2, pt: 2, pb: 5, borderRadius: "10px" }}>
			<Grid container>
				<SpaceHolder container item xs={0.5} />
				<Grid sx={{ pt: 2, paddingBottom: 1 }} container item xs={11}>
					<Stack sx={{ width: "100%", marginBottom: 2 }} direction="row" justifyContent="space-between">
						<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{title}</Typography>
						<IconButton key={currentFile.filename + "_more_btn"} onClick={(event) => {
							moreButtonOnClickHandler(event, currentFile);
						}} aria-label="comment">
							<MoreHorizIcon />
						</IconButton>
					</Stack>
				</Grid>
				<SpaceHolder container item xs={0.5} />
			</Grid>
			<Grid container>
				<Box
					onClick={(event) => { openImage(event, currentFile) }}
					component="img"
					sx={{
						"objectFit": "contain",
						width: "100%",
						height: "auto",
						maxHeight: "80vh",
						maxWidth: "100%",
					}}
					alt="latest homework list"
					src={url}
				/>
			</Grid>
		</Paper >);
}
const ACTION_IN_CHECK = {
	"DELETE_HOMEWORK_LIST": true,
	"UPLOAD_HOMEWORK_LIST": true,
};

export default function StudentSchedulePage() {
	const { t } = useTranslation();
	const { enrolmentId } = useParams();
	const [selectedFile, setSelectedFile] = React.useState(null);
	const [uploadHomeworkDialog, setUploadHomeworkDialog] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [page, setPage] = React.useState(1);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const debouncedGetHomeworkList = React.useMemo(() => debounce(getHomeworkList, 50), []);
	const HOMEWORK_LISTS = useHomeworkManagementState().homeworkLists;
	const HOMEWORK_REQUEST = useHomeworkManagementState().request;
	const ITEMS_PER_PAGE = 5;
	const NUMBER_OF_PAGE = Math.ceil(HOMEWORK_LISTS.length / ITEMS_PER_PAGE);
	const toggleUploadDialog = (event) => {
		event.preventDefault();
		setUploadHomeworkDialog(true);
	}

	const toggleDeleteDialog = (event) => {
		event.preventDefault();
		setDeleteDialogOpen(true);
		setAnchorEl(null);
	}

	const triggerContextualMenu = (event, currentFile) => {
		event.preventDefault();
		setSelectedFile(currentFile);
		setAnchorEl(event.currentTarget);
	}

	const triggerDeleteHomeworkListFile = (event) => {
		event.preventDefault();
		deleteHomeworkList({
			enrolmentId: enrolmentId,
			file: selectedFile
		})
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
		console.log(newPage);
	}

	React.useEffect(() => {
		debouncedGetHomeworkList({ enrolmentId: enrolmentId });
	}, []);


	React.useEffect(() => {
		if (ACTION_IN_CHECK[HOMEWORK_REQUEST.lastAction]) {
			if (HOMEWORK_REQUEST.status === "REFRESH_DATA_REQUIRE") {
				debouncedGetHomeworkList({ enrolmentId: enrolmentId });
			}
		}
	}, [HOMEWORK_REQUEST]);

	return (
		<React.Fragment>
			<Paper sx={{ borderRadius: "10px 10px 10px 10px", paddingBottom: 2, marginBottom: 2 }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("student:section_title-schoolwork")}</Typography>
							<Fab color="primary" size="small" onClick={toggleUploadDialog} aria-label="comment">
								<AddIcon />
							</Fab>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			{HOMEWORK_LISTS.slice((page - 1) * ITEMS_PER_PAGE, (page - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map((element) =>
				<HomeworkImageGrid key={`${element.filename}_${element.createdTime}`}
					moreButtonOnClickHandler={triggerContextualMenu}
					currentFile={element}
					title={MOMENT(element.createdTime).format("LL")}
					url={element.url}
				/>
			)}
			<Stack sx={{ width: "100%", mt: 3, mb: 2 }} direction="row" justifyContent="center" >
				<Pagination
					page={page}
					onChange={handlePageChange}
					count={NUMBER_OF_PAGE} />
			</Stack>
			<ConfirmActionDialog
				title={t("general:dialog_title-delete_entity", { entity: t("attribute:student-schoolwork_list") })}
				actionName={t("general:button-delete")}
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				confirmOnClick={triggerDeleteHomeworkListFile}
			/>
			<StudentHomeworkDialog setOpen={setUploadHomeworkDialog} open={uploadHomeworkDialog} />
			<ContextualMenu id="post_popover_menu" anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				<ContextualMenuItem title={t("general:button-delete")} onClick={toggleDeleteDialog}>
					<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
		</React.Fragment >
	);
}
