import React from "react";
import { MenuItem, ListSubheader, Select, InputLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

const GroupLabel = styled(ListSubheader)(({ theme }) => ({
    zIndex: "2",
    background: "#FFFFFF"
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DropdownItems({ field, options, labelId, labelText }) {
    var result = [];
    const { t } = useTranslation("attribute");
    for (let i of options) {
        if (i.group !== null) {
            result.push((<GroupLabel disabled key={i.group}>{t(i.group)}</GroupLabel>));
        }
        for (let option of i.options) {
            result.push(<MenuItem key={option} value={option}>{t(option)}</MenuItem>);
        }
    }

    return (
        <React.Fragment>
            <InputLabel id={labelId}>{labelText}</InputLabel>
            <Select {...field}
                data-cy={labelId}
                labelId={labelId}
                MenuProps={MenuProps}
                label={labelText}>
                {result}
            </Select>
        </React.Fragment>
    );
}
