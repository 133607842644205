import {
    createReduxModule
} from "hooks-for-redux";
import {
    createClassSessionRequest,
    getClassStudentForClassSessionRequest,
    getActiveClassStudentForClassSessionRequest,
    getClassSessionRequest,
    getClassSessionInfoRequest,
    getClassTutorForClassSessionRequest,
    getClassFacilityForClassSessionRequest,
    deleteClassSessionRequest,
    confirmClassSessionRequest,
    saveClassSessionRequest,
    addTutorToClassSessionRequest,
    removeTutorFromClassSessionRequest,
    addFacilityToClassSessionRequest,
    removeFacilityFromClassSessionRequest,
    addStudentToClassSessionRequest,
    removeStudentFromClassSessionRequest,
    updateStudentAttendanceRequest,
} from "./../api/classSessionAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import { pushRedirect } from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        classEvent: [],
        queryType: null,
        queryId: null,
        pendingClassSession: [],
        currentSession: {},
        newTutorList: [],
        tutorNameMap:{},
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "GET_CLASS_SESSION": "GET_CLASS_SESSION",
    "GET_CLASS_SESSION_INFO": "GET_CLASS_SESSION_INFO",
    "GET_CLASS_STUDENT_FOR_CLASS_SESSION": "GET_CLASS_STUDENT_FOR_CLASS_SESSION",
    "GET_ACTIVE_CLASS_STUDENT_FOR_CLASS_SESSION": "GET_ACTIVE_CLASS_STUDENT_FOR_CLASS_SESSION",
    "GET_CLASS_TUTOR_FOR_CLASS_SESSION": "GET_CLASS_TUTOR_FOR_CLASS_SESSION",
    "GET_ACTIVE_TUTOR_FOR_CLASS_SESSION": "GET_ACTIVE_TUTOR_FOR_CLASS_SESSION",
    "GET_CLASS_FACILITY_FOR_CLASS_SESSION": "GET_CLASS_FACILITY_FOR_CLASS_SESSION",
    "GET_ACTIVE_FACILITY_FOR_CLASS_SESSION": "GET_ACTIVE_FACILITY_FOR_CLASS_SESSION",
    "ADD_FACILITY_TO_SESSION": "ADD_FACILITY_TO_SESSION",
    "REMOVE_FACILITY_FROM_SESSION": "REMOVE_FACILITY_FROM_SESSION",
    "ADD_TUTOR_TO_SESSION": "ADD_TUTOR_TO_SESSION",
    "REMOVE_TUTOR_FROM_SESSION": "REMOVE_TUTOR_FROM_SESSION",
    "ADD_STUDENT_TO_SESSION": "ADD_STUDENT_TO_SESSION",
    "REMOVE_STUDENT_FROM_SESSION": "REMOVE_STUDENT_FROM_SESSION",
    "SAVE_CLASS_SESSION": "SAVE_CLASS_SESSION",
    "CREATE_CLASS_SESSION": "CREATE_CLASS_SESSION",
    "CONFIRM_CLASS_SESSION": "CONFIRM_CLASS_SESSION",
    "DELETE_CLASS_SESSION": "DELETE_CLASS_SESSION",
    "UPDATE_STUDENT_ATTENDANCE": "UPDATE_STUDENT_ATTENDANCE"
};

export const [useClassSessionManagementState, {
    setUpdating,
    getClassStudentForClassSession, getClassStudentForClassSessionSuccess, getClassStudentForClassSessionFail,
    getActiveClassStudentForClassSession, getActiveClassStudentForClassSessionSuccess, getActiveClassStudentForClassSessionFail,
    getClassSession, getClassSessionSuccess, getClassSessionFail,
    getClassSessionInfo, getClassSessionInfoSuccess, getClassSessionInfoFail,
    getClassTutorForClassSession, getClassTutorForClassSessionSuccess, getClassTutorForClassSessionFail,
    getClassFacilityForClassSession, getClassFacilityForClassSessionSuccess, getClassFacilityForClassSessionFail,
    addTutorToClassSession, addTutorToClassSessionSuccess, addTutorToClassSessionFail,
    removeTutorFromClassSession, removeTutorFromClassSessionSuccess, removeTutorFromClassSessionFail,
    addFacilityToClassSession, addFacilityToClassSessionSuccess, addFacilityToClassSessionFail,
    removeFacilityFromClassSession, removeFacilityFromClassSessionSuccess, removeFacilityFromClassSessionFail,
    addStudentToClassSession, addStudentToClassSessionSuccess, addStudentToClassSessionFail,
    removeStudentFromClassSession, removeStudentFromClassSessionSuccess, removeStudentFromClassSessionFail,
    createClassSession, createClassSessionSuccess, createClassSessionFail,
    saveClassSession, saveClassSessionSuccess, saveClassSessionFail,
    confirmClassSession, confirmClassSessionSuccess, confirmClassSessionFail,
    deleteClassSession, deleteClassSessionSuccess, deleteClassSessionFail,
    updateStudentAttendance, updateStudentAttendanceSuccess, updateStudentAttendanceFail
},
    classSessionManagementState
] = createReduxModule(
    "classSessionManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getClassStudentForClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_STUDENT_FOR_CLASS_SESSION),
        sessionId: payload.sessionId,
        classStudentCriteria: payload.searchCriteria
    }),
    getClassStudentForClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classStudentList: payload.data.newStudentList,
        classStudentCount: payload.data.newStudentCount
    }),
    getClassStudentForClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getActiveClassStudentForClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_ACTIVE_CLASS_STUDENT_FOR_CLASS_SESSION),
        sessionId: payload.sessionId,
        activeClassStudentCriteria: payload.searchCriteria
    }),
    getActiveClassStudentForClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        activeClassStudentList: payload.data.activeStudentList,
        activeClassStudentCount: payload.data.activeStudentCount
    }),
    getActiveClassStudentForClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_SESSION),
        queryType: payload.type,
        queryId: payload.queryId
    }),
    getClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        classEvent: payload.data.classSession,
        pendingClassEvent: payload.data.pendingClassSession,
        tutorNameMap : payload.data.tutorNameMap
    }),
    getClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassSessionInfo: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_SESSION_INFO),
        sessionId: payload.sessionId,
    }),
    getClassSessionInfoSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentSession: payload.data.sessionInfo
    }),
    getClassSessionInfoFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassTutorForClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_TUTOR_FOR_CLASS_SESSION),
        sessionId: payload.sessionId,
    }),
    getClassTutorForClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        newTutorList: payload.data.newTutorList,
    }),
    getClassTutorForClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getClassFacilityForClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_CLASS_FACILITY_FOR_CLASS_SESSION),
        sessionId: payload.sessionId,
    }),
    getClassFacilityForClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        newFacilityList: payload.data.newFacilityList,
    }),
    getClassFacilityForClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    createClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.CREATE_CLASS_SESSION),
        classSessionToBeCreated: payload.classSession
    }),
    createClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    createClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addTutorToClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_TUTOR_TO_SESSION),
        tutorToBeAdded: {
            sessionId: payload.sessionId,
            tutors: payload.tutors
        }
    }),
    addTutorToClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    addTutorToClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeTutorFromClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_TUTOR_FROM_SESSION),
        tutorToBeRemoved: {
            sessionId: payload.sessionId,
            tutors: payload.tutors
        }
    }),
    removeTutorFromClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    removeTutorFromClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addFacilityToClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_FACILITY_TO_SESSION),
        facilityToBeAdded: {
            sessionId: payload.sessionId,
            facilities: payload.facilities
        }
    }),
    addFacilityToClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    addFacilityToClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeFacilityFromClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_FACILITY_FROM_SESSION),
        facilityToBeRemoved: {
            sessionId: payload.sessionId,
            facilities: payload.facilities
        }
    }),
    removeFacilityFromClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    removeFacilityFromClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addStudentToClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_STUDENT_TO_SESSION),
        studentToBeAdded: {
            sessionId: payload.sessionId,
            students: payload.students
        }
    }),
    addStudentToClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    addStudentToClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeStudentFromClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_STUDENT_FROM_SESSION),
        studentToBeRemoved: {
            sessionId: payload.sessionId,
            students: payload.students
        }
    }),
    removeStudentFromClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    removeStudentFromClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    saveClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.SAVE_CLASS_SESSION),
        classSessionToBeSaved: {
            classSession: payload.classSession,
            currentSession: payload.currentSession
        }
    }),
    saveClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    saveClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    confirmClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.CONFIRM_CLASS_SESSION),
        classSessionToBeConfirm: {
            classId: payload.classId,
            id: payload.id
        }
    }),
    confirmClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    confirmClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    deleteClassSession: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_CLASS_SESSION),
        classSessionToBeRemoved: {
            classId: payload.classId,
            id: payload.id
        }
    }),
    deleteClassSessionSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    deleteClassSessionFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updateStudentAttendance: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_STUDENT_ATTENDANCE),
        studentToBeUpdated: {
            sessionId: payload.sessionId,
            students: payload.students,
            status: payload.value
        }
    }),
    updateStudentAttendanceSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    updateStudentAttendanceFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

});


const UPDATE_ACTION_HANDLER = {
    "GET_CLASS_STUDENT_FOR_CLASS_SESSION": {
        handler: async (currentState) => {
            let result = await getClassStudentForClassSessionRequest(currentState.sessionId, currentState.classStudentCriteria);
            if (result) {
                if (result.success) {
                    getClassStudentForClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassStudentForClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassStudentForClassSessionFail
    }, "GET_ACTIVE_CLASS_STUDENT_FOR_CLASS_SESSION": {
        handler: async (currentState) => {
            let result = await getActiveClassStudentForClassSessionRequest(currentState.sessionId, currentState.activeClassStudentCriteria);
            if (result) {
                if (result.success) {
                    getActiveClassStudentForClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getActiveClassStudentForClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getActiveClassStudentForClassSessionFail
    },
    "GET_CLASS_SESSION": {
        handler: async (currentState) => {
            let result = await getClassSessionRequest(currentState.queryId, currentState.queryType);
            if (result) {
                if (result.success) {
                    getClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassSessionFail
    }, "GET_CLASS_SESSION_INFO": {
        handler: async (currentState, action) => {
            let result = await getClassSessionInfoRequest(currentState.sessionId);
            if (result) {
                if (result.success) {
                    getClassSessionInfoSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    getClassSessionInfoFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassSessionInfoFail
    }, "GET_CLASS_TUTOR_FOR_CLASS_SESSION": {
        handler: async (currentState) => {
            let result = await getClassTutorForClassSessionRequest(currentState.sessionId);
            if (result) {
                if (result.success) {
                    getClassTutorForClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassTutorForClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassTutorForClassSessionFail
    }, "GET_CLASS_FACILITY_FOR_CLASS_SESSION": {
        handler: async (currentState) => {
            let result = await getClassFacilityForClassSessionRequest(currentState.sessionId);
            if (result) {
                if (result.success) {
                    getClassFacilityForClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    getClassFacilityForClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getClassFacilityForClassSessionFail
    }, "ADD_TUTOR_TO_SESSION": {
        handler: async (currentState, action) => {
            let result = await addTutorToClassSessionRequest(currentState.tutorToBeAdded);
            if (result) {
                if (result.success) {
                    addTutorToClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    addTutorToClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addTutorToClassSessionFail
    }, "REMOVE_TUTOR_FROM_SESSION": {
        handler: async (currentState, action) => {
            let result = await removeTutorFromClassSessionRequest(currentState.tutorToBeRemoved);
            if (result) {
                if (result.success) {
                    removeTutorFromClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    removeTutorFromClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeTutorFromClassSessionFail
    }, "ADD_FACILITY_TO_SESSION": {
        handler: async (currentState, action) => {
            let result = await addFacilityToClassSessionRequest(currentState.facilityToBeAdded);
            if (result) {
                if (result.success) {
                    addFacilityToClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    addFacilityToClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addFacilityToClassSessionFail
    }, "REMOVE_FACILITY_FROM_SESSION": {
        handler: async (currentState, action) => {
            let result = await removeFacilityFromClassSessionRequest(currentState.facilityToBeRemoved);
            if (result) {
                if (result.success) {
                    removeFacilityFromClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    removeFacilityFromClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeFacilityFromClassSessionFail
    }, "ADD_STUDENT_TO_SESSION": {
        handler: async (currentState, action) => {
            let result = await addStudentToClassSessionRequest(currentState.studentToBeAdded);
            if (result) {
                if (result.success) {
                    addStudentToClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    addStudentToClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addStudentToClassSessionFail
    }, "REMOVE_STUDENT_FROM_SESSION": {
        handler: async (currentState, action) => {
            let result = await removeStudentFromClassSessionRequest(currentState.studentToBeRemoved);
            if (result) {
                if (result.success) {
                    removeStudentFromClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    removeStudentFromClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeStudentFromClassSessionFail
    },
    "CREATE_CLASS_SESSION": {
        handler: async (currentState, action) => {
            let result = await createClassSessionRequest(currentState.classSessionToBeCreated);
            if (result) {
                if (result.success) {
                    createClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                    pushRedirect({ path: `/class_session/${result.data._id}` })
                } else {
                    createClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: createClassSessionFail
    }, "SAVE_CLASS_SESSION": {
        handler: async (currentState, action) => {
            let result = await saveClassSessionRequest(currentState.classSessionToBeSaved);
            if (result) {
                if (result.success) {
                    saveClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    saveClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: saveClassSessionFail
    },
    "CONFIRM_CLASS_SESSION": {
        handler: async (currentState, action) => {
            let result = await confirmClassSessionRequest(currentState.classSessionToBeConfirm);
            if (result) {
                if (result.success) {
                    confirmClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    confirmClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: confirmClassSessionFail
    },
    "DELETE_CLASS_SESSION": {
        handler: async (currentState, action) => {
            let result = await deleteClassSessionRequest(currentState.classSessionToBeRemoved);
            if (result) {
                if (result.success) {
                    deleteClassSessionSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    deleteClassSessionFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteClassSessionFail
    }, "UPDATE_STUDENT_ATTENDANCE": {
        handler: async (currentState, action) => {
            let result = await updateStudentAttendanceRequest(currentState.studentToBeUpdated);
            if (result) {
                if (result.success) {
                    updateStudentAttendanceSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data,
                        "requestStatus": result.status,
                        "action": action
                    });
                } else {
                    updateStudentAttendanceFail({
                        "message": "general:error-unknown",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deleteClassSessionFail
    }
};

classSessionManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});