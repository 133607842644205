import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    List, ListItem, ListItemText,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Paper, Grid, Button,
    IconButton, TextField
} from "@mui/material";
import LoadingPage from "./LoadingPage";
import { DashboardRootContainer, AdminFormRow } from "./../../component/common/Containers";
import { SpaceHolder } from "./../../component/common/Others";

import {
    getStripeConfig
} from "./../../api/paymentAPI.tsx";
import { useAuthenticationState } from "./../../redux/authentication";
import {
    payStudentBillRequest
} from "./../../api/billAPI.tsx";

export default function PayBillsuccess () {
    const { t } = useTranslation(["bill","general", "config"]);

    const CURRENT_ROLE = useAuthenticationState().currentRole;

    const [isProcessingStripe, setIsProcessingStripe] = React.useState(true);
    const [isTimeout, setIsTimeout] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    // console.log('Current role ' + JSON.stringify(CURRENT_ROLE));

    const procSession = async (sessionId) => {
        try {
            if (sessionId) {
                let stripekey = await getStripeConfig();
                const stripe = require('stripe')(stripekey.privateKey);
                const session = await stripe.checkout.sessions.retrieve(sessionId);

                // console.log('SESSION ' + JSON.stringify(session));

                let billId = session.client_reference_id;
                let amount = session.amount_total / 100;

                let billResult = await payStudentBillRequest({'billId': billId, 'studentId':CURRENT_ROLE.roleId, 'token': session.id, 'amount':amount});
                if (billResult.success) {
                    window.location.href = ('/student/' + CURRENT_ROLE.roleId + '?tab=bill');
                } else {
                    setIsProcessingStripe(false);
                    setIsError(true);
                }

            } else {
                setIsProcessingStripe(false);
                setIsTimeout(true);
            }
        } catch (err) {
            setIsProcessingStripe(false);
            setIsError(true);
            console.log('Error ' + JSON.stringify(err));
        }
    }

    let { sessionId } = useParams();
    React.useEffect(() => {
        procSession(sessionId);
    }, []);

    return(
        <React.Suspense fallback={<LoadingPage />}>
        <DashboardRootContainer>
            <Grid container>
                <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                { isProcessingStripe ? (t("bill:info-processing")) : null}
                { isTimeout ? (t("bill:error-session_timeout")) : null }
                { isError ? (t("general:error-unknown")) : null }
            </Grid>
        </DashboardRootContainer>
        </React.Suspense>
    )

}