import React from "react";
import {
    Grid, Button, Box, IconButton, Stack,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";
import { FormProvider, useForm, useFieldArray, Controller, useFormContext, useWatch} from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DialogFixedForm } from "../../../common/Forms";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SpaceHolder } from "./../../../common/Others";
import { PAYSLIP_FIELD, INIT_PAYSLIP_FORM, createPayslipFormSchema } from "./../../../form/model/PayslipFormModel";
import { issuePayslip, updatePayslip } from "../../../../redux/PayslipManagement";
import SectionLabel from "./../../../display/styled/SectionLabel";

import { yupResolver } from "@hookform/resolvers/yup";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

function PayslipDynamicFieldItem({ index, name, handleAmountChange, deleteListItem }) {
  const { t } = useTranslation(["general", "config", "attribute", "payslip"]);

  const PAYSLIP_FORM_METHOD = useFormContext();
  const { errors } = PAYSLIP_FORM_METHOD.formState;
  
  let descName = name+`.${index}.desc`;
  let amountName = name+`.${index}.amount`;

  return (
    <Stack sx={{width:"100%"}} spacing={1} key={index} direction="row" justifyContent="space-between" alignItems="baseline">
          <Controller
            name={descName}
            control={PAYSLIP_FORM_METHOD.control}
            render={({ field }) => (
              <StyledTextField
                variant="outlined"
                fullWidth
                label={t('attribute:item-description')}
                helperText={
                  errors[descName]
                    ? t(errors[descName].message)
                    : null
                }
                error={errors[descName] != null}
                {...field}
              />
            )}
          />
          <Controller
            name={name+`.${index}.amount`}
            control={PAYSLIP_FORM_METHOD.control}
            onChange={handleAmountChange}
            render={({ field }) => (
              <StyledTextField
                variant="outlined"
                label={t('attribute:item-amount')}
                type="number"
                helperText={
                  errors[amountName]
                    ? t(errors[amountName].message)
                    : null
                }
                error={errors[amountName] != null}
                {...field}
              />
            )}
          />
          <Box>
          <IconButton onClick={event => deleteListItem(event)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
          </Box>
    </Stack>
  );
}

const CalculatePayslipTotal = (relevantIncomes, nonRelevantIncomes, deductions) => {
  const relevantIncomeTotal = relevantIncomes.reduce((acc, income) => parseFloat(acc) + parseFloat(income.amount), 0);
  const nonRelevantIncomeTotal = nonRelevantIncomes.reduce((acc, income) => parseFloat(acc) + parseFloat(income.amount), 0);
  const deductionTotal = deductions.reduce((acc, deduction) => parseFloat(acc) + parseFloat(deduction.amount), 0);
  const total = relevantIncomeTotal + nonRelevantIncomeTotal - deductionTotal;
  return total;
};

const CalculatePayslipSubtotal = (inputArray) => {
  if(inputArray && inputArray.reduce){
      let total = inputArray.reduce((acc, item) => parseFloat(acc) + parseFloat(item.amount), 0);
      return total;
  }
  return 0;
};


const PayslipTotalAmount = () => {
  let result = 0.00;
  const {control,setValue} = useFormContext();
  const relatvantIncome = useWatch({ control, name: PAYSLIP_FIELD.relevantIncomes });
  const nonRelatventIncome = useWatch({ control, name: PAYSLIP_FIELD.nonRelevantIncomes });
  const detection = useWatch({ control, name: PAYSLIP_FIELD.deductions });
  const employerContributions = useWatch({ control, name: PAYSLIP_FIELD.employerContributions });
  
  result = CalculatePayslipTotal(relatvantIncome,nonRelatventIncome,detection);
  return "$ " + result;
};

const PayslipSubtotalAmount = ({name}) =>{
  let result = 0.00;
  const {control,setValue} = useFormContext();
  const arrayList = useWatch({ control, name: name });
  result = CalculatePayslipSubtotal(arrayList);
  return "$ " + result;  
}


const SectionTitle = ({ title, inputArray }) => {
    return (<Stack sx={{ marginTop: 3 }} direction="row" justifyContent="space-between">
        <SectionLabel>{title}</SectionLabel>
        <SectionLabel><PayslipSubtotalAmount name={inputArray}/></SectionLabel>
    </Stack>
    )
}

export default function TutorPayslipsDialog({
    title, payslip, centreId, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config", "attribute", "payslip"]);

    let { enrolmentId } = useParams();

    const PAYSLIP_FORM_METHOD = useForm({
        resolver: yupResolver(createPayslipFormSchema())
    });
    const { errors } = PAYSLIP_FORM_METHOD.formState;
    const REL_INCOME = useFieldArray({ name: PAYSLIP_FIELD.relevantIncomes, control: PAYSLIP_FORM_METHOD.control });
    const NON_REL_INCOME = useFieldArray({ name: PAYSLIP_FIELD.nonRelevantIncomes, control: PAYSLIP_FORM_METHOD.control });
    const DEDUCTION = useFieldArray({ name: PAYSLIP_FIELD.deductions, control: PAYSLIP_FORM_METHOD.control });
    const EMPLOYER_CONTRIBUTION = useFieldArray({ name: PAYSLIP_FIELD.employerContributions, control: PAYSLIP_FORM_METHOD.control });

    React.useEffect(() => {
        PAYSLIP_FORM_METHOD.reset(INIT_PAYSLIP_FORM);
    }, [isDialogOpen])

    React.useEffect(() => {
        if (payslip) {
            PAYSLIP_FORM_METHOD.reset(payslip);
        } else {
            PAYSLIP_FORM_METHOD.reset(INIT_PAYSLIP_FORM);
        }
    }, [enrolmentId, isDialogOpen])

    const addRelevantIncomes = () => {
        let newDetail = { desc: '', amount: 0 };
        REL_INCOME.append(newDetail);
    }

    const delRelevantIncomes = (index) => {
        REL_INCOME.remove(index);
    }

    const addNonRelevantIncomes = () => {
        let newDetail = { desc: '', amount: 0 };
        NON_REL_INCOME.append(newDetail);
    }

    const delNonRelevantIncomes = (index) => {
        NON_REL_INCOME.remove(index);
    }

    const addDeduction = () => {
        let newDetail = { desc: '', amount: 0 };
        DEDUCTION.append(newDetail);
    }

    const delDeduction = (index) => {
        DEDUCTION.remove(index);
    }

    const addEmployerContributions = () => {
        let newDetail = { desc: '', amount: 0 };
        EMPLOYER_CONTRIBUTION.append(newDetail);
    }

    const delEmployerContributions = (index) => {
        EMPLOYER_CONTRIBUTION.remove(index);
    }

    const handleAmountChange = () => {

    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const submitMethod = (data) => {
        if (payslip) {
            editPayslip(data);
        } else {
            addPayslip(data);
        }
    }

    const addPayslip = (data) => {
        // console.log('add payslip ' + JSON.stringify(data));
        data.tutorProfileId = enrolmentId;
        data.centreId = centreId;
        console.log('add payslip ' + JSON.stringify(data));
        issuePayslip(data);
        setIsDialogOpen(false);
    }

    const editPayslip = (data) => {
        data.centreId = centreId;
        data._id = payslip._id;
        updatePayslip(data);
        setIsDialogOpen(false);
    }



    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...PAYSLIP_FORM_METHOD} >
                <DialogFixedForm control={PAYSLIP_FORM_METHOD.control}
                    errors={PAYSLIP_FORM_METHOD.errors}
                    onSubmit={PAYSLIP_FORM_METHOD.handleSubmit(submitMethod)}>
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent dividers>

                        <Controller
                            name={PAYSLIP_FIELD.employeeId}
                            defaultValue={''}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={
                                ({ field }) => <StyledTextField
                                    variant="outlined" fullWidth
                                    label={t("attribute:payslip-employee_id")}
                                    type="number"
                                    helperText={errors[PAYSLIP_FIELD.employeeId] ? t(errors[PAYSLIP_FIELD.employeeId].message) : null}
                                    error={errors[PAYSLIP_FIELD.employeeId] != null}
                                    {...field}
                                />
                            }
                        />
                        <Controller
                            name={PAYSLIP_FIELD.designation}
                            defaultValue={''}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={
                                ({ field }) => <StyledTextField
                                    variant="outlined" fullWidth
                                    label={t("attribute:payslip-designation")}
                                    helperText={errors[PAYSLIP_FIELD.designation] ? t(errors[PAYSLIP_FIELD.designation].message) : null}
                                    error={errors[PAYSLIP_FIELD.designation] != null}
                                    {...field}
                                />
                            }
                        />
                        <Controller
                            name={PAYSLIP_FIELD.payPeriodFrom}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    fullWidth
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    id={PAYSLIP_FIELD.payPeriodFrom}
                                    format="DD/MM/yyyy"
                                    label={t("attribute:payslip-pay_period_start_date")}
                                    onChange={(newValue) => {
                                        PAYSLIP_FORM_METHOD.setValue(PAYSLIP_FIELD.payPeriodFrom, newValue);
                                    }}
                                    renderInput={(params) => <StyledTextField {...params} fullWidth
                                        helperText={errors[PAYSLIP_FIELD.payPeriodFrom] ? t(errors[PAYSLIP_FIELD.payPeriodFrom].message) : null}
                                        error={errors[PAYSLIP_FIELD.payPeriodFrom] != null}
                                    />}

                                />
                            )}
                        />
                        <Controller
                            name={PAYSLIP_FIELD.payPeriodTo}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    fullWidth
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    id={PAYSLIP_FIELD.payPeriodTo}
                                    format="DD/MM/yyyy"
                                    label={t("attribute:payslip-pay_period_end_date")}
                                    onChange={(newValue) => {
                                        PAYSLIP_FORM_METHOD.setValue(PAYSLIP_FIELD.payPeriodTo, newValue);
                                    }}
                                    renderInput={(params) => <StyledTextField {...params} fullWidth
                                        helperText={errors[PAYSLIP_FIELD.payPeriodTo] ? t(errors[PAYSLIP_FIELD.payPeriodTo].message) : null}
                                        error={errors[PAYSLIP_FIELD.payPeriodTo] != null}
                                    />}

                                />
                            )}
                        />
                        <Controller
                            name={PAYSLIP_FIELD.paymentDate}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    fullWidth
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    id={PAYSLIP_FIELD.paymentDate}
                                    format="DD/MM/yyyy"
                                    label={t("attribute:payslip-pay_date")}
                                    onChange={(newValue) => {
                                        PAYSLIP_FORM_METHOD.setValue(PAYSLIP_FIELD.paymentDate, newValue);
                                    }}
                                    renderInput={(params) => <StyledTextField {...params} fullWidth
                                        helperText={errors[PAYSLIP_FIELD.paymentDate] ? t(errors[PAYSLIP_FIELD.paymentDate].message) : null}
                                        error={errors[PAYSLIP_FIELD.paymentDate] != null}
                                    />}

                                />
                            )}
                        />
                        <Controller
                            name={PAYSLIP_FIELD.contributionDate}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    fullWidth
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    id={PAYSLIP_FIELD.contributionDate}
                                    format="DD/MM/yyyy"
                                    label={t("attribute:payslip-contribution_date")}
                                    onChange={(newValue) => {
                                        PAYSLIP_FORM_METHOD.setValue(PAYSLIP_FIELD.contributionDate, newValue);
                                    }}
                                    renderInput={(params) => <StyledTextField {...params} fullWidth
                                        helperText={errors[PAYSLIP_FIELD.contributionDate] ? t(errors[PAYSLIP_FIELD.contributionDate].message) : null}
                                        error={errors[PAYSLIP_FIELD.contributionDate] != null}
                                    />}

                                />
                            )}
                        />
                        <Controller
                            name={PAYSLIP_FIELD.paymentMethod}
                            defaultValue={''}
                            control={PAYSLIP_FORM_METHOD.control}
                            render={
                                ({ field }) => <StyledTextField
                                    variant="outlined" fullWidth
                                    label={t("attribute:payslip-payment_method")}
                                    helperText={errors[PAYSLIP_FIELD.paymentMethod] ? t(errors[PAYSLIP_FIELD.paymentMethod].message) : null}
                                    error={errors[PAYSLIP_FIELD.paymentMethod] != null}
                                    {...field}
                                />
                            }
                        />

                        <SectionTitle title={t("attribute:payslip-relevant_income")} inputArray={PAYSLIP_FIELD.relevantIncomes}/>
                        {REL_INCOME && REL_INCOME.fields && REL_INCOME.fields.map((item, index) => (
                                <PayslipDynamicFieldItem
                                  key={PAYSLIP_FIELD.relevantIncomes+"_"+index}
                                  index={index}
                                  name={PAYSLIP_FIELD.relevantIncomes}
                                  t={t}
                                  item={item}
                                  handleAmountChange={handleAmountChange}
                                  deleteListItem={delRelevantIncomes}
                                />
                        ))}

                        <Stack direction="row" spacing={2} sx={{ }}>
                            <Button autoFocus fullWidth onClick={addRelevantIncomes} variant="contained">
                                {t("general:button-add_item")}
                            </Button>
                        </Stack>
                        
                        <SectionTitle title={t("attribute:payslip-non_relevant_income")} inputArray={PAYSLIP_FIELD.nonRelevantIncomes}/>
                        {NON_REL_INCOME && NON_REL_INCOME.fields && NON_REL_INCOME.fields.map((item, index) => (
                            <PayslipDynamicFieldItem
                                  key={PAYSLIP_FIELD.nonRelevantIncomes+"_"+index}
                                  index={index}
                                  name={PAYSLIP_FIELD.nonRelevantIncomes}
                                  t={t}
                                  item={item}
                                  handleAmountChange={handleAmountChange}
                                  deleteListItem={delNonRelevantIncomes}
                            />
                        ))}

                        <Stack direction="row" spacing={2} sx={{ }}>
                            <Button autoFocus fullWidth onClick={addNonRelevantIncomes} variant="contained">
                                {t("general:button-add_item")}
                            </Button>
                        </Stack>

                        <SectionTitle title={t("attribute:payslip-deduction")} inputArray={PAYSLIP_FIELD.deductions}/>
                        {DEDUCTION && DEDUCTION.fields && DEDUCTION.fields.map((item, index) => (
                            <PayslipDynamicFieldItem
                                  key={PAYSLIP_FIELD.deductions+"_"+index}
                                  index={index}
                                  name={PAYSLIP_FIELD.deductions}
                                  t={t}
                                  item={item}
                                  handleAmountChange={handleAmountChange}
                                  deleteListItem={delDeduction}
                            />
                        ))}

                        <Stack direction="row" spacing={2} sx={{ }}>
                            <Button autoFocus fullWidth onClick={addDeduction} variant="contained">
                                {t("general:button-add_item")}
                            </Button>
                        </Stack>

                        <Stack sx={{marginTop:"24px",marginBottom:"24px"}} direction="row" spacing={2} justifyContent="space-between" >
                                <Typography variant="h6">
                                    {t("attribute:payslip-net_pay")}
                                </Typography>
                                <Typography variant="h6">
                                    <PayslipTotalAmount/>
                                </Typography>
                        </Stack>

                        <SectionTitle title={t("attribute:payslip-employer_contributions")} inputArray={PAYSLIP_FIELD.employerContributions}/>
                        {EMPLOYER_CONTRIBUTION && EMPLOYER_CONTRIBUTION.fields && EMPLOYER_CONTRIBUTION.fields.map((item, index) => (
                            <PayslipDynamicFieldItem
                                  key={PAYSLIP_FIELD.employerContributions+"_"+index}
                                  index={index}
                                  name={PAYSLIP_FIELD.employerContributions}
                                  t={t}
                                  handleAmountChange={handleAmountChange}
                                  deleteListItem={delEmployerContributions}
                            />
                        ))}

                        <Stack direction="row" spacing={2} sx={{ }}>
                            <Button autoFocus fullWidth onClick={addEmployerContributions} variant="contained">
                                {t("general:button-add_item")}
                            </Button>
                        </Stack>

                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button autoFocus onClick={closeDialog} variant="outlined">
                                {t("general:button-cancel")}
                            </Button>
                            <Button type="submit" variant="contained" >
                                {t("general:button-save")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </FormProvider>


        </Dialog >
    )
}