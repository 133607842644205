import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';
import {
	Autocomplete, FormControl,
	Grid, Stack, TextField,
	Divider, InputAdornment, Collapse,
	Typography, IconButton, Pagination,
	Button
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import OPTIONS_CATEGORY from "./../../../const/OptionsCategory";
import { SpaceHolder } from "./../../common/Others";
import { TabTitleContainer } from "./../../common/Containers";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import { loadCentreStudentEnrolment, useEnrolmentState } from "./../../../redux/Enrolment";

import { useOptionsState } from "./../../../redux/Options";
import CentrePendingStudentDialog from "./../../page/centreDetail/dialog/CentrePendingStudentDialog";

import { convertOptionsForAutoComplete } from "./../../../util/AutoCompleteUtil";
import StudentCard from "./../../page/student/StudentCard";


const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	"marginTop": theme.spacing(1),
	"paddingBottom": theme.spacing(1),
}));

const NarrowRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(1),
}));

export default function CentreStudentPage() {
	const { t } = useTranslation(["centre", "general"]);
	const [isFilterOpen, setIsFilterOpen] = React.useState(false);
	const [gradeFilterValue, setGradeFilterValue] = React.useState([]);
	const [searchString, setSearchString] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [page, setPage] = React.useState(0);
	const STUDENTS = useEnrolmentState().enroledStudent || [];
	const STUDENTS_COUNT = useEnrolmentState().enroledStudentCount;
	const PENDING_STUDENT = useEnrolmentState().pendingStudentEnrolment;
	const navigate = useNavigate();

	const OPTIONS_STATE = useOptionsState();
	let { centreId } = useParams();

	const OPTIONS = {
		GRADE: OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].options
	};

	let deboundedLoadCentreStudentEnrolment = React.useMemo(() => debounce(loadCentreStudentEnrolment, 50), []);
	const STUDENT_FILTER_FORM = useForm({ gradeFilter: [] });

	const handleChange = (event, newValue) => {
		event.preventDefault();
		setGradeFilterValue(newValue);
	};

	const updateSearchString = (event) => {
		event.preventDefault();
		setSearchString(event.target.value);
	};


	const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

	const toggleFilter = () => {
		setIsFilterOpen((prev) => !prev)
	}


	const handleClick = (event) => {
		event.preventDefault();
		if (PENDING_STUDENT.length > 0) {
			setDialogOpen((prev) => !prev);
		}
	};

	React.useEffect(() => {
		deboundedLoadCentreStudentEnrolment({
			centreId: centreId, searchCriteria: {
				search: searchString, grade: gradeFilterValue, page: page, rowsPerPage: rowsPerPage
			}
		});

	}, [])

	React.useEffect(() => {
		deboundedLoadCentreStudentEnrolment({
			centreId: centreId, searchCriteria: {
				search: searchString, grade: gradeFilterValue, page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [centreId, searchString, page, rowsPerPage, gradeFilterValue])

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-students")}</Typography>
							<CanUpdateCentre centreId={centreId} >
								<Button onClick={handleClick} sx={{ height: "50px", width: "140px" }} variant="contained">
									{t("general:button-pending") + ` (${PENDING_STUDENT.length})`}
								</Button>
							</CanUpdateCentre>
						</HeaderRow>
						<Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
							<TextField
								id="outlined-start-adornment"
								fullWidth
								onChange={(event) => { searchFieldOnChange(event) }}
								placeholder={t("general:label-search")}
								InputProps={{
									startAdornment:
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>,
									endAdornment:
										<InputAdornment position="end">
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											<IconButton
												name="filterToggle"
												aria-label="toggle filter"
												onClick={toggleFilter}
												onMouseDown={(event) => { event.preventDefault() }}
											>
												<FilterListIcon />
											</IconButton>
										</InputAdornment>,
								}}
							/>
							<Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
								<Stack>
									<StyledFormControl fullWidth variant="outlined">
										<Controller
											name="gradeFilter"
											defaultValue={gradeFilterValue}
											control={STUDENT_FILTER_FORM.control}
											render={
												({ field }) =>
													<Autocomplete
														multiple
														id="student_grade_filter"
														options={convertOptionsForAutoComplete(OPTIONS.GRADE)}
														getOptionLabel={(option) => t(`attribute:${option}`)}
														defaultValue={gradeFilterValue}
														value={gradeFilterValue}
														onChange={handleChange}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																label={t("attribute:student-grade")}
																placeholder={t("attribute:student-grade")}
															/>
														)}
													/>
											}
										/>
									</StyledFormControl>
								</Stack>
							</Collapse>
						</Stack>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>
			<NarrowRow container item md={12} spacing={0} >
				<Grid container justifyContent="center" item sm={12} md={12}>
					{STUDENTS.map(
						(studentEnrolment, index) => {
							let currentStudent = studentEnrolment.profile;
							return (<Grid key={currentStudent._id + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
								<StudentCard index={index} key={currentStudent._id} cardOnClick={() => { navigate(`/student/${studentEnrolment._id}`) }} currentStudent={currentStudent} />
							</Grid>)
						})
					}
					<Pagination
						onChange={(event, pageNumber) => {
							event.preventDefault();
							setPage(pageNumber - 1);
						}}
						count={Math.ceil(STUDENTS_COUNT / rowsPerPage)}
					/>
				</Grid>
			</NarrowRow>
			<CentrePendingStudentDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:students") })}
				pendingStudentList={PENDING_STUDENT}
				isDialogOpen={dialogOpen}
				setIsDialogOpen={setDialogOpen} />
		</React.Fragment >
	);
}

