import ERROR from "./../const/Error";

export function initalRequestState() {
    return {
        status: null,
        lastAction: "",
        success: null,
        updating: false,
        error: null,
        message: null,
    }
}

export function pushActionToUpdateActionList(state, action) {
    let actionArray = state.updateAction.slice();
    actionArray.push(action);
    return actionArray;
}

export function generalFailReudxResponse(state, payload) {
    let updateActionList = state.updateAction.slice();
    updateActionList.shift();
    return {
        updateAction: updateActionList,
        request: {
            success: false,
            status: payload.requestStatus,
            updating: false,
            lastAction: payload.action,
            error: payload.error,
            message: payload.message
        }
    }
}

export function generalSuccessReduxResponse(state, payload) {
    let updateActionList = state.updateAction.slice();
    updateActionList.shift();
    return {
        updateAction: updateActionList,
        request: {
            success: true,
            updating: false,
            status: payload.requestStatus,
            lastAction: payload.action,
            error: payload.error,
            message: payload.message
        }
    }
}

export function generalUpdatingRequest(state, updating, message, error) {
    return {
        success: null,
        status: null,
        updating: updating,
        error: error,
        message: message
    };
}

export function generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating) {
    if (!currentState.request.updating) {
        let nextAction = "";
        if (currentState.updateAction && currentState.updateAction.length > 0) {
            nextAction = currentState.updateAction[0];
        }

        let action_handlers = UPDATE_ACTION_HANDLER[nextAction];
        if (action_handlers && typeof action_handlers.handler == "function") {
            let handler = action_handlers.handler;
            try {
                setUpdating({
                    updating: true,
                    error: null
                });
                var handlerResult = handler.call(this, currentState, nextAction) || {};
            } catch (error) {
                console.error(error);
                let errorHandler = action_handlers.failHandler;
                errorHandler.call(this, {
                    "message": "general:error-unknown",
                    "action": nextAction,
                    "requestStatus": handlerResult.status,
                    error: ERROR.UNKNOWN_SERVER_ERROR_500
                });
            }
        }
    }
}