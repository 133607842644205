import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
	Autocomplete, FormControl,
	Grid, Stack, TextField,
	Divider, InputAdornment, Collapse,
	Typography, IconButton, Pagination,
	Button, Fab, Box
} from "@mui/material";
import { subject } from "@casl/ability";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import OPTIONS_CATEGORY from "./../../../const/OptionsCategory";
import { convertOptionsForAutoComplete } from "./../../../util/AutoCompleteUtil";


import { getCentreClasses, useClassesManagementState } from "./../../../redux/ClassesManagement";
import { useOptionsState } from "./../../../redux/Options";
import { useAuthenticationState } from "./../../../redux/authentication";

import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";
import CentreClassDialog from "./../class/dialog/CentreClassDialog";
import ClassCard from "./../../page/class/ClassCard";
import PendingClassDialog from "./../class/dialog/PendingClassDialog";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	"marginTop": theme.spacing(1),
	"paddingBottom": theme.spacing(1),
}));

export default function CentreClassPage() {
	const { t } = useTranslation(["centre", "general"]);
	const [isFilterOpen, setIsFilterOpen] = React.useState(false);
	const [subjectFilterValue, setSubjectFilterValue] = React.useState([]);
	const [gradeFilterValue, setGradeFilterValue] = React.useState([]);

	const [searchString, setSearchString] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [addDialogOpen, setAddDialogOpen] = React.useState(false);
	const rowsPerPage = 10;
	const [page, setPage] = React.useState(0);
	const CLASSES = useClassesManagementState().activeClasses || [];
	const CLASS_COUNT = useClassesManagementState().activeClassesrCount || 0;
	const PENDING_CLASS = useClassesManagementState().pendingClasses || [];
	const navigate = useNavigate();
	const AUTH_STATE = useAuthenticationState();
	const ABILITY = AUTH_STATE.ability;

	const OPTIONS_STATE = useOptionsState();
	let { centreId } = useParams();
	const OPTIONS = {
		SUBJECT: OPTIONS_STATE[OPTIONS_CATEGORY.SUBJECT].options,
		GRADE: OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].options
	};

	const deboundedLoadCentreClass = React.useMemo(() => debounce(getCentreClasses, 50), []);
	const CLASS_FILTER_FORM = useForm({
		subjectFilter: [],
		gradeFilter: []
	});

	const handleSubjectChange = (event, newValue) => {
		event.preventDefault();
		setSubjectFilterValue(newValue);
	};
	const handleGradeChange = (event, newValue) => {
		event.preventDefault();
		setGradeFilterValue(newValue);
	};

	const updateSearchString = (event) => {
		event.preventDefault();
		setSearchString(event.target.value);
	};

	const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

	const toggleFilter = () => {
		setIsFilterOpen((prev) => !prev)
	}

	const triggerAddDialog = (event) => {
		event.preventDefault();
		setAddDialogOpen(true);
	}

	const handleClick = (event) => {
		event.preventDefault();
		if (PENDING_CLASS.length > 0) {
			setDialogOpen((prev) => !prev);
		}
	};

	React.useEffect(() => {
		deboundedLoadCentreClass({
			centreId: centreId, searchCriteria: {
				search: searchString, subject: subjectFilterValue, grade: gradeFilterValue,
				page: page, rowsPerPage: rowsPerPage
			}
		});

	}, [])

	React.useEffect(() => {
		deboundedLoadCentreClass({
			centreId: centreId, searchCriteria: {
				search: searchString, subject: subjectFilterValue, grade: gradeFilterValue,
				page: page, rowsPerPage: rowsPerPage
			}
		});
	}, [centreId, searchString, page, rowsPerPage, subjectFilterValue, gradeFilterValue])

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 2 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-classes")}</Typography>
							{ABILITY.can("create", subject("class", { centreId: centreId })) ? (
								<React.Fragment>
									<Box>
										<Button onClick={handleClick} sx={{ height: "50px", width: "140px" }} variant="contained">
											{t("general:button-pending") + ` (${PENDING_CLASS.length})`}
										</Button>
										<Fab sx={{ marginLeft: 4 }} color="primary" size="small" onClick={triggerAddDialog} aria-label="comment">
											<AddIcon />
										</Fab>
									</Box>
								</React.Fragment>
							) :
								null}

						</HeaderRow>
						<Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
							<TextField
								id="outlined-start-adornment"
								fullWidth
								onChange={(event) => { searchFieldOnChange(event) }}
								placeholder={t("general:label-search")}
								InputProps={{
									startAdornment:
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>,
									endAdornment:
										<InputAdornment position="end">
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											<IconButton
												name="filterToggle"
												aria-label="toggle filter"
												onClick={toggleFilter}
												onMouseDown={(event) => { event.preventDefault() }}
											>
												<FilterListIcon />
											</IconButton>
										</InputAdornment>,
								}}
							/>
							<Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
								<Stack>
									<StyledFormControl fullWidth variant="outlined">
										<Controller
											name="subjectFilter"
											defaultValue={subjectFilterValue}
											control={CLASS_FILTER_FORM.control}
											render={
												({ field }) =>
													<Autocomplete
														multiple
														id="class_subject_filter"
														options={convertOptionsForAutoComplete(OPTIONS.SUBJECT)}
														getOptionLabel={(option) => t(`attribute:${option}`)}
														defaultValue={subjectFilterValue}
														value={subjectFilterValue}
														onChange={handleSubjectChange}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																label={t("attribute:class-subjects")}
																placeholder={t("attribute:class-subjects")}
															/>
														)}
													/>
											}
										/>
									</StyledFormControl>
									<StyledFormControl fullWidth variant="outlined">
										<Controller
											name="gradeFilter"
											defaultValue={gradeFilterValue}
											control={CLASS_FILTER_FORM.control}
											render={
												({ field }) =>
													<Autocomplete
														multiple
														id="class_grade_filter"
														options={convertOptionsForAutoComplete(OPTIONS.GRADE)}
														getOptionLabel={(option) => t(`attribute:${option}`)}
														defaultValue={gradeFilterValue}
														value={gradeFilterValue}
														onChange={handleGradeChange}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																label={t("attribute:class-grades")}
																placeholder={t("attribute:class-grades")}
															/>
														)}
													/>
											}
										/>
									</StyledFormControl>
								</Stack>
							</Collapse>
						</Stack>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>
			<Grid container justifyContent="center" item sm={12} md={12}>
				{CLASSES.map(
					(currentClass, index) => {
						return (
							<ClassCard index={index} key={currentClass._id + currentClass.displayName} cardOnClick={() => { navigate(`/class/${currentClass._id}`) }} currentClass={currentClass} />
						)
					})
				}
				<Pagination
					onChange={(event, pageNumber) => {
						event.preventDefault();
						setPage(pageNumber - 1);
					}}
					count={Math.ceil(CLASS_COUNT / rowsPerPage)}
				/>
			</Grid>
			<CentreClassDialog
				title={t("general:dialog_title-add_entity", { entity: t("attribute:class") })}
				isDialogOpen={addDialogOpen}
				setIsDialogOpen={setAddDialogOpen} />
			<PendingClassDialog
				title={t("general:dialog_title-pending_entity", { entity: t("attribute:classes") })}
				pendingClassList={PENDING_CLASS}
				isDialogOpen={dialogOpen}
				setIsDialogOpen={setDialogOpen} />
		</React.Fragment >
	);
}

