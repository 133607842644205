import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface User {
    username: string,
    userId: string,
    displayname: string,
    currentRole:Object,
    token: string,
    role: string,
    ability: Object
}


export async function login(username: string, password: string): Promise<User> {
    const url = '/auth/login';
    try {
        const { data } = await axios.post<User>(url, { "username": username, "password": password });
        return data;
    } catch (err) {
        throw err;
    }
}

export async function logout(): Promise<UpdateResultResponse> {
    const url = "/auth/logout";
    try {
        const { data } = await axios.post<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err;
    }
}


export async function validateJWT(): Promise<User> {
    const url = "/auth/validateJWT";

    try {
        const { data } = await axios.post<User>(url);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function resendVerificationEmail(): Promise<UpdateResultResponse> {
    const url = "/auth/sendVerificationEmail";

    try {
        const { data } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function requestRecoverPasswordEmail(email:string): Promise<UpdateResultResponse> {
    const url = "/auth/requestRecoverPassword";

    try {
        const body = {"email":email};
        const { data } = await axios.post<UpdateResultResponse>(url,body);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function requestUpdateForgetPassword(email:string,password:string,token:string): Promise<UpdateResultResponse> {
    const url = "/auth/recoverPassword";

    try{
        const body = {
            "email":email,
            "password":password,
            "token":token
        };
        const { data } = await axios.post<UpdateResultResponse>(url,body);
        return data;
    }catch(error){
        throw error;
    }
}

export async function requestUpdatePassword(password:string,newPassword:string): Promise<UpdateResultResponse> {
    const url = "/auth/updatePassword";

    try{
        const body = {
            "password":password,
            "newPassword":newPassword
        };
        const { data } = await axios.post<UpdateResultResponse>(url,body);
        return data;
    }catch(error){
        throw error;
    }
}


export async function updateRole(role: string): Promise<UpdateResultResponse> {
    const url = "/auth/updateRole";
    try {
        const { data } = await axios.post<UpdateResultResponse>(url, { "role": role });
        return data;
    } catch (error) {
        throw error;
    }
}