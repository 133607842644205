import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Paper, Grid,
	Typography,Stack
} from "@mui/material";
import { SpaceHolder } from "./../../common/Others";
import { useProfileState } from "./../../../redux/ProfileManagement";
import ContentRow from "./../../common/ContentRow";
import { useAuthenticationState } from "./../../../redux/authentication";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import { useParams } from "react-router-dom";
import moment from "moment";
const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),

}));
const NarrowRow = styled(Stack)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

function enrolmentIdInRolesList(enrolmentId, roleList) {
	for (let value of roleList) {
		if (enrolmentId === value._id) {
			return true;
		};
	}
	return false
}

export default function TutorAboutDetailPage() {
	const TUTOR_PROFILE_INFO = useProfileState().tutorProfileInfo || {};
	const { t } = useTranslation(["centre", "general"]);
	let { enrolmentId } = useParams();
	const ROLES = useAuthenticationState().roles;

	return (
		<React.Fragment>
			<Paper sx={{ borderRadius: "10px" }}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-about")}</Typography>
						</HeaderRow>
						<ContentRow content={TUTOR_PROFILE_INFO.remarks} />
						<ContentRow needI18Nhandle title={t("attribute:tutor-subjects")} content={TUTOR_PROFILE_INFO.subject} />

						{TUTOR_PROFILE_INFO.educationLevel && TUTOR_PROFILE_INFO.educationBackground ?
							<ContentRow title={t("attribute:tutor-education")} content={
								TUTOR_PROFILE_INFO.educationBackground + "\n" +
								t("tutor:attribute_value-education-level", {educationLevel: t(`attribute:${TUTOR_PROFILE_INFO.educationLevel}`)})
							} /> : null}
							
						<ContentRow needI18Nhandle title={t("attribute:tutor-main_language")} content={TUTOR_PROFILE_INFO.mainLanguage} />
						<ContentRow title={t("attribute:tutor-nationality")} content={TUTOR_PROFILE_INFO.nationality} />
						<ContentRow needI18Nhandle title={t("attribute:tutor-gender")} content={TUTOR_PROFILE_INFO.gender} />

					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
			<CanUpdateCentre centreId={TUTOR_PROFILE_INFO.centreId} or={enrolmentIdInRolesList(enrolmentId, ROLES)}>
				<NarrowRow spacing={1}>
					<Paper sx={{ borderRadius: "10px" }}>
						<Grid container>
							<SpaceHolder container item xs={0.5} />
							<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
								<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
									<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("tutor:section_title-personal_info")}</Typography>
								</HeaderRow>
								<ContentRow title={t("attribute:tutor-birthday")} content={moment(TUTOR_PROFILE_INFO.birthday).format("LL")} />
								<ContentRow title={t("attribute:tutor-phone_number")} content={TUTOR_PROFILE_INFO.phone} />
							</Grid>
							<SpaceHolder container item xs={0.5} />
						</Grid>
					</Paper>
				</NarrowRow>
			</CanUpdateCentre>
		</React.Fragment >
	);
}
