import React from 'react';
import { Grid, FormLabel } from "@mui/material";
import TutorCard from "./../../page/tutor/TutorCard";
import TutorEditDialog from "./../../page/tutor/TutorEditDialog";
import { useTranslation } from "react-i18next";
import { ProfileContainer } from "./../../common/Containers";
import { useProfileState } from "./../../../redux/ProfileManagement";
import { styled } from '@mui/material/styles';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    "fontWeight": "bold",
    "color": "#AAAAAA",
    "marginTop": theme.spacing(3),
    "marginBottom": theme.spacing(2)
}));

export default function TutorCards() {
    const TUTOR_LIST = useProfileState().tutorProfiles;
    const [editDialog, setEditDialog] = React.useState(false);
    const [selectedTutor, setSelectedTutor] = React.useState({});
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {TUTOR_LIST.length > 0 ?
                <StyledFormLabel>{t("attribute:tutor_profile")}</StyledFormLabel>
                : null
            }

            {TUTOR_LIST.map(
                (currentTutor, index) =>
                (<ProfileContainer key={currentTutor._id + "_container_grid"} container spacing={3}  >
                    <Grid item xs={12} md>
                        <TutorCard key={currentTutor._id} currentTutor={currentTutor} cardOnClick={() => {
                            setSelectedTutor(currentTutor);
                            setEditDialog(true);
                        }} index={index}></TutorCard>
                    </Grid>
                </ProfileContainer>)
            )}
            <TutorEditDialog currentTutor={selectedTutor} open={editDialog} setOpenDialog={setEditDialog} />
        </React.Fragment>
    )
}

