import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
	Grid,
	Typography, Fab, Pagination
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import { getCentreFacility, useFacilityManagementState } from "./../../../redux/FacilityManagement";
import CentreFacilityDialog from "./../../page/centreDetail/dialog/CentreFacilityDialog";
import CentreFacilityCard from "./../../page/centreDetail/CentreFacilityCard";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

export default function CentreFacilityPage() {
	const { t } = useTranslation(["centre", "general"]);
	const [selected, setSelected] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const rowsPerPage = 10;
	const [page, setPage] = React.useState(0);
	const CENTRE_FACILITY = useFacilityManagementState().facilities;
	const CENTRE_FACILITY_COUNT = useFacilityManagementState().centreFacilityCount || 0;

	let { centreId } = useParams();


	const triggerAddDialog = (event) => {
		event.preventDefault();
		setSelected(null);
		setDialogOpen((true));
	};

	const navigate = useNavigate();

	const handleClick = (event, currentFacility) => {
		event.preventDefault();
		navigate("/facility/" + currentFacility._id);
	}


	let deboundedGetCentreFacility = React.useMemo(() => debounce(getCentreFacility, 50), []);

	React.useEffect(() => {
		deboundedGetCentreFacility({ centreId: centreId, page: page, rowsPerPage: rowsPerPage });
	}, []);

	React.useEffect(() => {
		deboundedGetCentreFacility({ centreId: centreId, page: page, rowsPerPage: rowsPerPage });
	}, [page, rowsPerPage]);


	let CENTRE_FACILITY_LIST = React.useMemo(() => {
		return CENTRE_FACILITY.map(
			(currentFacility, index) => {
				return <CentreFacilityCard key={currentFacility._id + "card"} handleOnClick={handleClick} currentFacility={currentFacility} index={index} />
			});
	}, [CENTRE_FACILITY])

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-facilities")}</Typography>
							<CanUpdateCentre centreId={centreId} >
								<Fab color="primary" size="small" onClick={(event) => {
									triggerAddDialog(event);
								}} aria-label="comment">
									<AddIcon />
								</Fab>
							</CanUpdateCentre>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>

			{CENTRE_FACILITY_LIST}
			<Grid sx={{ marginTop: 3 }} container justifyContent="center" spacing={1} >
				<Pagination
					onChange={(event, pageNumber) => {
						event.preventDefault();
						setPage(pageNumber - 1);
					}}
					count={Math.ceil(CENTRE_FACILITY_COUNT / rowsPerPage)}
				/>
			</Grid>
			<CentreFacilityDialog
				currentFacility={selected}
				title={t("general:dialog_title-add_entity", { entity: t("attribute:facility") })}
				isDialogOpen={dialogOpen}
				setIsDialogOpen={setDialogOpen} />
		</React.Fragment >
	);
}
