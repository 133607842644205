const notifications = {
    "PENDING_TUTOR_NOTIFICATION": "settings:Notification-pending_tutor",
    "PENDING_STUDENT_NOTIFICATION": "settings:Notification-pending_student",
    "PENDING_CLASS_NOTIFICATION": "settings:Notification-pending_class",
    "PENDING_CLASS_STUDENT_NOTIFICATION": "settings:Notification-pending_class_student",
    "PENDING_SESSION_NOTIFICATION": "settings:Notification-pending_session",
    "CENTRE_ADMISSION_NOTIFICATION": "settings:Notification-centre_admission",
    "CENTRE_DISMISSAL_NOTIFICATION": "settings:Notification-centre_dismissal",
    "CLASS_ADMISSION_NOTIFICATION": "settings:Notification-class_admission",
    "CLASS_CHANGE_NOTIFICATION": "settings:Notification-class_change",
    "CLASS_CANCEL_NOTIFICATION": "settings:Notification-class_cancel",
    "CLASS_DISMISSAL_NOTIFICATION": "settings:Notification-class_dismissal",
    "SESSION_CHANGE_NOTIFICATION": "settings:Notification-session_change",
    "SESSION_CANCEL_NOTIFICATION": "settings:Notification-session_cancel",
    "BILL_ISSUANCE_NOTIFICATION": "settings:Notification-bill_issuance"
};

const systemLanguages = {
    "en": "attribute:system_language-001",
    "zh-HK": "attribute:system_language-002"
}

export default {notifications, systemLanguages}