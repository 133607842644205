import React from 'react';
import { Popover, ListItem, Divider, Typography, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import { signOut, useAuthenticationState } from "./../../redux/authentication";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from "react-i18next";

const StyledAccountCircleIcon = styled(AccountCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  margin: "0px",
  paddingRight: theme.spacing(2)
}));

const StyledExitToAppIcon = styled(ExitToAppIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  margin: "0px",
  paddingRight: theme.spacing(2)
}));

const MenuListItemText = styled(Typography)(({ theme }) => ({
  fontSize: "14px"
}));

const MenuListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText
}));

export default function UserListItem({ children, handleDrawerClose, documentTitle }) {
  const { t } = useTranslation("general");
  const AUTH_STATE = useAuthenticationState();
  const navigate = useNavigate();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'user-account-popup-popover',
  });

  function routeToAccountMgt(event) {
    event.preventDefault();
    popupState.close();
    if (handleDrawerClose)
      handleDrawerClose();
    navigate("/account", { state: {} });
  }

  function logoutOnClick(event) {
    event.preventDefault();
    popupState.close();
    if (handleDrawerClose)
    handleDrawerClose();
    signOut();
    navigate("/");
    document.title = documentTitle;
  }

  return (
    <span>
      <ListItem
        sx={{ width: "100%", paddingLeft: 1 }}

        data-cy="userListItem" button {...bindTrigger(popupState)}>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}>
          <AccountCircleIcon />
          {children ? children : null}
        </Stack>

      </ListItem>
      <Popover sx={{
        paper:
        {
          backgroundColor: 'primary.light',
          borderColor: 'primary.light',
          "borderRadius": "5px"
        }
      }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuListItem button data-cy="userListItem-account" onClick={routeToAccountMgt}>
          <StyledAccountCircleIcon />
          <MenuListItemText component="div">
            {AUTH_STATE.username}
          </MenuListItemText>
        </MenuListItem>
        <Divider />
        <MenuListItem button data-cy="userListItem-logout" onClick={logoutOnClick}>
          <StyledExitToAppIcon />
          <MenuListItemText component="div">
            {t("general:button-logout")}
          </MenuListItemText>
        </MenuListItem>
      </Popover>
    </span>
  );
}

