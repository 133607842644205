import React from "react";
import { useTranslation } from "react-i18next";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import { acceptTutorEnrolment, rejectTutorEnrolment } from "./../../../../redux/Enrolment";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import GeneralPendingDialog from "./../../../../component/common/GeneralPendingDialog"
import TutorCard from "./../../../page/tutor/TutorCard";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
export default function CentrePendingTutorDialog({
	title, pendingTutorList, isDialogOpen, setIsDialogOpen }) {
	const { t } = useTranslation(["general", "config"]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedTutor, setSelectedTutor] = React.useState(null);

	const openNewTab = (event, currentTutor) => {
		window.open(`/tutor/${currentTutor.enrolmentId}`, "_blank");
	}

	const toggleContextualMenu = (event, tutorFromOnclick) => {
		setSelectedTutor(tutorFromOnclick);
		setAnchorEl(event.currentTarget);
	};

	const acceptTutor = (event) => {
		event.preventDefault();
		setAnchorEl(null);
		acceptTutorEnrolment({ enrolmentId: selectedTutor.enrolmentId });

	}

	const rejectTutor = (event) => {
		event.preventDefault();
		setAnchorEl(null);
		rejectTutorEnrolment({ enrolmentId: selectedTutor.enrolmentId });
	}

	return (
		<React.Fragment>
			<GeneralPendingDialog title={title} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}>
				{
					pendingTutorList.map((currentTutor) =>
						<TutorCard
							key={"tutor_card_" + currentTutor._id}
							cardOnClick={openNewTab}
							currentTutor={currentTutor.profile}
							moreButtonOnClick={toggleContextualMenu}
						/>)
				}
			</GeneralPendingDialog>
			<ContextualMenu id={"admin-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				<ContextualMenuItem
					onClick={acceptTutor}
					title={t("general:button-accept")}>
					<CheckCircleOutlineIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					onClick={rejectTutor}
					title={t("general:button-delete")}>
					<CancelIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
		</React.Fragment>
	)
}