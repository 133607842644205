import React from "react";
import {
    Typography, Divider,
    Grid, Button,
    IconButton, Paper, Stack,
    Tabs, Tab, ButtonBase
} from "@mui/material";

import { useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TabContainer } from "./../../../../component/common/Containers";
import { SpaceHolder } from "./../../../../component/common/Others";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import SmsIcon from "@mui/icons-material/Sms";
import { useCentreManagementState } from "./../../../../redux/CentreManagement";
import { useAuthenticationState } from "./../../../../redux/authentication";
import { useEnrolmentState } from "./../../../../redux/Enrolment";
import { useProfileState } from "./../../../../redux/ProfileManagement";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import CanUpdateCentre from "./../../../../component/acl/CanUpdateCentre";
import { subject } from "@casl/ability";
import CentreEnrolmentDialog from "./../../../page/centreDetail/CentreEnrolmentDialog";
import CentreEditDialog from "./../../../../component/form/centreComponent/CentreEditDialog";

import useMediaQuery from '@mui/material/useMediaQuery';
import { MoreHoriz } from "@mui/icons-material";

const LogoContainer = styled(Grid)(({ theme }) => ({
    "paddingBottom": theme.spacing(1),
    "paddingRight": theme.spacing(2)
}));

const ButtonGrid = styled(Grid)(({ theme }) => ({

}));

const LogoThumbnail = styled("img")(({ theme }) => ({
    objectFit: "cover",
    objectPosition: "50% 50%",
    display: "block",
    borderRadius: "10px",
    width: "100px",
    height: "100px"
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


function Buttons({ toggleDialog, toggleContextualMenu }) {
    const { t } = useTranslation("general");
    let { centreId } = useParams();
    const CURRENT_ROLE = useAuthenticationState().currentRole;
    
    const PROFILE_STATE = useProfileState();
    const STUDENT_LIST = PROFILE_STATE.studentProfiles || [];
    const TUTOR_LIST = PROFILE_STATE.tutorProfiles || [];

    const REGISTERED_PROFILE = useAuthenticationState().registeredEnrolmentMap[centreId] || {};
    const CENTRE_PENDING_ENROLMENT = useEnrolmentState().pendingEnrolmentMap[centreId] || {};

    const PROFILE_SHOULD_BE_HIDDEN = Object.assign({}, REGISTERED_PROFILE, CENTRE_PENDING_ENROLMENT);
    const STUDENT_PROFILE_TO_BE_SHOW = STUDENT_LIST.filter((value) => (!PROFILE_SHOULD_BE_HIDDEN[value._id]));
    const TUTOR_PROFILE_TO_BE_SHOW = TUTOR_LIST.filter((value) => (!PROFILE_SHOULD_BE_HIDDEN[value._id]));

    let isEqualCurrentCentre = CURRENT_ROLE.centreId === centreId.split("?")[0];
    
    let profileExists = STUDENT_PROFILE_TO_BE_SHOW.length > 0
        || TUTOR_PROFILE_TO_BE_SHOW.length > 0
        || Object.entries(CENTRE_PENDING_ENROLMENT).length > 0;

    let isCentreAdmin = ["centre_admin"].includes(CURRENT_ROLE.roleName) && isEqualCurrentCentre
    let isPlatformAdmin = CURRENT_ROLE.roleName === "platform_admin";
    
    if ((["tutor", "student"].includes(CURRENT_ROLE.roleName) && isEqualCurrentCentre) || isCentreAdmin || isPlatformAdmin) {
        return (<IconButton
            sx={{
                marginTop: 2,
                backgroundColor: "#DDDDDD",
            }}
            color="primary"
            aria-label="upload picture" component="label"
            onClick={toggleContextualMenu}
        >
            <MoreHoriz/>
        </IconButton>)
    } else if (profileExists) {
            return (
                <React.Fragment>
                    {
                        <Button onClick={toggleDialog} sx={{ height: "50px", width: "140px" }} variant="contained">
                            {t("general:button-apply")}
                        </Button>
                    }
                </React.Fragment>
            );
    }
    return null;
}

export default function CentrePageHeader({
    TAB_ELEMENTS, value, setValue, setLogoDialogOpen }) {
    const { t } = useTranslation("general");
    let { centreId } = useParams();

    const [headerAnchorEl, setHeaderAnchorEl] = React.useState(null);
    const [portraitAnchorEl, setPortraitAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);

    const CENTRE = useCentreManagementState().currentCentre;
    const ABILITY = useAuthenticationState().ability;
    const CURRENT_ROLE = useAuthenticationState().currentRole;

    const LOCATION = useLocation();
    let param = LOCATION.state && LOCATION.state.hideEnrolmentSidebar;
    let isPlatformAdmin = CURRENT_ROLE.roleName === "platform_admin";
    let isEqualCurrentCentre = CURRENT_ROLE.centreId === centreId.split("?")[0];

    let isCentreAdmin = (param && isEqualCurrentCentre) || isPlatformAdmin;

    let buttonAlignment = "center";
    if (isCentreAdmin) {
        buttonAlignment = "flex-start";
    }

    const triggerPortraitMenu = (event) => {
        event.preventDefault();
        setPortraitAnchorEl(event.currentTarget);
    }

    const triggerUploadPortrait = (event) => {
        event.preventDefault();
        setPortraitAnchorEl(null);
        setLogoDialogOpen(true)
    }

    const triggerEditDialog = (event) => {
        event.preventDefault();
        setEditDialogOpen(true);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate({
            pathname: ("/centre/" + centreId),
            search: createSearchParams({
                tab: newValue
            }).toString(),
        }, { state: LOCATION.state });
    };

    const navigateToSettings = (event) => {
        event.preventDefault();
        navigate("/centre_settings/" + centreId, { state: LOCATION.state });
    }

    const navigateToChatroom = (event) => {
        window.location.href = (`/message/direct?centre=${centreId}`);
    }

    const toggleDialog = (event) => {
        setDialogOpen((prev) => !prev);
    }

    const toggleHeaderMenu = (event) => {
        event.preventDefault();
        setHeaderAnchorEl(event.currentTarget);
    }

    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Paper sx={{ width: "100%", borderRadius: "10px" }}>
                <Grid container item sm={12} xs={12}>
                    <SpaceHolder container item xs={0.5} sm={0.5} />
                    <Grid sx={{ marginTop: 3 }} container item xs={11.5} sm={11}>
                        {smUp ? (
                            <React.Fragment>
                                <LogoContainer container alignItems="flex-start" item xs={11} sm={10}
                                    justifyContent="flex-start"  >
                                    <Stack direction="row" justifyContent="flex-start"
                                        alignItems="center" spacing={2}>
                                        <ButtonBase disabled={!ABILITY.can("update", subject("centre", { id: centreId }))} sx={{ marginTop: 1 }} onClick={triggerPortraitMenu}>
                                            {!CENTRE.logo || CENTRE._id != centreId ? (
                                                <Grid sx={{ width: "100px", height: "100px", border: 1, borderColor: "divider" }} container alignItems="center" justifyContent="center">
                                                    <SchoolRoundedIcon color="disabled" fontSize="large" />
                                                </Grid>) :
                                                (<LogoThumbnail alt="centre_logo" src={CENTRE.logo} />
                                                )}
                                        </ButtonBase>
                                        <Stack spacing={1}>
                                            <Stack alignItems="center" direction="row">
                                                <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>{CENTRE.displayName}</Typography>
                                                <CanUpdateCentre centreId={centreId}>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: 1,
                                                            backgroundColor: "#DDDDDD",
                                                        }}
                                                        color="primary"
                                                        aria-label="upload picture" component="label"
                                                        onClick={triggerEditDialog}
                                                    >
                                                        <EditIcon sx={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </CanUpdateCentre>
                                            </Stack>
                                            <Typography color="secondary">{t("centre:page_title-home")}</Typography>
                                        </Stack>
                                    </Stack>
                                </LogoContainer>
                                <ButtonGrid container alignItems={buttonAlignment} justifyContent="right"
                                    item xs={1} sm={2}>
                                    <Buttons  toggleDialog={toggleDialog} toggleContextualMenu={toggleHeaderMenu} />
                                </ButtonGrid>
                                <Grid container item sm={12}>
                                    <TabContainer>
                                        <Tabs value={value} onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            allowScrollButtonsMobile
                                            aria-label="centre_settings_tabs">
                                            {TAB_ELEMENTS.map(
                                                (element, index) => (element.permission ?
                                                    <Tab
                                                        value={element.tab_index}
                                                        sx={{
                                                            textTransform: "none"
                                                        }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} />
                                                    : null)
                                            )}
                                        </Tabs>
                                    </TabContainer>
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        {smDown ? (
                            <React.Fragment>
                                <LogoContainer container item xs={12} md={2}
                                    alignItems="center" justifyContent="space-between"  >
                                    <Grid container item xs={6}>
                                        <ButtonBase disabled={!ABILITY.can("update", subject("centre", { id: centreId }))} onClick={triggerPortraitMenu}>
                                            {!CENTRE.logo || CENTRE._id != centreId ? (
                                                <Grid sx={{ width: "100px", height: "100px", border: 1, borderColor: "divider" }} container alignItems="center" justifyContent="center">
                                                    <SchoolRoundedIcon color="disabled" fontSize="large" />
                                                </Grid>) :
                                                (<LogoThumbnail alt="centre_logo" src={CENTRE.logo} />
                                                )}
                                        </ButtonBase>
                                    </Grid>
                                    <ButtonGrid container alignItems={buttonAlignment} justifyContent="right" item xs={6} md={2}>
                                        <Buttons toggleDialog={toggleDialog} toggleContextualMenu={toggleHeaderMenu} />
                                    </ButtonGrid>
                                </LogoContainer>
                                <Grid sx={{}} container alignItems="center" justifyContent="center" item xs={12} md={8}>
                                    <Grid container item xs={12} md={12}>
                                        <Stack alignItems="center" direction="row">
                                            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>{CENTRE.displayName}</Typography>
                                            <CanUpdateCentre centreId={centreId}>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: 1,
                                                        marginRight: 1,
                                                        backgroundColor: "#DDDDDD",
                                                    }}
                                                    color="primary"
                                                    aria-label="upload picture" component="label"
                                                    onClick={triggerEditDialog}
                                                >
                                                    <EditIcon sx={{ fontSize: 14 }} />
                                                </IconButton>
                                            </CanUpdateCentre>
                                        </Stack>

                                    </Grid>
                                    <Grid container item xs={12} md={12}>
                                        <Typography variant="subtitle2" component="div" color="secondary">{t("centre:page_title-home")}</Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                    <SpaceHolder container item xs={0} sm={0.5} />
                    {smDown ? (<Grid container item xs={12} md={12}>
                        <TabContainer>
                            <Divider sx={{ marginTop: 2.5 }} />
                            <Tabs
                                value={value} onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="centre_page_tabs">
                                {TAB_ELEMENTS.map((element, index) => (element.permission ?
                                    <Tab value={element.tab_index}
                                        sx={{
                                            textTransform: "none",
                                            minWidth: 70
                                        }} key={element.tab_label} label={t(element.tab_label)} {...a11yProps(index)} /> :
                                    null
                                ))
                                }
                            </Tabs>
                        </TabContainer>
                    </Grid>) : null}
                </Grid>
            </Paper>
            <ContextualMenu id={"header-menu"} anchorEl={headerAnchorEl} setAnchorEl={setHeaderAnchorEl} >
                <CanUpdateCentre centreId={centreId} >
                    <ContextualMenuItem title={t("general:button-settings")} onClick={navigateToSettings}>
                        <SettingsIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    {/* {CURRENT_ROLE.roleName != "platform_admin" &&
                    <ContextualMenuItem title={t("general:button-broadcast")} onClick={navigateToChatroomBroadcast}>
                        <CampaignIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>} */}
                </CanUpdateCentre >
                    {["tutor", "student"].includes(CURRENT_ROLE.roleName) &&
                    <ContextualMenuItem title={t("centre:button-chat")} onClick={navigateToChatroom}>
                        <SmsIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>}
            </ContextualMenu>
            <CanUpdateCentre centreId={centreId} >
                <ContextualMenu id={"portrait-menu"} anchorEl={portraitAnchorEl} setAnchorEl={setPortraitAnchorEl} >
                    <ContextualMenuItem title={t("general:button-change")} onClick={triggerUploadPortrait}>
                        <EditIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ContextualMenu>
            </CanUpdateCentre >
            <CentreEditDialog open={editDialogOpen} setOpenDialog={setEditDialogOpen} currentCentre={CENTRE} />
            <CentreEnrolmentDialog
                isDialogOpen={dialogOpen}
                setIsDialogOpen={setDialogOpen} />
        </React.Fragment >
    )
}