import React from "react";
import {
    Grid, Button,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import {uploadFacilityPhotos} from "./../../../../redux/FacilityManagement";
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    minWidth: "200px",
    minHeight: "200px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};


const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function CentreUploadImageDialog({ open, setOpen }) {

    const { t } = useTranslation();
    let { facilityId } = useParams();

    const {
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: { 'image/*': [] } });

    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const closeLogoDialog = (event) => {
        setOpen(false);
    }

    const uploadedLogo = (acceptedFiles) => {
        const formData = new FormData();
        for (let file of acceptedFiles) {
            formData.append("files", file);
        }
        formData.append("facilityId", facilityId);
        uploadFacilityPhotos(formData);
        setOpen(false);
    }

    return (
        <Dialog open={open}
            onClose={closeLogoDialog}
            scroll="paper"
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {t("general:dialog_title-upload_photos")}
            </DialogTitle>
            <DialogContent dividers data-cy="edit_dialog_content">
                <Dropzone onDrop={uploadedLogo}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <p>{t("general:dialog_content-upload_photos")}</p>
                        </div>

                    )}
                </Dropzone>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Button autoFocus onClick={closeLogoDialog} variant="outlined">
                        {t("general:button-cancel")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
