import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
	Grid, Stack, IconButton,
	CardActionArea, Card, CardContent
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfoIcon from "@mui/icons-material/Info";
import LimitedTextLabel from "./../../../component/display/LimitedTextLabel";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import useMediaQuery from "@mui/material/useMediaQuery";

const FacilityLogo = styled("img")(({ theme }) => ({
	borderRadius: "10px",
	width: "100px",
	height: "100px",
	objectFit: "cover",
	objectPosition: "50% 50%",
}));

const NarrowRow = styled(Stack)(({ theme }) => ({

}));

export default function CentreFacilityCard({ currentFacility, index, handleOnClick, moreButtonOnClick }) {
	let FACILITY_PROFILE_PIC = (
		<Grid sx={{ minWidth: "100px", width: "100px", height: "100px" }} container alignItems="center" justifyContent="center">
			<SchoolRoundedIcon color="disabled" fontSize="large" />
		</Grid>
	);

	if (currentFacility.logo) {
		FACILITY_PROFILE_PIC = (<FacilityLogo
			src={currentFacility.logo}
			alt="facilityProfile"
		/>);

	}

	let theme = useTheme();
	let smDown = useMediaQuery(theme.breakpoints.down("sm"));

	let cardContentStyle = { paddingLeft: 2 };
	let rootStackSpacing = 1.3;
	let subStackSpacing = 0.7;
	let displayNameStyle = { fontWeight: "bold", paddingLeft: 0.5 };
	let contentSpacing = 1
	let contentVariant = "body2";

	if (smDown) {
		cardContentStyle = { paddingLeft: 1.5, paddingRight: 1.5 };
		rootStackSpacing = 1;
		subStackSpacing = 0.5;
		displayNameStyle = { paddingLeft: 0.5 }
		contentSpacing = 0.7;
		contentVariant = "caption";
	}

	return (
		<NarrowRow sx={{ width: "100%" }} key={index + "_row"}>
			<Card sx={{ width: "100%", marginTop: 0, marginBottom: 2, borderRadius: "10px" }}>
				<CardActionArea component="span" disableTouchRipple data-cy={"edit_facility_btn_" + (index + 1)} onClick={(event) => { event.preventDefault(); handleOnClick.call(this, event, currentFacility) }}>
					<CardContent sx={cardContentStyle} >
						<Stack direction="row" spacing={rootStackSpacing} >
							{FACILITY_PROFILE_PIC}
							<Stack sx={{ width: "100%" }} alignItems="flex-start" justifyContent="center" spacing={subStackSpacing} >
								<Stack sx={{ width: "100%" }} justifyContent="space-between" alignItems="center" direction="row">
									<LimitedTextLabel component="div" variant="h6" customstyle={displayNameStyle}>
										{currentFacility.name}
									</LimitedTextLabel>
									{moreButtonOnClick ?
										<IconButton onClick={(event) => {
											event.stopPropagation();
											moreButtonOnClick.call(this, event, currentFacility);
										}}>
											<MoreHorizIcon />
										</IconButton>
										: null}
								</Stack>
								{currentFacility.description ?
									(<Stack direction="row" spacing={contentSpacing}>
										<InfoIcon color="disabled" fontSize="small" />
										<LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
											{currentFacility.description}
										</LimitedTextLabel>
									</Stack>) : null
								}
								{currentFacility.function ?
									(<Stack direction="row" spacing={contentSpacing}>
										<LocalOfferIcon color="disabled" fontSize="small" />
										<LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
											{currentFacility.function}
										</LimitedTextLabel>
									</Stack>) : null}
							</Stack>
						</Stack>
					</CardContent >
				</CardActionArea >
			</Card >
		</NarrowRow >
	);
}


