import React from "react";
import {
    Grid, Button, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { FormProvider, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { DialogFixedForm } from "./../../../common/Forms";
import ConfirmActionDialog from "./../../../common/ConfirmActionDialog";
import ClassSessionFieldList from "./../../../form/classComponent/ClassSessionFieldList";
import { createClassSesssionFormSchema } from "./../../../../component/form/model/ClassFormModel";
import { createClassSession, saveClassSession, useClassSessionManagementState } from "./../../../../redux/ClassSessionManagement";
import { useAuthenticationState } from "./../../../../redux/authentication";
import { isCentreAdmin } from "./../../../../util/ACLUtils";
import { yupResolver } from "@hookform/resolvers/yup";
const ErrorMessage = styled(Typography)(({ theme }) => ({
    "marginTop": "10px"
}));

export default function ClassSessionDialog({
    title, currentSession, currentDate, currentClassId, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
    const [cachedSubmitData, setCachedSubmitData] = React.useState(null);
    const SESSION_REQUEST = useClassSessionManagementState().request;
    const CURRENT_ROLE = useAuthenticationState().currentRole || {};
    const CLASS_FORM_METHOD = useForm({
        resolver: yupResolver(createClassSesssionFormSchema())
    });
    
    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const submitMethod = (data) => {
        if (data.date && data.date.setHours) {
            data.date.setUTCDate(data.date.getDate());
            data.date.setUTCHours(0, 0, 0, 0);
        }

        if (currentSession) {
            //do custom logic before calling submit request;
            let isDateModified = !moment(currentSession.date).isSame(moment(data.date));
            let isStartTimeModified = !(moment(currentSession.startTime).isSame(moment(data.startTime), "hour") && moment(currentSession.startTime).isSame(moment(data.startTime), "mintue"));
            let isEndTimeModified = !(moment(currentSession.endTime).isSame(moment(data.endTime), "hour") && moment(currentSession.endTime).isSame(moment(data.endTime), "mintue"));
            let isNotAdmin = !isCentreAdmin(CURRENT_ROLE);
            if ( isNotAdmin && 
                (isDateModified || isStartTimeModified || isEndTimeModified)
                ) {
                setCachedSubmitData(data);
                setConfirmDialogOpen(true);
                return;
            }

            saveClassSessionHandler(data);
            return;
        } else {
            addNewClassSession(data);
        }
    }

    const saveClassSessionHandler = (data) => {
        saveClassSession({
            currentSession: currentSession,
            classSession: data
        });
    }

    const addNewClassSession = (data) => {
        data.classId = currentClassId;
        createClassSession({ classSession: data });
    }

    const ACTION_IN_CHECK = {
        CREATE_CLASS_SESSION: true,
        SAVE_CLASS_SESSION: true
    }

    React.useEffect(() => {

        if (ACTION_IN_CHECK[SESSION_REQUEST.lastAction] && SESSION_REQUEST.success) {
            if (SESSION_REQUEST.status == "DISPLAY_ERROR_MESSAGE") {
                CLASS_FORM_METHOD.setError("general", { type: 'custom', message: SESSION_REQUEST.message });
            } else {
                setIsDialogOpen(false);
            }
        }
    }, [SESSION_REQUEST])

    return (
        <React.Fragment>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                scroll="paper"
                maxWidth="md"
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <FormProvider {...CLASS_FORM_METHOD} >
                    <DialogFixedForm control={CLASS_FORM_METHOD.control}
                        errors={CLASS_FORM_METHOD.errors}
                        onSubmit={CLASS_FORM_METHOD.handleSubmit(submitMethod)}>
                        <DialogTitle id="responsive-dialog-title">
                            {title}
                        </DialogTitle>
                        <DialogContent dividers>
                            <ClassSessionFieldList currentSession={currentSession} />
                            {CLASS_FORM_METHOD.formState.errors["general"] ?
                                (<ErrorMessage component="h2" variant="body2" color="error">
                                    {t(CLASS_FORM_METHOD.formState.errors["general"].message)}
                                </ErrorMessage>) : null}
                        </DialogContent>
                        <DialogActions>
                            <Grid container justifyContent="space-between">
                                <Button autoFocus onClick={closeDialog} variant="outlined">
                                    {t("general:button-cancel")}
                                </Button>
                                <Button type="submit" variant="contained" >
                                    {t("general:button-save")}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </DialogFixedForm>
                </FormProvider>
            </Dialog >
            <ConfirmActionDialog title={t("session:warning-rollback_status_pending")} actionName={"confirm"} open={confirmDialogOpen} setOpen={setConfirmDialogOpen} confirmOnClick={()=>{
                saveClassSessionHandler(cachedSubmitData);
            }} />

        </React.Fragment>
    )
}