const TUTOR_PROFILES = {
    chiName: "chiName",
    engName: "engName",
    legalName:"legalName",
    gender: "gender",
    birthday: "birthday",
    phone: "phone",
    mainLanguage:"mainLanguage",
    nationality: "nationality",
    educationBackground: "educationBackground",
    educationLevel: "educationLevel",
    subject: "subject",
    remarks: "remarks"
};

export default TUTOR_PROFILES;