import React from 'react';
import { useAuthenticationState, authenticationState, validateLocalJWT } from "./../../redux/authentication";
import { useThemePickerState } from "./../../redux/ThemePicker";
import Dashboard from "./../../component/layout/Dashboard";
import Unauthenticated from "./../../component/layout/Unauthenticated";
import { createTheme, ThemeProvider,responsiveFontSizes } from '@mui/material/styles';
import hueFromString from "./../../util/hueConverter";
import { getContrastColor } from "./../../util/ColorUtils";
import { useTranslation } from "react-i18next";
import SignUpPage from "./../page/SignUpPage";
import RequestVerificationPage from "./../page/RequestVerificationPage";
import USER_TYPE from "./../../const/UserType";
import { SnackbarProvider } from 'notistack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import { changeLanguage, useI18NControlState } from "./../../redux/I18NControl";
import I18N from "./../../I18n";
import {AbilityContext} from "./../../casl/authorization.js";


export default function ItutorApp() {

  React.useEffect(() => {
    validateLocalJWT();
  }, [])
  var pageToRender = null;
  var userId = useAuthenticationState().userId;
  var userTypeFromStore = authenticationState.getState().userType;
  const ABILITIES = useAuthenticationState().ability ;
  const LANG = useI18NControlState().lang;
  const LOCALES = useI18NControlState().locales;
  React.useEffect(() => {
    if (I18N.isInitialized && !LANG) {
      if (I18N.language) {
        changeLanguage({ lang: I18N.options.fallbackLng[I18N.language] || I18N.language });
      }
    }
  }, [I18N, LANG]);

  var userTheme = useThemePickerState();
  const theme = responsiveFontSizes(createTheme(
    {
      palette: {
        primary: {
          main: hueFromString(userTheme.primary.theme, userTheme.primary.shade)
        },
        secondary: {
          main: hueFromString(userTheme.secondary.theme, userTheme.secondary.shade)
        },
      },

      overrides: {
        MuiExpansionPanelSummary: {
          root: {
            backgroundColor: hueFromString(userTheme.primary.theme, userTheme.primary.shade),
            "color": getContrastColor(hueFromString(userTheme.primary.theme, userTheme.primary.shade)),
            "&$expanded": {},
          },
          expandIcon: {
            "color": getContrastColor(hueFromString(userTheme.primary.theme, userTheme.primary.shade)),
          }
        }
      }
    }));

  useTranslation();
  if (userId == null || userId === "") {
      pageToRender = <Unauthenticated></Unauthenticated>;
  } else {
    switch (userTypeFromStore) {
      case USER_TYPE.UNVERIFIED:
        pageToRender = <RequestVerificationPage></RequestVerificationPage>;
        break;
      case USER_TYPE.NEW_REGISTER:
        pageToRender = <RequestVerificationPage></RequestVerificationPage>;
        break;
      case USER_TYPE.ACTIVE:
        pageToRender = <Dashboard></Dashboard>;
        break;
      case USER_TYPE.PENDING:
        pageToRender = <SignUpPage></SignUpPage>;
        break;
      default:

        break;
    }
  };
  
  return (
    <div>
      <AbilityContext.Provider value={ABILITIES}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={LOCALES[LANG]}>
            <SnackbarProvider preventDuplicate axsnack={5}>
              {pageToRender}
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AbilityContext.Provider>
    </div>
  );
}