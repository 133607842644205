import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImageUploadDialog from "./../../../../component/common/ImageUploadDialog";
import { uploadHomeworkList } from "./../../../../redux/Homework";
import { useProfileState } from "./../../../../redux/ProfileManagement";

export default function StudentHomeworkDialog({ open, setOpen }) {
    const { t } = useTranslation();
    let { enrolmentId } = useParams();
    const STUDENT_PROFILE_INFO = useProfileState().studentProfileInfo || {};
    const CENTRE_ID = STUDENT_PROFILE_INFO.centreId;

    const uploadedLogo = (acceptedFiles) => {
        const formData = new FormData();
        for (let file of acceptedFiles) {
            formData.append("file", file);
        }
        formData.append("enrolmentId", enrolmentId);
        formData.append("centreId", CENTRE_ID);
        uploadHomeworkList({ formData: formData });
        setOpen(false);
    }

    return (
        <ImageUploadDialog 
        setOpen={setOpen} open={open} 
        content={t("general:dialog_content-upload_photo")} 
        title={t("general:dialog_title-upload_photo")} 
        uploadedImageHandler={uploadedLogo} />
    );
}
