import React from 'react';
import { List, ListItem, ListItemIcon, Box, Stack, Badge, IconButton } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { useAuthenticationState } from "./../../redux/authentication";
import { Can } from "./../../casl/authorization"

import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import StoreIcon from '@mui/icons-material/Store';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ClassIcon from '@mui/icons-material/Class';
import SmsIcon from '@mui/icons-material/Sms';
import SchemaIcon from '@mui/icons-material/Schema';
import UserListItem from "./../menuItem/userListItem";
import LanguageSwitcher from "./../I18N/LanguageSwitcher";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    marginRight: "-16px"
}));

function ListItemLink(props) {
    const { icon, primary, to, state } = props;
    const { t } = useTranslation("menu");

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} state={state} ref={ref} {...itemProps} />),
        [to, state],
    );
    return (
        <ListItem button onClick={(event) => {
            if (props.isInDrawer) {
                props.handleDrawerClose();
            }
        }} component={renderLink}>
            {icon ? <StyledListItemIcon>{icon}</StyledListItemIcon> : null}
            <span>{t(primary)}</span>
        </ListItem>
    );
}

function CurrentRegistrationContainCentreId({ children }) {
    const centreId = useAuthenticationState().currentRole.centreId || null;
    if (centreId) {
        return children
    }
    return null;
}

export default function Sidebar({ mobileOpen, handleDrawerToggle, isInDrawer, handleDrawerClose, notificationCount, documentTitle }) {
    const ABILITY = useAuthenticationState().ability;
    const CONTENT = [];
    const CENTRE_ID = useAuthenticationState().currentRole.centreId || null;
    const ENROLMENT_ID = useAuthenticationState().currentRole.roleId || null;
    const CURRENT_ROLE = useAuthenticationState().currentRole;

    const IS_CENTRE_ADMIN = CURRENT_ROLE.roleName === "centre_admin";
    const IS_TUTOR = CURRENT_ROLE.roleName === "tutor";
    const IS_STUDENT = CURRENT_ROLE.roleName === "student";

    CONTENT.push((
        <List key="sidebarMenu" component="nav" aria-label="secondary mailbox folders">
            <Can I="read" a="administrator" ability={ABILITY} >
                <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="users"
                    to="/users" primary="users" icon={<AccountCircleIcon />} />
                <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="centres"
                    to="/centres" primary="centres" icon={<StoreIcon />} />
                <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="platform_admins"
                    to="/platform_admins" primary="platform_admins" icon={<SupervisedUserCircleIcon />} />
                <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="plans"
                    to="/plans" primary="plans" icon={<SchemaIcon />} />
            </Can>
            <CurrentRegistrationContainCentreId>
                <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="centre"
                    to={{ pathname: (`/centre/${CENTRE_ID}`) }} state={{ hideEnrolmentSidebar: true }} primary="centre" icon={<StoreIcon />} />
                {IS_CENTRE_ADMIN ?
                    <React.Fragment>
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="tutors"
                            to={{ pathname: `/centre/${CENTRE_ID}`, search: "?tab=tutor" }} state={{ hideEnrolmentSidebar: true }} primary="tutors" icon={<SupportAgentIcon />} />
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="students"
                            to={{ pathname: `/centre/${CENTRE_ID}`, search: "?tab=student" }} state={{ hideEnrolmentSidebar: true }} primary="students" icon={<ChildCareIcon />} />
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="centre_class"
                            to={{ pathname: `/centre/${CENTRE_ID}`, search: "?tab=class" }} state={{ hideEnrolmentSidebar: true }} primary="classes" icon={<ClassIcon />} />
                    </React.Fragment>
                    : null
                }
                {IS_TUTOR ?

                    (<React.Fragment>
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="tutor_class"
                            to={{ pathname: `/tutor/${ENROLMENT_ID}`, search: "?tab=class" }} state={{ hideEnrolmentSidebar: true }} primary="classes" icon={<ClassIcon />} />
                            <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="tutor_schedule"
                            to={{ pathname: `/tutor/${ENROLMENT_ID}`, search: "?tab=schedule" }} state={{ hideEnrolmentSidebar: true }} primary="schedule" icon={<CalendarMonthIcon />} />
                            <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="tutor_schedule"
                            to={{ pathname: `/tutor/${ENROLMENT_ID}`, search: "?tab=payslip" }} state={{ hideEnrolmentSidebar: true }} primary="payslips" icon={<LocalAtmIcon />} />
                    </React.Fragment>)
                    : null
                }
                {IS_STUDENT ?

                    (<React.Fragment>
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="student_class"
                        to={{ pathname: `/student/${ENROLMENT_ID}`, search: "?tab=class" }} state={{ hideEnrolmentSidebar: true }} primary="classes" icon={<ClassIcon />} />
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="student_schedule"
                        to={{ pathname: `/student/${ENROLMENT_ID}`, search: "?tab=schedule" }} state={{ hideEnrolmentSidebar: true }} primary="schedule" icon={<CalendarMonthIcon />} />
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="student_schoolwork"
                        to={{ pathname: `/student/${ENROLMENT_ID}`, search: "?tab=schoolwork" }} state={{ hideEnrolmentSidebar: true }} primary="schoolwork" icon={<ListAltIcon />} />
                        <ListItemLink isInDrawer={isInDrawer} handleDrawerClose={handleDrawerClose} key="student_schoolwork"
                        to={{ pathname: `/student/${ENROLMENT_ID}`, search: "?tab=bill" }} state={{ hideEnrolmentSidebar: true }} primary="bills" icon={<LocalAtmIcon />} />
                     </React.Fragment>
                    ): null
                }
            </CurrentRegistrationContainCentreId>
        </List>
    ));



    return (
        <React.Fragment>
            {isInDrawer ? (< Stack sx={{
                display: { sm: "block", md: "none" },
                FlexGrow: 1,
                width: '200px',
                maxHeight: "100vh",
                height: "100vh",
                maxWidth: 240,
                overflowY: "auto",
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText
            }} justifyContent="space-between" >
                {CONTENT}
                <Stack sx={{
                    display: { xs: "flex", sm: "none" },
                    borderTop: 1,
                    paddingTop: 1,
                    paddingLeft: 1,
                    paddingRight: 1,
                    marginTop: 1,
                    marginBottom: 1
                }} justifyContent="space-between" alignItems="center" direction="row" >
                    {CURRENT_ROLE.roleName != "platform_admin" && 
                    <IconButton
                        color="inherit"
                        aria-label="open chat"
                        onClick={(e) => window.location.href = (`/message`)}
                    >
                        {notificationCount > 0 && <Badge badgeContent={notificationCount} color="info">
                            <SmsIcon/>
                        </Badge>}
                        {notificationCount == 0 && <SmsIcon/>}
                    </IconButton>}
                    <UserListItem handleDrawerClose={handleDrawerClose} documentTitle={documentTitle}>
                    </UserListItem>
                    <LanguageSwitcher handleDrawerClose={handleDrawerClose} />
                </Stack>
            </Stack >
            ) :
                (< Box sx={
                    {
                        display: { xs: "none", sm: 'none', md: 'block' },
                        width: '100%',
                        maxHeight: "calc(100vh - 70px) ",
                        height: "calc(100vh - 70px) ",
                        maxWidth: 240,
                        overflowY: "auto",
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText
                    }
                } >
                    {CONTENT}
                </Box >)
            }

        </React.Fragment>
    );
}