import React from "react";
import {
	Dialog, DialogTitle, DialogContent, IconButton, Stack
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function GeneralPendingDialog({ children, title, isDialogOpen, setIsDialogOpen }) {
	
	const closeDialog = () => {
		setIsDialogOpen(false);
	}

	React.useEffect(()=>{
		if(!children || children.length == 0){
			setIsDialogOpen(false);
		}
	},[children])

	return (
		<React.Fragment>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				scroll="paper"
				maxWidth="md"
				fullWidth
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					<Stack direction="row" alignItems="center">
						{title}
						<IconButton
							aria-label="close"
							onClick={closeDialog}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent sx={{ paddingBottom: 1, paddingTop: 3 }} dividers>
					{children}
				</DialogContent>
			</Dialog >
		</React.Fragment >
	)
}