import React from 'react';
import { useTranslation } from "react-i18next";
import {
	Grid, Button,
	Dialog, DialogTitle, DialogContent, DialogActions,
	TextField
} from "@mui/material";
import { SuccessButton } from "./../../../common/Buttons"
import { DialogFixedForm } from "./../../../common/Forms";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, FormProvider } from "react-hook-form";
import PlanFieldList from "./../../../form/planComponent/PlanFieldList";

import { createAddPlanFormSchema, INITIAL_PLAN_FORM } from "./../../../form/model/PlanFormModel";

export default function PlanAddEditDialog({ title, submitMethod, isDialogOpen, handleCloseDialog, currentPlan}) {

	const { t } = useTranslation();

    const PLAN_FORM = useForm({
        resolver: yupResolver(createAddPlanFormSchema()),
        defaultValue: INITIAL_PLAN_FORM
    });

    return (
        <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isDialogOpen}
        onClose={handleCloseDialog}
        scroll="paper"
        aria-labelledby="plan-dialog-title"
        aria-describedby="plan-dialog-description"
    >
        <DialogFixedForm
            onSubmit={PLAN_FORM.handleSubmit(submitMethod)} noValidate>
            <DialogTitle id="plan-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <FormProvider {...PLAN_FORM}>
                    <PlanFieldList currentPlan={currentPlan} />
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Button onClick={handleCloseDialog} variant="outlined" autoFocus>
                        {t("general:button-cancel")}
                    </Button>
                    <SuccessButton type="onSubmit" variant="contained" >
                        {t("general:button-save")}
                    </SuccessButton>
                </Grid>
            </DialogActions>
        </DialogFixedForm>
    </Dialog>

	);
}
