import {
    createReduxModule
} from "hooks-for-redux"
import I18N from "./../I18n";
import moment from "moment";
import "moment/locale/zh-hk";
import "moment/locale/en-gb";

const initialState = {
    lang:I18N.language || "",
    locales: {
      "zh-HK": "zh-hk",
      "en": "en-gb"
    }
}

function changeLanguageAction(state,lang) {
    I18N.changeLanguage(lang);
    moment.locale(state.locales[lang]);
    if (lang === "zh-HK") {
      moment.updateLocale("zh-hk", {
        weekdaysShort: '日_一_二_三_四_五_六'.split('_')
      })
    }
    return {
        lang:lang
    };
}

export const [useI18NControlState, {
        changeLanguage        
    },
    I18NControlState
] = createReduxModule(
    "I18NControlState",
    initialState, {
        changeLanguage: (I18NControlState, payload) => Object.assign({}, I18NControlState, changeLanguageAction(I18NControlState,payload.lang))
    }
);
