import React from 'react';
import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, TextField, FormHelperText } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

import { Controller } from "react-hook-form";
import { PLAN_FORM_FIELD, INITIAL_PLAN_FORM} from "./../model/PlanFormModel";

export default function PlanFieldList({ currentPlan }) {

    const StyledTextField = styled(TextField)(({ theme }) => ({
        "marginTop": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
    }));
    const { t } = useTranslation(["plan","general"]);

    const { control, formState: { errors }, setValue} = useFormContext();
    const { reset } = useFormContext();

    React.useEffect(() => {
        if (currentPlan) {
            reset(currentPlan);
        } else {
            reset(INITIAL_PLAN_FORM)
        }
    }, [currentPlan])

    return (
        <React.Fragment>
            <Controller
                name={PLAN_FORM_FIELD.name}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:subscription_plan-name")}
                        helperText={errors[PLAN_FORM_FIELD.name] ? t(errors[PLAN_FORM_FIELD.name].message) : null}
                        error={errors[PLAN_FORM_FIELD.name] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={PLAN_FORM_FIELD.monthlyPrice}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:subscription_plan-monthly_price")}
                        type="number"
                        error={errors[PLAN_FORM_FIELD.monthlyPrice] != null}
                        helperText={errors[PLAN_FORM_FIELD.monthlyPrice] ? t(errors[PLAN_FORM_FIELD.monthlyPrice].message) : 
                                    currentPlan?.monthlyPrice ? t("subscription:info-active_subscription_unaffected") : null}
                        {...field}
                    />
                }
            />
            <Controller
                name={PLAN_FORM_FIELD.studentQuota}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:subscription_plan-student_limit")}
                        type="number"
                        error={errors[PLAN_FORM_FIELD.studentQuota] != null}
                        helperText={errors[PLAN_FORM_FIELD.studentQuota] ? t(errors[PLAN_FORM_FIELD.studentQuota].message) : 
                                    currentPlan?.studentQuota ? t("subscription:info-active_subscription_unaffected") : null}
                        {...field}
                    />
                }
            />
            <Controller
                name={PLAN_FORM_FIELD.freeTrialDays}
                defaultValue={''}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:subscription_plan-free_trial")}
                        type="number"
                        helperText={errors[PLAN_FORM_FIELD.freeTrialDays] ? t(errors[PLAN_FORM_FIELD.freeTrialDays].message) : null}
                        error={errors[PLAN_FORM_FIELD.freeTrialDays] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
