import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImageUploadDialog from "./../../../../component/common/ImageUploadDialog";
import { uploadClassNotes } from "./../../../../redux/ClassesManagement";


export default function ClassNotesDialog({ open, setOpen }) {
    const { t } = useTranslation();
    let { classId } = useParams();

    const uploadedLogo = (acceptedFiles) => {
        const formData = new FormData();
        for (let file of acceptedFiles) {
            formData.append("files", file);
        }
        formData.append("classId", classId);
        uploadClassNotes({ formData: formData });
        setOpen(false);
    }

    return (
        <ImageUploadDialog setOpen={setOpen} open={open} 
        title={t("general:dialog_title-upload_file")} 
        content={t("general:dialog_content-upload_file")}
        uploadedImageHandler={uploadedLogo} />
    );
}
