import * as YUP from "yup";


function createAdministratorInvitationFormSchema() {
  return YUP.object().shape({
    email:YUP.string().email("general:validation_type_email").required("general:validation-mandatory")
  });
}

export {
  createAdministratorInvitationFormSchema
};