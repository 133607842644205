import React from 'react';
import { CENTRE_FACILITY_FIELD } from "./../model/CentreFormModel";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function CentreFacilityFieldList() {

    const StyledTextField = styled(TextField)(({ theme }) => ({
        "marginTop": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
    }));

    const {  control, formState: { errors } } = useFormContext();
    const { t } = useTranslation("attribute");

    return (
        <React.Fragment>
            <Controller
                name={CENTRE_FACILITY_FIELD.name}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        variant="outlined" fullWidth
                        label={t("attribute:facility-name")}
                        helperText={errors[CENTRE_FACILITY_FIELD.name] ? t(errors[CENTRE_FACILITY_FIELD.name].message) : null}
                        error={errors[CENTRE_FACILITY_FIELD.name] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={CENTRE_FACILITY_FIELD.description}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        minRows={2}
                        multiline variant="outlined" fullWidth
                        label={t("attribute:facility-description")}
                        helperText={errors[CENTRE_FACILITY_FIELD.description] ? t(errors[CENTRE_FACILITY_FIELD.description].message) : null}
                        error={errors[CENTRE_FACILITY_FIELD.description] != null}
                        {...field}
                    />
                }
            />
            <Controller
                name={CENTRE_FACILITY_FIELD.function}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        minRows={2}
                        multiline variant="outlined" fullWidth
                        label={t("attribute:facility-function")}
                        helperText={errors[CENTRE_FACILITY_FIELD.function] ? t(errors[CENTRE_FACILITY_FIELD.function].message) : null}
                        error={errors[CENTRE_FACILITY_FIELD.function] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
