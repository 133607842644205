import React from 'react';
import { CircularProgress, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import siteIcon from "./../../asset/img/site_icon.png";

const StyledGrid = styled(Grid)(({ theme }) => ({
    height: "100vh",
    width: "100vw",
    padding: "auto"
}));

const VerticalPlaceHolder = styled(Grid)(({ theme }) => ({
    height: "30vh",
}));

const LoadingPlaceHolder = styled(Grid)(({ theme }) => ({
    color: 'lightgrey',
    fontSize: '10px',
    fontWeight: 'bold',
    marginTop: 2
}));

function LoadingPage() {

    return (
        <StyledGrid item container align="center" justifyContent="center" md={12}>
            <VerticalPlaceHolder container item align="center" justifyContent="center" md={12} direction="row">
            </VerticalPlaceHolder>
            <Grid container item align="center" justifyContent="center" md={12} direction="row">
                <Grid container item align="center" justifyContent="center" md={12} direction="row">
                <Box
                    component="img"
                    sx={{
                        height: 120,
                        width: 120,
                    }}
                    src={siteIcon}
                />
                </Grid>
                <LoadingPlaceHolder container item align="center" justifyContent="center" md={12} direction="row">
                    L &nbsp;O &nbsp;A &nbsp;D &nbsp;I &nbsp;N &nbsp;G &nbsp;. &nbsp;. &nbsp;.
                </LoadingPlaceHolder>
            </Grid>
            <VerticalPlaceHolder container item align="center" justifyContent="center" md={12} direction="row">
            </VerticalPlaceHolder>
        </StyledGrid>
    );
}

export default LoadingPage;
