import React from 'react';
import { useTranslation } from "react-i18next";
import {
    Typography, CardActionArea, Card, CardContent, Grid,
    Stack, Box, IconButton
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled, useTheme } from '@mui/material/styles';
import EmptyAvatar from "./../../../asset/portrait/empty_avatar.png";
import SchoolIcon from '@mui/icons-material/School';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LimitedTextLabel from "./../../../component/display/LimitedTextLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import { nameInBracketStyle } from "./../../../util/DisplayUtil";

const Portrait = styled("img")(({ theme }) => ({
    borderRadius: "10px",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    objectPosition: "50% 50%",
}));

function SubjectDisplayer({ variant, index, subject }) {
    const { t } = useTranslation("attribute");
    const SEPARATOR = t("general:separator");
    if (subject) {
        if (subject.length == 1) {
            return (<Typography variant={variant} >{t(subject, { ns: "attribute" })}</Typography>);
        } else if (subject.length > 1) {

            let joinedString = subject.map(
                (currentSubject, index) => (t(currentSubject, { ns: "attribute" }))
            ).join(SEPARATOR);
            let result = (
                <LimitedTextLabel variant={variant}>
                    {joinedString}
                </LimitedTextLabel>
            )
            return (<Grid container>{result}</Grid>);
        }
    }
    return null;

}

export default function TutorCard({ currentTutor, index, cardOnClick, moreButtonOnClick, disabled = false }) {
    const [tutorPhotoPreview, setTutorPhotoPreview] = React.useState(EmptyAvatar);

    React.useEffect(() => {
        if (currentTutor && currentTutor.photo && currentTutor.photo.file) {
            setTutorPhotoPreview(currentTutor.photo.file);
        }
    }, [currentTutor]);


    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let displayNameStyle = { fontWeight: "bold", paddingLeft: 0.5 };
    let cardContentStyle = { paddingLeft: 2 };
    let stackStyle = {};
    let tutorEdBackgroundStyle = {};
    let contentVariant = "body2";
    if (smDown) {
        cardContentStyle = { width:"100%", paddingLeft: 1.5, paddingRight:1.5 };
        stackStyle = { width: "100%" };
        displayNameStyle = { paddingLeft: 0.5 }
        contentVariant = "caption";
        tutorEdBackgroundStyle = {maxWidth:"calc(80vw - 24px - 100px - 4px - 16px)"};
    }

    let cardStyle = { display: { md: "block" }, width: "100%", marginTop: 0, marginBottom: 2, borderRadius: '10px' };
    if (disabled) {
        cardStyle.backgroundColor ="#EEEEEE";
    }
    return (
        <React.Fragment>
            <Card sx={cardStyle}>
                <CardActionArea disabled={disabled} component="span" disableTouchRipple data-cy={"edit_tutor_btn_" + (index + 1)} onClick={(event) => { cardOnClick.call(this, event, currentTutor) }}>
                    <CardContent sx={cardContentStyle} >
                        <Stack sx={stackStyle} direction="row" spacing={1.5} >
                            <Box>
                                <Portrait alt={`Tutor" + ${index + 1}`} src={tutorPhotoPreview} />
                            </Box>
                            <Stack sx={{ width: "100%" }} justifyContent="center" alignItems="flex-start" spacing={1}>
                                <Stack sx={{ width: "100%" }} justifyContent="space-between" alignItems="center" direction="row">
                                    <LimitedTextLabel component="div" customstyle={displayNameStyle} variant="h6">
                                        {nameInBracketStyle(currentTutor.chiName, currentTutor.engName)}
                                    </LimitedTextLabel>
                                    {moreButtonOnClick ?
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation();
                                            moreButtonOnClick.call(this, event, currentTutor);
                                        }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                        : null}

                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <SchoolIcon color="disabled" fontSize="small" />
                                    <LimitedTextLabel variant={contentVariant} customstyle={tutorEdBackgroundStyle} color="text.secondary" component="div">
                                        {currentTutor.educationBackground}
                                    </LimitedTextLabel>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <LocalOfferIcon color="disabled" fontSize="small" />
                                    <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                        <SubjectDisplayer variant={contentVariant} subject={currentTutor.subject} />
                                    </LimitedTextLabel>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}