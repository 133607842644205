import React from "react";
import { styled } from '@mui/material/styles';
import { MenuItem, Box, ListSubheader, Select, Chip, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const GroupLabel = styled(ListSubheader)(({ theme }) => ({
    zIndex: "2",
    background: "#FFFFFF"
}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleDropdownItems({ field, options, labelId, labelText }) {
    var result = [];
    const { t } = useTranslation("attribute");

    for (let i of options) {
        if (i.group !== null) {
            result.push((<GroupLabel disabled key={i.group}>{t(i.group)}</GroupLabel>));
        }
        for (let option of i.options) {
            result.push(<MenuItem key={option} value={option}>{t(option)}</MenuItem>);
        }
    }

    return (
        <React.Fragment>
            <InputLabel id={labelId}>{labelText}</InputLabel>
            <Select multiple {...field}
                labelId={labelId}
                label={labelText}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (value && value != "" ? <Chip key={value} label={t(value)} /> : null))}
                        </Box>
                    )
                }}
            >
                {result}
            </Select>
        </React.Fragment>
    );
}
