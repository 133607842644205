import {
    createReduxModule
} from "hooks-for-redux"
import {
    getStudentBillRequest,
    addBillToClassRequest,
    updateStudentBillRequest,
    deleteStudentBillRequest,
    markAsPaidStudentBillRequest,
    payStudentBillRequest,
    getStripeBillSecretRequest
} from "./../api/billAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "../util/ReduxUtils";

function initialState() {
    return {
        updateAction: [],
        searchCriteria: {},
        stripeClientSecret:null,
        stripeAccountId:null,
        studentBills: [],
        studentBillsCount: 0,
        stripeRequestDetails:null,
        studentBillToBeSaved: null,
        studentBillToBeDeleted: null,
        studentBillToBeMarkAsPaid: null,
        studentBillToBePaid: null,
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "GET_STUDENT_BILL": "GET_STUDENT_BILL",
    "ADD_BILL_TO_CLASS": "ADD_BILL_TO_CLASS",
    "UPDATE_STUDENT_BILL": "UPDATE_STUDENT_BILL",
    "DELETE_STUDENT_BILL": "DELETE_STUDENT_BILL",
    "MARK_AS_PAID_STUDENT_BILL": "MARK_AS_PAID_STUDENT_BILL",
    "PAY_STUDENT_BILL" : "PAY_STUDENT_BILL",
    "GET_STRIPE_BILL_SECRET":"GET_STRIPE_BILL_SECRET"
};

export const [useStudentBillManagementState, {
    setUpdating,
    getStudentBill, getStudentBillSuccess, getStudentBillFail,
    addBillToClass, addBillToClassSuccess, addBillToClassFail,
    updateStudentBill, updateStudentBillSuccess, updateStudentBillFail,
    deleteStudentBill, deleteStudentBillSuccess, deleteStudentBillFail,
    markAsPaidStudentBill, markAsPaidStudentBillSuccess, markAsPaidStudentBillFail,
    payStudentBill, payStudentBillSuccess, payStudentBillFail,
    getStripeBillSecret, getStripeBillSecretSuccess, getStripeBillSecretFail,
    clearStripeBillSecretData
},
    studentBillManagementState
] = createReduxModule(
    "studentBillManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getStudentBill: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_STUDENT_BILL),
        studentId: payload.enrolmentId
    }),
    getStudentBillSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        studentBills: payload.data.studentBills,
        studentBillsCount: payload.data.studentBillsCount
    }),
    getStudentBillFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    addBillToClass: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_BILL_TO_CLASS),
        BillToBeSaved: payload
    }),
    addBillToClassSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        BillToBeSaved: {}
    }),
    addBillToClassFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    updateStudentBill: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_STUDENT_BILL),
        studentBillToBeSaved: payload
    }),
    updateStudentBillSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    updateStudentBillFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    deleteStudentBill: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_STUDENT_BILL),
        studentBillToBeDeleted: payload.billId
    }),
    deleteStudentBillSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    deleteStudentBillFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    markAsPaidStudentBill: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.MARK_AS_PAID_STUDENT_BILL),
        studentBillToBeMarkAsPaid: payload.billId
    }),
    markAsPaidStudentBillSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    markAsPaidStudentBillFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    payStudentBill: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.PAY_STUDENT_BILL),
        studentBillToBePaid: payload
    }),
    payStudentBillSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    payStudentBillFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getStripeBillSecret: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_STRIPE_BILL_SECRET),
        stripeRequestDetails: payload,
        stripeClientSecret: null,
        stripeAccountId: null
    }),
    getStripeBillSecretSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    },{
        stripeClientSecret:payload.data.secret,
        stripeAccountId: payload.data.accountId
    }),
    getStripeBillSecretFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    clearStripeBillSecretData :(state, payload) => Object.assign({}, state, {
       stripeRequestDetails: null,
       stripeClientSecret: null,
       stripeAccountId: null,
    }),
});

const UPDATE_ACTION_HANDLER = {
    "GET_STUDENT_BILL": {
        handler: async (currentState, action) => {
            var result = await getStudentBillRequest(currentState.studentId);
            if (result) {
                //console.log('GET_STUDENT_BILL ' + JSON.stringify(result));
                if (result.success) {
                    getStudentBillSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    getStudentBillFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getStudentBillFail
    },
    "ADD_BILL_TO_CLASS": {
        handler: async (currentState) => {
            var result = await addBillToClassRequest(currentState.BillToBeSaved);
            if (result) {
                if (result.success) {
                    addBillToClassSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "Bills": result.data
                    });
                    /*
                    let BillId = result.data.Bill._id;
                    pushRedirect({
                        path: `/Bill_settings/${BillId}`
                    })
                    */
                } else {
                    addBillToClassFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addBillToClassFail
    },
    "UPDATE_STUDENT_BILL": {
        handler: async (currentState, action) => {
            var result = await updateStudentBillRequest(currentState.studentBillToBeSaved);
            if (result) {
                if (result.success) {
                    updateStudentBillSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action":action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    updateStudentBillFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateStudentBillFail
    },
    "DELETE_STUDENT_BILL": {
        handler: async (currentState, action) => {
            var result = await deleteStudentBillRequest({billId:currentState.studentBillToBeDeleted});
            if (result) {
                console.log('DELETE_STUDENT_BILL ' + JSON.stringify(result));
                if (result.success) {
                    deleteStudentBillSuccess({
                        "success": true,
                        "error": result.error,
                        "action":action,
                        "requestStatus":result.status,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    deleteStudentBillFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updateStudentBillFail
    },
    "MARK_AS_PAID_STUDENT_BILL": {
        handler: async (currentState, action) => {
            var result = await markAsPaidStudentBillRequest({billId: currentState.studentBillToBeMarkAsPaid});
            if (result) {
                console.log('MARK_AS_PAID_STUDENT_BILL ' + JSON.stringify(result));
                if (result.success) {
                    markAsPaidStudentBillSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action":action,
                        "requestStatus":result.status,
                        "data": result.data
                    });
                } else {
                    markAsPaidStudentBillFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: markAsPaidStudentBillFail
    },
    "PAY_STUDENT_BILL" : {
        handler: async (currentState, action) => {
            var result = await payStudentBillRequest(currentState.studentBillToBePaid);
            if (result) {
                if (result.success) {
                    payStudentBillSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    payStudentBillFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: payStudentBillFail
    },
    "GET_STRIPE_BILL_SECRET" : {
        handler: async (currentState, action) => {
            console.log("called");
            var result = await getStripeBillSecretRequest(currentState.stripeRequestDetails);
            if (result) {
                if (result.success) {
                    getStripeBillSecretSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action":action,
                        "data": result.data
                    });
                } else {
                    getStripeBillSecretFail({
                        "message": result.message,
                        "action":action,
                        error: result.error
                    });
                }
            }
        },
        failHandler: getStripeBillSecretFail
    }
};

studentBillManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});