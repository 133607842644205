import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
    List, ListItem, ListItemText,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Paper, Grid, Button,
    IconButton, TextField
} from "@mui/material";
import debounce from 'lodash.debounce';
import { yupResolver } from "@hookform/resolvers/yup";
import { createAdministratorInvitationFormSchema } from "./../form/model/AdministratorFormModel";
import { inviteAdministrator, removeAdministrator, usePlatformAdminManagementState, getAdministratorEnrolment } from "./../../redux/PlatformAdministratorManagement";
import { useAuthenticationState } from "./../../redux/authentication";
import LoadingPage from "./LoadingPage";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Controller, useForm } from "react-hook-form";
import { SpaceHolder } from "./../../component/common/Others";
import { DialogFixedForm } from "./../../component/common/Forms";
import { SuccessButton, ContextualMenuItem } from "./../../component/common/Buttons";
import ContextualMenu from "./../../component/common/ContextualMenu";
import GeneralHeader from "./../../component/common/GeneralHeader";
import { DashboardRootContainer, AdminFormRow } from "./../../component/common/Containers";
import ConfirmActionDialog from "./../../component/common/ConfirmActionDialog";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: "100%",
    width: "100%",
    borderRadius: "10px"
}));


export default function PlatformAdmin() {
    const rows = usePlatformAdminManagementState().administratorEnrolment;
    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;

    const [selected, setSelected] = React.useState({});
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const addPlatformAdminFormMethod = useForm({
        resolver: yupResolver(createAdministratorInvitationFormSchema()),
        defaultValue: {
            "email": ""
        }
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation(["general", "attribute", "platform_admin"]);

    let deboundedGetAdministratorEnrolment = React.useMemo(() => debounce(getAdministratorEnrolment, 50), []);

    React.useEffect(() => {
        deboundedGetAdministratorEnrolment();
    }, []);

    function addAdministrator(data) {
        inviteAdministrator({
            email: data.email
        });
        addPlatformAdminFormMethod.reset();
        setIsDialogOpen(false);
        deboundedGetAdministratorEnrolment();
    }

    function triggerRemoveAdministrator() {
        removeAdministrator({
            id: selected._id
        });
        deboundedGetAdministratorEnrolment();
    }

    const handleClick = (event, value) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelected(value);
    };

    const openAddAdminDialog = (event) => {
        event.preventDefault();
        addPlatformAdminFormMethod.reset();
        setIsDialogOpen(true);
    }

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setIsDialogOpen(false);
    }

    const openDeleteDialog = (event) => {
        event.preventDefault();
        setDeleteDialogOpen(true);
        setAnchorEl(null);
    }


    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item  xs={12} md={11} lg={10} xl={8}>
                        <GeneralHeader onClickHandler={openAddAdminDialog}
                            displayButton={ABILITY.can("add", "administrator")}
                            title={t("platform_admin:page_title")} />
                        <Row container item md={12}>
                            <StyledPaper>
                                <List sx={{ width: '100%' }}>
                                    {rows.map((value) => (
                                        <ListItem
                                            key={value._id}
                                            secondaryAction={
                                                <IconButton onClick={(event) => {
                                                    handleClick(event, value)
                                                }} aria-label="comment">
                                                    <MoreVertIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText primary={value.username} />
                                        </ListItem>
                                    ))}
                                </List>
                                <ContextualMenu id={"admin-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
                                    <ContextualMenuItem
                                        disabled={selected.username == AUTH_STATE.username}
                                        onClick={openDeleteDialog}
                                        title={t("general:button-remove")}>
                                        <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                                    </ContextualMenuItem>
                                </ContextualMenu>
                            </StyledPaper>
                        </Row>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isDialogOpen}
                onClose={handleCloseDialog}
                scroll="paper"
                aria-labelledby="administrator-dialog-title"
                aria-describedby="administrator-dialog-description"
            >
                <DialogFixedForm
                    onSubmit={addPlatformAdminFormMethod.handleSubmit(addAdministrator)} noValidate>
                    <DialogTitle id="administrator-dialog-title">
                        {t("general:dialog_title-add_entity", { entity: t("attribute:platform-admin") })}
                    </DialogTitle>
                    <DialogContent dividers>
                        <AdminFormRow justifyContent="center" alignItems="center" container >
                            <Controller
                                name="email"
                                defaultValue={''}
                                control={addPlatformAdminFormMethod.control}
                                render={
                                    ({ field }) => <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        label={t("attribute:user-email_address")}
                                        helperText={addPlatformAdminFormMethod.formState.errors.email ? t(addPlatformAdminFormMethod.formState.errors.email.message) : null}
                                        error={addPlatformAdminFormMethod.formState.errors.email ? true : false}
                                        type="string"
                                    />
                                }
                            />
                        </AdminFormRow>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button onClick={handleCloseDialog} variant="outlined" autoFocus>
                                {t("general:button-cancel")}
                            </Button>
                            <SuccessButton type="onSubmit" variant="contained" >
                                {t("general:button-add")}
                            </SuccessButton>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </Dialog>
            <ConfirmActionDialog
                title={t("general:dialog_title-delete_entity", { entity: t("attribute:platform-admin") })}
                actionName={t("general:button-delete")}
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                confirmOnClick={triggerRemoveAdministrator}
            />
        </React.Suspense>
    );
}

