import React from "react";
import {
    Grid
} from "@mui/material";
import debounce from 'lodash.debounce';
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LoadingPage from "./LoadingPage";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import ClassSessionAboutPage from "./session/ClassSessionAboutPage";
import ClassSessionStudentPage from "./session/ClassSessionStudentPage";
import ClassSessionProgressPage from "./session/ClassSessionProgressPage";
import ClassSessionPageHeader from "./session/header/ClassSessionPageHeader";
import { getClassSessionInfo, useClassSessionManagementState } from "./../../redux/ClassSessionManagement";
import { useAuthenticationState } from "./../../redux/authentication";
import { pushRedirect } from "./../../redux/Redirect";
import { subject } from "@casl/ability";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export default function ClassSessionPage() {

    const CLASS_SESSION_INFO = useClassSessionManagementState().currentSession || {};
    const ABILITY = useAuthenticationState().ability;
    let isPending = CLASS_SESSION_INFO.status == "pending";
    const ALL_TABS = [
        { "tab_element": (<ClassSessionAboutPage />), "tab_label": "general:tab_name-about", permission: true },
        { "tab_element": (<ClassSessionStudentPage />), "tab_label": "general:tab_name-students", permission: ABILITY.can("read", subject("class_session", CLASS_SESSION_INFO), "students") },
        { "tab_element": (<ClassSessionProgressPage />), "tab_label": "general:tab_name-progress", permission: !isPending && ABILITY.can("read", subject("class_session", CLASS_SESSION_INFO), "progress") },
    ];

    const TAB_ELEMENTS = ALL_TABS.filter((element) => element.permission == true);
    let { sessionId } = useParams();
    let [searchParams] = useSearchParams();
    const SESSION_REQUEST = useClassSessionManagementState().request;
    const SEARCH_PARAM_TAB = parseInt(searchParams.get("tab")) || 0;
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const debouncedGetClassSessionInfo = React.useMemo(() => debounce(getClassSessionInfo, 50), []);

    const ACTION_IN_CHECK = {
        "GET_CLASS_SESSION_INFO": true,
        "REMOVE_STUDENT_FROM_CLASS": true,
        "ADD_TUTOR_TO_SESSION": true,
        "REMOVE_TUTOR_FROM_SESSION": true,
        "ADD_FACILITY_TO_SESSION": true,
        "REMOVE_FACILITY_FROM_SESSION": true,
        "SAVE_CLASS_SESSION": true,
        "CONFIRM_CLASS_SESSION": true,
        "DELETE_CLASS_SESSION": true,
        "APPLY_CLASS": true,
        "UPDATE_STUDENT_ATTENDANCE": true
    };

    React.useEffect(() => {
        if (sessionId) {
            debouncedGetClassSessionInfo({ sessionId: sessionId });
        }
    }, []);


    React.useEffect(() => {
        if (sessionId) {
            debouncedGetClassSessionInfo({ sessionId: sessionId });
        }
    }, [sessionId]);

    React.useEffect(() => {

        if (ACTION_IN_CHECK[SESSION_REQUEST.lastAction]) {
            if (SESSION_REQUEST.status === "REQUEST_OBJECT_NOT_FOUND") {
                pushRedirect({ path: "/" });
            }
            if (SESSION_REQUEST.status === "REFRESH_DATA_REQUIRE") {
                debouncedGetClassSessionInfo({ sessionId: sessionId });
            }
        }
    }, [SESSION_REQUEST]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2}  />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <Row container item xs={12}>
                            <ClassSessionPageHeader TAB_ELEMENTS={TAB_ELEMENTS}
                                value={value} setValue={setValue} />
                        </Row>
                        <Grid sx={{ marginTop: 2 }} container item xs={12} md={12}>
                            {
                                TAB_ELEMENTS.map(
                                    (element, index) =>
                                        <TabPanel key={`element_${index}`} value={value} index={index}>
                                            {element.tab_element}
                                        </TabPanel>
                                )
                            }
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
        </React.Suspense>
    );
}
