import {
    createReduxModule
} from "hooks-for-redux"
import {
    getPlansRequest,
    addPlanRequest,
    updatePlanRequest,
    deletePlanRequest
} from "./../api/planAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        plans: [],
        plansCount: 0,
        currentPlan: {},
        planId: null,
        planToBeSaved: null,
        planToBeDeleted: null,
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "GET_PLAN": "GET_PLAN",
    "ADD_PLAN": "ADD_PLAN",
    "UPDATE_PLAN": "UPDATE_PLAN",
    "DELETE_PLAN": "DELETE_PLAN"
};

export const [usePlanManagementState, {
    setUpdating,
    getPlans, getPlansSuccess, getPlansFail,
    addPlan, addPlanSuccess, addPlanFail,
    updatePlan, updatePlanSuccess, updatePlanFail,
    deletePlan, deletePlanSuccess, deletePlanFail
},
    planManagementState
] = createReduxModule(
    "planManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getPlans: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_PLAN)
    }),
    getPlansSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        plans: payload.plans,
        plansCount: payload.count
    }),
    getPlansFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    addPlan: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ADD_PLAN),
        planToBeSaved: payload.plan
    }),
    addPlanSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        planToBeSaved: {}
    }),
    addPlanFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    updatePlan: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_PLAN),
        planToBeSaved: payload.plan
    }),
    updatePlanSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        currentPlan: payload.plan
    }),
    updatePlanFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    deletePlan: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_PLAN),
        planToBeDeleted: payload
    }),
    deletePlanSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        photoToBeDeleted: null
    }),
    deletePlanFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

});


const UPDATE_ACTION_HANDLER = {
    "GET_PLAN": {
        handler: async (currentState) => {
            var result = await getPlansRequest();
            if (result) {
                if (result.success) {
                    getPlansSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "plans": result.data.plans,
                        "centreCount": result.data.count
                    });
                } else {
                    getPlansFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getPlansFail
    },
    "ADD_PLAN": {
        handler: async (currentState) => {
            var result = await addPlanRequest(currentState.planToBeSaved);
            if (result) {
                if (result.success) {
                    addPlanSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "plan": result.data
                    });
                    pushRedirect({
                        path: '/plans'
                    })
                } else {
                    addPlanFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: addPlanFail
    },
    "UPDATE_PLAN": {
        handler: async (currentState) => {
            let result = await updatePlanRequest(currentState.planToBeSaved);
            if (result) {
                if (result.success) {
                    updatePlanSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    pushRedirect({
                        path: '/plans'
                    })
                } else {
                    updatePlanFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updatePlanFail
    },
    "DELETE_PLAN": {
        handler: async (currentState) => {
            let result = await deletePlanRequest(currentState.planToBeDeleted);
            if (result) {
                if (result.success) {
                    deletePlanSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                    pushRedirect({
                        path: '/plans'
                    })
                } else {
                    deletePlanFail({
                        "message": "centre:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deletePlanFail
    }
};

planManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});