import {
    createReduxModule
} from "hooks-for-redux"
import {
    getPayslipsRequest,
    issuePayslipRequest,
    updatePayslipRequest,
    deletePayslipRequest,
} from "./../api/payslipAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "../util/ReduxUtils";
import {
    pushRedirect
} from "./Redirect";

function initialState() {
    return {
        updateAction: [],
        searchCriteria: {},
        payslips: [],
        payslipsCount: 0,
        payslipToBeSaved: null,
        payslipToBeDeleted: null,
        request: initalRequestState()
    }
}

const UPDATE_ACTION = {
    "GET_PAYSLIP": "GET_PAYSLIP",
    "ISSUE_PAYSLIP": "ISSUE_PAYSLIP",
    "UPDATE_PAYSLIP": "UPDATE_PAYSLIP",
    "DELETE_PAYSLIP": "DELETE_PAYSLIP"
};

export const [usePayslipManagementState, {
    setUpdating,
    getPayslips, getPayslipsSuccess, getPayslipsFail,
    issuePayslip, issuePayslipSuccess, issuePayslipFail,
    updatePayslip, updatePayslipSuccess, updatePayslipFail,
    deletePayslip, deletePayslipSuccess, deletePayslipFail
},
    payslipManagementState
] = createReduxModule(
    "payslipManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getPayslips: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_PAYSLIP),
        tutorProfileId: payload.tutorProfileId
    }),
    getPayslipsSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        payslips: payload.data.payslips,
        payslipsCount: payload.data.payslipsCount
    }),
    getPayslipsFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    issuePayslip: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.ISSUE_PAYSLIP),
        payslipToBeSaved: payload
    }),
    issuePayslipSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    issuePayslipFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    updatePayslip: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.UPDATE_PAYSLIP),
        payslipToBeSaved: payload
    }),
    updatePayslipSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    updatePayslipFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

    deletePayslip: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.DELETE_PAYSLIP),
        payslipToBeDeleted: payload
    }),
    deletePayslipSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
    }),
    deletePayslipFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),

});

const UPDATE_ACTION_HANDLER = {
    "GET_PAYSLIP": {
        handler: async (currentState, action) => {
            var result = await getPayslipsRequest(currentState.tutorProfileId);
            if (result) {
                if (result.success) {
                    getPayslipsSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "action": action,
                        "requestStatus": result.status,
                        "data": result.data
                    });
                } else {
                    getPayslipsFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getPayslipsFail
    },
    "ISSUE_PAYSLIP" :{
        handler: async (currentState, action) => {
            // console.log("PayslipManagement " + JSON.stringify(currentState.payslipToBeSaved));
            var result = await issuePayslipRequest(currentState.payslipToBeSaved);
            if (result) {
                console.log('ISSUE_PAYSLIP ' + JSON.stringify(result));
                if (result.success) {
                    issuePayslipSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "requestStatus":result.status,
                        "action":action,
                        "data": result.data
                    });
                } else {
                    issuePayslipFail({
                        "message": "Bill:fail_to_load",
                        "action":action,
                        error: result.error
                    });
                }
            }
        },
        failHandler: issuePayslipFail
    },
    "UPDATE_PAYSLIP": {
        handler: async (currentState, action) => {
            var result = await updatePayslipRequest(currentState.payslipToBeSaved);
            if (result) {
                console.log('UPDATE_PAYSLIP ' + JSON.stringify(result));
                if (result.success) {
                    updatePayslipSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "requestStatus":result.status,
                        "action":action,
                        "data": result.data
                    });
                } else {
                    updatePayslipFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: updatePayslipFail
    },
    "DELETE_PAYSLIP": {
        handler: async (currentState, action) => {
            var result = await deletePayslipRequest(currentState.payslipToBeDeleted);
            if (result) {
                console.log('DELETE_PAYSLIP ' + JSON.stringify(result));
                if (result.success) {
                    deletePayslipSuccess({
                        "success": true,
                        "error": result.error,
                        "message": result.message,
                        "data": result.data
                    });
                } else {
                    deletePayslipFail({
                        "message": "Bill:fail_to_load",
                        error: result.error
                    });
                }
            }
        },
        failHandler: deletePayslipFail
    }
};

payslipManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating);
});