import sanitizeHtml from 'sanitize-html';
import React from "react";
import axios from "axios";

export default function SanitizeHtmlWrapper({url}){
  const [content,setContent] = React.useState("");
  
  React.useEffect(()=>{
    axios.get(url).then((result)=>{
      if(result.status == 200){
        setContent(result.data || "");
      }
    });
  },[url]);
  
  const clean = sanitizeHtml(content, {
    allowedTags: ['b', 'i', 'em', "a",'strong','span','li','ol','ul','p','h1','h2','h3','h4'],
    allowedAttributes: {
      a: ['href', 'target']
    }
  });
  return (
    <span 
      dangerouslySetInnerHTML={{__html: clean}}
    />
  );
};