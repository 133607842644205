import * as YUP from "yup";


const INITIAL_STUDENT_FORM =  {
    engName: "",
    chiName: "",
    gender: "",
    birthday: "",
    school: "",
    grade: "",
    district: "",
    remarks: "",
    guardian_name: "",
    guardian_alias: "",
    guardian_relationship: "",
    guardian_phone: "",
    contact_person_1_name: "",
    contact_person_2_name: "",
    contact_person_1_phone: "",
    contact_person_2_phone: "",
}

function createStudentFormSchema(genderValidValue, gradeValidValue, districtValidValue, aliasValidValue, relationshipValidValue) {
  return YUP.object().shape({
    engName: YUP.string().required("general:validation-mandatory"),
    chiName: YUP.string(),
    gender: YUP.string().oneOf(genderValidValue).required("general:validation-mandatory"),
    birthday: YUP.date().max(new Date(),"general:validation-date").typeError("general:validation-date").required("general:validation-mandatory"),
    school: YUP.string().required("general:validation-mandatory"),
    grade: YUP.string().nullable().oneOf(gradeValidValue).required("general:validation-mandatory"),
    district: YUP.string().nullable().oneOf(districtValidValue).required("general:validation-mandatory"),
    remarks: YUP.string(),
    guardian_name: YUP.string().required("general:validation-mandatory"),
    guardian_alias: YUP.string().nullable().oneOf(aliasValidValue).required("general:validation-mandatory"),
    guardian_relationship: YUP.string().nullable().oneOf(relationshipValidValue).required("general:validation-mandatory"),
    guardian_phone: YUP.string().matches(/^\+?[0-9]{7,15}$/,"general:validation-phone").required("general:validation-mandatory"),
    contact_person_1_name: YUP.string().required("general:validation-mandatory"),
    contact_person_2_name: YUP.string().required("general:validation-mandatory"),
    contact_person_1_phone: YUP.string().matches(/^\+?[0-9]{7,15}$/,"general:validation-phone").required("general:validation-mandatory"),
    contact_person_2_phone: YUP.string().matches(/^\+?[0-9]{7,15}$/,"general:validation-phone").required("general:validation-mandatory"),
  });
}

const STUDENT_PROFILE_FIELD = {
    engName: "engName",
    chiName: "chiName",
    gender: "gender",
    birthday: "birthday",
    school: "school",
    grade: "grade",
    district: "district",
    remarks: "remarks",
    guardian_name: "guardian_name",
    guardian_alias: "guardian_alias",
    guardian_relationship: "guardian_relationship",
    guardian_phone: "guardian_phone",
    studentPhoto: "studentPhoto",
    studentPhotoFile: "studentPhotoFile",
    contactPerson1Name: "contact_person_1_name",
    contactPerson2Name: "contact_person_2_name",
    contactPerson1Contact: "contact_person_1_phone",
    contactPerson2Contact: "contact_person_2_phone"
}

export {createStudentFormSchema,INITIAL_STUDENT_FORM,STUDENT_PROFILE_FIELD};