import React from "react";
import {
    Typography,
    Grid,
    IconButton, Paper, Stack,
    Tabs, Tab,
    Breadcrumbs, Link,
    Button
} from "@mui/material";
import { useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import { subject } from "@casl/ability";
import { TabContainer } from "./../../../../component/common/Containers";
import { SpaceHolder } from "./../../../../component/common/Others";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";

import { useAuthenticationState } from "./../../../../redux/authentication";
import { useClassesManagementState, publishClass, deleteClass, removeClass, removeStudentFromClass } from "./../../../../redux/ClassesManagement";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import { Can } from "./../../../../casl/authorization";
import CanAccess from "./../../../acl/CanAccess";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CampaignIcon from '@mui/icons-material/Campaign';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

import ViewableByAdminsOnly from "./../../../../component/acl/ViewableByAdminsOnly";

import ConfirmActionDialog from "./../../../../component/common/ConfirmActionDialog";
import ApplyClassDialog from "./../../../page/class/dialog/ApplyClassDialog"
import CentreClassDialog from "./../dialog/CentreClassDialog";
import ClassBillDialog from "./../dialog/ClassBillDialog";

const LogoContainer = styled(Grid)(({ theme }) => ({
    "paddingBottom": theme.spacing(1),
    "paddingRight": theme.spacing(2)
}));

const ButtonGrid = styled(Grid)(({ theme }) => ({

}));


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ShowInStatus({ children, status }) {
    const CLASS_INFO = useClassesManagementState().currentClass || {};

    if (CLASS_INFO.status == status) {
        return children;
    }
    return null;
}

function Buttons({ triggerClassMenu, applyClassHandler }) {
    const CLASS_INFO = useClassesManagementState().currentClass || {};
    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;
    
    const CAN_UPDATE_CLASS_NAME = ABILITY.can("update", subject("class", CLASS_INFO), "name");
    const CAN_LEAVE_CLASS = ABILITY.can("leave", subject("class", CLASS_INFO));
    const CAN_BROADCAST = CLASS_INFO.status == "active" && CLASS_INFO.tutors.includes(AUTH_STATE.currentRole.roleId);
    const CAN_APPLY_CLASS = ABILITY.can("apply", subject("class", CLASS_INFO));
    let pendingStudentsList = CLASS_INFO.pendingStudents || [];
    const DISABLE_APPLY_BUTTON = pendingStudentsList.indexOf(AUTH_STATE.currentRole.roleId) != -1;
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <ShowInStatus status="pending">
                <ViewableByAdminsOnly>
                    <CanAccess action="update" entity={CLASS_INFO} entityType="class">
                        <IconButton
                            sx={{
                                marginTop: 2,
                                backgroundColor: "#DDDDDD",
                            }}
                            color="primary"
                            aria-label="upload picture" component="label"
                            onClick={triggerClassMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </CanAccess>
                </ViewableByAdminsOnly>
            </ShowInStatus>
            <ShowInStatus status="active">
                {CAN_LEAVE_CLASS || CAN_UPDATE_CLASS_NAME || CAN_BROADCAST ?
                    (<IconButton
                        sx={{
                            marginTop: 2,
                            backgroundColor: "#DDDDDD",
                        }}
                        color="primary"
                        aria-label="upload picture" component="label"
                        onClick={triggerClassMenu}
                    >
                        <MoreHorizIcon />
                    </IconButton>) : null
                }
                {CAN_APPLY_CLASS || DISABLE_APPLY_BUTTON ?
                    (<Button disabled={DISABLE_APPLY_BUTTON} onClick={applyClassHandler} sx={{ height: "50px", width: "140px" }} variant="contained">
                        {t("general:button-apply")}
                    </Button>) : null}
            </ShowInStatus>

        </React.Fragment>
    );
}

export default function ClassPageHeader({ TAB_ELEMENTS, value, setValue }) {
    const { t } = useTranslation("general");
    let { classId } = useParams();
    const CLASS_INFO = useClassesManagementState().currentClass || {};
    const CLASS_CENTRE = CLASS_INFO.centre || {};
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
    const [quitClassDialogOpen, setQuitClassDialogOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [issueBillDialogOpen, setIssueBillDialogOpen] = React.useState(false);
    const [applyClassDialogOpen, setApplyClassDialogOpen] = React.useState(false);

    const CENTRE_ID = CLASS_INFO.centreId;
    const LOCATION = useLocation();
    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;

    const triggerClassMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    const triggerEditDialog = (event) => {
        event.preventDefault();
        setEditDialogOpen(true);
    }

    const triggerQuitClassDialog = (event) => {
        event.preventDefault();
        setQuitClassDialogOpen(true);
    }

    const triggerApplyClassDialog = (event) => {
        event.preventDefault();
        setApplyClassDialogOpen(true);
    }

    const triggerIssueBillDialog = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        setIssueBillDialogOpen(true);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate({
            pathname: ("/class/" + classId),
            search: createSearchParams({
                tab: newValue
            }).toString(),
        }, { state: LOCATION.state });
    };

    const publishClassOnClick = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        publishClass({ classId: classId });
    }

    const deleteClassOnClick = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    const removeClassOnClick = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        setRemoveDialogOpen(true);
    }

    const triggerDeleteClass = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        deleteClass({ classId: classId });
    }

    const triggerRemoveClass = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        removeClass({ classId: classId });
    }

    const triggerQuitClass = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        removeStudentFromClass({ classId: classId, enrolmentId: AUTH_STATE.currentRole.roleId });
    }

    const navigate = useNavigate();

    const navigateToCentre = (event) => {
        navigate("/centre/" + CENTRE_ID + "?tab=class", { state: { hideEnrolmentSidebar: true } });
    }

    const navigateToChatroom = (event) => {
        window.location.href = (`/message/broadcast?class=${classId}`);
    }
    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let smUp = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <React.Fragment>
            <Paper sx={{ width: "100%", borderRadius: "10px" }}>
                <Grid container item sm={12} xs={11.5}>
                    <SpaceHolder container item sm={0.5} xs={0.5} />
                    <Grid sx={{ marginTop: 3 }} container item sm={11} xs={11.5}>
                        {smUp ? (<React.Fragment>
                            <LogoContainer container alignItems="flex-start" item xs={11} sm={10}
                                justifyContent="flex-start"  >
                                <Stack direction="row" justifyContent="flex-start"
                                    alignItems="center" spacing={2}>
                                    <Stack spacing={1}>
                                        <Stack alignItems="center" direction="row">
                                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>{CLASS_INFO.name}</Typography>
                                            <Can I="update" this={subject("class", CLASS_INFO)} field="name" ability={ABILITY} >
                                                <IconButton
                                                    sx={{
                                                        marginLeft: 1,
                                                        backgroundColor: "#DDDDDD",
                                                    }}
                                                    color="primary"
                                                    aria-label="upload picture" component="label"
                                                    onClick={triggerEditDialog}
                                                >
                                                    <EditIcon sx={{ fontSize: 14 }} />
                                                </IconButton>
                                            </Can>
                                        </Stack>
                                        <Breadcrumbs sx={{ display: { xs: "block", sm: "block" }, cursor: "pointer" }} separator={<NavigateNextIcon fontSize="small" />}>
                                            <Link underline="hover" onClick={navigateToCentre} color="secondary">
                                                <Typography color="secondary">{CLASS_CENTRE.displayName}</Typography>
                                            </Link>
                                            <Typography color="secondary">{CLASS_INFO.status ? t("class:page_title-" + CLASS_INFO.status) : ""}</Typography>
                                        </Breadcrumbs>
                                    </Stack>
                                </Stack>
                            </LogoContainer>
                            <ButtonGrid container alignItems="flex-start" justifyContent="right"
                                item xs={1} sm={2}>
                                <Buttons triggerClassMenu={triggerClassMenu} applyClassHandler={triggerApplyClassDialog} />
                            </ButtonGrid>
                        </React.Fragment>) : null}
                        {smDown ? (<React.Fragment>
                            <LogoContainer container alignItems="flex-start" item xs={10} sm={10}
                                justifyContent="flex-start"  >
                                <Stack direction="row" justifyContent="flex-start"
                                    alignItems="center" spacing={2}>
                                    <Stack spacing={1}>
                                        <Stack justifyContent="space-between" alignItems="center" direction="row">
                                            <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>{CLASS_INFO.name}</Typography>
                                            <Can I="update" this={subject("class", CLASS_INFO)} field="name" ability={ABILITY} >
                                                <IconButton
                                                    sx={{
                                                        marginLeft: 1,
                                                        backgroundColor: "#DDDDDD",
                                                    }}
                                                    color="primary"
                                                    aria-label="upload picture" component="label"
                                                    onClick={triggerEditDialog}
                                                >
                                                    <EditIcon sx={{ fontSize: 14 }} />
                                                </IconButton>
                                            </Can>
                                        </Stack>
                                        <Breadcrumbs sx={{ display: { xs: "block", sm: "block" }, cursor: "pointer" }} separator={<NavigateNextIcon fontSize="small" />}>
                                            <Link underline="hover" onClick={navigateToCentre} color="secondary">
                                                <Typography color="secondary">{CLASS_CENTRE.displayName}</Typography>
                                            </Link>
                                            <Typography color="secondary">{CLASS_INFO.status ? t("class:page_title-" + CLASS_INFO.status) : null}</Typography>
                                        </Breadcrumbs>
                                    </Stack>
                                </Stack>
                            </LogoContainer>
                            <ButtonGrid container alignItems="flex-start" justifyContent="right"
                                item xs={2} sm={2}>
                                <Buttons triggerClassMenu={triggerClassMenu} applyClassHandler={triggerApplyClassDialog} />
                            </ButtonGrid>
                        </React.Fragment>) : null}
                        <Grid container item sm={12}>
                            <TabContainer>
                                <Tabs value={value} onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    aria-label="class_settings_tabs">
                                    {
                                        TAB_ELEMENTS.map(
                                            (element, index) => (
                                                <Tab
                                                    value={element.tab_index}
                                                    sx={{
                                                        textTransform: "none"
                                                    }}
                                                    key={element.tab_label}
                                                    label={t(element.tab_label)} {...a11yProps(index)}
                                                />
                                            )
                                        )
                                    }
                                </Tabs>
                            </TabContainer>
                        </Grid>
                    </Grid>
                    <SpaceHolder container item sm={0.5} />
                </Grid>
            </Paper>
            <ContextualMenu id={"header-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
                <ShowInStatus status="pending" >
                    <ContextualMenuItem
                        permission={{
                            I: "publish",
                            this: subject("class", CLASS_INFO),
                            ability: ABILITY
                        }}
                        onClick={publishClassOnClick}
                        title={t("general:button-publish")}>
                        <CheckCircleOutlineIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    <ContextualMenuItem
                        permission={{
                            I: "delete",
                            this: subject("class", CLASS_INFO),
                            ability: ABILITY
                        }}
                        onClick={deleteClassOnClick}
                        title={t("general:button-delete")}>
                        <CancelIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ShowInStatus>
                <ShowInStatus status="active" >
                    <ContextualMenuItem
                        permission={{
                            I: "issue",
                            this: subject("bill", { centreId: CLASS_INFO.centreId }),
                            ability: ABILITY
                        }}
                        onClick={triggerIssueBillDialog}
                        title={t("class:button-issue_bill")}>
                        <LocalAtmIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    {AUTH_STATE.currentRole.roleName != "platform_admin" ?
                        <ContextualMenuItem
                            permission={{
                                I: "broadcast",
                                this: "message",
                                ability: ABILITY
                            }}
                            onClick={navigateToChatroom}
                            title={t("general:button-broadcast")}>
                            <CampaignIcon sx={{ mr: 0 }} fontSize="small" />
                        </ContextualMenuItem> : null}
                    <ContextualMenuItem
                        permission={{
                            I: "remove",
                            this: subject("class", CLASS_INFO),
                            ability: ABILITY
                        }}
                        onClick={removeClassOnClick}
                        title={t("general:button-remove_entity", { entity: t("attribute:class").toLowerCase() })}>
                        <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                    <ContextualMenuItem
                        permission={{
                            I: "leave",
                            this: subject("class", CLASS_INFO),
                            ability: ABILITY
                        }}
                        onClick={triggerQuitClassDialog}
                        title={t("general:button-quit_entity", { entity: t("attribute:class").toLowerCase() })}>
                        <RemoveCircleIcon sx={{ mr: 0 }} fontSize="small" />
                    </ContextualMenuItem>
                </ShowInStatus>
            </ContextualMenu>
            <CentreClassDialog
                title={t("general:dialog_title-edit_entity_info", { entity: t("attribute:class") })}
                currentClass={CLASS_INFO}
                isDialogOpen={editDialogOpen}
                setIsDialogOpen={setEditDialogOpen} />
            <ApplyClassDialog
                title={t("dialog_title-apply_entity", { entity: t("attribute:class") })}
                isDialogOpen={applyClassDialogOpen}
                setIsDialogOpen={setApplyClassDialogOpen} />
            <ConfirmActionDialog title={t("general:dialog_title-delete_entity", { entity: t("attribute:class") })} open={deleteDialogOpen} actionName={t("general:button-delete")} setOpen={setDeleteDialogOpen} confirmOnClick={triggerDeleteClass} />
            <ConfirmActionDialog title={t("general:dialog_title-remove_entity", { entity: t("attribute:class") })} open={removeDialogOpen} actionName={t("general:button-remove")} setOpen={setRemoveDialogOpen} confirmOnClick={triggerRemoveClass} />
            <ConfirmActionDialog title={t("general:dialog_title-quit_entity", { entity: t("attribute:class") })} open={quitClassDialogOpen} actionName={t("general:button-quit")} setOpen={setQuitClassDialogOpen} confirmOnClick={triggerQuitClass} />
            <ClassBillDialog
                currentBill={null}
                currentClassId={classId}
                isDialogOpen={issueBillDialogOpen}
                setIsDialogOpen={setIssueBillDialogOpen} />
        </React.Fragment >
    )
}