import React from 'react';
import debounce from 'lodash.debounce';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
	Paper, Grid, Stack, InputAdornment, FormControl,
	Typography, Fab, TextField, Collapse, Box,
	IconButton, Button, Divider, Autocomplete,
    Dialog, DialogTitle, DialogContent, DialogActions, Pagination
} from "@mui/material";
import { styled } from '@mui/material/styles';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer } from "./../../component/common/Containers";
import { useAuthenticationState } from "./../../redux/authentication";
import { addPlan, getPlans, updatePlan, deletePlan, usePlanManagementState } from "./../../redux/PlanManagement";

import { ContextualMenuItem } from "./../../component/common/Buttons";
import ContextualMenu from "./../../component/common/ContextualMenu";
import ConfirmActionDialog from "./../../component/common/ConfirmActionDialog";
import GeneralHeader from "./../../component/common/GeneralHeader";
import PlanAddEditDialog from "./plan/dialog/PlanAddEditDialog";

function sortPlans(plans) {
	return plans.sort((a, b) => a.monthlyPrice - b.monthlyPrice);
}

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const NarrowRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: 900
}));


export default function PlanList() {

    const { t } = useTranslation("plan","general","attribute");
    const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
    const [deletePlanDialogOpen, setDeletePlanDialogOpen ] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedPlan, setSelectedPlan] = React.useState(null);
	const [planAnchorEl, setPlanAnchorEl] = React.useState(null);

	const [plans, setPlans] = React.useState([]);

    const navigate = useNavigate();
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		event.preventDefault();
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

    const PLANS = usePlanManagementState().plans || [];
    const PLANS_COUNT = usePlanManagementState().plansCount || 0;
    const ABILITY = useAuthenticationState().ability;

	const togglePlanContextualMenu = (event, planFormOnClick) => {
		event.preventDefault();
		setSelectedPlan(planFormOnClick);
		console.log(planFormOnClick)
		setPlanAnchorEl(event.currentTarget);
	};

    const openAddPlanDialog = (event) => {
        event.preventDefault();
		setPlanAnchorEl(null);
        setIsAddDialogOpen(true);
    }

    const openEditPlanDialog = (event) => {
        event.preventDefault();
		setPlanAnchorEl(null);
        setIsEditDialogOpen(true);
    }

    const triggerDeletePlanDialog = (event) => {
        event.preventDefault();
		setPlanAnchorEl(null);
        setDeletePlanDialogOpen(true);
    }

    const handleAddPlanCloseDialog = (event) => {
        event.preventDefault();
        setIsAddDialogOpen(false);
    }

    const handleEditPlanCloseDialog = (event) => {
        event.preventDefault();
        setIsEditDialogOpen(false);
    }

    const dialogAddPlan = (data) => {
		setPlans(sortPlans([ ...plans, { ...data, _id: 'new_plan' } ]));
        addPlan({ plan: data });
        setIsAddDialogOpen(false);
        deboundedGetPlans({ page: page, rowsPerPage: rowsPerPage });
    }
	
    const dialogEditPlan = (data) => {
		const plansCopy = [...plans];
		plansCopy[plans.findIndex((plan) => plan._id === data._id)] = data;
		setPlans(sortPlans(plansCopy));
		updatePlan({ plan: data });
        setIsEditDialogOpen(false);
        deboundedGetPlans({ page: page, rowsPerPage: rowsPerPage });
    }
	
    const confirmDeletePlan = (event) => {
		setPlans(plans.filter((plan) => plan._id !== selectedPlan._id));
		deletePlan({ planId: selectedPlan._id });
		setDeletePlanDialogOpen(false);
        deboundedGetPlans({ page: page, rowsPerPage: rowsPerPage });
    }

    let deboundedGetPlans = React.useMemo(() => debounce(getPlans, 50), []);

    React.useEffect(() => {
        deboundedGetPlans({ page: page, rowsPerPage: rowsPerPage });
    }, [page, rowsPerPage]);

	React.useEffect(() => {
		setPlans(PLANS);
	}, [PLANS]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={2} />
                    <Grid container justifyContent="center" item xs={12} md={8}>
                        <GeneralHeader title={t("subscription:page_title-list")}
                            displayButton={ABILITY.can("add", "plan")}
                            onClickHandler={openAddPlanDialog} />
                        <Row container item md={12}>
                            <NarrowRow container item md={12} >
                                <Grid container justifyContent="center" item sm={12} md={12}>

                                <TableContainer sx={{ borderRadius: "10px", }} component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow  >
							<BoldTableCell >{t("attribute:subscription_plan-name")}</BoldTableCell>
							<BoldTableCell >{t("attribute:subscription_plan-monthly_price")}</BoldTableCell>
							<BoldTableCell >{t("attribute:subscription_plan-student_limit")}</BoldTableCell>
							<BoldTableCell align="right"></BoldTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{plans.map((plan) => {
							return (
								<TableRow key={plan._id}>
									<TableCell component="th" scope="row">
                                        {plan.name}
									</TableCell>
									<TableCell >{plan.monthlyPrice}</TableCell>
									<TableCell >{plan.studentQuota}</TableCell>
									<TableCell align="right">
										<IconButton onClick={(event) => { togglePlanContextualMenu(event, plan) }} aria-label="delete">
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					labelRowsPerPage={t("general:label-rows_per_page")}
					rowsPerPageOptions={[1, 5, 10, 25]}
					component="div"
					count={plans.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>

                                </Grid>
                            </NarrowRow>

                        </Row>
                    </Grid>
                    <SpaceHolder container item xs={0} md={2} />
                </Grid>
            </DashboardRootContainer>

			<ContextualMenu id={"admin-menu"} anchorEl={planAnchorEl} setAnchorEl={setPlanAnchorEl}>
				<ContextualMenuItem
					onClick={openEditPlanDialog}
					title={t("general:button-edit")}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				<ContextualMenuItem
					onClick={triggerDeletePlanDialog}
					title={t("general:button-delete")}>
					<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>

			<PlanAddEditDialog
				title={t("general:dialog_title-add_entity", { entity: t("attribute:subscription_plan") })}
				submitMethod={dialogAddPlan}
				isDialogOpen={isAddDialogOpen}
				handleCloseDialog={handleAddPlanCloseDialog}
			/>

			<PlanAddEditDialog
				title={t("general:dialog_title-edit_entity", { entity: t("attribute:subscription_plan") })}
				submitMethod={dialogEditPlan}
				isDialogOpen={isEditDialogOpen}
				handleCloseDialog={handleEditPlanCloseDialog}
				currentPlan={selectedPlan}
			/>

            <ConfirmActionDialog
				title={t("general:dialog_title-archive_entity", { entity: selectedPlan?.name || t("attribute:subscription_plan")})}
				actionName={t("general:button-archive")}
				content={t("subscription:info-active_subscription_remain")}
				open={deletePlanDialogOpen}
				setOpen={setDeletePlanDialogOpen}
				confirmOnClick={confirmDeletePlan}
			/>
        </React.Suspense>
    );
}
