import React from "react";
import {
    Grid
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import debounce from 'lodash.debounce';
import LoadingPage from "./LoadingPage";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import { getFacilityInfo, useFacilityManagementState } from "./../../redux/FacilityManagement";
import { pushRedirect } from "./../../redux/Redirect";
import FacilityAboutDetailPage from "./facilityDetail/FacilityAboutDetailPage";
import FacilitySchedulePage from "./facilityDetail/FacilitySchedulePage";
import FacilityPageHeader from "./facilityDetail/header/FacilityPageHeader";
import FacilityLogoUploadDialog from "./facilityDetail/dialog/FacilityLogoUploadDialog";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));


const TAB_ELEMENTS = [
    { "tab_element": (<FacilityAboutDetailPage />), "tab_index": "about", "tab_label": "general:tab_name-about" },
    { "tab_element": (<FacilitySchedulePage />), "tab_index": "schedule", "tab_label": "general:tab_name-schedule" },
]


export default function FacilityDetails() {

    let { facilityId } = useParams();
    let [searchParams] = useSearchParams();

    const [logoDialogOpen, setLogoDialogOpen] = React.useState(false);
    const SEARCH_PARAM_TAB = searchParams.get("tab") || "about";
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const FACILITY_REQUEST = useFacilityManagementState().request;
    const ACTION_IN_CHECK = {
        GET_FACILITY_INFO: true
    }

    const debouncedGetFacilityInfo = React.useMemo(() => debounce(getFacilityInfo, 50), []);

    React.useEffect(() => {
        if (facilityId) {
            debouncedGetFacilityInfo({ facilityId: facilityId });
        }
    }, []);

    React.useEffect(() => {
        if (facilityId) {
            debouncedGetFacilityInfo({ facilityId: facilityId });
        }
    }, [facilityId]);

    React.useEffect(() => {
        if (ACTION_IN_CHECK[FACILITY_REQUEST.lastAction]) {
            if (FACILITY_REQUEST.status === "REQUEST_OBJECT_NOT_FOUND") {
                pushRedirect({ path: "/" });
            }
        }
    }, [FACILITY_REQUEST]);


    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2}  />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <Row container item xs={12}>
                            <FacilityPageHeader TAB_ELEMENTS={TAB_ELEMENTS}
                                value={value} setValue={setValue}
                                setLogoDialogOpen={setLogoDialogOpen} />
                        </Row>
                        <Grid sx={{ marginTop: 2 }} container item xs={12} md={12}>
                            {TAB_ELEMENTS.map(((element, index) => (
                                <TabPanel key={`element_${index}`} value={value} index={element.tab_index}>
                                    {element.tab_element}
                                </TabPanel>
                            )))
                            }
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2}  />
                </Grid>
            </DashboardRootContainer>
            <FacilityLogoUploadDialog open={logoDialogOpen} setOpen={setLogoDialogOpen} />
        </React.Suspense>
    );
}
