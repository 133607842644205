import React from 'react';
import { Grid, Typography, Paper, Button, IconButton,Tooltip } from "@mui/material";
import siteIcon from "./../../asset/img/site_icon.png";
import LanguageSwitcher from "./../I18N/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut, requestVerificationEmail, useAuthenticationState, UPDATE_ACTION } from "./../../redux/authentication";
import { useSnackbar } from "notistack";
import { styled } from '@mui/material/styles';
import { NeedHelp, SiteIcon, ContactUs, PageTitle } from "./../../component/common/Others";
import { LogoutArrow } from "./../../component/common/Containers";
import { NonDashboardRootContainer } from "./../../component/common/Containers";

const MarginTopBottom = styled(Button)(({ theme }) => ({
  "marginBottom": "10px",
  "marginTop": "10px"
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
  "textAlign": "center"
}));

const CenterAlignedGrid = styled(Grid)(({ theme }) => ({
  display: "grid",
  placeItems: "center"
}));

const CantFindEmail = styled(Grid)(({ theme }) => ({
  display: "grid",
  "marginBottom": "10px",
  placeItems: "center"
}));

const Instruction = styled(Grid)(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  "marginBottom": "30px",
  "marginTop": "30px"
}));

const AlmostDone = styled(Grid)(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  "marginBottom": "15px"
}));

const Title = styled(Grid)(({ theme }) => ({
  "paddingTop": "25px",
  "marginBottom": "25px",
  display: "grid",
  placeItems: "center"
}));

const BoldTextField = styled(Typography)(({ theme }) => ({
  "fontWeight": "bold"
}));

export default function RequestVerificationPage() {
  const { t } = useTranslation(["verification", "general"]);
  const csEmail = "help@itutor.com";
  const subject = t("verification:contact_subject");
  const mailtoUrl = `mailto:${csEmail}?subject=${subject}`;
  const { enqueueSnackbar } = useSnackbar();
  const authentcationState = useAuthenticationState();
  const request = authentcationState.request;
  const username = authentcationState.username;

  React.useEffect(() => {
    if (request && request.lastAction == UPDATE_ACTION.VERIFICATION_EMAIL && request.message) {
      if (request.error) {
        enqueueSnackbar(t("general:error-unknown"), {
          "variant": "error"
        });
      } else {
        enqueueSnackbar(t("verification:success-resend_email"), {
          "variant": "success"
        });
      }
    }
  });

  function RequestEmail(event) {
    event.preventDefault();
    requestVerificationEmail();

  }

  function logout(event) {
    event.preventDefault();
    signOut();
  }

  function TitleSection() {
    return (
      <React.Fragment>
        <Grid container item xs={1} md={1} xl={1}>
          <LogoutArrow item xs={12} md={12} xl={12}>
            <Tooltip title={t("general:button-logout")}>
              <IconButton data-cy='logoutArrow' size="small" onClick={logout}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </LogoutArrow>
        </Grid>
        <Grid container item xs={11} md={11} xl={11}>
          <ContentGrid item xs={12} md={12} xl={12}>
            <Typography variant="body2" component="span" color="textSecondary" align="right">
              <LanguageSwitcher disableContrast={true} showText={false}></LanguageSwitcher>
            </Typography>
          </ContentGrid>
        </Grid>
      </React.Fragment>
    );
  }

  function ContentSection() {

    return (
      <Grid container item xs={12} md={12} xl={12}>
        <ContentGrid item xs={1} md={3} xl={3}>
        </ContentGrid>
        <ContentGrid item xs={10} md={6} xl={6}>
          <Paper elevation={10}>
            <CenterAlignedGrid container item xs={12} md={12} xl={12}>
              <SiteIcon alt="Company Logo" src={siteIcon} />
            </CenterAlignedGrid>
            <Title container item xs={12} md={12} xl={12}>
              <PageTitle variant="h5">
                {t("verification:page_title")}
              </PageTitle>
            </Title>
            <AlmostDone container item xs={12} md={12} xl={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {t("verification:instruction-check_email-1")}
              </Typography>
              <BoldTextField variant="subtitle1">
                {username}
              </BoldTextField>
            </AlmostDone>
            <Instruction container item xs={12} md={12} xl={12}>
              <Typography variant="body1">
                {t("verification:instruction-check_email-2")}
              </Typography>
              <Typography variant="body1">
                {t("verification:instruction-check_email-3")}
              </Typography>
            </Instruction>
            <CantFindEmail container item xs={12} md={12} xl={12}>
              <Typography variant="body1">
                {t("verification:help-resend_email")}
              </Typography>
            </CantFindEmail>
            <CenterAlignedGrid container item xs={12} md={12} xl={12}>
              <MarginTopBottom data-cy="btn_resend" variant="contained" onClick={RequestEmail} color="primary">
                {t("verification:button-resend_email")}
              </MarginTopBottom>
            </CenterAlignedGrid>
            <NeedHelp container item xs={12} md={12} xl={12}>
              <Typography variant="body1">
                {t("general:help-need_help")}
                <ContactUs href={mailtoUrl}>
                  {t("general:link-contact_us")}
                </ContactUs>
              </Typography>
            </NeedHelp>
          </Paper>
        </ContentGrid>
      </Grid>
    );
  }

  return (
    <NonDashboardRootContainer container>
      <TitleSection></TitleSection>
      <ContentSection></ContentSection>
    </NonDashboardRootContainer>
  );
}
