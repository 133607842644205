import React from "react";
import {
    Grid, Stack, Collapse, IconButton, TextField,
    Dialog, DialogTitle, DialogContent,
    FormControl, InputAdornment, Autocomplete, Divider,
    Pagination
} from "@mui/material";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { getCentreStudentForClass } from "./../../../../redux/ClassesManagement";
import { addStudentToClass, useClassesManagementState } from "./../../../../redux/ClassesManagement";
import { useOptionsState } from "./../../../../redux/Options";
import OPTIONS_CATEGORY from "./../../../../const/OptionsCategory";
import StudentCard from "./../../../page/student/StudentCard";
import { convertOptionsForAutoComplete } from "./../../../../util/AutoCompleteUtil";



const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

const NarrowRow = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

export default function ClassAddStudentDialog({
    title, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);

    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const { classId } = useParams();
    const CLASS_STUDENT_LIST = useClassesManagementState().classCentreStudents || [];
    const STUDENT_COUNT = useClassesManagementState().classCentreStudentsCount || 0;
    const [searchString, setSearchString] = React.useState(null);
    const rowsPerPage = 10;
    const [page, setPage] = React.useState(0);

    const OPTIONS_STATE = useOptionsState();

    const [gradeFilterValue, setGradeFilterValue] = React.useState([]);

    const STUDENT_FILTER_FORM = useForm({ gradeFilter: [] });

    React.useEffect(() => {
        setGradeFilterValue([]);
        STUDENT_FILTER_FORM.reset({ gradeFilter: [] });
    },[isDialogOpen])

    let deboundedGetCentreStudentForClass = React.useMemo(() => debounce(getCentreStudentForClass, 50), []);

    React.useEffect(() => {
        if (isDialogOpen) {
            deboundedGetCentreStudentForClass({
                classId: classId,
                searchCriteria: {
                    search: searchString, grades: gradeFilterValue, page: page, rowsPerPage: rowsPerPage
                }
            })
        }
    }, [isDialogOpen, searchString, gradeFilterValue, page, rowsPerPage, classId]);

    const updateSearchString = (event) => {
        event.preventDefault();
        setSearchString(event.target.value);
    };
    const searchFieldOnChange = React.useMemo(() => debounce(updateSearchString, 300), []);

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setGradeFilterValue(newValue);
    };


    const toggleFilter = () => {
        setIsFilterOpen((prev) => !prev)
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const addStudentToClassOnClick = (event, selectedStudent) => {
        event.preventDefault();
        addStudentToClass({ classId: classId, students: selectedStudent._id })
        setIsDialogOpen(false);

    }



    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...STUDENT_FILTER_FORM} >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Stack sx={{ width: "100%" }}>
                        <Stack sx={{ width: "100%", marginTop: 0, marginBottom: 0 }} spacing={1}>
                            <TextField
                                id="outlined-start-adornment"
                                fullWidth
                                onChange={(event) => { searchFieldOnChange(event) }}
                                placeholder={t("general:label-search")}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <IconButton
                                                name="filterToggle"
                                                aria-label="toggle filter"
                                                onClick={toggleFilter}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                <FilterListIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />
                            <Collapse sx={{ width: "100%" }} component="div" in={isFilterOpen}>
                                <Stack>
                                    <StyledFormControl fullWidth variant="outlined">
                                        <Controller
                                            name="gradeFilter"
                                            defaultValue={gradeFilterValue}
                                            control={STUDENT_FILTER_FORM.control}
                                            render={
                                                ({ field }) =>
                                                    <Autocomplete
                                                        multiple
                                                        id="student_grade_filter"
                                                        options={convertOptionsForAutoComplete(OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].options)}
                                                        getOptionLabel={(option) => t(`attribute:${option}`)}
                                                        defaultValue={gradeFilterValue}
                                                        value={gradeFilterValue}
                                                        onChange={handleChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label={t("attribute:class-grades")}
                                                                placeholder={t("attribute:class-grades")}
                                                            />
                                                        )}
                                                    />
                                            }
                                        />
                                    </StyledFormControl>
                                </Stack>
                            </Collapse>
                        </Stack>
                    </Stack>
                    <NarrowRow spacing={0} >
                        <Grid container justifyContent="center" item sm={12} md={12}>
                            {CLASS_STUDENT_LIST.map(
                                (studentEnrolment, index) => {
                                    let currentStudent = studentEnrolment.profile;
                                    return (<Grid key={currentStudent._id + currentStudent.displayName + "_Grid"} container justifyContent="center" item sm={12} md={12} lg={12}>
                                        <StudentCard index={index} key={currentStudent._id + currentStudent.displayName}
                                            cardOnClick={(event) => {
                                                addStudentToClassOnClick(event, studentEnrolment);
                                            }}
                                            currentStudent={currentStudent} />
                                    </Grid>)
                                })
                            }
                            <Pagination
                                onChange={(event, pageNumber) => {
                                    event.preventDefault();
                                    setPage(pageNumber - 1);
                                }}
                                count={Math.ceil(STUDENT_COUNT / rowsPerPage)}
                            />
                        </Grid>
                    </NarrowRow>
                </DialogContent>
            </FormProvider>
        </Dialog >
    )
}