import React from 'react';

import { Paper, Grid, Typography } from "@mui/material";
import { changeTheme } from "./../../../redux/ThemePicker";
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import blue from '@mui/material/colors/blue';
import lightBlue from '@mui/material/colors/lightBlue';
import cyan from '@mui/material/colors/cyan';
import teal from '@mui/material/colors/teal';
import green from '@mui/material/colors/green';
import lightGreen from '@mui/material/colors/lightGreen';
import lime from '@mui/material/colors/lime';
import yellow from '@mui/material/colors/yellow';
import amber from '@mui/material/colors/amber';
import orange from '@mui/material/colors/orange';
import deepOrange from '@mui/material/colors/deepOrange';
import grey from '@mui/material/colors/grey';
import blueGrey from '@mui/material/colors/blueGrey';
import brown from '@mui/material/colors/brown';
import { useTranslation } from "react-i18next";
import { useThemePickerState } from "./../../../redux/ThemePicker";

const OuterContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  width: 200,
}));

const CheckedIcon = styled(CheckIcon)(({ theme }) => ({
  "color": theme.palette.primary.contrastText,
}));

const ColorGrid = styled(Grid)(({ theme }) => ({
  height: 30,
  width: 30,
  fontSize: 12,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: "#BBBBBB",
  fontWeight: "bold",
  marginBottom: 10
}));


function generateGridArray(shade, theme) {
  var gridStatus =
    [{
      id: "red",
      style: {
        "background": red[shade]
      }
    }, {
      id: "pink",
      style: {
        "background": pink[shade]
      }
    }, {
      id: "purple",
      style: {
        "background": purple[shade]
      }
    }, {
      id: "deepPurple",
      style: {
        "background": deepPurple[shade]
      }
    }, {
      id: "indigo",
      style: {
        "background": indigo[shade]
      }
    }, {
      id: "blue",
      style: {
        "background": blue[shade]
      }
    }, {
      id: "lightBlue",
      style: {
        "background": lightBlue[shade]
      }
    }, {
      id: "cyan",
      style: {
        "background": cyan[shade]
      }
    }, {
      id: "teal",
      style: {
        "background": teal[shade]
      }
    }, {
      id: "green",
      style: {
        "background": green[shade]
      }
    }, {
      id: "lightGreen",
      style: {
        "background": lightGreen[shade]
      }
    }, {
      id: "lime",
      style: {
        "background": lime[shade]
      }
    }, {
      id: "yellow",
      style: {
        "background": yellow[shade]
      }
    }, {
      id: "amber",
      style: {
        "background": amber[shade]
      }
    }, {
      id: "orange",
      style: {
        "background": orange[shade]
      }
    }, {
      id: "deepOrange",
      style: {
        "background": deepOrange[shade]
      }
    }, {
      id: "grey",
      style: {
        "background": grey[shade]
      }
    }, {
      id: "blueGrey",
      style: {
        "background": blueGrey[shade]
      }
    }, {
      id: "brown",
      style: {
        "background": brown[shade]
      }
    },];
  return gridStatus.map(element => {
    element.checked = (theme === element.id);
    return element;
  });

}

function ColorGrids({ themePickerKey }) {
  const { t } = useTranslation("attribute");
  var themePickerState = useThemePickerState();
  var shade = themePickerState[themePickerKey].shade;
  var theme = themePickerState[themePickerKey].theme;

  function handleOnClick(event, type, theme) {
    event.preventDefault();
    changeTheme({
      type: type,
      theme: theme
    });
  }

  return (
    <OuterContainer>
      <Subtitle>{t("attribute:centre-color-hue")}</Subtitle>
      <Grid container xs={12} sm={12} >
        {generateGridArray(shade, theme).map((state, index) => {
          return <ColorGrid data-cy={themePickerKey + "-0-" + state.id} key={themePickerKey + "-0-" + state.id}
            onClick={(event) => { handleOnClick(event, themePickerKey, state.id) }}
            style={state.style}
            container>
            <StyledPaper style={state.style} >
              {state.checked ? <CheckedIcon /> : null}
            </StyledPaper>
          </ColorGrid>;
        })}
      </Grid>
    </OuterContainer>
  );
}

export default ColorGrids;  