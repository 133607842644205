import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
	Paper, Grid, Stack,
	Typography, IconButton
} from "@mui/material";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SpaceHolder } from "./../../common/Others";
import ContentRow from "./../../common/ContentRow";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));


const AttributeLabel = styled(Typography)(({ theme }) => ({
	color: theme.palette.secondary.main, 
}));


const NarrowRow = styled(Stack)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

export default function CentrePostCard({ currentPost, handleClick, index }) {
	let paperStyle = { borderRadius: '10px' };
	if (currentPost.status != "active") {
		paperStyle.backgroundColor = "#DDDDDD";
	}

	let theme = useTheme();
	let smDown = useMediaQuery(theme.breakpoints.down("sm"));


	let contentVariant = "body2";

	if (smDown) {
		contentVariant = "caption";
	}

	return (
		<NarrowRow key={index + "_row"} spacing={1} >
			<Paper sx={paperStyle} key={index + "_paper"}>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Stack sx={{width:"calc(100% - 48px )"}} spacing={0}>
								<Typography sx={{ fontWeight: "bold" }} variant="body1" component="div">{currentPost.subject}</Typography>
								<AttributeLabel variant={contentVariant} component="div">{moment(currentPost.publishDate).format("LL")}</AttributeLabel>
							</Stack>
							<CanUpdateCentre centreId={currentPost.centreId}>
								<IconButton key={index + "_more_btn"} onClick={(event) => {
									handleClick(event, currentPost);
								}} aria-label="comment">
									<MoreHorizIcon />
								</IconButton>
							</CanUpdateCentre>
						</HeaderRow>
						<ContentRow content={currentPost.content} />
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</Paper>
		</NarrowRow>
	);
}
