import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box, Paper } from "@mui/material";

const LogoutArrow = styled(Grid)(({ theme }) => ({
  "marginLeft": "10px",
  "marginTop": "5px"
}));

const DashboardRootContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  minWidth: "100%",
  minHeight: "calc(100vh - 72px - 16px)",
  "background": "rgb(243,243,243)"
}));

const NonDashboardRootContainer = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  "background": "rgb(243,243,243)",
  height: "100vh",
  maxHeight: "100vh",
  overflowY: "auto"
}));

const StyledTabPanelContainer = styled("div")(({ theme }) => ({
  width: "100%"
}))

function TabContainer(props) {
  return (<Box
    {...props}
    sx={{
      marginBottom: 0,
      paddingBottom: 0,
      marginLeft: { xs: 0, sm: 0 }, marginRight: { xs: 0, sm: 0 },
      paddingLeft: { xs: 0, sm: 0 }, paddingRight: { xs: 0, sm: 0 },
      width: { xs: "calc(100vw - 10px)" },
      maxWidth: { xs: "calc(100vw - 10px)", sm: "100%", md: "100%", lg: "100%" },
      borderColor: "divider"
    }}
  >
    {props.children}
  </Box>)

}


const TabPanel = ({ children, index, value, ...other }) => {
  return (
    <StyledTabPanelContainer
      {...other}
      elevation={3}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index ? children : null}
    </StyledTabPanelContainer>
  );
}

const TabTitleContainer = styled(Paper)(({ theme }) => ({
  borderRadius: "10px",
  marginBottom: theme.spacing(2)
}));


const AdminFormRow = styled(Grid)(({ theme }) => ({
  height: "15vh",
}));


const ProfileContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap'
}));

export { LogoutArrow, TabContainer, DashboardRootContainer, NonDashboardRootContainer, TabPanel, AdminFormRow, ProfileContainer, TabTitleContainer };