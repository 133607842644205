
export function isPlatformAdmin(role) {
    if (typeof role == "object") {
        role = role.roleName;
    }
    if (role == "platform_admin") {
        return true;
    }
    return false
}

export function isCentreAdmin(role) {
    if (typeof role == "object") {
        role = role.roleName;
    }
    if (role == "centre_admin") {
        return true;
    }
    return false
}

export function isCentreAdminBelongsToCentre(centreId, centreIdFromRole) {
    if (centreId == centreIdFromRole) {
        return true;
    }
    return false;
}
