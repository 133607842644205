const OPTIONS_CATEGORY = {
    "ALIAS": "alias",
    "DISTRICT": "district",
    "EDUCATION_LEVEL": "educationLevel",
    "GENDER": "gender",
    "GRADE": "grade",
    "MAIN_LANGUAGE": "mainLanguage",
    "RELATIONSHIP": "relationship",
    "SUBJECT": "subject",
    "SYSTEM_LANGUAGE": "systemLanguage",
    "WEEK_DAY": "weekDay",
};
export default OPTIONS_CATEGORY;