import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export interface Bill {
	_id: string,
	classId: string,
    studentId: string,
	description: string,
	issueDate: Date,
    dueDate: Date,
    currency: string,
    billAmount: Number
}

export async function loadBill() {
	
}

export async function getBillsRequest(classId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/centre/class/bill/${classId}`;
	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function addBillToClassRequest(bill: object): Promise<UpdateResultResponse> {
	const url = "/bill/issue";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, bill);
		console.log('Called add bill');
		return data;
	} catch (err) {
		throw err
	}
}

export async function getStudentBillRequest(studentId: string, searchCriteria: object): Promise<UpdateResultResponse> {
	const url = `/bill/student/${studentId}`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
		return data;
	} catch (err) {
		throw err
	}
}

export async function updateStudentBillRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/bill/update";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function deleteStudentBillRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/bill/delete";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function markAsPaidStudentBillRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/bill/markaspaid";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function payStudentBillRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/bill/pay";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err;
	}
}

export async function getStripeBillSecretRequest(body:object): Promise<UpdateResultResponse>{
	const url ="/bill/get_stripe_sceret";
	try{
		const {
			data
		} = await axios.post<UpdateResultResponse>(url,body);
		return data;
	}catch(err){
		throw err;
	}
}