import React from 'react';
import { useTranslation } from "react-i18next";
import {
    Button, Grid, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm, FormProvider, useFormContext, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { INITIAL_CENTRE_ADDITIONAL_VALUE_FORM, CENTRE_ADDITIONAL_VALUE_FORM, createCentreAdditionalValueUpdateFormSchema } from "./../../../form/model/CentreFormModel";
import { SuccessButton } from "./../../../common/Buttons";
import { DialogFixedForm } from "./../../../common/Forms";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

function CustomInputField({ customFields, customFieldsValue }) {

    const { control, reset, formState: { errors } } = useFormContext();
    const { t } = useTranslation();



    React.useEffect(() => {
        if (customFieldsValue) {
            let result = {};
            for (let key of Object.keys(customFieldsValue)) {
                result[CENTRE_ADDITIONAL_VALUE_FORM.customField + "." + key] = customFieldsValue[key];
            }
            reset(result);
        }
    }, [customFieldsValue]);

    return (
        Object.keys(customFields).map((value) => {
            return (<Controller
                name={CENTRE_ADDITIONAL_VALUE_FORM.customField + `.${value}`}
                control={control}
                render={({ field }) => (
                    <StyledTextField
                        variant="outlined"
                        fullWidth
                        label={customFields[value]}
                        helperText={errors[CENTRE_ADDITIONAL_VALUE_FORM.customField] ? t(errors[CENTRE_ADDITIONAL_VALUE_FORM.customField].message) : null}
                        error={errors[CENTRE_ADDITIONAL_VALUE_FORM.customField] != null}
                        {...field}
                    />
                )}
            />);
        })
    );
}

export default function StudentAdditionalInfoDialog({
    open,
    setOpenDialog,
    customFields,
    customFieldsValue,
    onSubmitHandler
}) {
    const { t } = useTranslation(["account", "general"]);
    const ADDITIONAL_INFO_FORM_METHOD = useForm({
        resolver: yupResolver(createCentreAdditionalValueUpdateFormSchema()),
        defaultValues: INITIAL_CENTRE_ADDITIONAL_VALUE_FORM
    });
    function closeEditDialog() {
        setOpenDialog(false);
    }

    return (<Dialog
        open={open}
        onClose={closeEditDialog}
        scroll={"paper"}
        fullWidth
        aria-labelledby="responsive-Student-dialog-title"
    >
        <FormProvider {...ADDITIONAL_INFO_FORM_METHOD}>
            <DialogFixedForm
                control={ADDITIONAL_INFO_FORM_METHOD.control} errors={ADDITIONAL_INFO_FORM_METHOD.errors}
                onSubmit={ADDITIONAL_INFO_FORM_METHOD.handleSubmit(onSubmitHandler)}>
                <DialogTitle id="responsive-Student-dialog-title">
                    {t("general:dialog_title-edit_entity", { entity: t("attribute:student-additional_info") })}
                </DialogTitle>
                <DialogContent dividers data-cy="edit_dialog_content">
                    <CustomInputField customFields={customFields}
                        customFieldsValue={customFieldsValue} />
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button autoFocus data-cy={"edit_Student_card_cancel"} onClick={closeEditDialog} variant="outlined">
                            {t("general:button-cancel")}
                        </Button>
                        <SuccessButton type="submit" data-cy={"edit_Student_card_save"} variant="contained">
                            {t("general:button-save")}
                        </SuccessButton>
                    </Grid>
                </DialogActions>
            </DialogFixedForm>
        </FormProvider>
    </Dialog>);
}