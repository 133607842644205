import React from 'react';
import { useAuthenticationState } from "./../../redux/authentication";
import {
    useLocation,
    Navigate
} from "react-router-dom";
import { subject } from "@casl/ability";

export default function CanUpdateCentre({ children, centreId, redirect, or = false }) {
    const AUTH_STATE = useAuthenticationState();
    let location = useLocation();
    let ability = AUTH_STATE.ability;
    if (ability) {
        if (ability.can("update", subject("centre", { id: centreId })) || or) {
            return children;
        }
    };
    if (redirect) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    return null;
}
