import React, { useEffect } from 'react';
import { Grid, Typography, Paper, Button, IconButton, TextField,InputAdornment } from "@mui/material";
import { styled } from '@mui/material/styles';
import siteIcon from "./../../asset/img/site_icon.png";
import LanguageSwitcher from "./../I18N/LanguageSwitcher";
import { Link as RouterLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuthenticationState, updateForgetPassword, setUpdating } from "./../../redux/authentication";
import { createForgetPasswordFormSchema } from "./../../component/form/model/PasswordFormModel";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import queryString from 'query-string';
import {NeedHelp,SiteIcon,ContactUs} from "./../../component/common/Others";
import {LogoutArrow} from "./../../component/common/Containers";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NonDashboardRootContainer } from "./../../component/common/Containers";

const ContentGrid = styled(Grid)(({ theme }) => ({
  "textAlign": "center"
}));

const CenterAlignContainer = styled(Grid)(({ theme }) => ({
  display: "grid",
  placeItems: "center"
}));

const Title = styled(Grid)(({ theme }) => ({
  "paddingTop": "25px",
  "marginBottom": "25px",
  display: "grid",
  placeItems: "center"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": "6px",
    "marginBottom": "6px",
    width: "300px"
}));

function TitleSection() {
  return (
    <React.Fragment>
      <Grid container item xs={1} md={1} xl={1}>
        <LogoutArrow item xs={12} md={12} xl={12}>
          <RouterLink to='/'>
            <IconButton data-cy='logoutArrow' size="small">
              <ArrowBackIcon />
            </IconButton>
          </RouterLink>
        </LogoutArrow>
      </Grid>
      <Grid container item xs={11} md={11} xl={11}>
        <ContentGrid item xs={12} md={12} xl={12}>
          <Typography variant="body2" component="span" color="textSecondary" align="right">
            <LanguageSwitcher disableContrast={true} showText={false}></LanguageSwitcher>
          </Typography>
        </ContentGrid>
      </Grid>
    </React.Fragment>
  );
}

export default function ResetPasswordPage() {

  const { t } = useTranslation(["reset_password", "general"]);
  const CS_EMAIL = "help@itutor.com";
  const SUBJECT = t("reset_password:contact_subject");
  const MAIL_TO_URL = `mailto:${CS_EMAIL}?subject=${SUBJECT}`;
  const { token } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const QUERY_VALUES = queryString.parse(search);
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(createForgetPasswordFormSchema())
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowPassword = (name) => {
    switch (name) {
      case "password":
        setShowPassword((prev) => !prev);
        break;
      case "confirmPassword":
        setShowConfirmPassword((prev) => !prev);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setUpdating({
      updating: false,
      error: null
    });
  }, []);

  function confirm(data) {

    updateForgetPassword({
      renewPassword: data.password,
      email: QUERY_VALUES.email,
      token: token
    });
    navigate('/');
  }

  return (
    <NonDashboardRootContainer container >
      <TitleSection></TitleSection>
      <Grid container item xs={12} md={12} xl={12}>
        <ContentGrid item xs={1} md={3} xl={3}></ContentGrid>
        <ContentGrid item xs={10} md={6} xl={6}>
          <Paper elevation={10}>
            <CenterAlignContainer id="CompanyLogoContainer" key="CompanyLogoContainer" container item xs={12} md={12} xl={12}>
              <SiteIcon id="CompanyLogo" key="CompanyLogo" alt="Company Logo" src={siteIcon} />
            </CenterAlignContainer>
            <Title container item xs={12} md={12} xl={12}>
              <Typography variant="h5">
                {t("reset_password:page_title")}
              </Typography>
              <div><br /></div>
              <form onSubmit={handleSubmit(confirm)} noValidate>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => <StyledTextField
                      variant="outlined"
                      margin="normal"
                      label={t("reset_password:field-password-new-1")}
                      helperText={errors.password ? t(errors.password.message) : null}
                      error={errors.password ? true : false}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              name="test"
                              aria-label="toggle password visibility"
                              onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClickShowPassword("password") }}
                              onMouseDown={(event) => { event.preventDefault() }}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...field}
                    />}
                  />
                </div>
                <div>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => <StyledTextField
                      variant="outlined"
                      margin="normal"
                      label={t("reset_password:field-password-new-2")}
                      helperText={errors.confirmPassword ? t(errors.confirmPassword.message) : null}
                      error={errors.confirmPassword ? true : false}
                      type={showConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              name="test"
                              aria-label="toggle password visibility"
                              onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleClickShowPassword("confirmPassword") }}
                              onMouseDown={(event) => { event.preventDefault() }}
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...field}
                    />}
                  />
                </div>
                <Typography component="h2" variant="body2" color="error">
                  {t(useAuthenticationState().request.message)}
                </Typography>
                <div><br /></div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t("general:button-submit")}
                </Button>
              </form>
            </Title>
            <NeedHelp container item xs={12} md={12} xl={12}>
              <Typography variant="body1">
                {t("general:help-need_help")}
                <ContactUs href={MAIL_TO_URL}>
                  {t("general:link-contact_us")}
                </ContactUs>
              </Typography>
            </NeedHelp>
          </Paper>
        </ContentGrid>
      </Grid>
    </NonDashboardRootContainer>
  );
}