import React from "react";
import {
    Grid, Button,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { applyClass,  useClassesManagementState } from "./../../../../redux/ClassesManagement";
import SectionLabel from "./../../../display/styled/SectionLabel";
import ClassCard from "./../../../page/class/ClassCard";
import { useAuthenticationState } from "./../../../../redux/authentication";

export default function ApplyClassDialog({
    title, currentClass, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);
    const CLASS_INFO = useClassesManagementState().currentClass || {};
    const closeDialog = () => {
        setIsDialogOpen(false);
    }
    const { classId } = useParams();
    const { roleId } = useAuthenticationState().currentRole || {};

    const applyClassOnClick = (event) => {
        event.preventDefault();
        setIsDialogOpen(false);
        applyClass({ classId: classId, studentId: roleId })
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <SectionLabel>{t("attribute:class")}</SectionLabel>
                <Grid item xs={12} md>
                    <ClassCard disabled currentClass={CLASS_INFO} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Button autoFocus onClick={closeDialog} variant="outlined">
                        {t("general:button-cancel")}
                    </Button>
                    <Button onClick={applyClassOnClick} variant="contained" >
                        {t("general:button-submit")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog >
    )
}