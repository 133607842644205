import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
	Paper, Grid, Stack,
	Typography, IconButton
} from "@mui/material";
import { useParams } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { SpaceHolder } from "./../../common/Others";
import { useProfileState, updateStudentAdditionalInfo } from "./../../../redux/ProfileManagement";
import { useAuthenticationState } from "./../../../redux/authentication";
import ContentRow from "./../../common/ContentRow";
import ContextualMenu from "./../../common/ContextualMenu";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import { ContextualMenuItem } from "./../../common/Buttons";
import StudentAdditionalInfoDialog from "./dialog/StudentAdditionalInfoDialog";
import moment from "moment";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),
}));

const NarrowRow = styled(Stack)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

function enrolmentIdInRolesList(enrolmentId, roleList) {
	for (let value of roleList) {
		if (enrolmentId === value._id) {
			return true;
		};
	}
	return false
}

function AdditonalInfoRows() {
	const STUDENT_PROFILE_INFO = useProfileState().studentProfileInfo || {};

	if (!STUDENT_PROFILE_INFO.fieldNameMap || Object.keys(STUDENT_PROFILE_INFO.fieldNameMap).length == 0) {
		return null;
	}
	const NAME_MAP = STUDENT_PROFILE_INFO.fieldNameMap || {};
	const VALUE_MAP = STUDENT_PROFILE_INFO.customFieldValue || {};

	const CUSTOM_FIELD_ENTRIES = Object.keys(NAME_MAP) || [];
	let component = CUSTOM_FIELD_ENTRIES.map((value, index) => {
		return <ContentRow key={value} needI18Nhandle title={NAME_MAP[value]} content={VALUE_MAP[value] || ""} />
	});
	return component;
}

export default function StudentAboutDetailPage() {
	const STUDENT_PROFILE_INFO = useProfileState().studentProfileInfo || {};
	const { t } = useTranslation(["centre", "general"]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [additionInfoDialogOpen, setAdditionInfoDialogOpen] = React.useState(false);
	let { enrolmentId } = useParams();
	const ROLES = useAuthenticationState().roles;

	const triggerContentualMenu = (event) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	}

	const triggerAdditionalInfoDialog = (event) => {
		event.preventDefault();
		setAdditionInfoDialogOpen(true);
	}

	const dialogSubmitHandler = (data) => {
		setAdditionInfoDialogOpen(false);
		data.enrolmentId = enrolmentId;
		updateStudentAdditionalInfo(data);
	}
	return (
		<React.Fragment>
			<Stack spacing={1}>
				<Paper sx={{ borderRadius: "10px" }}>
					<Grid container>
						<SpaceHolder container item xs={0.5} />
						<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
							<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
								<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("general:section_title-about")}</Typography>
							</HeaderRow>
							<ContentRow needI18Nhandle title={t("attribute:student-school")} content={STUDENT_PROFILE_INFO.school} />
							<ContentRow needI18Nhandle title={t("attribute:student-grade")} content={STUDENT_PROFILE_INFO.grade} />
							<ContentRow title={t("attribute:student-birthday")} content={moment(STUDENT_PROFILE_INFO.birthday).format("LL")} />
							<ContentRow needI18Nhandle title={t("attribute:student-gender")} content={STUDENT_PROFILE_INFO.gender} />
							<ContentRow needI18Nhandle title={t("attribute:student-district")} content={STUDENT_PROFILE_INFO.district} />
							<ContentRow title={t("attribute:student-guardian")}
								content={t("student:attribute_value-guardian",
									{
										alias: t(`attribute:${STUDENT_PROFILE_INFO.guardian_alias}`),
										name: STUDENT_PROFILE_INFO.guardian_name,
										relationship: t(`attribute:${STUDENT_PROFILE_INFO.guardian_relationship}`)
									})} />
						</Grid>
						<SpaceHolder container item xs={0.5} />
					</Grid>
				</Paper>
			</Stack>
			<CanUpdateCentre centreId={STUDENT_PROFILE_INFO.centreId} or={enrolmentIdInRolesList(enrolmentId, ROLES)}>
				<NarrowRow spacing={1}>
					<Paper sx={{ borderRadius: "10px" }}>
						<Grid container>
							<SpaceHolder container item xs={0.5} />
							<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
								<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
									<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("student:section_title-contact")}</Typography>
								</HeaderRow>
								<ContentRow needI18Nhandle title={t("attribute:student-guardian-contact")} content={`${STUDENT_PROFILE_INFO.guardian_name} - ${STUDENT_PROFILE_INFO.guardian_phone}`} />
								{STUDENT_PROFILE_INFO.contactPerson ? STUDENT_PROFILE_INFO.contactPerson.map((element, index) => {
									return (<ContentRow needI18Nhandle
										key={`contentRow_emergency_${index}`}
										title={t(`attribute:student-emergency_contact_${index + 1}`)}
										content={`${element.name + " - " + element.phone}`} />);
								}) : null}
							</Grid>
							<SpaceHolder container item xs={0.5} />
						</Grid>
					</Paper>
				</NarrowRow>
			</CanUpdateCentre>

			{!STUDENT_PROFILE_INFO.fieldNameMap || Object.keys(STUDENT_PROFILE_INFO.fieldNameMap).length == 0 ? null : (
				<NarrowRow spacing={1}>
					<Paper sx={{ borderRadius: "10px" }}>
						<Grid container>
							<SpaceHolder container item xs={0.5} />
							<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
								<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
									<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("student:section_title-additional")}</Typography>
									<CanUpdateCentre centreId={STUDENT_PROFILE_INFO.centreId} or={enrolmentIdInRolesList(enrolmentId, ROLES)}>
										<IconButton
											sx={{
												marginTop: 2,
												backgroundColor: "#DDDDDD",
											}}
											color="primary"
											aria-label="upload picture" component="label"
											onClick={triggerContentualMenu}
										>
											<MoreHorizIcon />
										</IconButton>
									</CanUpdateCentre>
								</HeaderRow>
								<AdditonalInfoRows />
							</Grid>
							<SpaceHolder container item xs={0.5} />
						</Grid>
					</Paper>
				</NarrowRow>
			)}

			<ContextualMenu id={"admin-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				<ContextualMenuItem
					onClick={triggerAdditionalInfoDialog}
					title={t("general:button-edit")}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<StudentAdditionalInfoDialog open={additionInfoDialogOpen} setOpenDialog={setAdditionInfoDialogOpen}
				customFields={STUDENT_PROFILE_INFO.fieldNameMap}
				customFieldsValue={STUDENT_PROFILE_INFO.customFieldValue}
				onSubmitHandler={dialogSubmitHandler} />
		</React.Fragment >
	);
}
