import { useAuthenticationState } from "./../../redux/authentication";
import { isPlatformAdmin } from "./../../util/ACLUtils";

export default function ViewableByPlatformAdminOnly({ children }) {
    const AUTH_STATE = useAuthenticationState();
    let currentRole = AUTH_STATE.currentRole;
    if (currentRole) {
        let roleName = currentRole.roleName;
        if (isPlatformAdmin(roleName)) {
            return children;
        }
    }
    return null;
}
