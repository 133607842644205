import { useAuthenticationState } from "./../../redux/authentication";
import {
    useLocation,
    Navigate
} from "react-router-dom";
import { isPlatformAdmin } from "./../../util/ACLUtils";

export default function PlatformAdminOnly({ children }) {
    const AUTH_STATE = useAuthenticationState();
    let location = useLocation();
    let currentRole = AUTH_STATE.currentRole;
    if (currentRole) {
        let roleName = currentRole.roleName;
        if (isPlatformAdmin(roleName)) {
            return children;
        }
    };
    return <Navigate to="/login" state={{ from: location }} replace />;

}
