import React from "react";
import debounce from 'lodash.debounce';
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
	Grid,
	Typography, Fab, Pagination
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContextualMenu from "./../../common/ContextualMenu";
import { ContextualMenuItem } from "./../../common/Buttons";
import { TabTitleContainer } from "./../../common/Containers";
import { SpaceHolder } from "./../../common/Others";
import CanUpdateCentre from "./../../acl/CanUpdateCentre";
import { getCentrePost, deleteCentrePost, toggleCentrePostVisibility, useCentreManagementState } from "./../../../redux/CentreManagement";
import ConfirmActionDialog from "./../../common/ConfirmActionDialog";
import CentrePostDialog from "./../../page/centreDetail/dialog/CentrePostDialog";
import CentrePostCard from "./../../page/centreDetail/CentrePostCard";

const HeaderRow = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(2.5),

}));

export default function CentrePostPage() {
	const { t } = useTranslation(["centre", "general"]);
	const [selected, setSelected] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

	const rowsPerPage = 10;
	const [page, setPage] = React.useState(0);
	const CENTRE_POST = useCentreManagementState().centrePost;
	const CENTRE_POST_COUNT = useCentreManagementState().centrePostCount || 0;

	let { centreId } = useParams();

	const triggerAddDialog = (event) => {
		event.preventDefault();
		setSelected(null);
		setDialogOpen((true));
	};

	const handleClick = (event, selectedValue) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setSelected(selectedValue);
	};

	const openEditPostDialog = (event) => {
		event.preventDefault();
		setDialogOpen(true);
		setAnchorEl(null);
	}

	const openDeleteDialog = (event) => {
		event.preventDefault();
		setDeleteDialogOpen(true);
		setAnchorEl(null);
	}

	const deletePost = (event) => {
		event.preventDefault();
		setAnchorEl(null);
		deleteCentrePost(selected);
	}

	const togglePostVisibility = (event) => {
		event.preventDefault();
		setAnchorEl(null);
		setDeleteDialogOpen(false);
		if (selected.status === "active") {
			selected.status = "inactive";
		} else {
			selected.status = "active";
		}
		toggleCentrePostVisibility(selected);
	}

	let deboundedGetCentrePost = React.useMemo(() => debounce(getCentrePost, 50), []);

	React.useEffect(() => {
		deboundedGetCentrePost({ centreId: centreId, page: page, rowsPerPage: rowsPerPage });
	}, []);

	React.useEffect(() => {
		deboundedGetCentrePost({ centreId: centreId, page: page, rowsPerPage: rowsPerPage });
	}, [page, rowsPerPage]);


	let CENTRE_POST_LIST = React.useMemo(() => {
		return CENTRE_POST.map(
			(currentPost, index) => {
				return <CentrePostCard key={index + "_centre_post_card"} currentPost={currentPost} index={index} handleClick={handleClick} />
			});
	}, [CENTRE_POST]);

	return (
		<React.Fragment>
			<TabTitleContainer>
				<Grid container>
					<SpaceHolder container item xs={0.5} />
					<Grid sx={{ paddingBottom: 1 }} container item xs={11}>
						<HeaderRow alignItems="center" justifyContent="space-between" container item xs={12}>
							<Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">{t("centre:section_title-posts")}</Typography>
							<CanUpdateCentre centreId={centreId} >
								<Fab color="primary" size="small" onClick={(event) => {
									triggerAddDialog(event);
								}} aria-label="comment">
									<AddIcon />
								</Fab>
							</CanUpdateCentre>
						</HeaderRow>
					</Grid>
					<SpaceHolder container item xs={0.5} />
				</Grid>
			</TabTitleContainer>

			{CENTRE_POST_LIST}
			<Grid sx={{ marginTop: 3 }} container justifyContent="center" spacing={1} >
				<Pagination
					onChange={(event, pageNumber) => {
						event.preventDefault();
						setPage(pageNumber - 1);
					}}
					count={Math.ceil(CENTRE_POST_COUNT / rowsPerPage)}
				/>
			</Grid>
			<ContextualMenu id="post_popover_menu" anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
				<ContextualMenuItem title={t("general:button-edit")} onClick={openEditPostDialog}>
					<EditIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
				{
					selected && selected.status == "active" ?
						(<ContextualMenuItem title={t("general:button-hide")} onClick={togglePostVisibility}>
							<VisibilityOffIcon sx={{ mr: 0 }} fontSize="small" />
						</ContextualMenuItem>) :
						(<ContextualMenuItem title={t("general:button-show")} onClick={togglePostVisibility}>
							<VisibilityIcon sx={{ mr: 0 }} fontSize="small" />
						</ContextualMenuItem>)
				}
				<ContextualMenuItem title={t("general:button-delete")} onClick={openDeleteDialog}>
					<DeleteIcon sx={{ mr: 0 }} fontSize="small" />
				</ContextualMenuItem>
			</ContextualMenu>
			<ConfirmActionDialog
				title={t("general:dialog_title-delete_entity", { entity: t("attribute:post") })}
				actionName={t("general:button-delete")}
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				confirmOnClick={deletePost} />
			<CentrePostDialog
				currentPost={selected}
				title={t("general:dialog_title-add_entity", { entity: t("attribute:post") })}
				editTitle={t("general:dialog_title-edit_entity", { entity: t("attribute:post") })}
				isDialogOpen={dialogOpen}
				setIsDialogOpen={setDialogOpen} />
		</React.Fragment >
	);
}
