import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";
export interface Centres {
    name: String,
    calories: String,
    fat: String,
    carbs: String,
    protein: String
}


export async function addStudent(student: FormData): Promise<UpdateResultResponse> {
    const url = '/account/new_student_profile';

    let headers = { 'Content-Type': 'multipart/form-data' };
    if (student.has("studentPhoto") === false) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    }
    const config = {
        headers: headers
    }
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, student, config);
        return data;
    } catch (err) {
        throw err
    }
}

export async function getStudent(username: string): Promise<UpdateResultResponse> {
    const url = '/account/student_profile';
    try {
        const { data } = await axios.get<UpdateResultResponse>(url, { params: { username: username } });
        return data;
    } catch (err) {
        throw err
    }
}

export async function saveStudent(student: FormData): Promise<UpdateResultResponse> {
    const url = '/account/save_student_profile';

    let headers = { 'Content-Type': 'multipart/form-data' };
    if (student.has("studentPhoto") === false) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    }
    const config = {
        headers: headers
    }
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, student, config);
        return data;
    } catch (err) {
        throw err
    }
}

export async function addTutor(tutor: FormData): Promise<UpdateResultResponse> {
    const url = '/account/new_tutor_profile';
    let headers = { 'Content-Type': 'multipart/form-data' };
    if (tutor.has("tutorPhoto") === false) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    }
    const config = {
        headers: headers
    }
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, tutor, config);
        return data;
    } catch (err) {
        throw err
    }
}

export async function getTutor(username: string): Promise<UpdateResultResponse> {
    const url = '/account/tutor_profile';
    try {
        const { data } = await axios.get<UpdateResultResponse>(url, { params: { username: username } });
        return data;
    } catch (err) {
        throw err
    }
}


export async function saveTutor(tutor: FormData): Promise<UpdateResultResponse> {
    const url = '/account/save_tutor_profile';

    let headers = { 'Content-Type': 'multipart/form-data' };
    if (tutor.has("tutorPhoto") === false) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    }
    const config = {
        headers: headers
    }
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, tutor, config);
        return data;
    } catch (err) {
        throw err
    }
}

export async function signUpUser(user: Object): Promise<UpdateResultResponse> {
    const url = "/account/signup_account";
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, user);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function saveUserInfo(user: Object): Promise<UpdateResultResponse> {
    const url = "/account/save_account";
    try {
        const {
            data
        } = await axios.post<UpdateResultResponse>(url, user);
        return data;
    } catch (err) {
        throw err;
    }
}

export async function getUserInfo(username: string): Promise<UpdateResultResponse> {
    const url = "/account/account_info";
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url, { params: { "username": username } });
        return data;
    } catch (err) {
        throw err;
    }
}

export async function getUsersRequest(searchCriteria: Object): Promise<UpdateResultResponse> {
    const url = "/account/users";
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url, { params: searchCriteria });
        return data;
    } catch (err) {
        throw err;
    }
}

export async function getTutorInfo(enrolmentId: string): Promise<UpdateResultResponse> {
    const url = `/account/enrolment/tutor/${enrolmentId}`;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err;
    }
}


export async function getStudentInfo(enrolmentId: string): Promise<UpdateResultResponse> {
    const url = `/account/enrolment/student/${enrolmentId}`;
    try {
        const {
            data
        } = await axios.get<UpdateResultResponse>(url);
        return data;
    } catch (err) {
        throw err;
    }
}



