import React from "react";
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Grid, Button
} from "@mui/material";
import { createAddCentreFormSchema, INITIAL_CENTRE_FORM } from "./../model/CentreFormModel"
import CentreFieldList from "./CentreFieldList";
import { useTranslation } from "react-i18next";
import { useOptionsState } from "./../../../redux/Options";
import { updateCentre } from "./../../../redux/CentreManagement";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SuccessButton } from "./../../common/Buttons";
import { DialogFixedForm } from "./../../common/Forms";

export default function CentreEditDialog({
    open,
    setOpenDialog,
    currentCentre
}) {

    const { t } = useTranslation("account", "attribute", "general");
    const VALID_VALUES = {
        DISTRICT: useOptionsState().district.validValues
    };

    const ADD_CENTRE_FORM = useForm({
        resolver: yupResolver(createAddCentreFormSchema(VALID_VALUES.DISTRICT)),
        defaultValue: INITIAL_CENTRE_FORM
    });

    function saveUpdatedCentre(data) {
        updateCentre({ centre: data });
        ADD_CENTRE_FORM.reset(INITIAL_CENTRE_FORM);
        setOpenDialog(false);
    }


    function closeEditDialog() {
        ADD_CENTRE_FORM.reset(INITIAL_CENTRE_FORM);
        setOpenDialog(false);

    }

    return (<Dialog
        open={open}
        onClose={closeEditDialog}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
    >
        <FormProvider {...ADD_CENTRE_FORM}>
            <DialogFixedForm
                control={ADD_CENTRE_FORM.control}
                errors={ADD_CENTRE_FORM.errors}
                onSubmit={ADD_CENTRE_FORM.handleSubmit(saveUpdatedCentre)}>
                <DialogTitle id="responsive-dialog-title">
                    {t("general:dialog_title-edit_entity_info", { entity: t("attribute:centre") })}
                </DialogTitle>
                <DialogContent dividers data-cy="edit_dialog_content">
                    <CentreFieldList showLabel={false} hidePaymentInfo currentCentre={currentCentre}></CentreFieldList>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button autoFocus data-cy={"edit_centre_card_cancel_" + currentCentre._id} onClick={closeEditDialog} variant="outlined">
                            {t("general:button-cancel")}
                        </Button>
                        <SuccessButton type="submit" data-cy={"edit_centre_card_save_" + currentCentre._id} variant="contained" >
                            {t("general:button-save")}
                        </SuccessButton>
                    </Grid>
                </DialogActions>
            </DialogFixedForm>
        </FormProvider>
    </Dialog>);
}