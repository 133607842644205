import React from "react";
import {
    Grid, Button,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DialogFixedForm } from "./../../../../component/common/Forms";
import CentreClassFieldList from "./../../../../component/form/centreComponent/CentreClassFieldList";
import { INITIAL_CENTRE_CLASS_FORM, createClassFormSchema } from "./../../../../component/form/model/CentreFormModel";
import { addCentreClass, updateCentreClass } from "./../../../../redux/ClassesManagement";
import { useOptionsState } from "./../../../../redux/Options";
import { yupResolver } from "@hookform/resolvers/yup";
import OPTIONS_CATEGORY from "./../../../../const/OptionsCategory";

export default function CentreClassDialog({
    title, currentClass, currentCentreId, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);

    let { centreId } = useParams();
    if (currentCentreId) {
        centreId = currentCentreId;
    }
    const OPTIONS_STATE = useOptionsState();

    const CLASS_FORM_METHOD = useForm({
        resolver: yupResolver(createClassFormSchema(
            OPTIONS_STATE[OPTIONS_CATEGORY.GRADE].validValues,
            OPTIONS_STATE[OPTIONS_CATEGORY.WEEK_DAY].validValues,
            OPTIONS_STATE[OPTIONS_CATEGORY.SUBJECT].validValues))
    });

    React.useEffect(() => {
        if (currentClass) {
            CLASS_FORM_METHOD.reset(currentClass);
        } else {
            CLASS_FORM_METHOD.reset(INITIAL_CENTRE_CLASS_FORM);
        }

    }, [isDialogOpen])


    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const submitMethod = (data) => {
        if (currentClass) {
            editCentreClass(data);
        } else {
            addNewCentreClass(data);
        }
    }

    const editCentreClass = (data) => {
        data.centreId = centreId;
        data._id = currentClass._id;
        updateCentreClass(data);
        setIsDialogOpen(false);
    }

    const addNewCentreClass = (data) => {
        data.centreId = centreId;
        addCentreClass(data);
        setIsDialogOpen(false);
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...CLASS_FORM_METHOD} >
                <DialogFixedForm control={CLASS_FORM_METHOD.control}
                    errors={CLASS_FORM_METHOD.errors}
                    onSubmit={CLASS_FORM_METHOD.handleSubmit(submitMethod)}>
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <CentreClassFieldList />
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button autoFocus onClick={closeDialog} variant="outlined">
                                {t("general:button-cancel")}
                            </Button>
                            <Button type="submit" variant="contained" >
                                {t("general:button-save")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </FormProvider>
        </Dialog >
    )
}