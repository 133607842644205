import i18n from "./../../I18n";

async function LanguageInterceptor(req) {
	try {
		req.headers["I18nextLang"] = i18n.language || "en";
	} catch (error) {
		console.error(error);
	}
	return req;
}

export default LanguageInterceptor;