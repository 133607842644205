import * as YUP from "yup";

function createAddPlanFormSchema() {
    return YUP.object().shape({
        name: YUP.string().required("general:validation-mandatory"),
    });
}

const INITIAL_PLAN_FORM = {
    name: "",
    monthlyPrice: 0,
    studentQuota: 0,
    freeTrialDays: 0
}

const PLAN_FORM_FIELD = {
    "name": "name",
    "monthlyPrice" : "monthlyPrice",
    "studentQuota" : "studentQuota",
    "freeTrialDays" : "freeTrialDays"

}

export {
    createAddPlanFormSchema, INITIAL_PLAN_FORM,
    PLAN_FORM_FIELD
}