import * as YUP from "yup";
import MOMENT from "moment";

function createAddCentreFormSchema(districtValidValue) {
    return YUP.object().shape({
        displayName: YUP.string().required("general:validation-mandatory"),
        district: YUP.string().nullable().oneOf(districtValidValue).required("general:validation-mandatory"),
        address: YUP.string(),
        phone: YUP.string().matches(/^\+?[0-9]{7,15}$/, "general:validation-phone"),
        openinghour: YUP.string().required("general:validation-mandatory"),
        description: YUP.string(),
        chiBusinessName: YUP.string(),
        engBusinessName: YUP.string().required("general:validation-mandatory"),
        paymentKey: YUP.string()
    });
}

function createUpdateCentrePublicInfoFormSchema(districtValidValue) {
    return YUP.object().shape({
        displayName: YUP.string().required("general:validation-mandatory"),
        district: YUP.string().nullable().oneOf(districtValidValue).required("general:validation-mandatory"),
        address: YUP.string(),
        phone: YUP.string().matches(/^\+?[0-9]{7,15}$/, "general:validation-phone"),
        openinghour: YUP.string().required("general:validation-mandatory"),
        description: YUP.string()
    });
}

function createUpdateCentreBusinessInfoFormSchema(districtValidValue) {
    return YUP.object().shape({
        chiBusinessName: YUP.string(),
        engBusinessName: YUP.string().required("general:validation-mandatory"),
        paymentKey: YUP.string()
    });
}


function createCentrePostFormSchema(districtValidValue) {
    return YUP.object().shape({
        subject: YUP.string().required("general:validation-mandatory"),
        content: YUP.string(),
        publishDate: YUP.date().required("general:validation-mandatory").max(new Date(), "general:validation-date").nullable().typeError("general:validation-date"),
    });
}

function createCentreAdminInvitationFormSchema() {
    return YUP.object().shape({
        email: YUP.string().email("general:validation_type_email").required("general:validation-mandatory")
    });
}

function createCentreAdditionalFieldFormSchema() {
    return YUP.object().shape({
        name: YUP.string().required("general:validation-mandatory")
    });
}

function createCentreEnrolmentFormSchema() {
    return YUP.object().shape({
        agreeTC: YUP.boolean().oneOf([true], 'account:validation-agree_tc'),
        customFields: YUP.object().nullable()
    });
}

function createCentreAdditionalValueUpdateFormSchema() {
    return YUP.object().shape({
        customFields: YUP.object().nullable()
    });
}

function createCentreFacilityFormSchema() {
    return YUP.object().shape({
        name: YUP.string().required("general:validation-mandatory"),
        description: YUP.string().nullable(),
        function: YUP.string().nullable()
    });
}

function createCentreNotificationFormSchema(languages) {
    const yupObjectShape = {};
    languages.forEach((lanugage) => {
        yupObjectShape[`${lanugage}_title`] = YUP.string().required("general:validation-mandatory");
        yupObjectShape[`${lanugage}_content`] = YUP.string().required("general:validation-mandatory");
    });
    return YUP.object().shape(yupObjectShape);
}

function createClassFormSchema(gradeLevelValidValue, weekdysValidValue, subjectsValidValue) {
    return YUP.object().shape({
        name: YUP.string().required("general:validation-mandatory"),
        subjects: YUP.array().of(YUP.string().oneOf(subjectsValidValue)).required("general:validation-mandatory").min(1, "general:validation-mandatory"),
        grades: YUP.array().of(YUP.string().oneOf(gradeLevelValidValue)).required("general:validation-mandatory").min(1, "general:validation-mandatory"),
        weekdays: YUP.array().of(YUP.string().oneOf(weekdysValidValue)).nullable(),
        startTime: YUP.date().required("general:validation-mandatory"),
        endTime: YUP.date().required("general:validation-mandatory").test("isLaterThenStartTime", () => {
            return "general:validation-endTime_greater_than_startTime";
        }, async (value, testContext) => {
            let startTimeInDate = MOMENT(testContext.parent.startTime).second(0).millisecond(0);
            let endTimeInDate = MOMENT(value).second(0).millisecond(0);
            return (endTimeInDate.isSameOrAfter(startTimeInDate.toISOString()));
        }),
        classFee: YUP.number().nullable().required("general:validation-mandatory"),
        classSize: YUP.number().nullable(),
        description: YUP.string().nullable(),
        remarks: YUP.string().nullable(),
    });
}

const INITIAL_CENTRE_ENROLMENT_FORM = {
    "agreeTC": false,
    "customField": {}
}

const CENTRE_ENROLMENT_FIELD = {
    "agreeTC": "agreeTC",
    "customField": "customField"
}

const INITIAL_CENTRE_ADDITIONAL_VALUE_FORM = {
    "customField": {}
}

const CENTRE_ADDITIONAL_VALUE_FORM = {
    "customField": "customField"
}

const INITIAL_CENTRE_FORM = {
    displayName: "",
    district: "",
    address: "",
    phone: "",
    openinghour: "",
    description: "",
    chiBusinessName: "",
    engBusinessName: "",
    paymentKey: ""
}

const INITIAL_CENTRE_POST_FORM = {
    "subject": "",
    "content": "",
    "publishDate": new Date()
}

const CENTRE_PROFILE_FIELD = {
    displayName: "displayName",
    district: "district",
    address: "address",
    phone: "phone",
    openinghour: "openinghour",
    description: "description",
    chiBusinessName: "chiBusinessName",
    engBusinessName: "engBusinessName",
    paymentKey: "paymentKey"
}

const INITIAL_CENTRE_CLASS_FORM = {
    name: "",
    subjects: [],
    grades: [],
    weekdays: [],
    startTime: new Date(),
    endTime: new Date(),
    classFee: 0,
    classSize: 0,
    description: "",
    remarks: ""
}

const CENTRE_CLASS_FIELD = {
    name: "name",
    subjects: "subjects",
    grades: "grades",
    weekdays: "weekdays",
    startTime: "startTime",
    endTime: "endTime",
    classFee: "classFee",
    classSize: "classSize",
    description: "description",
    remarks: "remarks"
}

const CENTRE_POST_FIELD = {
    "subject": "subject",
    "content": "content",
    "publishDate": "publishDate"
}

const CENTRE_FACILITY_FIELD = {
    name: "name",
    description: "description",
    function: "function"
}

const INITIAL_CENTRE_FACILITY_FORM = {
    name: "",
    description: "",
    function: ""
}


export {
    createAddCentreFormSchema,
    createUpdateCentrePublicInfoFormSchema,
    createUpdateCentreBusinessInfoFormSchema,
    createCentreAdminInvitationFormSchema,
    createCentreAdditionalFieldFormSchema,
    INITIAL_CENTRE_FORM, CENTRE_PROFILE_FIELD,
    createCentreEnrolmentFormSchema,
    createCentrePostFormSchema, CENTRE_POST_FIELD, INITIAL_CENTRE_POST_FORM,
    INITIAL_CENTRE_ENROLMENT_FORM, CENTRE_ENROLMENT_FIELD,
    createCentreFacilityFormSchema, CENTRE_FACILITY_FIELD, INITIAL_CENTRE_FACILITY_FORM,
    INITIAL_CENTRE_ADDITIONAL_VALUE_FORM, CENTRE_ADDITIONAL_VALUE_FORM, createCentreAdditionalValueUpdateFormSchema,
    INITIAL_CENTRE_CLASS_FORM, CENTRE_CLASS_FIELD, createClassFormSchema,
    createCentreNotificationFormSchema
};