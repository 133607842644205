import { useAuthenticationState } from "./../../redux/authentication";


export default function WithSelectedEnrolmentOnly({ children}) {
    const AUTH_STATE = useAuthenticationState();
    const IS_WITH_ROLE = AUTH_STATE.currentRole.roleId != null;
    if (IS_WITH_ROLE) {
            return children;
    }
    return null;
}
