import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import { useOptionsState } from "./../../redux/Options";
import { useAuthenticationState } from "./../../redux/authentication";
import { useProfileState, addStudentProfile, addTutorProfile, getStudentProfile, getTutorProfile } from "./../../redux/ProfileManagement";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import { FormProvider } from "react-hook-form";
import { INITIAL_STUDENT_FORM, createStudentFormSchema } from "./../form/model/StudentFormModel";
import { INITIAL_TUTOR_FORM, createTutorFormSchema } from "./../form/model/TutorFormModel";
import AccountInformation from "./accountManagement/AccountInformation";
import StudentProfiles from "./accountManagement/StudentProfiles";
import TutorProfiles from "./accountManagement/TutorProfiles";
import PasswordForm from "./../form/PasswordForm";
import { Can } from "./../../casl/authorization";
import { SpaceHolder } from "./../../component/common/Others";
import { subject } from "@casl/ability";
import { DashboardRootContainer } from "./../../component/common/Containers";


const Title = styled(Typography)(({ theme }) => ({
    width: "100%",
    textAlign: "center",
    alignItems: "center"
}));

const ContainerRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const AccordionPanel = styled(Accordion)(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    '&.MuiAccordion-rounded': {
        borderRadius: "10px",
    }
}));

const AddButton = styled(Button)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
}));

export default function AccountManagement() {
    const { t } = useTranslation(["account", "general"]);
    const LOCATION = useLocation();
    const STUDENT_PROFILES = useProfileState().studentProfiles;
    const TUTOR_PROFILES = useProfileState().tutorProfiles;
    const VALID_VALUES = {
        GENDER: useOptionsState().gender.validValues,
        GRADE: useOptionsState().grade.validValues,
        ALIAS: useOptionsState().alias.validValues,
        RELATIONSHIP: useOptionsState().relationship.validValues,
        DISTRICT: useOptionsState().district.validValues,
        SUBJECTS: useOptionsState().subject.validValues,
        EDUCATION_LEVEL: useOptionsState().educationLevel.validValues,
        MAIN_LANGUAGE: useOptionsState().mainLanguage.validValues
    };

    const STUDENT_PROFILE_SCHEMA = createStudentFormSchema(VALID_VALUES.GENDER, VALID_VALUES.GRADE, VALID_VALUES.DISTRICT, VALID_VALUES.ALIAS, VALID_VALUES.RELATIONSHIP);
    const TUTOR_RPROFILE_SCHEMA = createTutorFormSchema(VALID_VALUES.GENDER, VALID_VALUES.MAIN_LANGUAGE, VALID_VALUES.EDUCATION_LEVEL, VALID_VALUES.SUBJECTS);
    const AUTH_STATE = useAuthenticationState();
    const ABILITY = AUTH_STATE.ability;
    const studentFormMethods = useForm({
        resolver: yupResolver(STUDENT_PROFILE_SCHEMA),
        defaultValues: INITIAL_STUDENT_FORM
    });

    const tutorFormMethods = useForm({
        resolver: yupResolver(TUTOR_RPROFILE_SCHEMA),
        defaultValues: INITIAL_TUTOR_FORM
    });

    const [studentDialogOpen, setStudentDialogOpen] = React.useState(false);
    const [studentPhotoObject, setStudentPhotoObject] = React.useState({ "file": null });
    const [tutorDialogOpen, setTutorDialogOpen] = React.useState(false);
    const [tutorPhotoObject, setTutorPhotoObject] = React.useState({ "file": null });
    const [isPanelsOpen, setIsPanelsOpen] = React.useState({
        "account": true,
        "profile": true,
        "password": true
    });

    const LOCATION_USERNAME = LOCATION.state ? LOCATION.state.username : null;
    const CURRENT_PROFILE_USERNAME = useProfileState().username;
    function toggleTutorDialog(event) {
        event.preventDefault();
        event.stopPropagation();
        setTutorDialogOpen((prev) => !prev);
    }

    function toggleStudentDialog(event) {
        event.preventDefault();
        event.stopPropagation();
        setStudentDialogOpen((prev) => !prev);
    }

    function handleStudentDialogClose() {
        setStudentPhotoObject({});
        studentFormMethods.reset(INITIAL_STUDENT_FORM);
        setStudentDialogOpen(false);
    }

    function handleTutorDialogClose() {
        setTutorPhotoObject({});
        tutorFormMethods.reset(INITIAL_TUTOR_FORM);
        setTutorDialogOpen(false);
    }

    function addStudentOnClick(data) {
        let studentToBeUpdated = Object.assign({}, data, { photo: studentPhotoObject });
        let formData = new FormData();

        if (studentPhotoObject && studentPhotoObject.file) {
            formData.append("studentPhoto", studentPhotoObject.file);
        }
        formData.append("student", JSON.stringify(data));
        formData.append("username", CURRENT_PROFILE_USERNAME);
        addStudentProfile({ student: studentToBeUpdated, studentInForm: formData });
        setStudentPhotoObject({});
        studentFormMethods.reset(INITIAL_STUDENT_FORM);
        setStudentDialogOpen(false);
    }

    function addTutorOnClick(data) {
        let tutorToBeUpdated = Object.assign({}, data, { photo: tutorPhotoObject });
        let formData = new FormData();
        if (tutorPhotoObject && tutorPhotoObject.file) {
            formData.append("tutorPhoto", tutorPhotoObject.file);
        }
        formData.append("tutor", JSON.stringify(data));
        addTutorProfile({ tutor: tutorToBeUpdated, tutorInForm: formData });
        setTutorPhotoObject({});
        tutorFormMethods.reset(INITIAL_TUTOR_FORM);
        setTutorDialogOpen(false);
    }

    React.useEffect(() => {
        let username = AUTH_STATE.username;

        if (LOCATION.state) {
            let state = LOCATION.state;
            if (state.username) {
                username = LOCATION_USERNAME;
            }
        }
        if (username != CURRENT_PROFILE_USERNAME) {
            getStudentProfile({ username: username });
            getTutorProfile({ username: username });
        }
    }, [LOCATION_USERNAME]);

    React.useEffect(() => {
        if (TUTOR_PROFILES && TUTOR_PROFILES.length == 0
            && STUDENT_PROFILES && STUDENT_PROFILES.length == 0
            && !LOCATION_USERNAME) {
            setIsPanelsOpen({
                profile: true,
                account: false,
                password: false
            });
        }
    }, [TUTOR_PROFILES, STUDENT_PROFILES]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container justifyContent="center">
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container item  xs={12} md={11} lg={10} xl={8}>
                        <ContainerRow container item xs={12} md={12}>
                            <AccordionPanel expanded={isPanelsOpen["account"]} onChange={() => {
                                setIsPanelsOpen((prev) => {
                                    return Object.assign({}, prev, { "account": !prev["account"] })
                                });
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="account_information_panel-content"
                                    id="account_information_panel-header"
                                >
                                    <Title variant="h6" id="tableTitle" component="div">
                                        {t("account:section_title-account_info")}
                                    </Title>
                                </AccordionSummary>
                                <AccountInformation />
                            </AccordionPanel>
                        </ContainerRow>
                        <Can I="update" this={subject("user", { username: CURRENT_PROFILE_USERNAME })} field="password" ability={ABILITY}>
                            <ContainerRow container item xs={12} md={12} >
                                <AccordionPanel expanded={isPanelsOpen["password"]} onChange={() => {
                                    setIsPanelsOpen((prev) => {
                                        return Object.assign({}, prev, { "password": !prev["password"] })
                                    })
                                }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="password_form-content"
                                        id="password_form-header"
                                    >
                                        <Title variant="h6" id="tableTitle" component="div">
                                            {t("account:section_title-password")}
                                        </Title>
                                    </AccordionSummary>
                                    <PasswordForm />
                                </AccordionPanel>
                            </ContainerRow>
                        </Can>
                        <ContainerRow container item xs={12} md={12}>
                            <AccordionPanel expanded={isPanelsOpen["profile"]} onChange={() => {
                                setIsPanelsOpen((prev) => {
                                    return Object.assign({}, prev, { "profile": !prev["profile"] })
                                })
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="profile_panel-content"
                                    id="profile_panel-header"
                                >
                                    <Title variant="h6" id="tableTitle" component="div">
                                        {t("account:section_title-profiles")}
                                    </Title>
                                </AccordionSummary>
                                <Row container item xs={12} md={12}>
                                    <FormProvider {...studentFormMethods}>
                                        <StudentProfiles
                                            studentDialogOpen={studentDialogOpen} handleStudentDialogClose={handleStudentDialogClose}
                                            addStudentOnClick={addStudentOnClick} studentPhotoObject={studentPhotoObject}
                                            setStudentPhotoObject={setStudentPhotoObject}
                                        />
                                    </FormProvider>
                                    <FormProvider {...tutorFormMethods}>
                                        <TutorProfiles
                                            tutorDialogOpen={tutorDialogOpen} handleTutorDialogClose={handleTutorDialogClose}
                                            addTutorOnClick={addTutorOnClick} tutorPhotoObject={tutorPhotoObject}
                                            setTutorPhotoObject={setTutorPhotoObject} />
                                    </FormProvider>
                                </Row>
                                <Row container item justifyContent="center" md={12}>
                                    <Grid container item justifyContent="right" sx={{ display: { xs: 'none', md: "flex" }, marginTop: 2 }} md={5}>
                                        <AddButton data-cy="btn_add_student" onClick={toggleStudentDialog} fullWidth variant="contained" color="primary">
                                            {t("general:button-add_entity", { entity: t("attribute:student_profile") })}
                                        </AddButton>
                                    </Grid>
                                    <Grid container item justifyContent="center" sx={{ display: { md: 'none' }, marginTop: 3 }} xs={12} >
                                        <AddButton data-cy="btn_add_student_sm" onClick={toggleStudentDialog} fullWidth variant="contained" color="primary">
                                            {t("general:button-add_entity", { entity: t("attribute:student_profile") })}
                                        </AddButton>
                                    </Grid>
                                    <Grid container item xs={0} md={1}></Grid>
                                    <Grid container item justifyContent="left" sx={{ display: { xs: 'none', md: "flex" }, marginTop: 2 }} md={5}>
                                        <AddButton data-cy="btn_add_tutor" onClick={toggleTutorDialog} fullWidth variant="contained" color="primary">
                                            {t("general:button-add_entity", { entity: t("attribute:tutor_profile") })}
                                        </AddButton>
                                    </Grid>
                                    <Grid container item justifyContent="center" sx={{ display: { md: 'none' }, marginTop: 3 }} xs={12} >
                                        <AddButton data-cy="btn_add_tutor_sm" onClick={toggleTutorDialog} fullWidth variant="contained" color="primary">
                                            {t("general:button-add_entity", { entity: t("attribute:tutor_profile") })}
                                        </AddButton>
                                    </Grid>
                                </Row>
                            </AccordionPanel>
                        </ContainerRow>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
        </React.Suspense>
    );
}
