import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: {
      "en-GB": ["en"],
      "en-US": ["en"],
      "zh": ["zh-HK"],
      "zh-CN": ["zh-HK"],
      "zh-TW": ["zh-HK"]
    },
    supportedLngs: ["en", "zh-HK", "en-GB", "en-US", "zh", "zh-CN", "zh-TW"],
    debug: false,
    ns: [
      "attribute", "general", "menu", "settings",
      "class", "verification", "login", "account",
      "centre", "role", "enrolment", "user","subscription",
      "facility", "tutor", "student","status","session","bill","payslip","plan"],
    defaultNS: 'attribute',
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;
