import { useAuthenticationState } from "./../../redux/authentication";


export default function WithoutSelectedEnrolmentOnly({ children}) {
    const AUTH_STATE = useAuthenticationState();
    const IS_WITHOUT_ROLE = !AUTH_STATE.currentRole.roleId;
    if (IS_WITHOUT_ROLE) {
            return children;
    }
    return null;
}
