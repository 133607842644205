import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { changeLanguage, useI18NControlState } from "./../../redux/I18NControl";

const supportedLanguage = [{
    countryCode: "GB",
    label: "English",
    displayText: "ENG",
    key: "en",
    value: "en"
}, {
    countryCode: "HK",
    label: "Chinese",
    displayText: "中文",
    key: "zh-HK",
    value: "zh-HK"
}];

const ItemText = styled('span')(({ theme }) => ({
    fontSize: "12px",
}));


function LanguageSwitcher({ disableContrast, handleDrawerClose }) {
    const LANG = useI18NControlState().lang;
    const handleChange = event => {
        if (handleDrawerClose)
            handleDrawerClose();
        changeLanguage({ lang: event.target.value });
    };
    if (disableContrast) {
        return (
            <div sx={{ "marginRight": "5px" }}>
                <Select sx={{
                    fontSize: "12px"
                }}
                    variant="standard" inputProps={{
                        name: 'lang',
                        id: 'language-switcher',
                    }} value={LANG} onChange={handleChange}>
                    {supportedLanguage.map((lang) => {
                        return (<MenuItem value={lang.value} key={lang.key}>
                            <ItemText>{lang.displayText}</ItemText>
                        </MenuItem>
                        );
                    })}
                </Select>
            </div>
        );
    } else {
        return (
            <div sx={{
                color: (theme) => theme.palette.primary.contrastText,
                "marginRight": "5px"
            }}>
                <Select sx={{
                    fontSize: "12px",
                    marginLeft: { xs: "5px" },
                    color: (theme) => theme.palette.primary.contrastText
                }} variant="standard" inputProps={{
                    name: 'lang',
                    id: 'language-switcher',
                }} value={LANG} onChange={handleChange}>
                    {supportedLanguage.map((lang) => {
                        return (<MenuItem value={lang.value} key={lang.key}>
                            <ItemText>{lang.displayText}</ItemText>
                        </MenuItem>
                        );
                    })}
                </Select>
            </div >
        );
    }

}

export default LanguageSwitcher;  