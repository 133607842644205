import React from 'react';
import { useTranslation } from "react-i18next";
import {
	Grid, Button,
	Dialog, DialogTitle, DialogContent, DialogActions,
	TextField
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DialogFixedForm } from "./../../../common/Forms";
import { SuccessButton } from "./../../../common/Buttons";
import { AdminFormRow } from "./../../../common/Containers";

const FIELD_NAME = "name";
export default function CentreDataFieldDialog({ title, submitMethod, isDialogOpen, handleCloseDialog, formMethod }) {

	const { t } = useTranslation();
	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={isDialogOpen}
			onClose={handleCloseDialog}
			scroll="paper"
			aria-labelledby="administrator-dialog-title"
			aria-describedby="administrator-dialog-description"
		>
			<DialogFixedForm
				onSubmit={formMethod.handleSubmit(submitMethod)} noValidate>
				<DialogTitle id="administrator-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent dividers>
					<AdminFormRow justifyContent="center" alignItems="center" container >
						<Controller
							name={FIELD_NAME}
							defaultValue={''}
							control={formMethod.control}
							render={
								({ field }) => <TextField
									{...field}
									fullWidth
									variant="outlined"
									margin="normal"
									label={t("attribute:centre-field-name")}
									helperText={formMethod.formState.errors[FIELD_NAME] ? t(formMethod.formState.errors[FIELD_NAME].message) : null}
									error={formMethod.formState.errors[FIELD_NAME] ? true : false}
									type="string"
								/>
							}
						/>
					</AdminFormRow>
				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="space-between">
						<Button onClick={handleCloseDialog} variant="outlined" autoFocus>
							{t("general:button-cancel")}
						</Button>
						<SuccessButton type="onSubmit" variant="contained" >
							{t("general:button-save")}
						</SuccessButton>
					</Grid>
				</DialogActions>
			</DialogFixedForm>
		</Dialog>

	);
}
