import React from "react";
import {
    Grid, Button,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";

import { FormProvider, useForm, useFormState } from "react-hook-form";
import { dirtyValuesFromAllValues } from "./../../../../util/FormUtils";
import { useTranslation } from "react-i18next";
import { DialogFixedForm } from "./../../../common/Forms";
import ClassSessionProgressFormFieldList from "./../../../form/classComponent/ClassSessionProgressFormFieldList";
import { createClassSesssionProgressFormSchema } from "./../../../../component/form/model/ClassFormModel";
import { saveClassSession } from "./../../../../redux/ClassSessionManagement";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ClassSessionProgressDialog({
    title, currentSession, currentClassId, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config"]);
    const CLASS_FORM_METHOD = useForm({
        resolver: yupResolver(createClassSesssionProgressFormSchema())
    });

    const { dirtyFields } = useFormState({
        control: CLASS_FORM_METHOD.control
    });


    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const submitMethod = (data) => {
        saveClassSessionHandler(data);
    }

    const saveClassSessionHandler = (data) => {
        saveClassSession({
            classSession: Object.assign({}, {
                _id: data._id,
                classId: data.classId
            }, dirtyValuesFromAllValues(dirtyFields, data))
        });
        setIsDialogOpen(false);
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <FormProvider {...CLASS_FORM_METHOD} >
                <DialogFixedForm control={CLASS_FORM_METHOD.control}
                    errors={CLASS_FORM_METHOD.errors}
                    onSubmit={CLASS_FORM_METHOD.handleSubmit(submitMethod)}>
                    <DialogTitle id="responsive-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ClassSessionProgressFormFieldList currentSession={currentSession} />
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button autoFocus onClick={closeDialog} variant="outlined">
                                {t("general:button-cancel")}
                            </Button>
                            <Button type="submit" variant="contained" >
                                {t("general:button-save")}
                            </Button>
                        </Grid>
                    </DialogActions>
                </DialogFixedForm>
            </FormProvider>
        </Dialog >
    )
}