import { createReduxModule } from "hooks-for-redux"
import { getUsersRequest } from "./../api/accountAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";

function initialState() {
    return {
        updateAction: [],
        users: [],
        userCount: 0,
        getUserCriteria: {},
        request: initalRequestState()
    }
}

export const UPDATE_ACTION = {
    "GET_USERS": "GET_USERS",
};

export const [useUserManagementState, {
    setUpdating,
    getUsers, getUsersFail, getUsersSuccess
},
    userManagementState
] = createReduxModule(
    "userManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getUsers: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_USERS),
        getUserCriteria: payload
    }),
    getUsersFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getUsersSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        users: payload.user,
        userCount: payload.userCount,
    })
});

const UPDATE_ACTION_HANDLER = {
    "GET_USERS": {
        handler: async (currentState) => {
            var result = await getUsersRequest(currentState.getUserCriteria);
            if (result) {
                if (result.success) {
                    let users = [];
                    let userCount = 0;
                    if (result.data) {
                        users = result.data.users;
                        userCount = result.data.count;
                    }
                    getUsersSuccess({
                        "success": true,
                        "user": users,
                        "userCount": userCount,
                        "message": result.message
                    });
                } else {
                    getUsersFail({
                        "message": "getUsers request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getUsersFail
    }
}

userManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});