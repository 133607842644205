
function convertOptionsForAutoComplete(options) {

    let result = [];
    for(let option of options){
        result = result.concat(option.options);
    }
    return result;
}

export {  convertOptionsForAutoComplete };