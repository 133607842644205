import React from "react";
import GeneralPendingDialog from "./../../../common/GeneralPendingDialog";
import StudentCard from "./../../../page/student/StudentCard";
import ContextualMenu from "./../../../../component/common/ContextualMenu";
import { useTranslation } from "react-i18next";
import { ContextualMenuItem } from "./../../../../component/common/Buttons";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from '@mui/icons-material/Cancel';
import { useClassesManagementState, acceptStudentEnrolment, removeStudentEnrolment } from "./../../../../redux/ClassesManagement";

export default function ClassPendingStudentDialog({
    title, isDialogOpen, setIsDialogOpen }) {

    const [selectedStudent, setSelectedStudent] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const PENDING_STUDENTS = useClassesManagementState().classPendingStudents || [];

    const openNewTab = (event, currentStudent) => {
        window.open(`/student/${currentStudent.roleEnrolmentId}`, "_blank");
    }

    const triggerContextualMenu = (event, selectedStudent) => {
        setSelectedStudent(selectedStudent);
        setAnchorEl(event.currentTarget);
    }

    const acceptStudent = (event) => {
        setAnchorEl(null);
        acceptStudentEnrolment({ classId: selectedStudent.classId, roleEnrolmentId: selectedStudent.roleEnrolmentId });
    }

    const rejectStudent = (event) => {
        setAnchorEl(null);
        removeStudentEnrolment({ classId: selectedStudent.classId, roleEnrolmentId: selectedStudent.roleEnrolmentId });
    }
    return (
        <React.Fragment>
            <GeneralPendingDialog title={title} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen}>
                {
                    PENDING_STUDENTS.map((currentStudent) =>
                        <StudentCard
                            key={"class_card_" + currentStudent._id}
                            cardOnClick={openNewTab}
                            currentStudent={currentStudent.studentProfile}
                            moreButtonOnClick={triggerContextualMenu}
                        />)
                }
            </GeneralPendingDialog>
            <ContextualMenu id={"admin-menu"} anchorEl={anchorEl} setAnchorEl={setAnchorEl} >
                <ContextualMenuItem
                    onClick={acceptStudent}
                    title={t("general:button-accept")}>
                    <CheckCircleOutlineIcon sx={{ mr: 0 }} fontSize="small" />
                </ContextualMenuItem>
                <ContextualMenuItem
                    onClick={rejectStudent}
                    title={t("general:button-delete")}>
                    <CancelIcon sx={{ mr: 0 }} fontSize="small" />
                </ContextualMenuItem>
            </ContextualMenu>
        </React.Fragment >
    )
}