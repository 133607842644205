import React from 'react';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
    Paper, Grid,
    InputAdornment,
    IconButton, TextField
} from "@mui/material";
import { useUserManagementState, getUsers } from "./../../redux/UserManagement";
import LoadingPage from "./LoadingPage";
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SpaceHolder } from "./../../component/common/Others";
import ContextualMenu from "./../../component/common/ContextualMenu";
import { ContextualMenuItem } from "./../../component/common/Buttons";
import { DashboardRootContainer } from "./../../component/common/Containers";
import GeneralHeader from "./../../component/common/GeneralHeader";


const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 900
}));

export default function UserManagement() {
    const { t } = useTranslation();
    const rows = useUserManagementState().users;
    const userCount = useUserManagementState().userCount;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [searchString, setSearchString] = React.useState(null);
    const [selected, setSelected] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const debouncedGetUsers = React.useMemo(() => debounce(getUsers, 50), []);

   React.useEffect(() => {
        debouncedGetUsers({ search: searchString, page: page, rowsPerPage: rowsPerPage });
    }, []);

    React.useEffect(() => {
        debouncedGetUsers({ search: searchString, page: page, rowsPerPage: rowsPerPage });
    }, [page, searchString, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        event.preventDefault();
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleOnClick = (event, value) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelected(value);
    }

    const searchUser = (event) => {
        event.preventDefault();
        setSearchString(event.target.value);
    };

    const triggerEditUser = () => {
        setAnchorEl(null);
        navigate("/account", { state: selected });
    }

    const searchFieldOnChange = React.useMemo(() => debounce(searchUser, 300), []);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <GeneralHeader title={t("user:page_title")} />
                        <Grid sx={{ marginTop: 3, marginBottom: 0 }} alignItems="center" container item md={12}>
                            <TextField
                                id="outlined-start-adornment"
                                fullWidth
                                onChange={(event) => { searchFieldOnChange(event) }}
                                placeholder={t("general:label-search")}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Row container item md={12}>
                            <TableContainer sx={{ borderRadius: "10px", }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow  >
                                            <BoldTableCell >{t("attribute:user-email_address")}</BoldTableCell>
                                            <BoldTableCell >{t("attribute:user-display_name")}</BoldTableCell>
                                            <BoldTableCell >{t("attribute:user-mobile_number")}</BoldTableCell>
                                            <BoldTableCell align="right"></BoldTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <React.Suspense fallback={<LoadingPage />}>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.username}>
                                                    <TableCell component="th" scope="row">
                                                        {row.username}
                                                    </TableCell>
                                                    <TableCell >{row.displayName}</TableCell>
                                                    <TableCell >{row.phone}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton onClick={(event) => { handleOnClick(event, row) }} aria-label="delete">
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </React.Suspense>
                                </Table>
                                <TablePagination
                                    labelRowsPerPage={t("general:label-rows_per_page")}
                                    rowsPerPageOptions={[1, 5, 10, 25]}
                                    component="div"
                                    count={userCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                            <ContextualMenu id="user-menu"
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            >
                                <ContextualMenuItem onClick={triggerEditUser} title={t("general:button-edit")} >
                                    <EditIcon sx={{ mr: 0 }} fontSize="small" />
                                </ContextualMenuItem>
                            </ContextualMenu>
                        </Row>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
        </React.Suspense >
    );
}

