import React from "react";
import {
    Grid, Stack,
    Card, CardContent, CardActionArea
} from "@mui/material";
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
import LimitedTextLabel from "./../../../component/display/LimitedTextLabel";
import useMediaQuery from '@mui/material/useMediaQuery';

const CentreLogo = styled("img")(({ theme }) => ({
    borderRadius: "10px",
    width: "100px",
    height: "100px"
}));

export default function CentreCard({ currentCentre }) {

    const navigate = useNavigate();

    let CENTRE_PROFILE_PIC = (
        <Grid sx={{ minWidth: "100px", width: "100px", height: "100px" }} container alignItems="center" justifyContent="center">
            <SchoolRoundedIcon color="disabled" fontSize="large" />
        </Grid>
    );

    if (currentCentre.logo) {
        CENTRE_PROFILE_PIC = (<CentreLogo
            src={currentCentre.logo}
            alt="centreProfile"
        />);

    }

    const forwardToDetailPage = (event) => {
        event.preventDefault();
        navigate("/centre/" + currentCentre._id);
    }

    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));

    let cardContentStyle = { paddingLeft: 2 };
    let rootStackSpacing = 1.3;
    let subStackSpacing = 0.7;
    let displayNameStyle = { fontWeight: "bold", paddingLeft: 0.5 };
    let contentSpacing = 1
    let contentVariant = "body2";
    if (smDown) {
        cardContentStyle = { paddingLeft: 1.5, paddingRight: 1.5 };
        rootStackSpacing = 1;
        subStackSpacing = 0.5;
        displayNameStyle = { paddingLeft: 0.5 }
        contentSpacing = 0.7;
        contentVariant = "caption";
    }

    return (
        <React.Fragment>
            <Card sx={{ display: { md: "block" }, width: "100%", marginTop: 0, marginBottom: 2, borderRadius: '10px' }}>
                <CardActionArea onClick={forwardToDetailPage} >
                    <CardContent sx={cardContentStyle} >
                        <Stack direction="row" spacing={rootStackSpacing} >
                            {CENTRE_PROFILE_PIC}
                            <Stack sx={{ width: "100%" }} alignItems="flex-start" justifyContent="center" spacing={subStackSpacing} >
                                <LimitedTextLabel component="div" variant="h6" customstyle={displayNameStyle}>
                                    {currentCentre.displayName}
                                </LimitedTextLabel>
                                {currentCentre.address ?
                                    (<Stack direction="row" spacing={contentSpacing}>
                                        <LocationOnIcon color="disabled" fontSize="small" />
                                        <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                            {currentCentre.address}
                                        </LimitedTextLabel>
                                    </Stack>) : null
                                }
                                {currentCentre.description ?
                                    (<Stack direction="row" spacing={contentSpacing}>
                                        <InfoIcon color="disabled" fontSize="small" />
                                        <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                            {currentCentre.description}
                                        </LimitedTextLabel>
                                    </Stack>) : null}
                            </Stack>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}
