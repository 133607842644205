import React from "react";
import { FormProvider, useForm, useFieldArray, Controller, useFormContext, useWatch} from "react-hook-form";
import {
    Grid, Button, IconButton,Stack, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce';
import { DialogFixedForm } from "../../../common/Forms";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SectionLabel from "./../../../display/styled/SectionLabel";
import { nameInBracketStyle } from "../../../../util/DisplayUtil";

import { initClassBillForm, CLASS_BILL_FIELD, createClassBillFormSchema } from "../../../form/model/ClassBillFormModel";
import { getClassInfo, useClassesManagementState} from "../../../../redux/ClassesManagement";
import { addBillToClass, updateStudentBill } from "../../../../redux/StudentBillManagement";

import { yupResolver } from "@hookform/resolvers/yup";

function totalCal(results) {
  let totalValue = 0;

  if (results) {
    for (var idx=0; idx<results.length; idx++) {
        var result = results[idx].amount;
        if (typeof result === "string") {
            const output = parseInt(result, 10);
            totalValue = totalValue + (Number.isNaN(output) ? 0 : output);
        } else {
            totalValue = totalValue + result;
        }
    }
  }
  return totalValue;
}

const Calc = ({  arrayField, totalField }) => {
  const {control,setValue} = useFormContext();
  const results = useWatch({ control, name: arrayField });
  const output = totalCal(results);

  setValue(totalField, output);

  return "$ " + output;
};

const StyledTextField = styled(TextField)(({ theme }) => ({
        "marginTop": theme.spacing(1),
        "paddingBottom": theme.spacing(1),
    }));

function DynamicFieldItem({ index, item, handleAmountChange, deleteListItem }) {
  const { t } = useTranslation(["general", "config", "attribute", "payslip"]);
  const BILL_FORM_METHOD = useFormContext();
  const { errors } = BILL_FORM_METHOD.formState;

  let nameForAmounts = `${CLASS_BILL_FIELD.billDetails}[${index}].amount`;
  let nameForDesc = `${CLASS_BILL_FIELD.billDetails}[${index}].desc`;

  return (
    <Stack sx={{width:"100%"}} spacing={1} key={index} direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          <Controller
            name={nameForDesc}
            control={BILL_FORM_METHOD.control}
            render={({ field }) => (
              <StyledTextField
                variant="outlined"
                fullWidth
                label={t('attribute:item-description')}
                helperText={
                  errors[nameForDesc]
                    ? t(errors[nameForDesc].message)
                    : null
                }
                error={errors[nameForDesc] != null}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={nameForAmounts}
            control={BILL_FORM_METHOD.control}
            render={({ field }) => (
              <StyledTextField
                variant="outlined"
                fullWidth
                label={t('attribute:item-amount')}
                type="number"
                helperText={
                  errors[nameForAmounts]
                    ? t(errors[nameForAmounts].message)
                    : null
                }
                error={errors[nameForAmounts] != null}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          <IconButton onClick={event => deleteListItem(event)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Grid>
    </Stack>
  );
}

export default function ClassBillDialog({currentStudent, currentBill, currentClassId, isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation(["general", "config", "bill"]);

    const deouncedGetClassInfo = React.useMemo(() => debounce(getClassInfo, 50), []);
    let { classId } = useParams();
    if (currentClassId) {
        classId = currentClassId;
    }

    let billTo = t("bill:label-all_students");
    
    if(currentStudent){
        let profile = currentStudent.profile;
        if(profile){
            billTo = nameInBracketStyle(profile.chiName,profile.engName);    
        }
    }

    React.useEffect(() => {
        if (classId) {
            deouncedGetClassInfo({ classId: classId });
        }
    }, []);

    const CLASS_INFO = useClassesManagementState().currentClass;
    const BILL_FORM_METHOD = useForm({
        resolver: yupResolver(createClassBillFormSchema())
    });
    const { errors } = BILL_FORM_METHOD.formState;
    const { fields, append, remove } = useFieldArray({name: CLASS_BILL_FIELD.billDetails, control: BILL_FORM_METHOD.control});
    let title= t("bill:dialog_title-issue_bill_for_entity",{entity:CLASS_INFO.name});
    if(currentBill){
        title = t("bill:dialog_title-edit_bill_for_entity",{"entity":CLASS_INFO.name});
    }

    React.useEffect(() => {
        if (classId) {
            deouncedGetClassInfo({ classId: classId });
        }
        if (currentBill) {
            BILL_FORM_METHOD.reset(currentBill);
        } else {
            BILL_FORM_METHOD.reset(initClassBillForm(CLASS_INFO,t));
        }
    }, [classId,isDialogOpen])

    const addBillDetails = () => {
        let newDetail = { desc: '', amount: 0 };
        append(newDetail);
    }

    const delBillDetails = (index) => {
        remove(index);
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    const submitMethod = (data) => {
        if (currentBill) {
            editClassBill(data);
        } else {
            data.studentId = "all";
            if(currentStudent){
                data.studentId = currentStudent._id;
            }
            addClassBill(data);
        }
    }

    const addClassBill = (data) => {
        data.classId = classId;
        data.centreId = CLASS_INFO.centreId;
        addBillToClass(data);
        setIsDialogOpen(false);
    }

    const editClassBill = (data) => {
        data.classId = classId;
        data.centreId = CLASS_INFO.centreId;
        data._id = currentBill._id;
        updateStudentBill(data);
        setIsDialogOpen(false);
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
            scroll="paper"
            maxWidth="md"
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogFixedForm onSubmit={BILL_FORM_METHOD.handleSubmit(submitMethod)} noValidate>
                    <DialogTitle id="responsive-dialog-title">
                        <Stack direction="row" sx={{paddingLeft:3, paddingRight:3}} >
                        {title}
                        </Stack>
                    </DialogTitle>
                    <DialogContent dividers>
                    <Stack sx={{paddingTop:2, paddingLeft:3, paddingRight:3, paddingBottom:2}} spacing={3}>
                        <Stack alignItems="center" direction="row" justifyContent="space-between">
                            <Grid item xs={3}>
                                <Typography variant="body1"color="textSecondary" >{t("attribute:bill-to")}</Typography>
                            </Grid>
                            <Grid item xs={9}>{billTo}</Grid>
                        </Stack>
                        <FormProvider {...BILL_FORM_METHOD}>
                            <Stack alignItems="center" direction="row" justifyContent="space-between">
                                <Grid item xs={3}>
                                    <Typography variant="body1" color="textSecondary" >{t("bill:label-due_on")}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                        name={CLASS_BILL_FIELD.dueDate}
                                        control={BILL_FORM_METHOD.control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                fullWidth
                                                openTo="day"
                                                views={["year", "month", "day"]}
                                                id={CLASS_BILL_FIELD.dueDate}
                                                format="DD/MM/yyyy"
                                                label={t("attribute:bill-due_date")}
                                                onChange={(newValue) => {
                                                    BILL_FORM_METHOD.setValue(CLASS_BILL_FIELD.dueDate, newValue);
                                                }}
                                                renderInput={(params) => <StyledTextField {...params} fullWidth
                                                    helperText={errors[CLASS_BILL_FIELD.dueDate] ? t(errors[CLASS_BILL_FIELD.dueDate].message) : null}
                                                    error={errors[CLASS_BILL_FIELD.dueDate] != null}
                                                />}

                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            
                            <SectionLabel>{t("attribute:bill-items")}</SectionLabel>

                            <Stack>
                            {fields.map((item, index) => (
                                <DynamicFieldItem
                                    item={item}
                                    key={CLASS_BILL_FIELD.billDetails + index}
                                    index={index}
                                    t={t}
                                    handleAmountChange={() => { }}
                                    deleteListItem={() => { delBillDetails(index); }}
                                />
                            ))}
                            </Stack>
                            

                            <Grid container direction="row" justifyContent="space-between">
                                <Button autoFocus fullWidth onClick={addBillDetails} variant="contained">
                                    {t("general:button-add_item")}
                                </Button>
                            </Grid>

                            <Stack sx={{"paddingTop":3}} direction="row" justifyContent="space-between">
                                <Grid item xs={10}>
                                    <Typography variant="h6">
                                        {t("attribute:bill-amount-total")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={2} justifyContent="flex-end">
                                    <Calc
                                        arrayField={CLASS_BILL_FIELD.billDetails}
                                        totalField={CLASS_BILL_FIELD.billAmount} />
                                </Grid>
                            </Stack>
                        </FormProvider>
                    </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button autoFocus onClick={closeDialog} variant="outlined">
                                {t("general:button-cancel")}
                            </Button>
                            <Button type="submit" variant="contained" >
                                {currentBill ? (
                                    t("general:button-save")
                                ):
                                    t("general:button-issue")
                                }
                            </Button>
                        </Grid>
                    </DialogActions>
            </DialogFixedForm>
        </Dialog >
    )
}