import React from "react";
import {
    Grid
} from "@mui/material";
import debounce from 'lodash.debounce';
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LoadingPage from "./LoadingPage";
import { SpaceHolder } from "./../../component/common/Others";
import { DashboardRootContainer, TabPanel } from "./../../component/common/Containers";
import TutorAboutDetailPage from "./tutor/TutorAboutDetailPage";
import TutorClassPage from "./tutor/TutorClassPage";
import TutorSchedulePage from "./tutor/TutorSchedulePage";
import TutorPageHeader from "./tutor/header/TutorPageHeader";
import TutorPayslipPage from "./tutor/TutorPayslipPage";
import { getTutorProfileInfo, useProfileState, UPDATE_ACTION as PROFILE_ACTION } from "./../../redux/ProfileManagement";
import { useEnrolmentState } from "./../../redux/Enrolment";
import { pushRedirect } from "./../../redux/Redirect";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));


const TAB_ELEMENTS = [
    { "tab_element": (<TutorAboutDetailPage />), "tab_index": "about", "tab_label": "general:tab_name-about" },
    { "tab_element": (<TutorClassPage />), "tab_index": "class", "tab_label": "general:tab_name-classes" },
    { "tab_element": (<TutorSchedulePage />), "tab_index": "schedule", "tab_label": "general:tab_name-schedule" },
    { "tab_element": (<TutorPayslipPage />), "tab_index": "payslip", "tab_label": "general:tab_name-payslips" },
]


export default function TutorDetail() {

    let { enrolmentId } = useParams();
    let [searchParams] = useSearchParams();

    const SEARCH_PARAM_TAB = searchParams.get("tab") || "about";
    const [value, setValue] = React.useState(SEARCH_PARAM_TAB);
    const PROFILE_REQUEST = useProfileState().request;
    const ENROLMENT_REQUEST = useEnrolmentState().request;
    const debouncedGetTutorProfileInfo = React.useMemo(() => debounce(getTutorProfileInfo, 50), []);

    const STATUS_CHANGE_REQUEST = {
        "ACCEPT_TUTOR_ENROLMENT": true,
        "REJECT_TUTOR_ENROLMENT": true,
    }
    React.useEffect(() => {
        setValue(SEARCH_PARAM_TAB)
    }, [SEARCH_PARAM_TAB]);

    React.useEffect(() => {
        if (enrolmentId) {
            debouncedGetTutorProfileInfo({ profileId: enrolmentId });
        }
    }, []);

    React.useEffect(() => {
        if (enrolmentId) {
            debouncedGetTutorProfileInfo({ profileId: enrolmentId });
        }
    }, [enrolmentId]);

    React.useEffect(() => {
        let IS_REQUESTED_PROFILE_INFO_NULL_OR_INVACTIVE =
            PROFILE_REQUEST.success == true &&
            PROFILE_REQUEST.lastAction == PROFILE_ACTION.GET_TUTOR_PROFILE_INFO
            && PROFILE_REQUEST.status == "REQUEST_OBJECT_NOT_FOUND";
        if (IS_REQUESTED_PROFILE_INFO_NULL_OR_INVACTIVE) {
            pushRedirect({ path: "/" });
        }
    }, [PROFILE_REQUEST]);

    React.useEffect(() => {
        let IS_STATUS_CHANGE_REQUEST = STATUS_CHANGE_REQUEST[ENROLMENT_REQUEST.lastAction];

        if (IS_STATUS_CHANGE_REQUEST) {
            if (ENROLMENT_REQUEST.success && ENROLMENT_REQUEST.status == "REQUEST_OBJECT_UPDATED") {
                debouncedGetTutorProfileInfo({ profileId: enrolmentId });
            }
        }
    }, [ENROLMENT_REQUEST]);

    return (
        <React.Suspense fallback={<LoadingPage />}>
            <DashboardRootContainer>
                <Grid container>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                    <Grid container justifyContent="center" item xs={12} md={11} lg={10} xl={8}>
                        <Row container item xs={12}>
                            <TutorPageHeader TAB_ELEMENTS={TAB_ELEMENTS}
                                value={value} setValue={setValue} />
                        </Row>
                        <Grid sx={{ marginTop: 2 }} container item xs={12} md={12}>
                            {TAB_ELEMENTS.map(((element, index) => (
                                <TabPanel key={`element_${index}`} value={value} index={element.tab_index}>
                                    {element.tab_element}
                                </TabPanel>
                            )))
                            }
                        </Grid>
                    </Grid>
                    <SpaceHolder container item xs={0} md={0.5} lg={1} xl={2} />
                </Grid>
            </DashboardRootContainer>
        </React.Suspense>
    );
}
