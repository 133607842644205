import React from 'react';
import { Grid, Button, TextField, Paper, Typography, FormControl, FormHelperText, Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { INITIAL_USER_FORM, USER_DATA_FIELD, createUserRegistrationFormSchema } from "./model/UserFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOptionsState } from "./../../redux/Options";
import { submitSignUpUser } from "./../../redux/AccountManagement";
import DropdownItems from "./commonComponent/DropdownItems";
import TermsAndConditionsAccordion from "./commonComponent/TermsAndConditionsAccordion";
import SanitizeHtmlWrapper from "./../display/SanitizeHtmlWrapper";
import siteIcon from "./../../asset/img/site_icon.png";
import { SiteIcon, PageTitle } from "./../../component/common/Others";

const Title = styled(Typography)(({ theme }) => ({
    "textTransform": "uppercase",
    "marginBottom": "20px",
    "paddingTop": "30px",
    "textAlign": "center"
}));

const StyledForm = styled("form")(({ theme }) => ({
    width: '90%',
    "paddingLeft": '5%',
    "paddingRight": '5%',
    "marginBottom": "12px"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": "12px",
    "marginBottom": "12px",
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "marginTop": "12px",
    "marginBottom": "12px",
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    "fontWeight": "bold",
    "color": "#AAAAAA",
    "paddingTop": "20px"
}));

const StyledSubmit = styled(Button)(({ theme }) => ({
    "paddingTop": "12px",
    "paddingBottom": "12px",
    "marginTop": "30px",
    "marginBottom": "40px"
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    paddingLeft: theme.spacing(1)
}));

const CentreAlignedContainer = styled(Grid)(({ theme }) => ({
    display: "grid",
    placeItems: "center"
}));

export default function GeneralRegistrationForm({ isHidePassword }) {
    const { t } = useTranslation(["account", "attribute", "general", "config"]);

    const OPTIONS = { SYSTEM_LANGUAGE: useOptionsState().systemLanguage.options };
    const VALID_VALUES = { SYSTEM_LANGUAGE: useOptionsState().systemLanguage.validValues };
    const USER_FORM_SCHEMA = createUserRegistrationFormSchema(VALID_VALUES.SYSTEM_LANGUAGE);
    const REGISTRATION_FORM_METHOD = useForm({
        resolver: yupResolver(USER_FORM_SCHEMA),
        defaultValues: INITIAL_USER_FORM
    });
    const { formState: { errors } } = REGISTRATION_FORM_METHOD;

    function submitUserData(data) {
        submitSignUpUser({ user: data });
        window.location.reload();
    }

    return (
        <Paper elevation={10}>
            <CentreAlignedContainer>
                <SiteIcon alt="Company Logo" src={siteIcon} />
            </CentreAlignedContainer>
            <Title component="div">
                <PageTitle variant="h5">
                    {t("account:section_title-account_info")}
                </PageTitle>
            </Title>
            <FormProvider {...REGISTRATION_FORM_METHOD}>
                <StyledForm onSubmit={REGISTRATION_FORM_METHOD.handleSubmit(submitUserData)}>
                    <Controller
                        name={USER_DATA_FIELD.displayName}
                        control={REGISTRATION_FORM_METHOD.control}
                        render={
                            ({ field }) => <StyledTextField
                                variant="outlined" fullWidth
                                label={t("attribute:user-display_name")}
                                helperText={errors[USER_DATA_FIELD.displayName] ? t(errors[USER_DATA_FIELD.displayName].message) : null}
                                error={errors[USER_DATA_FIELD.displayName] != null}
                                {...field}
                            />
                        }
                    />
                    <Controller
                        name={USER_DATA_FIELD.phone}
                        control={REGISTRATION_FORM_METHOD.control}
                        render={
                            ({ field }) => <StyledTextField
                                variant="outlined" fullWidth
                                label={t("attribute:user-mobile_number")}
                                helperText={errors[USER_DATA_FIELD.phone] ? t(errors[USER_DATA_FIELD.phone].message) : null}
                                error={errors[USER_DATA_FIELD.phone] != null}
                                {...field}
                            />
                        }
                    />

                    <Controller
                        name={USER_DATA_FIELD.notificationLanguage}
                        control={REGISTRATION_FORM_METHOD.control}
                        render={
                            ({ field }) => <StyledFormControl fullWidth variant="outlined"
                                data-cy="notificationLanguageSelect"
                                error={errors[USER_DATA_FIELD.notificationLanguage] != null}>
                                <DropdownItems field={field}
                                    options={OPTIONS.SYSTEM_LANGUAGE}
                                    labelId="user_form_notificationLanguagelabel"
                                    labelText={t("attribute:user-notification_language")} />
                                {errors[USER_DATA_FIELD.notificationLanguage] != null &&
                                    <FormHelperText>{t(errors[USER_DATA_FIELD.notificationLanguage].message)}</FormHelperText>
                                }
                            </StyledFormControl>
                        }
                    />
                    <FormControl>
                        <StyledFormLabel>{t("account:section_title-notification_methods")}</StyledFormLabel>
                        <Controller
                            name={USER_DATA_FIELD.notificationMethod + ".email"}
                            control={REGISTRATION_FORM_METHOD.control}
                            render={({ field }) => (
                                <StyledFormControlLabel
                                    control={<Checkbox value="email" checked={field.value != null && field.value != false}
                                        onChange={(event) => {
                                            REGISTRATION_FORM_METHOD.setValue("notificationMethod", { email: true });
                                            field.onChange(event);
                                        }}
                                        name={USER_DATA_FIELD.notificationMethod + ".email"} />}
                                    label={t("account:checkbox-notification_methods-email")}
                                />
                            )}
                        />
                    </FormControl>

                    <TermsAndConditionsAccordion label={t("account:checkbox-agree_tc")} t={t}>
                        <SanitizeHtmlWrapper url={t("config:path-tc")} />
                    </TermsAndConditionsAccordion>
                    
                    <TermsAndConditionsAccordion name={USER_DATA_FIELD.agreePrivacy} label={t("account:checkbox-agree_privacy")} t={t}>
                        <SanitizeHtmlWrapper url={t("config:path-pp")} />
                    </TermsAndConditionsAccordion>


                    <StyledSubmit type="submit" fullWidth variant="contained" color="primary">
                        {t("general:button-submit")}
                    </StyledSubmit>
                </StyledForm>
            </FormProvider>
        </Paper>
    );
}