import {
	getLuminance,
} from '@mui/material/styles';


function getContrastColor(color) {
	if (color != null) {
		var result = (getLuminance(color) <= 0.5) ? "#fff" : "rgba(0, 0, 0, 0.87)";
		return result;
	}
	return color;
}

function getDefaultThemeColor() {
	return {
		primary: {
			shade: 100,
			theme: "grey"
		},
		secondary: {
			shade: 100,
			theme: "grey"
		}
	}
}

export { getContrastColor, getDefaultThemeColor };