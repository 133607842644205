import React from 'react';
import { useTranslation } from "react-i18next";
import { INIT_CLASS_SESSION, CLASS_SESSION_FIELD } from "./../model/ClassFormModel";
import { TextField } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";


const StyledTextField = styled(TextField)(({ theme }) => ({
    "marginTop": theme.spacing(1),
    "paddingBottom": theme.spacing(1),
}));

export default function ClassSessionProgressFormFieldList({ currentSession }) {

    const { reset } = useFormContext();
    const { control, formState: { errors } } = useFormContext();
    const { t } = useTranslation();
    React.useEffect(() => {
        if (currentSession) {
            reset(currentSession);
        } else {
            reset(INIT_CLASS_SESSION)
        }
    }, [currentSession])

    return (
        <React.Fragment>
            <Controller
                name={CLASS_SESSION_FIELD.progress}
                control={control}
                render={
                    ({ field }) => <StyledTextField
                        multiline variant="outlined" fullWidth
                        minRows="2"
                        label={t("attribute:session-progress")}
                        helperText={errors[CLASS_SESSION_FIELD.progress] != null ? t(errors[CLASS_SESSION_FIELD.progress].message) : null}
                        error={errors[CLASS_SESSION_FIELD.progress] != null}
                        {...field}
                    />
                }
            />
        </React.Fragment>
    );
}
