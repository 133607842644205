import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignInPage from './../page/SignInPage';
import ForgetPasswordPage from './../page/ForgetPasswordPage';
import ResetPasswordPage from './../page/ResetPasswordPage';

export default function Unauthenticated() {
    return (
            <Routes>
                <Route path='/login' element={ <SignInPage/> } />
                <Route path="/forgetpassword" element={ <ForgetPasswordPage/> } />
                <Route path="/resetPassword/:token" element={ <ResetPasswordPage/> } />
                <Route index path='/' element={ <SignInPage/> } />
                <Route path='*' element={ <SignInPage/> } />
            </Routes>       
    );
}