import React from 'react';
import { Button, Grid, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import PortraitUploader from "./../../form/commonComponent/PortraitUploader";
import { useProfileState } from "./../../../redux/ProfileManagement";
import { useFormContext } from "react-hook-form";
import StudentCards from "./StudentCards";
import StudentFieldList from "./../../form/studentComponent/StudentFieldList";
import { useTranslation } from "react-i18next";
import { SuccessButton } from "./../../common/Buttons";
import { DialogFixedForm } from "./../../common/Forms";

export default function StudentProfiles({ studentDialogOpen, handleStudentDialogClose,
  addStudentOnClick, studentPhotoObject, setStudentPhotoObject }) {
  const { t } = useTranslation(["account", "general", "attribute"]);
  const { handleSubmit } = useFormContext();
  const [needRenderStudent, setNeedRenderStudent] = React.useState(false);
  const STUDENT_LIST = useProfileState().studentProfiles;

  React.useEffect(() => {
    if (STUDENT_LIST && STUDENT_LIST.length > 0) {
      setNeedRenderStudent(true);
    } else {
      setNeedRenderStudent(false);
    }
  }, [STUDENT_LIST]);

  return (
    <React.Fragment>
      {
        needRenderStudent ? (<StudentCards />) : null
      }

      <Dialog
        open={studentDialogOpen}
        onClose={handleStudentDialogClose}
        fullWidth
        scroll="paper"
        aria-labelledby="student-profile-dialog-title"
      >
        <DialogFixedForm onSubmit={handleSubmit(addStudentOnClick)} noValidate>
          <DialogTitle id="student-profile-dialog-title">
            {t("general:dialog_title-add_entity", { entity: t("attribute:student_profile") })}
          </DialogTitle>
          <DialogContent dividers>
            <PortraitUploader labelText={t("attribute:student-profile_picture")} id="edit_dialog_uploader" photoObject={studentPhotoObject} setPhotoObject={setStudentPhotoObject} />
            <StudentFieldList></StudentFieldList>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Button variant="outlined" onClick={handleStudentDialogClose} color="primary">
                {t("general:button-cancel")}
              </Button>
              <SuccessButton data-cy="btn_add_student_submit" variant="contained" type="submit">
                {t("general:button-add")}
              </SuccessButton>
            </Grid>
          </DialogActions>
        </DialogFixedForm>
      </Dialog>
    </React.Fragment>)
}

