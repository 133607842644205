import { createReduxModule } from "hooks-for-redux"
import {
    getAdministratorEnrolmentRequest,
    sendAdministratorInvitationEmail,
    removeAdministratorRequest
} from "./../api/enrolmentAPI.tsx";
import {
    generalFailReudxResponse,
    pushActionToUpdateActionList,
    generalSuccessReduxResponse,
    generalUpdatingRequest,
    generalReduxRequestController,
    initalRequestState
} from "./../util/ReduxUtils";

function initialState() {
    return {
        updateAction: [],
        administratorEnrolment: [],
        administratorToBeInvited: "",
        administratorToBeRemoved: "",
        request: initalRequestState()
    }
}

export const UPDATE_ACTION = {
    "GET_ADMINISTRATOR_ENROLMENT": "GET_ADMINISTRATOR_ENROLMENT",
    "INVITE_ADMINISTRATOR": "INVITE_ADMINISTRATOR",
    "REMOVE_ADMINISTRATOR": "REMOVE_ADMINISTRATOR"
};

export const [usePlatformAdminManagementState, {
    setUpdating,
    getAdministratorEnrolment, getAdministratorEnrolmentFail, getAdministratorEnrolmentSuccess,
    inviteAdministrator, inviteAdministratorFail, inviteAdministratorSuccess,
    removeAdministrator, removeAdministratorFail, removeAdministratorSuccess,
},
    platformAdministratorManagementState
] = createReduxModule(
    "platformAdministratorManagementState",
    initialState(), {
    setUpdating: (state, payload) => Object.assign({}, state, {
        request: generalUpdatingRequest(state, payload.updating, payload.message, payload.error)
    }),
    getAdministratorEnrolment: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.GET_ADMINISTRATOR_ENROLMENT),
    }),
    getAdministratorEnrolmentFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    getAdministratorEnrolmentSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        administratorEnrolment: payload.data
    }),
    inviteAdministrator: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.INVITE_ADMINISTRATOR),
        "administratorToBeInvited": payload.email,
    }),
    inviteAdministratorFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    inviteAdministratorSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        administratorToBeInvited: "",
    }),
    removeAdministrator: (state, payload) => Object.assign({}, state, {
        updateAction: pushActionToUpdateActionList(state, UPDATE_ACTION.REMOVE_ADMINISTRATOR),
        "administratorToBeRemoved": payload.id
    }),
    removeAdministratorFail: (state, payload) => Object.assign({}, state, generalFailReudxResponse(state, payload)),
    removeAdministratorSuccess: (state, payload) => Object.assign({}, state, generalSuccessReduxResponse(state, payload), {
        administratorToBeRemoved: "",
    })
}
);

const UPDATE_ACTION_HANDLER = {
    "GET_ADMINISTRATOR_ENROLMENT": {
        handler: async (currentState) => {
            var result = await getAdministratorEnrolmentRequest();
            if (result) {
                if (result.success) {
                    getAdministratorEnrolmentSuccess({
                        "success": true,
                        "data": result.data,
                        "message": result.message
                    });
                } else {
                    getAdministratorEnrolmentFail({
                        "message": "getAdministratorEnrolment request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: getAdministratorEnrolmentFail
    }, "INVITE_ADMINISTRATOR": {
        handler: async (currentState) => {
            var result = await sendAdministratorInvitationEmail(currentState.administratorToBeInvited);
            if (result) {
                if (result.success) {
                    inviteAdministratorSuccess({
                        "success": true,
                        "message": ""
                    });
                } else {
                    inviteAdministratorFail({
                        "message": "inviteAdministrator request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: inviteAdministratorFail
    }, "REMOVE_ADMINISTRATOR": {
        handler: async (currentState) => {
            let result = await removeAdministratorRequest(currentState.administratorToBeRemoved);
            if (result) {
                if (result.success) {
                    removeAdministratorSuccess({
                        "success": true,
                        "message": ""
                    });
                } else {
                    removeAdministratorFail({
                        "message": "removeAdministrator request failed",
                        error: result.error
                    });
                }
            }
        },
        failHandler: removeAdministratorFail
    }
}

platformAdministratorManagementState.subscribe((currentState) => {
    generalReduxRequestController(currentState, UPDATE_ACTION_HANDLER, setUpdating)
});
