import React from 'react';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Grid,
    List, ListItem, ListItemText,
    Typography, IconButton
} from "@mui/material";

import CentreNotificationDialog from "./dialog/CentreNotificationDialog";
import ContextualMenu from "../../common/ContextualMenu";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TabTitleContainer } from "../../common/Containers";
import { useForm } from "react-hook-form";
import { createCentreNotificationFormSchema } from "../../form/model/CentreFormModel";
import { SpaceHolder } from "../../common/Others";
import { ContextualMenuItem } from "../../common/Buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    useCentreManagementState, getCentreNotification, updateCentreNotification, addCentreNotification
} from "../../../redux/CentreManagement";
import CENTRE_NOTIFICATION_I18N from "../../../const/CentreNotificationI18N";

const Row = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5)
}));

export default function CentreDataSettingsTab() {

    const { t } = useTranslation();
    const CENTRE_NOTIFICATIONS = useCentreManagementState().centreNotifications;
    const CENTRE_NOTI_REQUEST = useCentreManagementState().request;
    const [selected, setSelected] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const { centreId } = useParams();

    const notificationTemplateMap = React.useRef(new Map());
    const notificationTitleMap = React.useRef(new Map());

    const editNotificationTemplateFormMethod = useForm({
        resolver: yupResolver(createCentreNotificationFormSchema(Object.keys(CENTRE_NOTIFICATION_I18N.systemLanguages)))
    });


    const editNotificationTemplate = (data) => {
        const requestBody = { centreId: centreId, notificationType: selected };
        const currentTemplate = CENTRE_NOTIFICATIONS.template[selected].language
        Object.keys(CENTRE_NOTIFICATION_I18N.systemLanguages).forEach((language) => {
            currentTemplate[language].content = data[`${language}_content`]
            currentTemplate[language].title = data[`${language}_title`]
        })
        requestBody[selected] = currentTemplate
        updateCentreNotification(requestBody);
        setIsEditDialogOpen(false);
    };

    const triggerEditNotificationTemplate = (event) => {
        event.preventDefault();
        editNotificationTemplateFormMethod.reset({...notificationTemplateMap.current.get(selected), ...notificationTitleMap.current.get(selected)});
        setIsEditDialogOpen(true);
        setAnchorEl(null);
    };

    const handleCloseEditDialog = () => {
        setIsEditDialogOpen(false);
    }

    const triggerContextualMenu = (event, value) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelected(value);
    };

    const debouncedGetCentreNotification = React.useMemo(() => debounce(getCentreNotification, 50), []);
    const debouncedAddCentreNotification = React.useMemo(() => debounce(addCentreNotification, 50), []);
    
    React.useEffect(() => {
        debouncedGetCentreNotification(centreId);
    }, []);

    React.useEffect(() => {
        if (CENTRE_NOTI_REQUEST.success && !CENTRE_NOTIFICATIONS) {
            debouncedAddCentreNotification(centreId);
        }

        const notificationTemplate = CENTRE_NOTIFICATIONS?.template;
        if (notificationTemplate) {
            setLoaded(true);
            Object.keys(notificationTemplate).forEach((notificationType) => {
                const languageTemplates = notificationTemplate[notificationType].language;
                const languageTemplatesContent = {};
                const languageTemplatesTitle = {};
                Object.keys(languageTemplates).forEach((language) => {
                    languageTemplatesContent[`${language}_content`] = languageTemplates[language].content;
                    languageTemplatesTitle[`${language}_title`] = languageTemplates[language].title;
                })
                notificationTemplateMap.current.set(notificationType, languageTemplatesContent)
                notificationTitleMap.current.set(notificationType, languageTemplatesTitle)
            })
        }
    }, [CENTRE_NOTIFICATIONS]);

    return (
        <TabTitleContainer>
            <Grid container>
                <SpaceHolder container item xs={0.5} />
                <Grid container item xs={11}>
                    <Row alignItems="center" justifyContent="space-between" container item xs={12}>
                        <Typography sx={{ fontWeight: "bold" }} variant="h6" component="div">
                            {t("centre:section_title-notification_messages")}
                        </Typography>
                    </Row>
                    <Grid sx={{ marginBottom: 2 }} container>
                        <List sx={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
                            {loaded && Object.keys(CENTRE_NOTIFICATION_I18N.notifications).map((value) => (
                                <ListItem
                                    key={value}
                                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                                    secondaryAction={
                                        <IconButton
                                            sx={{ position: "absolute", top: "-20px", "right": "-15px" }}
                                            onClick={(event) => {
                                                triggerContextualMenu(event, value)
                                            }} aria-label="comment">
                                            <MoreVertIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText sx={{ maxWidth: "85%" }} primary={t(CENTRE_NOTIFICATION_I18N.notifications[value])} />
                                </ListItem>
                            ))}
                        </List>
                        <ContextualMenu
                            id="notification-menu"
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                        >
                            <ContextualMenuItem title={t("general:button-edit")} onClick={triggerEditNotificationTemplate}>
                                <EditIcon sx={{ mr: 0 }} fontSize="small" />
                            </ContextualMenuItem>
                        </ContextualMenu>
                    </Grid>
                </Grid>
                <SpaceHolder container item xs={0.5} />
            </Grid>
            <CentreNotificationDialog
                title={t(CENTRE_NOTIFICATION_I18N.notifications[selected])} submitMethod={editNotificationTemplate}
                isDialogOpen={isEditDialogOpen} handleCloseDialog={handleCloseEditDialog}
                formMethod={editNotificationTemplateFormMethod} >
            </CentreNotificationDialog>
        </TabTitleContainer>
    );
}
