import axios from './axiosModules';
import UpdateResultResponse from "./interface/UpdateResultResponse";

export async function getPlansRequest(): Promise<UpdateResultResponse> {
	const url = `/plan/plans`;

	try {
		const {
			data
		} = await axios.get<UpdateResultResponse>(url, {});
		return data;
	} catch (err) {
		throw err
	}
}

export async function addPlanRequest(plan: object): Promise<UpdateResultResponse> {
	const url = "/plan/new_plan";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, plan);
		return data;
	} catch (err) {
		throw err
	}
}

export async function updatePlanRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/plan/save_plan";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}

export async function deletePlanRequest(body: object): Promise<UpdateResultResponse> {
	const url = "/plan/delete_plan";
	try {
		const {
			data
		} = await axios.post<UpdateResultResponse>(url, body);
		return data;
	} catch (err) {
		throw err
	}
}
