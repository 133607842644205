function isRequestSuccessWithStack(request) {
	return (request.status === "DISPLAY_SUCCESS_MESSAGE")
}

function isRequestFailWithStack(request) {
	return (request.status === "DISPLAY_ERROR_MESSAGE")
}

export {
	isRequestSuccessWithStack,
	isRequestFailWithStack
}