import React from 'react';
import { useTranslation } from "react-i18next";
import {
    CardActionArea, Card, CardContent, Grid,
    Stack, IconButton
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import LimitedTextLabel from "./../../../component/display/LimitedTextLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import { SpaceHolder } from "./../../common/Others";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useClassSessionManagementState } from "./../../../redux/ClassSessionManagement";
import { nameInBracketStyle } from "./../../../util/DisplayUtil";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


export default function SessionCard({ currentSession, index, cardOnClick, moreButtonOnClick }) {

    const TUTOR_NAME_MAP = useClassSessionManagementState().tutorNameMap || {};
    let { t } = useTranslation();
    let theme = useTheme();
    let smDown = useMediaQuery(theme.breakpoints.down('sm'));
    let displayNameStyle = { fontWeight: "bold", paddingLeft: 0.5 };
    let cardContentStyle = { paddingLeft: 0 };
    let contentVariant = "body2";
    if (smDown) {
        cardContentStyle = { width: "100%", paddingLeft: 0 };
        displayNameStyle = { paddingLeft: 0.5 }
        contentVariant = "caption";
    }

    return (
        <React.Fragment>
            <Card sx={{ display: { md: "block" }, width: "100%", marginTop: 0, marginBottom: 2, borderRadius: '10px' }}>
                <CardActionArea component="span" disableTouchRipple data-cy={"edit_tutor_btn_" + (index + 1)} onClick={(event) => { cardOnClick.call(this, event, currentSession) }}>
                    <CardContent sx={cardContentStyle} >
                        <Grid container >
                            <SpaceHolder container item xs={0.5} />
                            <Grid sx={{ paddingBottom: 2 }} container item xs={11}>
                                <Stack sx={{ width: "100%" }} justifyContent="center" alignItems="flex-start" spacing={1}>
                                    <Stack sx={{ width: "100%" }} justifyContent="space-between" alignItems="center" spacing={0} direction="row">
                                        <LimitedTextLabel component="div" customstyle={displayNameStyle} variant="h6">
                                            {t("session:session_name", { date: moment(currentSession.date).format('ll') })}
                                        </LimitedTextLabel>
                                        {moreButtonOnClick ?
                                            <IconButton onClick={(event) => {
                                                event.stopPropagation();
                                                moreButtonOnClick.call(this, event, currentSession);
                                            }}>
                                                <MoreHorizIcon />
                                            </IconButton>
                                            : null}

                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <ScheduleIcon color="disabled" fontSize="small" />
                                        <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                            {moment(currentSession.startTime).format('hh:mm A') + t("general:time-separator") + moment(currentSession.endTime).format('hh:mm A')}
                                        </LimitedTextLabel>
                                    </Stack>
                                    {TUTOR_NAME_MAP[currentSession.tutors[0]]?
                                        <Stack direction="row" spacing={1}>
                                            <SupportAgentIcon color="disabled" fontSize="small" />
                                            <LimitedTextLabel variant={contentVariant} color="text.secondary" component="div">
                                                {nameInBracketStyle(TUTOR_NAME_MAP[currentSession.tutors[0]].chiName,TUTOR_NAME_MAP[currentSession.tutors[0]].engName)}
                                            </LimitedTextLabel>
                                        </Stack>
                                        :null
                                    }
                                </Stack>
                            </Grid>
                            <SpaceHolder container item xs={0.5} />
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}