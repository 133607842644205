import React from "react";
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {Button, Stack, Box } from "@mui/material";
import stripePoweredImage from "./../../../asset/bill/PoweredByStripe.png";



export default function CheckoutForm({submitCallbackSuccess, submitCallbackFail}){
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

     let paymentResult = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://itutorhk.com/',
      },
      redirect: 'if_required' 
    });

    if (!paymentResult.paymentIntent || paymentResult.error) {
      let confirmError = paymentResult.error;
      console.log(confirmError);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      //need add a decent message for general error.
      setErrorMessage("general:error-unknown");
      if(submitCallbackFail){
        submitCallbackFail();
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      //updated the bill
      //get the refresh data issue
      if(submitCallbackSuccess){
        submitCallbackSuccess();
      }

      //close dialog
    }
  };

  return (
    <form sx={{width:"100%"}} onSubmit={handleSubmit}>
      <Stack sx={{padding:2}} spacing={2}>
        <PaymentElement />
        <Box  component="img" alt={"powered by Stripe"} src={stripePoweredImage} 
        sx={{width:"150px"}}/>
        <Button type="submit" disabled={!stripe} variant="contained">Submit</Button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </Stack>
    </form>
    
  )
  
}
