import * as YUP from "yup";

function createTutorFormSchema(genderValidValue, mainLanguageValidValue, educationLevelValidValue, subjectsValidValue) {
  return YUP.object().shape({
    chiName: YUP.string(),
    engName: YUP.string().required("general:validation-mandatory"),
    gender: YUP.string().oneOf(genderValidValue).nullable().required("general:validation-mandatory"),
    birthday: YUP.date().max(new Date(), "general:validation-date").nullable().typeError("general:validation-date"),
    phone: YUP.string().matches(/^\+?[0-9]{7,15}$/, "general:validation-phone").required("general:validation-mandatory"),
    nationality: YUP.string(),
    mainLanguage: YUP.array().of(YUP.string().oneOf(mainLanguageValidValue)).required("general:validation-mandatory").min(1, "general:validation-mandatory"),
    educationBackground: YUP.string().required("general:validation-mandatory"),
    educationLevel: YUP.string().oneOf(educationLevelValidValue).nullable().required("general:validation-mandatory"),
    subject: YUP.array().of(YUP.string().oneOf(subjectsValidValue)).required("general:validation-mandatory").min(1, "general:validation-mandatory"),
    remarks: YUP.string(),
  });
}

const INITIAL_TUTOR_FORM = {
  chiName: "",
  engName: "",
  gender: "",
  birthday: null,
  phone: "",
  mainLanguage: [],
  nationality: "",
  educationBackground: "",
  educationLevel: "",
  subject: [],
  remarks: ""
};

export {
  createTutorFormSchema,
  INITIAL_TUTOR_FORM
};